export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  // Format hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  // Format the day and month
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });

  return `${formattedHours}.${minutes} ${period}, ${day} ${month}`;
}

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });

  return `${day} ${month}, ${year}`;
}
