import React from "react";
import AppRouter from "./routes/AppRouter";
import "./App.css";
import { WebSocketProvider } from "./utils/context/WebsocketContext";
import Loader from "./components/common/Loader";

function App() {
  return (
    <div className="min-h-screen text-gray-800">
      <WebSocketProvider>
        <AppRouter />
        <Loader />
      </WebSocketProvider>
    </div>
  );
}

export default App;
