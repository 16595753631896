import React, { useEffect, useRef, useState } from "react";
import Properties from "../common/Properties";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedProperties, getMLSPropertyTypes } from "../../redux/slices/propertySlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const FeaturedProperties = () => {
  const dispatch = useDispatch();

  const { featuredProperties, MLSPropertyTypes } = useSelector((state) => state.properties);

  const scrollableDivRef = useRef(null);

  const [selectedType, setSelectedType] = useState("All");

  const scrollAmount = 100;

  const scrollLeft = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    dispatch(getMLSPropertyTypes());
  }, [dispatch]);

  useEffect(() => {
    if (selectedType) dispatch(getFeaturedProperties(selectedType));
  }, [dispatch, selectedType]);

  return (
    <div className="w-full mt-4 py-10 max-w-screen-xl mx-auto my-10 px-4">
      <div className="text-center mb-6">
        <h2 className="text-4xl font-semibold text-gray-900 mb-2">
          Featured Properties
        </h2>
        <p className="text-gray-600 text-base">
          Explore all the different types of properties so you can choose the
          best option for you.
        </p>
      </div>

      {
        MLSPropertyTypes && MLSPropertyTypes.length > 0 && (
          <div className="flex items-center">
            <button
              className="text-gray-400 px-3 pb-4 text-2xl"
              onClick={scrollLeft}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>

            <div
              ref={scrollableDivRef}
              className="w-full flex pb-4 px-2 overflow-x-scroll scroll-smooth"
            >
              <div className="mx-auto inline-flex gap-3">
                {MLSPropertyTypes.map((type, i) => (
                  <button
                    key={`filter-button-${i + 1}`}
                    className={`whitespace-nowrap rounded-full border shadow px-4 py-2 ${selectedType === type ? "border-[8E9C9C] bg-[#8E9C9C] text-white" : "border-gray-200 bg-white text-gray-700"}`}
                    onClick={() => setSelectedType(type)}
                  >
                    {type}
                  </button>
                ))}
              </div>
            </div>

            <button
              className="text-gray-400 px-3 pb-4 text-2xl"
              onClick={scrollRight}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        )
      }

      <Properties properties={featuredProperties} isMyProperty={false} />
    </div>
  );
};
