import React, { useEffect, useState } from 'react';
import { AngularVector, AreaStudioLogo, UploadIcon, RedElipse, CrossIcon, TopRightArrow, DownLoadImageIcon } from '../../assets/icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';
import { logout } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { fetchDraftProperty, generateAIText, saveAIGeneratedDraft } from '../../redux/slices/propertySlice';

const ListingEnhancement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { error, user } = useSelector((state) => state.auth);
  const { draft_property, error: property_error } = useSelector((state) => state.properties);

  const [images, setImages] = useState([]);
  const [enhancedImages, setEnhancedImages] = useState([]);
  const [showVideoContent, setShowVideoContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [savingAsDraft, setSavingAsDraft] = useState(false)
  const [textGenerated, setTextGenerated] = useState(false);

  const [initialDetails, setInitialDetails] = useState('');

  const [initialOverview, setInitialOverview] = useState('');

  useEffect(() => {
    if (draft_property) {
      setInitialDetails(draft_property?.LegalDetails || '')
      setInitialOverview(draft_property?.LotFeatureList || '')
    }
  }, [draft_property])

  useEffect(() => {
    dispatch(fetchDraftProperty())
  }, [dispatch])

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map(file => ({
      original: URL.createObjectURL(file),
      file: file
    }));

    setImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleImageRemove = (image) => {
    setImages(prevImages => prevImages.filter(img => img.original !== image.original));
  };

  const handleEnhancedImageRemove = (image) => {
    setEnhancedImages(prevImages => prevImages.filter(img => img.original !== image.original));
  };

  const handleGenerateDescription = () => {
    if (initialDetails.trim() === '' || initialOverview.trim() === '') {
      showAllNotifications('Details can not be blank', ToastColors.error)
      return;
    }
    if (initialDetails.trim().length < 5 || initialOverview.trim().length < 5) {
      showAllNotifications('Details should be atleast 5 characters long', ToastColors.error)
      return;
    }

    setIsLoading(true);

    const data = {
      property_details: initialDetails,
      property_overview: initialOverview,
    }

    dispatch(generateAIText(data)).unwrap()
      .then((response) => {
        setIsLoading(false);
        setTextGenerated(true)
        showAllNotifications("Text has been generated successfully", ToastColors.success);
        setInitialDetails(response.data.property_overview)
        setInitialOverview(response.data.property_details)
      })
      .catch((error) => {
        console.error('Failed to generate text', error);
        if (property_error?.detail === "Authentication credentials were not provided.") {
          showAllNotifications('Please login to continue', ToastColors.error);
          dispatch(logout());
          navigate('/login');
          setIsLoading(false);
        }
      });
  }

  const handleSaveTextAsDraft = () => {
    if (initialDetails.trim() === '' || initialOverview.trim() === '') {
      showAllNotifications('Details can not be blank', ToastColors.error)
      return;
    }
    if (initialDetails.trim().length < 5 || initialOverview.trim().length < 5) {
      showAllNotifications('Details should be atleast 5 characters long', ToastColors.error)
      return;
    }

    setSavingAsDraft(true);

    const data = {
      LegalDetails: initialDetails,
      LotFeatureList: initialOverview,
    }

    dispatch(saveAIGeneratedDraft({ data, type: 'text' })).unwrap()
      .then((response) => {
        setSavingAsDraft(false);
        setTextGenerated(false)
        showAllNotifications(response.message, ToastColors.success);
        setInitialDetails(response.property.LegalDetails)
        setInitialOverview(response.property.LotFeatureList)
      })
      .catch((error) => {
        setSavingAsDraft(false);
        console.error('Failed to generate text', error);
        if (error?.code === 'token_not_valid') {
          showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
          navigate('/login');
        }
        if (property_error?.detail === "Authentication credentials were not provided.") {
          showAllNotifications('Please login to continue', ToastColors.error);
          dispatch(logout());
        }
      });
  }

  const handleSaveImageAsDraft = () => {
    setSavingAsDraft(true);

    let public_urls = []
    enhancedImages.forEach((image) => {
      public_urls.push(image.original)
    })

    const data = {
      images: public_urls
    }


    dispatch(saveAIGeneratedDraft({ data, type: 'images' })).unwrap()
      .then((response) => {
        setSavingAsDraft(false);
        setTextGenerated(false)
        showAllNotifications(response.message, ToastColors.success);

      })
      .catch((error) => {
        setSavingAsDraft(false);
        console.error('Failed to save enhanced images', error);
        if (error?.code === 'token_not_valid') {
          showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
          navigate('/login');
        }
        if (property_error?.detail === "Authentication credentials were not provided.") {
          showAllNotifications('Please login to continue', ToastColors.error);
          dispatch(logout());
        }
      });
  }

  const handleSaveAsDraft = () => {
    if (!user) {
      showAllNotifications('You must be signed in to use this feature!', ToastColors.error)
      return;
    }

    if (!textGenerated && (enhancedImages.length === 0)) {
      showAllNotifications('No changes were made to be saved')
      return;
    }

    if (textGenerated) {
      handleSaveTextAsDraft();
    }

    if (enhancedImages.length > 0) {
      handleSaveImageAsDraft();
    }
  };

  const handleImageEnhancement = async () => {
    const enhancedImageUrls = [];

    showAllNotifications('Please wait while we enhance your images')

    for (const img of images) {
      const formData = new FormData();
      formData.append('file', img.file);

      const operations = {
        "operations": {
          "restorations": {
            "upscale": "smart_enhance"
          }
        },
        "output": {
          "format": {
            "type": "jpeg",
            "quality": 80,
            "progressive": false
          }
        }
      };

      formData.append('data', JSON.stringify(operations));

      try {
        const URL = 'https://api.claid.ai/v1-beta1/image/edit/upload/'
        setIsGeneratingImages(true)

        const response = await fetch(URL, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_IMAGE_ENHANCEMENT_API_KEY}`
          },
          body: formData
        });

        if (response.ok) {
          const result = await response.json();
          const enhancedImageUrl = result.data.output.tmp_url;
          enhancedImageUrls.push({
            original: enhancedImageUrl,
            file: img.file
          });
        } else {
          console.error('Failed to enhance image:', response);
          showAllNotifications('There was a error in enhancing your images!', ToastColors.error)
          setIsGeneratingImages(false)
          return
        }
      } catch (error) {
        console.error('Error enhancing image:', error);
        showAllNotifications('There was a error in enhancing your images!', ToastColors.error)
        setIsGeneratingImages(false)
      }
    }

    showAllNotifications('Your images have been enhanced successfully!', ToastColors.success)
    setIsGeneratingImages(false)
    setEnhancedImages(enhancedImageUrls)
  }

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url, {
        mode: 'cors',
      });
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = url.split('/').pop(); // Sets the filename as the last part of the URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed", error);
    }
  };


  if (error) {
    console.error(error)
    if (error?.code === 'token_not_valid') {
      showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
      dispatch(logout());
      navigate('/login');
    }
  }

  return (
    // Outer container
    <div className="mx-auto font-poppins max-w-screen-xl pt-4">
      {/* Listing enhancement section */}
      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between py-8">
        {/* Left Column */}
        <div className="flex flex-col items-center lg:items-start lg:w-1/2 mt-4">
          <h2 className="text-4xl font-bold leading-tight text-center lg:text-left">
            Listing Enhancement
          </h2>
          <p className="text-[#414141] text-base flex items-center space-x-2 justify-center lg:justify-start">
            <span className="text-[#414141] font-normal whitespace-nowrap">Area</span>
            <img src={AngularVector} alt="Location Icon" className="w-4 h-4" />
            <span className="font-normal whitespace-nowrap">Area Studio</span>
            <img src={AngularVector} alt="Location Icon" className="w-4 h-4" />
            <span className="font-normal whitespace-nowrap">Listing Enhancement</span>
          </p>
        </div>
        {/* Right Column */}
        <div className="flex items-center lg:items-start lg:w-1/2 justify-center lg:justify-end mt-2 lg:mt-4">
          <img
            src={AreaStudioLogo}
            alt="AreA"
            width="169"
            height="101"
            className="object-cover"
          />
        </div>
      </div>

      {/* Upload Photo section */}
      <div className="w-full flex flex-col items-center py-8">
        <div className="w-full lg:w-[90%] flex flex-col lg:flex-row items-center lg:items-start">
          {/* Text and Description */}
          <div className="flex flex-col text-center lg:text-left lg:w-1/2 lg:pr-4 mt-5">
            <h3 className="text-[45px] font-grotesk font-bold leading-[54px]">Photos Upload</h3>
            <p className="text-[#666666] mt-2 font-normal text-[18px] leading-[28px]">
              Upload your images and enhance those images to reflect in your properties listing page.
            </p>
          </div>
          {/* Upload Section */}
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-end mt-4 me-1 lg:mt-0">
            <div className="bg-white shadow-[0px_0px_15px_0px_#00000040] rounded-xl p-8 text-center">
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="hidden"
                id="upload"
              />
              <label
                htmlFor="upload"
                className="cursor-pointer bg-[#8E9C9C] text-white py-2 px-4 rounded-full inline-flex items-center font-poppins text-[22px] font-bold"
              >
                <img src={UploadIcon} alt="Upload Icon" className="mr-2" />
                Upload Image
              </label>
              <p className="mt-4 font-poppins text-[12px] font-normal text-[#666666]">
                or drop a file here
              </p>
              <p className="font-poppins text-[12px] font-normal text-[#666666]">
                CTRL+V to paste image or URL
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Uploaded Images */}
      <div className="w-full flex flex-wrap gap-4 mt-8">
        {images.map((image, index) => (
          <div key={index} className="relative w-[120px] h-[145px]">
            <img src={image.original} alt={`Uploaded ${index}`} className="w-full h-full object-cover rounded-lg" />
            <div className="absolute top-[-10px] right-[-10px] flex items-center justify-center w-[30px] h-[30px]">
              <img src={RedElipse} alt="Red Ellipse" className="absolute w-full h-full" />
              <button
                onClick={() => handleImageRemove(image)}
                className="relative z-10 rounded-full p-1"
              >
                <img src={CrossIcon} alt="Remove Icon" className="w-3 h-3" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {(images.length !== 0) &&
        <div className="w-full flex flex-wrap gap-4 mt-8">
          {/* First Button */}
          <button
            disabled={isGeneratingImages}
            className="bg-[#8E9C9C] text-white py-3 px-4 rounded-full inline-flex items-center font-normal">
            <img src={TopRightArrow} alt="First Icon" className="mr-2" /> {/* Replace with your icon */}
            Export to Property Listing page
          </button>

          {/* Second Button */}
          <button
            disabled={isGeneratingImages}
            onClick={handleImageEnhancement}
            className="bg-white text-[#8E9C9C] py-3 px-4 rounded-full border border-[#8E9C9C] font-normal outline outline-1">
            {isGeneratingImages ? (
              <svg
                className="animate-spin h-5 w-5 text-gray-500 mr-3"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="white"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : 'Enhance the Images with AI'}
          </button>
        </div>
      }


      {/* Image Enhancer Section */}
      <div className="w-[80%] mx-auto text-center py-8">
        <h3 className="text-[45px] font-grotesk font-bold leading-[54px] text-center mb-4">Image Enhancer</h3>
        <p className="text-center text-[#666666] mt-2 font-poppins text-[18px] leading-[27px] mb-4">
          Upload your images and enhance those images to reflect in your<br />
          properties listing page.
        </p>
        <div className="flex justify-center">
          <iframe
            className="rounded-3xl mb-3"
            width="930"
            height="520"
            src="https://www.youtube.com/embed/S0fKURwyhdE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="flex flex-wrap justify-center gap-6 mt-10">
          {enhancedImages.map((image, index) => (
            <div key={index} className="relative w-[234.34px] h-[304px]">
              <img src={image.original} alt={`Uploaded ${index}`} className="w-full h-full object-cover rounded-lg" />

              {/* Top-Right Remove Icon */}
              <div className="absolute top-[-10px] right-[-10px] flex items-center justify-center w-[30px] h-[30px]">
                <img src={RedElipse} alt="Red Ellipse" className="absolute w-full h-full" />
                <button
                  onClick={() => handleEnhancedImageRemove(image)}
                  className="relative z-10 rounded-full p-1"
                >
                  <img src={CrossIcon} alt="Remove Icon" className="w-3 h-3" />
                </button>
              </div>

              {/* Bottom-Left Download Icon */}
              <div className="absolute bottom-2 left-2">
                <button
                  onClick={() => handleDownload(image.original)}
                  className="flex items-center justify-center p-1 rounded-full shadow"
                >
                  <img src={DownLoadImageIcon} alt="Download Icon" className="w-[32px] h-[32px]" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* AI Text Creator */}

      <div className="w-[80%] mx-auto text-center py-8">
        <div>
          <h4 className="text-[69px] font-grotesk font-normal leading-[77px]">
            AI Text Creator
          </h4>
        </div>
        <div className="max-w-full mx-auto mt-6">
          <p className="text-black font-poppins font-normal text-[18px] leading-[27px]">
            Generate compelling, data-driven text and descriptions, sourced from a wide range of public and internet <br /> databases, tailored to enrich content with accuracy and relevance.
          </p>
        </div>

        {/* Card Section */}
        <div className="w-[70%] mx-auto mt-10 bg-white shadow-[0px_0px_16px_0px_#00000040] p-6 rounded-lg relative pb-16">
          <div className="text-left mb-2 font-roboto">
            <p className="font-roboto text-[18px] font-bold leading-[28px] text-[#000000] text-left mb-1">
              Describe your property in one line
            </p>
            <p className="font-roboto text-[14px] font-normal leading-[20px] text-[#6B7280] text-left mb-2">
              Describe in a few words what your page is about
            </p>
            <input
              type="text"
              onChange={(e) => setInitialDetails(e.target.value)}
              value={initialDetails}
              placeholder="e.g keto diet for beginners"
              className="py-[14px] px-[12px] border-4 border-[#F3F4F6] rounded-[500px] w-full text-[#9CA3AF]"
            />
          </div>
          <div className="text-left mb-4 font-roboto">
            <p className="font-roboto text-[18px] font-bold leading-[28px] text-[#000000] text-left mb-1">
              Give overview of your property
            </p>
            <p className="font-roboto text-[14px] font-normal leading-[20px] text-[#6B7280] text-left mb-2">
              Add one or more keywords (separated by commas) that you would like to be included in the <br /> meta description
            </p>
            <textarea
              onChange={(e) => setInitialOverview(e.target.value)}
              value={initialOverview}
              placeholder="e.g proteins, plans"
              className="pt-[11px] pb-[30px] px-[20px] rounded-[25px] border-4 border-[#F3F4F6] w-full resize-none text-[#9CA3AF]"
            />
          </div>
          <button
            onClick={handleGenerateDescription}
            disabled={isLoading || isGeneratingImages}
            className="absolute bottom-4 right-4 bg-[#8E9C9C] text-white py-[10px] px-[23px] rounded-[500px] font-roboto text-[18px] font-normal leading-[28px] text-center"
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-white mr-3"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : textGenerated ? 'Re-Generate Descriptions' : 'Generate Descriptions'}
          </button>
        </div>
      </div>

      {/* Video Generator */}

      <div className="w-[70%] mx-auto text-center py-8">
        <div>
          <h4 className="text-[69px] font-grotesk font-normal leading-[77px]">
            Video Generator
          </h4>
        </div>
        <div className="max-w-full mx-auto mt-4 mb-4">
          <p className="text-black font-poppins font-normal text-[18px] leading-[27px]">
            Get an AI generated video based on the images or text you have generated through AI
          </p>
        </div>
        <div className="flex justify-center">
          <iframe
            className="rounded-3xl"
            width="850"
            height="520"
            src="https://www.youtube.com/embed/S0fKURwyhdE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        {/* Generate Video Button */}
        <div className="flex justify-center mt-8">
          <button onClick={() => setShowVideoContent(!showVideoContent)}
            className="bg-[#8E9C9C] text-white py-[10px] px-[23px] rounded-[500px] font-roboto text-[18px] font-normal leading-[28px] text-center">
            Generate Video
          </button>
        </div>

        {showVideoContent &&
          <>
            <div className="max-w-full mx-auto mt-8">
              <p className="text-black font-poppins font-normal text-[18px] leading-[27px]">
                Here is the video based on your images and description
              </p>
            </div>

            <div className="flex justify-center mt-8">
              <iframe
                className="rounded-3xl"
                width="850"
                height="520"
                src="https://www.youtube.com/embed/S0fKURwyhdE"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </>
        }
      </div>

      <div className="flex justify-center items-center gap-5 mb-5 mt-5">
        <button
          onClick={handleSaveAsDraft}
          disabled={savingAsDraft || isLoading || isGeneratingImages}
          className="px-[31px] py-[11px] bg-white text-[#8E9C9C] font-poppins text-[16px] font-normal rounded-[500px] border border-[#8E9C9C] outline outline-1">
          {savingAsDraft ? (
            <svg
              className="animate-spin h-5 w-5 text-gray-500 mr-3"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="white"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : 'Save As Draft'}
        </button>
        <button
          disabled={savingAsDraft || isLoading || isGeneratingImages}
          className="bg-[#8E9C9C] text-white py-[10px] px-[27px] font-roboto text-[18px] leading-[28px] font-normal rounded-[500px]">
          Post All Data on Ad Creation Page
        </button>
      </div>
    </div>
  );
};

export default ListingEnhancement;
