import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { STATUSES } from '../constants/statuses';
import AdminSidebar from "../pages/Admin/Sidebar/Sidebar";
import AdminNavbar from "../pages/Admin/Navbar/Navbar";

const AdminRoute = ({ component: Component }) => {
    const { isAuthenticated, status, user } = useSelector((state) => state.auth);

    if (status === STATUSES.LOADING) return null;

    if (!isAuthenticated || !user) return <Navigate to="/login" />;

    return user.roles.includes("admin") ? (
        <div className="w-full flex h-[100vh]">
            <AdminSidebar />
            <div className="h-[100vh] flex flex-col flex-grow overflow-y-scroll">
                <AdminNavbar />
                <div className="w-full flex-grow p-8">
                    <Component />
                </div>
            </div>
        </div>
    ) : <Navigate to="/" />;
};

export default AdminRoute;
