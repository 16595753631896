import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { googleAuth } from '../../redux/slices/authSlice';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';

const GoogleLoginButton = () => {
  const { error } = useSelector((state) => state.auth);

  const dispatch = useDispatch()

  const handleSuccess = async (response) => {
    const { credential } = response;

    dispatch(googleAuth(credential))
  };

  if (error) {
    showAllNotifications(error, ToastColors.error)
  }

  return (
    <GoogleLogin
      onSuccess={handleSuccess}
      onFailure={() => console.error('Google login failed')}
    />
  );
};

export default GoogleLoginButton;