import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AngularVector,
} from "src/assets/icons/icons";
import SearchBar from "../../components/Homepage/Searchbar";
// import { Formik, Form, Field } from 'formik';
// import { FiSearch } from 'react-icons/fi';
import PropertyCard from '../../components/common/PropertyCard';
import { useDispatch, useSelector } from 'react-redux';
import { searchProperties } from '../../redux/slices/propertySlice';

const LIMIT = 12;

const SearchAndBuy = () => {
  const dispatch = useDispatch();

  const { filteredProperties, filter, count } = useSelector((state) => state.properties);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (filteredProperties.length === 0) {
      dispatch(searchProperties({ limit: 12, offset: 0 }));
    }
  }, [dispatch, filteredProperties]);

  useEffect(() => {
    if (offset !== 0) {
      dispatch(searchProperties({ filter, limit: LIMIT, offset }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, offset]);

  const loadMoreProperties = () => {
    setOffset((prevOffset) => prevOffset + LIMIT);
  };

  return (
    <div className="w-full py-10 max-w-screen-xl mx-auto my-10 px-4">
      <div className="text-left py-7">
        <h2 className="text-4xl font-semibold text-gray-900 mb-2 font-poppins">
          Buy
        </h2>
        <p className="text-gray-600 text-base flex items-center space-x-2">
          <Link to="/" className="text-gray-600 no-underline hover:underline font-poppins">Home</Link>
          <img src={AngularVector} alt="Location Icon" />
          <span className="font-poppins">Buy</span>
        </p>
      </div>

      <SearchBar className="relative w-full pb-10" bgColor="bg-[#F4F5F5]" />

      <div className="font-medium pb-6">
        {count} Properties Available
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {filteredProperties && filteredProperties.map((property) => (
          <PropertyCard property={property} key={`property-${property.PropertyID}`} />
        ))}
      </div>

      <div className="flex justify-center my-6 mt-20">
        {(filteredProperties.length < count) && <button
          onClick={loadMoreProperties}
          className="bg-white text-gray-400 border border-gray-300 font-bold py-3 px-12 rounded-full shadow focus:outline-none focus:shadow-outline font-jost text-lg"
        >
          Load More
        </button>
        }
      </div>
    </div>
  );
};

export default SearchAndBuy;