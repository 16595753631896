import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AngularVector, AreaIcon, BathIcon, BedIcon, Favorite, Listing, LocationIcon, PendingInvoices, PropertyArrows, PropertyFavorite, PropertySearch, SoldProperties } from "../../assets/icons/icons";
import "./dashboard.css";
import { DefaultProfilePicture, HousePlacholder } from "../../assets/images/images";
import { favoriteProperty, getDashboardData, myProperties } from "../../redux/slices/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { formatPrice } from "../../utils/price_formatter";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dashboardData } = useSelector((state) => state.properties);

  useEffect(() => {
    dispatch(getDashboardData());
    dispatch(myProperties());
  }, [dispatch]);

  const handlePropertyClick = (id) => {
    navigate(`/properties/${id}`);
  };

  const getPropertyImage = (property) => {
    const { PhotoURLPrefix, PhotoKey, PhotosCount } = property;

    if (PhotosCount > 0)
      return `${PhotoURLPrefix}${PhotoKey}/photo_1.jpg` // return first image

    return HousePlacholder
  }

  return (
    <div className="w-full mt-4 py-10 max-w-screen-xl mx-auto my-10 px-4">
      <div className="text-left mb-12">
        <h2 className="text-4xl font-semibold text-gray-900 mb-2 font-poppins">
          Dashboard
        </h2>
        <p className="text-gray-600 text-base flex items-center space-x-2">
          <Link to="/" className="text-gray-600 no-underline hover:underline font-poppins">Home</Link>
          <img src={AngularVector} alt="Location Icon" />
          <span className="font-poppins">Dashboard</span>
        </p>
      </div>

      <div className="w-full flex gap-6 mb-12">
        <div className="w-full flex border-1 border-[#8E8E93] rounded-2xl p-4 bg-[#8E9C9C26]">
          <div>
            <div className="text-sm font-medium text-[#797979] pb-4">Your Listing</div>
            <div className="text-3xl font-['Nunito']">{dashboardData?.properties_count}</div>
          </div>
          <div className="ms-auto">
            <img src={Listing} alt="listing" />
          </div>
        </div>
        <div className="w-full flex border-1 border-[#8E8E93] rounded-2xl p-4 bg-[#8E9C9C26]">
          <div>
            <div className="text-sm font-medium text-[#797979] pb-4">Sold Properties</div>
            <div className="text-3xl font-['Nunito']">{dashboardData?.sold_properties_count}</div>
          </div>
          <div className="ms-auto">
            <img src={SoldProperties} alt="SoldProperties" />
          </div>
        </div>
        <div className="w-full flex border-1 border-[#8E8E93] rounded-2xl p-4 bg-[#8E9C9C26]">
          <div>
            <div className="text-sm font-medium text-[#797979] pb-4">Pending Invoices</div>
            <div className="text-3xl font-['Nunito']">20</div>
          </div>
          <div className="ms-auto">
            <img src={PendingInvoices} alt="PendingInvoices" />
          </div>
        </div>
        <div className="w-full flex border-1 border-[#8E8E93] rounded-2xl p-4 bg-[#8E9C9C26]">
          <div>
            <div className="text-sm font-medium text-[#797979] pb-4">Favorites</div>
            <div className="text-3xl font-['Nunito']">{dashboardData?.favorite_properties_count}</div>
          </div>
          <div className="ms-auto">
            <img src={Favorite} alt="Favorite" />
          </div>
        </div>
      </div>

      <div className="w-full mb-6">
        <div className="font-bold text-2xl mb-2">Active Listing</div>
        <div className="w-full overflow-x-scroll flex flex-cols gap-5 py-4 scrollbar-hidden">
          {dashboardData && dashboardData?.active_properties.map((property, index) => (
            <div
              key={`property-${property.LegalDetails}-${index}`}
              className="bg-white rounded-xl border border-gray-200 shadow-md p-4 cursor-pointer w-96"
              onClick={() => handlePropertyClick(property.PropertyID)}
            >
              <div className="relative">
                <img
                  src={property?.image || HousePlacholder}
                  alt="Property"
                  className="w-full h-64 object-cover rounded-lg mb-4"
                />
                {/* <div className="absolute top-2 left-2 bg-gray-600 text-white text-xs rounded px-2 py-1">
                  Active
                </div> */}
                <div className="absolute bottom-2 left-2 bg-white text-gray-900 text-lg font-semibold px-2 py-1">
                  ${property.OriginalListingPrice}
                </div>
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {property.LegalDetails}
              </h3>
              <p className="text-gray-700 text-sm mb-2">
                <img
                  src={LocationIcon}
                  alt="Location"
                  className="inline w-4 h-4 mr-2"
                />
                {property.MLSListingAddress}
              </p>
              <div className="flex justify-between text-gray-700 text-sm">
                <div>
                  <img src={BedIcon} alt="Beds" className="inline w-4 h-4 mr-2" />
                  {property.BedroomsTotal} Beds
                </div>
                <div>
                  <img
                    src={BathIcon}
                    alt="Baths"
                    className="inline w-4 h-4 mr-2"
                  />
                  {property.BathroomsFull} Baths
                </div>
                <div>
                  <img
                    src={AreaIcon}
                    alt="Area"
                    className="inline w-4 h-4 mr-2"
                  />
                  {property.LotSizeSquareFeet} SqFt
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full mb-6">
        <div className="font-bold text-2xl mb-2">Favorite Listing</div>
        <div className="w-full overflow-x-scroll flex flex-cols gap-5 py-4 scrollbar-hidden">
          {dashboardData && dashboardData?.favorite_properties.map((property) => (
            <div
              key={property.PropertyID}
              className="bg-white rounded-xl border border-gray-200 shadow-md p-4 cursor-pointer min-w-96"
              onClick={() => handlePropertyClick(property.PropertyID)}
            >
              <div className="relative">
                <img
                  src={getPropertyImage(property)}
                  alt="Property"
                  className="w-full h-64 object-cover rounded-lg mb-4"
                />
                <div className="absolute top-2 left-2 bg-gray-600 text-white text-xs rounded px-2 py-1">
                  Featured
                </div>
                <div className="absolute bottom-2 left-2 bg-white text-gray-900 text-lg font-semibold px-2 py-1">
                  {formatPrice(property.OriginalListingPrice)}
                </div>
                <div className="absolute bottom-2 right-2 flex space-x-2">
                  <img src={PropertyArrows} alt="Icons" className="h-9" />
                  <img src={PropertyFavorite} alt="Icons" className="h-9" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(favoriteProperty(property.PropertyID));
                  }} />
                  <img src={PropertySearch} alt="Icons" className="h-9" />
                </div>
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {property.address}
              </h3>
              <p className="text-gray-700 text-sm mb-2">
                <img
                  src={LocationIcon}
                  alt="Location"
                  className="inline w-4 h-4 mr-2"
                />
                {property.MLSListingAddress}
              </p>
              <div className="flex justify-between text-gray-700 text-sm mb-4">
                <div>
                  <img src={BedIcon} alt="Beds" className="inline w-4 h-4 mr-2" />
                  {property.BedroomsTotal} Beds
                </div>
                <div>
                  <img
                    src={BathIcon}
                    alt="Baths"
                    className="inline w-4 h-4 mr-2"
                  />
                  {property.BathroomsFull} Baths
                </div>
                <div>
                  <img
                    src={AreaIcon}
                    alt="Area"
                    className="inline w-4 h-4 mr-2"
                  />
                  {property.LotSizeSquareFeet} SqFt
                </div>
              </div>
              <div className="flex">
                <div className="flex items-center w-full">
                  <div
                    className="w-10 h-10 bg-cover rounded-full mr-2"
                    style={{ backgroundImage: `url(${property.userImage || DefaultProfilePicture})` }}
                  ></div>
                  <div className="flex flex-row w-full items-center">
                    <div className="text-gray-700 text-sm">
                      {property.userName}
                    </div>
                    <div className="text-gray-500 text-xs ml-auto">
                      {property.timeAgo}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
