import api from "../../../api/api";

const getUsers = (data) => {
  let url = `custom-admin/list-active-users/?days=${data.days}`;
  if (data.keyword) url += `&keyword=${data.keyword}`;
  if (data.limit) url += `&limit=${data.limit}`;
  if (data.offset) url += `&offset=${data.offset}`;
  return api.get(url);
};

const userService = {
  getUsers,
};

export default userService;
