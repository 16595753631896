import React from 'react';
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className='flex margin-top-9 m-[90px] justify-between '>
      <p className='text-Sgreen'>© Copyrights AreA | All Rights Reserved</p>
      <div className='flex  text-Sgreen'>
        <FaFacebookF className='size-6 mx-2'/>
        <FaTwitter  className='size-6 mx-2' />
        <FaSnapchatGhost className='size-6 mx-2' />
        <AiFillInstagram className='size-6 mx-2'/>
        <FaLinkedin className='size-6 mx-2'/>
      </div>
    </div>
  )
}

export default Footer