import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { AreaAIIcon, Dashboard, DefaultImg, Logout, Message, MyProfile, MyProperties, NoNotificationIcon, NotificationIcon } from "src/assets/icons/icons";
import { logout } from "../../redux/slices/authSlice";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import MareaModal from "./MareaModal";
import { openModal, setShowChooseAgentModal } from "../../redux/slices/mareaSlice";
import MareaImg from "../../assets/images/marea.png";
import MaxImg from "../../assets/images/Max.png";
import NotificationsModal from "../Notifications/NotificationsModal";
import { fetchAllNotifications } from "../../redux/slices/notificationSlice";
import { timeAgo } from "../../utils/time_ago";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import { setUnreadMessagesCount, setUserMessages } from "../../redux/slices/chatSlice";
import { useWebSocket } from "../../utils/context/WebsocketContext";
import ChooseAgentModal from "./ChooseAgentModal";

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation()

  const websocket = useWebSocket();

  const area_studio_page = pathname.includes('/area-studio');

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { data } = useSelector((state) => state.notifications);
  const { data: properties } = useSelector((state) => state.properties);
  const { userMessages, unreadMessagesCount } = useSelector((state) => state.chat);
  const { showChooseAgentModal } = useSelector((state) => state.marea);

  const [showHeader, setShowHeader] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuItemClick = (url) => {
    setShowMenu(false);
    navigate(url);
  };

  const countUnreadNotifications = () => {
    const unread = data.filter(notification => !notification.is_read).length;
    setUnreadCount(unread);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const handleInboxMessages = () => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify({ action: "inbox_view_messages" }));
    }
  };

  const handleMarkAllAsRead = () => {
    if (websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify({ action: "mark_all_as_read" }));
    }
  };

  const handleOpenModal = () => dispatch(user && !user?.selected_agent ? setShowChooseAgentModal(true) : openModal());

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (event) => {
        const response = JSON.parse(event.data).data;

        if (![200, 201].includes(response.status_code)) {
          showAllNotifications(response.error, ToastColors.error);
          return;
        }

        switch (response.action) {
          case "get_unread_messages_count":
            dispatch(setUnreadMessagesCount(response.data));
            break;
          case "inbox_view_messages":
            dispatch(setUserMessages(response.data));
            break;
          case "mark_all_as_read":
            if (response.data) {
              if (websocket && websocket.readyState === WebSocket.OPEN) {
                websocket.send(JSON.stringify({ action: "get_unread_messages_count" }));
                websocket.send(JSON.stringify({ action: "inbox_view_messages" }));
              }
            }
            break;
          case "send_message":
            if (websocket && websocket.readyState === WebSocket.OPEN) {
              websocket.send(JSON.stringify({ action: "get_unread_messages_count" }));
              websocket.send(JSON.stringify({ action: "inbox_view_messages" }));
            }
            break;
          default:
            console.warn("Unhandled action: ", response.action);
        }
      };
    }
  }, [dispatch, websocket]);

  useEffect(() => {
    if (data.length > 0) {
      countUnreadNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    document.documentElement.removeAttribute("style");
  }, [showMenu]);

  useEffect(() => {
    const handleScroll = () => {
      setShowHeader(window.scrollY <= window.innerHeight);
      setShowMenu(window.scrollY <= window.innerHeight);
    };

    const handleWindowResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchAllNotifications());
    }
  }, [dispatch, properties, isAuthenticated]);

  return (
    <>
      <Navbar
        bg="gray"
        expand="lg"
        fixed="top"
        className={`transition-transform duration-300 mx-auto mt-7 rounded-[45px] shadow-[0px_0px_10px_0px_#00000026] ${isCollapsed ? "bg-[#acbfbf94] !py-4" : "bg-[#acbfbf]"
          } border-[1px] border-solid max-w-screen-xl !border-[#8E9C9C] ${showHeader ? "translate-y-0" : "-translate-y-[7.25rem]"
          }`}
        style={{ zIndex: 50 }}
      >
        <Container className="relative w-full max-w-[76rem] md:!ms-4 py-1 sm:py-3 font-poppins">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="sm:!text-lg !text-sm"
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
          <Navbar.Brand className="mx-auto md:me-auto" as={Link} to="/">
            <img
              className="w-24 sm:w-[9.125rem]"
              alt="Group"
              src={AreaAIIcon}
            />
          </Navbar.Brand>
          {!isSmallScreen && (
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto flex">
                {!area_studio_page && (
                  <>
                    <Nav.Link
                      as={Link}
                      to={isAuthenticated ? "/search-n-buy" : "/buy"}
                      className="text-black no-underline"
                    >
                      Buy
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to={isAuthenticated ? "/properties/new" : "/sell"}
                      className="text-black no-underline"
                    >
                      Sell
                    </Nav.Link>
                  </>
                )}
                {area_studio_page ? (
                  <>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/reporting-dashboard"
                      className="text-black no-underline">
                      Reporting Dashboard
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/listing-enhancement"
                      className="text-black no-underline"
                    >
                      Listing Enhancement
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/ad-creation"
                      className="text-black no-underline"
                    >
                      Ad Creation
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/starter-kit"
                      className="text-black no-underline"
                    >
                      Starter Kit
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/home-store"
                      className="text-black no-underline"
                    >
                      AreA Home Store
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/listing-enhancement"
                      className="text-black no-underline"
                    >
                      Area Studio
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/contact-us"
                      className="text-black no-underline"
                    >
                      Contact Us
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/about-us"
                      className="text-black no-underline"
                    >
                      About Us
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          )}
          <div>
            {isAuthenticated ? (
              <div className="flex">
                {/* Notification Image Modal */}
                <Menu as="div" className="relative ml-3">
                  <div className="flex items-center h-full">
                    <MenuButton onClick={() => setShowNotificationModal(!showNotificationModal)}>
                      <img src={NotificationIcon} alt="Notification Badge" className="relative" />
                      {unreadCount > 0 && (
                        <div className="absolute top-[5px] -right-[4px] flex items-center justify-center px-1 min-w-4 w-fit h-4 bg-gray-400 rounded-full">
                          <p className="text-[12px] font-bold text-white flex items-center justify-center">
                            {unreadCount}
                          </p>
                        </div>
                      )}
                    </MenuButton>
                  </div>
                  <NotificationsModal
                    show={showNotificationModal}
                    setShowNotificationModal={setShowNotificationModal}
                    notifications={data}
                    onClose={() => setShowNotificationModal(false)}
                  />
                </Menu>

                {/* Messages */}
                <Menu as="div" className="relative ml-3">
                  <div className="flex items-center h-full">
                    <MenuButton className="!border-transparent hover:!border-transparent hover:!bg-transparent flex p-1 !bg-transparent" onClick={() => handleInboxMessages()}>
                      <img
                        className="w-6"
                        alt="Group"
                        src={Message}
                      />
                    </MenuButton>
                    {unreadMessagesCount > 0 && (
                      <div className="absolute top-[5px] -right-[4px] flex items-center justify-center px-1 min-w-4 w-fit h-4 bg-gray-400 rounded-full">
                        <span className={`${unreadMessagesCount > 99 ? "text-[8px]" : "text-xs"} ${unreadMessagesCount > 9 ? "font-medium" : "font-bold"} text-white flex items-center justify-center`}>
                          {unreadMessagesCount < 100 ? unreadMessagesCount : "99+"}
                        </span>
                      </div>
                    )}
                  </div>
                  <MenuItems
                    transition
                    className="flex flex-col w-[414px] h-[619px] overflow-y-scroll absolute right-0 z-10 whitespace-nowrap rounded-lg !bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    {
                      userMessages && userMessages.length > 0 && (
                        <div className="w-full px-4 pt-4 pb-2 flex justify-between">
                          <div className="text-3xl font-semibold">
                            Inbox ({unreadMessagesCount})
                          </div>
                          <Button className="text-[#8E9C9C] text-sm font-medium mt-auto !px-0" variant="transparent" onClick={() => handleMarkAllAsRead()}>Mark all as read</Button>
                        </div>
                      )
                    }
                    {
                      userMessages && userMessages.map((message) => (
                        <MenuItem key={`message-${message.id}`}>
                          <div className={`!border-none px-4 py-2 hover:bg-[#d8dddd] ${message.read ? "" : "bg-[#ebeeee]"}`}>
                            <Link
                              to={`/messages?userId=${message.sender.id}`}
                              className="w-full flex items-center text-black no-underline py-2"
                            >
                              <img className="w-12 h-12 me-3 rounded-full object-cover" src={message.sender.profile_picture || DefaultImg} alt="properties" />
                              <div className="w-full">
                                <div className="w-full font-medium text-sm leading-5">
                                  <span className="underline text-[#2F3135]">{message.sender_name}</span>
                                  <span className="text-[#8E9C9C]">@{message.sender.username}</span>
                                </div>
                                <div className="w-full font-medium text-[#2F3135] overflow-hidden text-ellipsis whitespace-nowrap leading-5">
                                  {message.message}
                                </div>
                                <div className="w-full text-xs leading-4 text-[#121314]">{timeAgo(message.timestamp)}</div>
                              </div>
                            </Link>
                          </div>
                        </MenuItem>
                      ))
                    }
                    {
                      (userMessages === null || (userMessages && userMessages.length === 0)) && (
                        <div className="flex flex-col items-center justify-center w-full h-full p-4">
                          <img
                            src={NoNotificationIcon}
                            alt="No Notifications"
                            className="w-[284px] h-[168.55px] mb-5"
                          />
                          <h2 className="text-[26px] font-semibold font-poppins mb-3">
                            No message yet.
                          </h2>
                          <p className="text-[14px] font-poppins font-normal text-center w-[70%] text-[#2F3135]">
                            Stay tuned! Messages will show up soon.
                          </p>
                        </div>
                      )
                    }
                  </MenuItems>
                </Menu>

                {/* Logged In User dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <MenuButton
                      onClick={() => setShowMenu(!showMenu)}
                      className="relative rounded-full border border-black flex p-1 !bg-white">
                      <img
                        className="rounded-full w-9 h-9"
                        src={user?.profile_picture || DefaultImg}
                        alt="img"
                      />
                      <div className="w-9 h-9 flex justify-center items-center">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                    </MenuButton>
                  </div>
                  {
                    showHeader && (
                      <MenuItems
                        transition
                        className="grid w-40 absolute right-0 z-10 mt-2 px-3 whitespace-nowrap origin-top-right rounded-lg !bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          <div className="whitespace-nowrap !border-b-[0.5px] !border-b-[#F2F2F2] !border-b-solid">
                            <button
                              onClick={() => handleMenuItemClick("/dashboard")}
                              className="w-full flex items-center text-black no-underline py-2"
                            >
                              <img className="h-4 pe-2" src={Dashboard} alt="properties" />
                              Dashboard
                            </button>
                          </div>
                        </MenuItem>
                        {
                          user && user.roles.includes("admin") && (
                            <MenuItem>
                              <div className="whitespace-nowrap !border-b-[0.5px] !border-b-[#F2F2F2] !border-b-solid">
                                <button
                                  onClick={() => handleMenuItemClick("/admin")}
                                  className="w-full flex items-center text-black no-underline py-2"
                                >
                                  <img className="h-4 pe-2" src={Dashboard} alt="properties" />
                                  Admin Panel
                                </button>
                              </div>
                            </MenuItem>
                          )
                        }
                        <MenuItem>
                          <div className="whitespace-nowrap !border-b-[0.5px] !border-b-[#F2F2F2] !border-b-solid">
                            <button
                              onClick={() => handleMenuItemClick("/my-properties")}
                              className="w-full flex items-center text-black no-underline py-2"
                            >
                              <img className="h-4 pe-2" src={MyProperties} alt="properties" />
                              My Properties
                            </button>
                          </div>
                        </MenuItem>
                        <MenuItem>
                          <div className="w-full whitespace-nowrap !border-b-[0.5px] !border-b-[#F2F2F2] !border-b-solid">
                            <button
                              onClick={() => handleMenuItemClick("/profile")}
                              className="w-full flex items-center text-black no-underline py-2">
                              <img className="h-4 pe-2" src={MyProfile} alt="profile" />
                              My Profile
                            </button>
                          </div>
                        </MenuItem>
                        <MenuItem>
                          <div className="w-full whitespace-nowrap !border-b-[0.5px] !border-b-[#F2F2F2] !border-b-solid hover:!bg-transparent">
                            <Button
                              className="w-full !flex items-center text-black no-underline text-sm text-start font-medium !border-none !px-0 py-2 !bg-transparent"
                              onClick={handleLogout}
                            >
                              <img className="h-4 pe-2" src={Logout} alt="logout" />
                              Logout
                            </Button>
                          </div>
                        </MenuItem>
                      </MenuItems>
                    )
                  }
                </Menu>
              </div>
            ) : (
              <Button
                className="inline-flex !border-none text-white !text-xs sm:!text-base items-center justify-center md:mt-0 py-1 sm:py-2.5 !bg-[#8E9C9C] !rounded-full shadow-[0px_0px_10px_0px_#00000026]"
                as={Link}
                to="/login"
              >
                Start Sell/Buy
              </Button>
            )}
          </div>
          {isSmallScreen && (
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto flex">
                {isAuthenticated && !area_studio_page && (
                  <>
                    <Nav.Link
                      as={Link}
                      to="/buy"
                      className="text-black no-underline !border-b-[0.5px] !border-gray-500 !border-solid"
                    >
                      Buy
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/sell"
                      className="text-black no-underline !border-b-[0.5px] !border-gray-500 !border-solid"
                    >
                      Sell
                    </Nav.Link>
                  </>
                )}
                {area_studio_page ?
                  <>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/reporting-dashboard"
                      className="text-black no-underline !border-b-[0.5px] !border-gray-500 !border-solid"
                    >
                      Reporting Dashboard
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/listing-enhancement"
                      className="text-black no-underline !border-b-[0.5px] !border-gray-500 !border-solid"
                    >
                      Listing Enhancement
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/ad-creation"
                      className="text-black no-underline !border-b-[0.5px] !border-gray-500 !border-solid"
                    >
                      Ad Creation
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/area-studio/starter-kit"
                      className="text-black no-underline !border-b-[0.5px] !border-gray-500 !border-solid"
                    >
                      Starter Kit
                    </Nav.Link>
                    <Nav.Link
                      href="/area-studio/home-store"
                      className="text-black no-underline !border-b-[0.5px] !border-gray-500 !border-solid"
                    >
                      AreA Home Store
                    </Nav.Link></>
                  : <>
                    <Nav.Link
                      as={Link}
                      to="/contact-us"
                      className="text-black no-underline !border-b-[0.5px] !border-gray-500 !border-solid"
                    >
                      Contact Us
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/about-us"
                      className="text-black no-underline"
                    >
                      About Us
                    </Nav.Link>
                  </>
                }
              </Nav>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
      <div
        style={{ height: "5.7rem", display: showHeader ? "block" : "none" }}
      ></div>
      {<img className="fixed right-9 bottom-12 z-50 w-40 cursor-pointer" src={user?.selected_agent === 'Max' ? MaxImg : MareaImg} alt="Marea Suggestions" onClick={handleOpenModal} />}
      <MareaModal />
      {showChooseAgentModal && <ChooseAgentModal />}
    </>
  );
};
