import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'; // Ensure you have Bootstrap installed and imported
import MareaImg from "../../assets/images/marea.png";
import MaxImg from '../../assets/images/Max.png';
import { useDispatch, useSelector } from 'react-redux';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';
import { updateAgent } from '../../redux/slices/authSlice';
import { openModal, setShowChooseAgentModal } from '../../redux/slices/mareaSlice';

const ChooseAgentModal = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const [selectedAgent, setSelectedAgent] = useState('Max');

  const handleClose = () => {
    dispatch(setShowChooseAgentModal(false));
  };

  const handleActivateAgent = () => {
    dispatch(updateAgent({ id: user.id, data: { agent_name: selectedAgent } }))
      .unwrap()
      .then(() => {
        showAllNotifications('Agent has been activated!', ToastColors.success);
        handleClose();
        dispatch(openModal())
      })
      .catch((error) => {
        // Handle errors if needed
        console.error('Failed to update agent:', error);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg lg:max-w-4xl md:max-w-2xl max-w-72">
        {/* Header Row */}
        <div className="flex justify-between items-center px-[12px] py-[8px] md:px-8 md:py-6 border-b border-[#EDF1F5]">
          <h2 className="font-poppins sm:text-[28px] font-semibold">Select FREE AI Agent</h2>
          <button
            onClick={handleClose}
            className="right-5 text-4xl font-extralight text-[#A1A4A9]"
          >
            &times;
          </button>
        </div>

        {/* Centered Text */}
        <p className="font-poppins text-sm mt-4 sm:pe-20 md:ps-8 md:py-6 px-[12px] py-[8px]">
          Activate one of the AI bots to assist you throughout your journey in AREA. They will help you create ads, generate invoices, make property purchasing decisions, and find the right property.
        </p>

        {/* Image Row */}
        <div className="flex justify-center mt-8 space-x-8 md:px-8 px-[12px]">
          <div className="w-[341px] text-center flex flex-col">
            <div className={
              `w-full rounded-md flex flex-grow ${
                selectedAgent === 'Max' ? 'border-2 border-blue-500' : 'border-[0.66px] border-[#8E9C9C]'
              }`
            }>
              <img
                src={MaxImg}
                alt="Agent 1"
                className="max-w-full w-auto max-h-full mx-auto mt-auto pt-4 px-4 cursor-pointer"
                onClick={() => setSelectedAgent('Max')}
              />
            </div>
            <p className="font-poppins text-[14px] font-bold text-[#3A3A3C] mt-2">Max</p>
          </div>
          <div className="w-[341px] text-center flex flex-col">
            <div className={
              `w-full rounded-md flex flex-grow ${
                selectedAgent === 'Marea' ? 'border-2 border-blue-500' : 'border-[0.66px] border-[#8E9C9C]'
              }`
            }>
              <img
                src={MareaImg}
                alt="Agent 1"
                className="max-w-full w-auto max-h-full mx-auto mt-auto pt-4 px-4 cursor-pointer"
                onClick={() => setSelectedAgent('Marea')}
              />
            </div>
            <p className="font-poppins text-[14px] font-bold text-[#3A3A3C] mt-2">Marea</p>
          </div>
        </div>

        {/* Button Row */}
        <div className="flex justify-end items-center md:px-8 md:py-6 px-[12px] py-[8px] mt-6 border-t border-[#EDF1F5]">
          <Button
            variant="outline-danger"
            onClick={handleClose}
            className="mr-2 !rounded-[50px] font-poppins text-[14px] font-medium px-3 py-2"
          >
            Cancel
          </Button>
          <Button
            onClick={handleActivateAgent}
            className="font-poppins !rounded-[50px] text-[14px] font-medium text-white border-none !bg-[#8E9C9C] !border-[#8E9C9C] hover:!bg-[#8E9C9C] focus:!bg-[#8E9C9C] px-3 py-2"
          >
            Active Now
          </Button>

        </div>
      </div>
    </div>
  );
}

export default ChooseAgentModal;
