import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { property_types } from "../../constants/property/property_types";
import { USA_CITIES } from "../../constants/USA_Cities";
import { SearchIcon } from "../../assets/icons/icons";
import { fetchKeywordListing, searchProperties, setFilterValue } from "../../redux/slices/propertySlice";
import { useNavigate } from "react-router-dom";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import debounce from "lodash.debounce";

const propertyTypeOptions = [
  ...property_types.residential,
  ...property_types.plot,
  ...property_types.commercial,
].map((type) => ({ label: type.label, value: type.label }));

const SearchBar = ({ className, bgColor }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const [showKeywordDropdown, setShowKeywordDropdown] = useState(false);
  const {
    filter: {
      lease_option,
      property_type,
      property_city,
    }, keywordListing, filter
  } = useSelector((state) => state.properties);
  const [searchKeyword, setSearchKeyword] = useState(filter.keyword);

  useEffect(() => {
    setSearchKeyword(filter.keyword);
  }, [filter.keyword]);

  const handleSearchPropertyClick = (e) => {
    e.preventDefault();
    if ((searchKeyword !== "" && filter.keyword === "")) {
      showAllNotifications('Please select a valid keyword from the list.', ToastColors.error);
      return;
    } else {
      dispatch(searchProperties({ filter, limit: 12, offset: 0 }));
    }
    if (!window.location.pathname.includes("search-n-buy")) navigate("/search-n-buy");
  };

  const debouncedFetchKeywordListing = useCallback(
    debounce((value) => {
      dispatch(fetchKeywordListing(value));
    }, 300),
    []
  );

  return (
    <div className={className}>
      <div className="w-full relative h-[179px] top-px max-w-screen-xl mx-auto">
        <div
          className={`absolute w-[410px] h-[59px] top-0 left-0 ${bgColor} rounded-t-[30px]`}
        >
          <div
            className={`absolute w-[410px] h-[55px] top-0 left-0 rounded-t-[30px] ${bgColor} flex items-end`}
          >
            <div className="flex items-center ml-8">
              <input
                type="radio"
                id="for-sale"
                key={`lease-option-${lease_option}`}
                name="lease-option"
                value="0"
                checked={lease_option === "0"}
                className="w-[15px] h-[15px] rounded-full border-2 border-solid border-[#0d263c]"
                onChange={() =>
                  dispatch(setFilterValue({ key: "lease_option", value: "0" }))
                }
              />
              <label
                htmlFor="for-sale"
                className="ml-2 [font-family:'Jost-Regular',Helvetica] font-normal text-black text-base"
              >
                For Sale
              </label>
            </div>
            <div className="flex items-center ml-8">
              <input
                type="radio"
                id="for-rent"
                key={`lease-option-${lease_option}`}
                name="lease-option"
                value="1"
                checked={lease_option === "1"}
                className="w-[15px] h-[15px] rounded-full border-2 border-solid border-[#0d263c]"
                onChange={() =>
                  dispatch(setFilterValue({ key: "lease_option", value: "1" }))
                }
              />
              <label
                htmlFor="for-rent"
                className="ml-2 [font-family:'Jost-Regular',Helvetica] font-normal text-black text-base"
              >
                For Rent
              </label>
            </div>
          </div>
          <div
            className={`absolute w-8 h-8 top-[43px] left-[394px] ${bgColor} rounded-[18px] -rotate-90`}
          />
        </div>
        <div
          className={`absolute w-full h-[121px] top-[58px] left-0 ${bgColor} rounded-[0px_30px_30px_30px]`}
        >
          <div className="flex items-center justify-between gap-8 p-8">
            <div className="w-full relative h-[61px] bg-white rounded-full border-[#c7d5e1] border border-solid flex items-center px-4">
              <input
                key={`keyword-${filter.keyword}`}
                type="text"
                name="keyword"
                placeholder="Enter keyword"
                autoComplete="off"
                className="appearance-none w-full h-[50px] px-1 rounded-[500px] !border-none focus:outline-none font-poppins"
                onBlur={() => {
                  setTimeout(() => {
                    setShowKeywordDropdown(false);
                  }, 100);
                }}
                value={searchKeyword}
                onChange={(e) => {
                  e.preventDefault();
                  setSearchKeyword(e.target.value);
                  if (e.target.value !== "") {
                    debouncedFetchKeywordListing(e.target.value);
                  }
                  dispatch(
                    setFilterValue({ key: "keyword", value: "" })
                  );
                  dispatch(
                    setFilterValue({ key: "keyword_column", value: "" })
                  );
                  setShowKeywordDropdown(true);
                }}
              />
              {showKeywordDropdown && keywordListing.length > 0 && (
                <ul className="absolute top-14 left-2 w-[95%] !pl-0 bg-white border border-solid border-[#00000042] max-h-60 overflow-y-auto z-10">
                  {
                    keywordListing.map(({ text, column, label, value }, i) => (
                      <li
                        key={`keyword-${column}-${text}-${i}`}
                        className="flex !p-2 cursor-pointer hover:bg-gray-200 text-xs"
                        onClick={(e) => {
                          e.preventDefault();
                          setSearchKeyword(text);
                          dispatch(
                            setFilterValue({ key: "keyword", value })
                          );
                          dispatch(
                            setFilterValue({ key: "keyword_column", value: column })
                          );
                          setShowKeywordDropdown(false);
                        }}
                      >
                        {text}
                        <div className={`px-2 py-1 flex justify-center items-center ms-auto my-auto rounded-full bg-gray-500 text-white text-[9px] h-fit text-center leading-[10px]`}>
                          {label}
                        </div>
                      </li>
                    ))
                  }
                </ul>
              )}
            </div>
            <div className="w-full h-[60px] bg-white rounded-full border border-solid border-[#00000026] flex items-center px-4">
              <select
                className="w-full h-full bg-transparent outline-none text-[#999999] [font-family:'Jost-Regular',Helvetica] text-base"
                defaultValue={property_type}
                onChange={(event) => {
                  dispatch(
                    setFilterValue({
                      key: "property_type",
                      value: event.target.value,
                    })
                  );
                }}
              >
                <option value="">
                  Property Type
                </option>
                {propertyTypeOptions.map((opt, i) => (
                  <option key={`opt-${i}`} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full relative h-[61px] bg-white rounded-full border-[#c7d5e1] border border-solid flex items-center px-4">
              <input
                type="text"
                name="city"
                placeholder="Select your city"
                className="appearance-none w-full h-[50px] pl-12 pr-6 rounded-[500px] !border-none focus:outline-none font-poppins"
                onClick={(e) => {
                  e.preventDefault();
                  setShowCityDropdown(true);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setShowCityDropdown(false);
                  }, 100);
                }}
                value={property_city}
                onChange={(e) => {
                  e.preventDefault();
                  dispatch(
                    setFilterValue({
                      key: "property_city",
                      value: e.target.value,
                    })
                  );
                }}
              />
              <div className="absolute top-0 left-0 h-full flex items-center pl-6">
                <img src={SearchIcon} alt="Search Icon" className="w-5 h-5" />
              </div>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4 text-gray-600">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M7 10l5 5 5-5H7z" />
                </svg>
              </div>
              {showCityDropdown && (
                <ul className="absolute top-14 w-[88%] bg-white border border-solid border-[#00000042] max-h-60 overflow-y-auto z-10">
                  {USA_CITIES.filter((city) =>
                    city.toLowerCase().includes(property_city.toLowerCase())
                  ).map((city) => (
                    <li
                      key={city}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => {
                        dispatch(
                          setFilterValue({ key: "property_city", value: city })
                        );
                        setShowCityDropdown(false);
                      }}
                    >
                      {city}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <button
              className="w-full h-[60px] bg-grey rounded-full flex items-center justify-center text-white [font-family:'Jost-Regular',Helvetica] text-base"
              onClick={handleSearchPropertyClick}
            >
              Search Property
            </button>
          </div>
        </div>
        {/* <img className="absolute w-[72px] h-[60px] top-0 left-[274px]" alt="Link" src={Icon} /> */}
      </div>
    </div>
  );
};

export default SearchBar;
