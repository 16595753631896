import React from 'react'

const AuthPageLayout = ({ src, alt, imgClassName, divClassName }) => {
  return (
    <div className={divClassName}>
      <img
        src={src}
        alt={alt}
        className={imgClassName}
      />
    </div>
  )
}

export default AuthPageLayout;
