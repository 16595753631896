import { useLocation } from "react-router-dom";
import { Header } from "../components/common/Header";
import { Footer } from "../components/common/Footer";

const PublicRoute = ({ children }) => {
  const { pathname } = useLocation();

  const AUTH_LINKS = [
    "/login",
    "/register",
    "/set-new-password",
    "/forgot-password",
    "/otp",
  ];

  return (
    <>
      {!AUTH_LINKS.includes(pathname) && <Header />}
      {children}
      {!AUTH_LINKS.includes(pathname) && <Footer />}
    </>
  );
};

export default PublicRoute;
