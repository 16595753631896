import React, { useEffect, useState } from 'react';
import { MenuItems } from '@headlessui/react';
import { NoNotificationIcon, SettingsIcon, VolumeIcon } from '../../assets/icons/icons';
import { timeAgo } from '../../utils/time_ago';
import { categorizeNotifications } from '../../utils/sortNotifications';
import { useNavigate } from 'react-router-dom';
import { markAllAsRead, markNotificationRead } from '../../redux/slices/notificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { showAllNotifications } from '../../utils/notificationHelper';
import { logout } from '../../redux/slices/authSlice';
import { clearError } from '../../redux/slices/propertySlice';
import ToastColors from '../../constants/toastColors';
import { DefaultProfilePicture } from '../../assets/images/images';

const NotificationsModal = ({ notifications, setShowNotificationModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sortedNotifications, setSortedNotifications] = useState({});
  const { error } = useSelector((state) => state.auth);

  useEffect(() => {
    if (notifications.length > 0) {
      const orderedCategories = categorizeNotifications(notifications);
      setSortedNotifications(orderedCategories);
    }
  }, [notifications]);


  const handleShowNotification = (id, url) => {
    dispatch(markNotificationRead(id));
    setShowNotificationModal(false);
    navigate(url);
  };

  const handleMarkAllAsRead = () => {
    const hasUnreadNotifications = notifications.some(notification => !notification.is_read);

    if (hasUnreadNotifications) {
      dispatch(markAllAsRead());
      setShowNotificationModal(false);
    }
  };

  // if (!show) return null;

  if (error) {
    if (error?.code === 'token_not_valid') {
      showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
      dispatch(logout());
      dispatch(clearError());
      navigate('/login');
    }
  }

  return (
    <MenuItems
      transition
      className="absolute right-0 mt-2 w-[414px] h-[619px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col"
    >
      {notifications.length > 0 ? (
        <div className="flex-grow overflow-y-auto">
          <div className="p-4">
            <h2 className="text-[28px] font-semibold font-poppins text-left">
              Notifications
            </h2>
          </div>
          {Object.keys(sortedNotifications).map((category, index) => (
            sortedNotifications[category].length > 0 && (
              <div key={category} className="mb-4 relative">
                <h3 className={`font-poppins text-[20px] font-semibold text-left mb-2 mt-3 px-4 py-2 ${index === 0 ? 'relative pr-16' : ''}`}>
                  {category}
                  {index === 0 && (
                    <button
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 px-4 py-2 text-[14px] font-medium text-[#8E9C9C]"
                      onClick={handleMarkAllAsRead}
                    >
                      Mark All As Read
                    </button>
                  )}
                </h3>
                {sortedNotifications[category].map((notification) => (
                  <div
                    key={notification.id}
                    className={`mx-auto px-4 py-3 text-sm flex items-center cursor-pointer ${!notification.is_read ? 'bg-[#CFDADA3B]' : 'bg-white'
                      }`}
                    onClick={() => handleShowNotification(notification.id, notification.source_url)}
                  >
                    <img
                      src={notification.sender.profile_picture || DefaultProfilePicture}
                      alt="Profile"
                      className="w-[48px] h-[48px] rounded-full mr-4"
                    />
                    <div className="flex flex-col justify-center mt-3 h-[50px]">
                      <p className="font-poppins text-[14px] mt-1 font-medium text-[#121314]">
                        {notification.message}
                      </p>
                      <p className="font-poppins text-[12px] font-normal text-[#121314]">
                        {timeAgo(notification.created_at)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full p-4">
          <img
            src={NoNotificationIcon}
            alt="No Notifications"
            className="w-[284px] h-[168.55px] mb-5"
          />
          <h2 className="text-[26px] font-semibold font-poppins mb-3">
            No notifications yet.
          </h2>
          <p className="text-[14px] font-poppins font-normal text-center w-[70%] text-[#2F3135]">
            Stay tuned! Notifications about your activity will show up soon.
          </p>
        </div>
      )}
      {/* footer */}
      <div className="border-t border-[#E9E9E9] flex items-center p-2">
        <button>
          <img
            src={VolumeIcon}
            alt="Volume Icon"
            className="ml-2 mr-4"
          />
        </button>
        <button>
          <img
            src={SettingsIcon}
            alt="Settings Icon"
          />
        </button>
      </div>
    </MenuItems>
  );
};

export default NotificationsModal;
