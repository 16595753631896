import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sendContactUsMessage } from "../../redux/slices/contactSlice";

const ContactUs = ({ contact_us_page }) => {
  const dispatch = useDispatch();
  const initialForm = {
    name: "",
    email: "",
    phone: "",
    message: "",
  }
  const [form, setForm] = useState(initialForm);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendContactUsMessage(form));
    setForm(initialForm);
    setFormSubmitted(true);
  };

  return (
    <div className="w-full p-2 sm:p-4 md:p-6 lg:p-8 ">
      <form
        className="w-full bg-white p-4 sm:p-8 rounded-xl mx-2"
        onSubmit={handleSubmit}
      >
        {/* Heading */}
        <div className="text-center mb-10">
          {contact_us_page && (
            <h1 className="text-[30px] font-space-grotesk font-bold">
              Contact form
            </h1>
          )}
        </div>
        <div className={`flex flex-wrap mb-6 ${contact_us_page ? 'mt-5' : 'mt-12'}`}>
          <div className="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0 md:pr-2">
            <label
              className="block text-[#3A3A3C] text-[16px] font-mulish font-extrabold mb-2"
              htmlFor="name"
            >
              Your name
            </label>
            <input
              className="w-full px-3 py-3 text-[#3A3A3C] font-poppins text-[14px] font-normal border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              type="text"
              id="name"
              name="name"
              placeholder="Your name"
              value={form.name}
              onChange={handleChange}
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0 md:px-2">
            <label
              className="block text-[#3A3A3C] text-[16px] font-mulish font-extrabold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full px-3 py-3 text-[#3A3A3C] font-poppins text-[14px] font-normal border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={form.email}
              onChange={handleChange}
            />
          </div>
          <div className="w-full lg:w-1/3 mb-4 lg:mb-0 lg:pl-2">
            <label
              className="block text-[#3A3A3C] text-[16px] font-mulish font-extrabold mb-2"
              htmlFor="phone"
            >
              Phone
            </label>
            <input
              className="w-full px-3 py-3 text-[#3A3A3C] font-poppins text-[14px] font-normal border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone"
              value={form.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-6">
          <label
            className="block text-[#3A3A3C] text-[16px] font-mulish font-extrabold mb-2"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            className="w-full px-3 py-3 text-gray-700 border rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-400"
            id="message"
            name="message"
            rows="5"
            placeholder=""
            value={form.message}
            onChange={handleChange}
          />
        </div>
        <div className="flex">
          <button
            className="w-[178.97px] h-[54.5px] bg-[#8E9C9C] text-white rounded-full text-[15px] font-poppins font-bold hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-400"
            type="submit"
          >
            Send Request
          </button>
        </div>
        {
          formSubmitted && (
            <p className="text-center mt-4 text-green-500">Thank you for your message!</p>
          )
        }
      </form >
    </div >
  );
};

export default ContactUs;
