import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, subscribeProperty } from "../../redux/slices/paymentSlice";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { capitalize } from "../../utils/properties";
import ToastColors from "../../constants/toastColors";
import { showAllNotifications } from "../../utils/notificationHelper";

const StripePaymentModal = ({ selectedPlan }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.payment);
  const { clientSecret, selectedPlan: { propertyId } } = useSelector((state) => state.payment);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: 'if_required',
    });

    if (result.error) {
      console.error(result.error.message);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        showAllNotifications(`You have paid successfully for the plan $${selectedPlan.amount}/${capitalize(selectedPlan.plan_type)}.`, ToastColors.success);
        dispatch(subscribeProperty({ plan_id: selectedPlan.id, property_id: propertyId, payment_intent_id: result.paymentIntent.id }));
      }
      dispatch(closeModal());
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4 z-50">
        <div className="bg-white rounded-lg shadow-lg relative max-w-screen-lg w-full max-h-screen flex flex-col">
          <button
            onClick={() => dispatch(closeModal())}
            className="absolute top-4 right-5 text-4xl font-extralight text-[#A1A4A9]"
          >
            &times;
          </button>
          <h1 className="text-2xl font-bold mb-0 border-b px-6 py-4">
            Select Payment Method
          </h1>
          <div className="w-full p-8">
            <div className="mb-4">
              Selected Plan: <span className="text-4xl font-bold mb-2 leading-9">${selectedPlan.amount} <span className="font-normal !text-lg"> / {capitalize(selectedPlan.plan_type)}</span></span>
            </div>
            <h5 className="font-bold mb-4">Enter Details</h5>
            {
              clientSecret && (
                <form onSubmit={handleSubmit} className="w-full">
                  <PaymentElement />
                  <Button
                    type="button"
                    variant="outline"
                    className="font-poppins !border-[#8e9c9c] hover:!bg-[#8e9c9c] !text-[#8e9c9c] hover:!text-white !rounded-full mt-8 sm:mb-0 !px-6 me-4"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="secondary"
                    disabled={!stripe || !clientSecret}
                    className="font-poppins !bg-[#8e9c9c] hover:!bg-[#637272] !rounded-full mt-8 sm:mb-0 !px-6"
                  >
                    Pay Now
                  </Button>
                </form>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default StripePaymentModal;
