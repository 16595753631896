import React from "react";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import Login from "../features/auth/Login";
import Register from "../features/auth/Register";
import ContactUs from "../pages/ContactUs/ContactUs";
import AboutUs from "../pages/AboutUs/AboutUs";
import { Homepage } from "../pages/Homepage/Homepage";
import { Buy } from "../pages/Buy/Buy";
import { Sell } from "../pages/Sell/Sell";
import { PropertyDetails } from "../pages/MyProperties/PropertyDetails";
import VerifyOTP from "../features/auth/VerifyOTP";
import ForgotPassword from "../features/auth/ForgotPassword";
import SetNewPassword from "../features/auth/SetNewPassword";
import ListingEnhancement from "../pages/AreaStudio/ListingEnhancement";
import SearchAndBuy from "../pages/Buy/SearchAndBuy";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import StarterKit from "../pages/AreaStudio/StarterKit";
import ComingSoon from "../pages/ComingSoon/ComingSoon";
import Profile from "../features/auth/Profile";
import { MyProperties } from "../pages/MyProperties/MyProperties";
import AddProperty from "../pages/MyProperties/AddProperty";
import Dashboard from "../pages/Dashboard/Dashboard";
import CreateContract from "../pages/Contracts/CreateContract";
import Chat from "../pages/Chat/Chat";
import AdCreation from "../pages/Ads/AdsCreation";
import AdminDashboardOverview from "../pages/Admin/Dashboard/Overview";
import AdminUsers from "../pages/Admin/Users/Users";
import AdRevenue from "../pages/Admin/AreaStudio/AdRevenue";

const AppRouter = () => {
  const PUBLIC_ROUTES = [
    {
      path: "/area-studio/listing-enhancement",
      component: <ListingEnhancement />,
    },
    { path: "/otp", component: <VerifyOTP /> },
    { path: "/forgot-password", component: <ForgotPassword /> },
    { path: "/set-new-password", component: <SetNewPassword /> },
    { path: "/register", component: <Register /> },
    { path: "/login", component: <Login /> },
    { path: "/contact-us", component: <ContactUs /> },
    { path: "/about-us", component: <AboutUs /> },
    { path: "/buy", component: <Buy /> },
    { path: "/sell", component: <Sell /> },
    { path: "/", component: <Homepage /> },
    { path: "/search-n-buy", component: <SearchAndBuy /> },
    { path: "/terms-and-conditions", component: <TermsAndConditions /> },
    { path: "/area-studio/starter-kit", component: <StarterKit /> },
    { path: "/area-studio/reporting-dashboard", component: <ComingSoon targetDate={new Date("2024-09-15T23:59:59")} /> },
    { path: "/area-studio/home-store", component: <ComingSoon  targetDate={new Date("2024-10-15T23:59:59")}/> },
    { path: "/properties/:id", component: <PropertyDetails /> },
  ];

  const PRIVATE_ROUTES = [
    { path: "/my-properties", component: MyProperties },
    { path: "/properties/new", component: AddProperty },
    { path: "/properties/:id/edit", component: AddProperty },
    { path: "/properties/:id/contract", component: CreateContract },
    { path: "/profile", component: Profile },
    { path: "/dashboard", component: Dashboard },
    { path: "/messages", component: Chat },
    { path: "/area-studio/ad-creation", component: AdCreation },
  ];

  const ADMIN_ROUTES = [
    { path: "/", component: AdminDashboardOverview },
    { path: "/overview", component: AdminDashboardOverview },
    { path: "/users", component: AdminUsers },
    { path: "/area-partners", component: () => <ComingSoon targetDate={new Date("2024-10-15T23:59:59")} /> },
    { path: "/premier-partners", component: () => <ComingSoon targetDate={new Date("2024-10-15T23:59:59")} /> },
    { path: "/premier-partners", component: () => <ComingSoon targetDate={new Date("2024-10-15T23:59:59")} /> },
    { path: "/area-affiliated-partners", component: () => <ComingSoon targetDate={new Date("2024-10-15T23:59:59")} /> },
    { path: "/affiliate-marketing", component: () => <ComingSoon targetDate={new Date("2024-10-15T23:59:59")} /> },
    { path: "/ad-revenue", component: AdRevenue},
    { path: "/starter-kits", component: () => <ComingSoon targetDate={new Date("2024-10-15T23:59:59")} /> },
    { path: "/home-accessories", component: () => <ComingSoon targetDate={new Date("2024-10-15T23:59:59")} /> },
    { path: "/property-approvals", component: () => <ComingSoon targetDate={new Date("2024-09-15T23:59:59")} /> },
    { path: "/user-referrals", component: () => <ComingSoon targetDate={new Date("2024-10-15T23:59:59")} /> },
    { path: "/menu-settings", component: () => <ComingSoon targetDate={new Date("2024-09-15T23:59:59")} /> },
  ];

  return (
    <Routes>
      {PUBLIC_ROUTES.map((r, i) => (
        <Route
          path={r.path}
          element={<PublicRoute>{r.component}</PublicRoute>}
          key={`${r.path}-${i + 1}`}
        />
      ))}
      {PRIVATE_ROUTES.map((r, i) => (
        <Route
          path={r.path}
          element={<PrivateRoute component={r.component} />}
          key={`${r.path}-${i + 1}`}
        />
      ))}
      {ADMIN_ROUTES.map((r, i) => (
        <Route
          path={`/admin${r.path}`}
          element={<AdminRoute component={r.component} />}
          key={`${r.path}-${i+1}`}
        />
      ))}
    </Routes>
  );
};

export default AppRouter;
