import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { capitalize } from "../../utils/properties";
import { Button, Modal, Badge } from "react-bootstrap";
import { AngularVector, AreaVector, BathVector, BedVector, CrossButton, DeleteVector, IconCalendar, IconCheckMark, CircleCheckMark, IconLocation, ImagesVector, LocationLinkVector, NoteVector, PrintVector, ShareVector } from "../../assets/icons/icons";
import PropertyImages from "./PropertyImages";
import PropertyAddress from "./PropertyAddress";
import PropertyOverview from "./PropertyOverview";
import FeatureListings from "./FeatureListings";
import { useDispatch, useSelector } from "react-redux";
import { clearError, closeProperty, fetchProperty } from "../../redux/slices/propertySlice";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import { logout } from "../../redux/slices/authSlice";
import { closeModal, fetchClientSecret, getPropertyPlans } from "../../redux/slices/paymentSlice";
import { formatPrice } from "../../utils/price_formatter";
import StripePaymentModal from "../../components/common/StripePaymentModal";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

export const PropertyDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { property } = useSelector((state) => state.properties);
  const { error, user } = useSelector((state) => state.auth);
  const { plans, clientSecret } = useSelector((state) => state.payment);

  const { id } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [propertyImages, setPropertyImages] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(0);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleSelectedPlan = (plan) => {
    setSelectedPlan(plan);
    dispatch(fetchClientSecret({ plan_id: plan.id, property_id: id }));
  }

  const isMyProperty = () => user && property.users && user.id && user.id === property.users.id;

  useEffect(() => {
    if (property) {
      const { PhotoURLPrefix, PhotoKey, PhotosCount } = property;
      const images = Array.from({ length: PhotosCount }, (_, index) => {
        return `${PhotoURLPrefix}${PhotoKey}/photo_${index + 1}.jpg`;
      });
      setPropertyImages(images);
    }
  }, [property]);

  useEffect(() => {
    dispatch(fetchProperty(id));
    dispatch(getPropertyPlans());
    dispatch(closeModal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  if (!property)
    return <div><h1 className="mt-5 mb-5">Property not found</h1></div>;

  if (error) {
    if (error?.code === 'token_not_valid') {
      showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
      dispatch(logout());
      dispatch(clearError());
      navigate('/login');
    }
  }

  const handleCopyUrlToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        showAllNotifications("Property link is copied", ToastColors.success);
      })
      .catch(err => {
        showAllNotifications("Failed to copy property link", ToastColors.error);
      });
  }

  return (
    <div className="bg-white flex flex-col items-center w-full">
      <div className="w-full bg-white overflow-hidden relative">
        <div className="w-full mt-4 py-10 max-w-screen-xl mx-auto my-10 px-4">

          {/* Properties Section */}
          <div className="mb-6 flex flex-col items-center sm:flex-row sm:justify-between sm:items-center">
            <div className="sm:text-left md:text-left mb-4 sm:mb-0">
              <h2 className="text-4xl font-semibold text-gray-900 mb-2 font-poppins">
                {isMyProperty() ? "My " : ""}Properties
              </h2>
              <p className="text-gray-600 text-base flex flex-row flex-wrap items-center justify-center sm:justify-start space-x-2">
                <Link to="/" className="text-gray-600 no-underline hover:underline font-poppins">Home</Link>
                <img src={AngularVector} alt="Location Icon" />
                <Link to={isMyProperty() ? "/my-properties" : "/search-n-buy"} className="text-gray-600 no-underline hover:underline font-poppins">{isMyProperty() ? "My " : ""}Properties</Link>
                <img src={AngularVector} alt="Location Icon" />
              </p>
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
              {
                isMyProperty() ? (
                  <>
                    <Button
                      variant="outline-danger"
                      className="font-poppins !rounded-full mb-2 sm:mb-0"
                      onClick={handleShow}
                    >
                      Close this Ad
                    </Button>
                    <Button
                      variant="secondary"
                      className="font-poppins !bg-[#8e9c9c] !rounded-full mb-2 sm:mb-0"
                    >
                      Take AI Tour
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="secondary"
                    className="font-poppins !bg-[#8e9c9c] hover:!bg-[#637272] !rounded-full mb-2 sm:mb-0"
                    onClick={() => navigate(`/properties/${property.PropertyID}/contract`, { state: { id: property.PropertyID } })}
                  >
                    Buy Now
                  </Button>
                )
              }
            </div>
          </div>


          <Modal show={showModal} onHide={handleClose} centered>
            <div className="position-relative">
              <img
                src={CrossButton}
                alt="Close"
                className="position-absolute top-2 end-2 cursor-pointer"
                onClick={handleClose}
                style={{ width: '24px', height: '24px', zIndex: 1050 }}  // Ensure zIndex is high enough
              />
              <Modal.Body className="text-center p-4 mt-3">
                <h5 className="mb-4">Are you sure?</h5>
                <p className="mb-4">Are you sure you want to close this ad?</p>
                <div className="mt-4">
                  <Button variant="outline-danger" onClick={handleClose} className="mr-2">
                    No, cancel
                  </Button>
                  <Button variant="secondary" onClick={() => {
                    dispatch(closeProperty(id));
                    handleClose();
                  }}>
                    Yes, confirm
                  </Button>
                </div>
              </Modal.Body>
            </div>
          </Modal>

          {/* Address Information Section */}

          <div className="w-full mt-8 flex flex-col md:flex-row justify-between items-start">
            <div className="w-full md:w-1/2 mb-6 md:mb-0">
              <h2 className={`${property.LegalDetails.length > 60 ? "text-xl" : "text-4xl"} font-semibold text-gray-900 mb-2 font-poppins`}>
                {property.LegalDetails}
              </h2>
              <div className="flex items-center mb-2">
                <Badge bg="secondary" className="mr-3 font-mulish">
                  {property.PropertyStatus}
                </Badge>
                <img src={IconLocation} alt="Location Icon" className="text-gray-600 mr-1" />
                <span className="text-gray-600 font-poppins mr-3">{property.MLSListingAddress}</span>
                <img src={IconCalendar} alt="Location Icon" className="text-gray-600 mr-1" />
                <span className="text-gray-600">{property.ListingDate ? property.ListingDate.split('-')[0] : ""}</span>
              </div>
              <div className="flex items-center">
                <img src={BedVector} alt="Bed Icon" className="text-gray-600 mr-1" />
                <span className="text-gray-600 mr-4 font-poppins"> Beds:  <span className="font-bold">{property.BedroomsTotal}</span></span>
                <img src={BathVector} alt="Bath Icon" className="text-gray-600 mr-1" />
                <span className="text-gray-600 mr-4 font-poppins">Baths:  <span className="font-bold">{property.BathroomsFull}</span></span>
                <img src={AreaVector} alt="Area Icon" className="text-gray-600 mr-1" />
                <span className="text-gray-600 font-poppins">SqFt <span className="font-bold">{property.LotSizeSquareFeet}</span></span>
              </div>
            </div>
            <div className="w-full md:w-1/2 flex flex-col items-end mt-auto">
              <div className="flex items-center mb-2">
                <img src={ImagesVector} alt="Images Icon" className="mr-1 cursor-pointer" />
                <img src={LocationLinkVector} alt="Location Link Icon" className="mr-1 cursor-pointer" />
                {
                  isMyProperty() && (
                    <>
                      <img
                        onClick={() => navigate(`/properties/${property.PropertyID}/edit`, { state: { property, type: 'update' } })}
                        src={NoteVector}
                        alt="Note Icon"
                        className="mr-1 cursor-pointer"
                      />
                      <img src={DeleteVector} alt="Delete Icon" className="mr-1 cursor-pointer" />
                    </>
                  )
                }
                <img src={ShareVector} alt="Share Icon" className="mr-1 cursor-pointer" onClick={handleCopyUrlToClipboard} />
                <img src={PrintVector} alt="Print Icon" className="mr-1 cursor-pointer" />
              </div>
              <h2 className="text-4xl font-semibold text-gray-900 mb-2 font-poppins">
                {formatPrice(property.OriginalListingPrice)}
              </h2>
              <Link to="#" className="text-gray-400 underline font-poppins">View the property on another site</Link>
            </div>
          </div>
        </div>
      </div>

      {/* PropertyImages Section */}

      {propertyImages && <PropertyImages images={propertyImages} />}

      <div className="w-full max-w-screen-xl mx-auto my-10 px-4 flex flex-col md:flex-row text-sm">
        <div className="w-full md:w-[65%]">
          {/* Overview Section */}
          <PropertyOverview property={property} />
          {/* Property Details Section */}
          <div className="bg-white border border-gray-200 rounded-xl shadow-[0px_0px_15px_0px_#00000026] p-6 mb-6 font-poppins">
            <h3 className="text-2xl font-semibold mb-2 font-poppins">Property Details</h3>
            <hr className="border-gray-300 mb-4" />
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2">
                <div className="flex flex-col">
                  <div className="flex items-center p-2">
                    <div className="font-bold min-w-[100px]">Price</div>
                    <div className="ml-4">{formatPrice(property.OriginalListingPrice)}</div>
                  </div>
                  <div className="flex items-center p-2">
                    <div className="font-bold min-w-[100px]">Size</div>
                    <div className="ml-4">{property.LotSizeSquareFeet} SqFt</div>
                  </div>
                  <div className="flex items-center p-2">
                    <div className="font-bold min-w-[100px]">Baths</div>
                    <div className="ml-4">{property.BathroomsFull}</div>
                  </div>
                  <div className="flex items-center p-2">
                    <div className="font-bold min-w-[100px]">Garages</div>
                    <div className="ml-4">{property.GarageSpaces}</div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="flex flex-col">
                  <div className="flex items-center p-2">
                    <div className="font-bold min-w-[100px]">Beds</div>
                    <div className="ml-4">{property.BedroomsTotal}</div>
                  </div>
                  <div className="flex items-center p-2">
                    <div className="font-bold min-w-[100px]">Type</div>
                    <div className="ml-4">{property.MLSPropertyType}</div>
                  </div>
                  <div className="flex items-center p-2">
                    <div className="font-bold min-w-[100px]">Status</div>
                    <div className="ml-4">{property.NumberOfLeaseOption === 1 ? 'For Rent' : 'For Sale'}</div>
                  </div>
                  <div className="flex items-center p-2">
                    <div className="font-bold min-w-[100px]">Year Built</div>
                    <div className="ml-4">{property.YearBuilt}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Features Section */}
          <div className="bg-white border border-gray-200 rounded-xl shadow-[0px_0px_15px_0px_#00000026] p-6 mb-6">
            <h3 className="text-2xl font-semibold mb-2 font-poppins">Features {property.BuildingKeywords.split(',')}</h3>
            <hr className="border-gray-300 mb-4" />
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-8 gap-y-4">
              {property.BuildingKeywords && property.BuildingKeywords.split(', ').map((feature, index) => (
                <div key={index} className="flex items-center">
                  <img src={IconCheckMark} alt="Check Mark Icon" className="text-gray-600 mr-2" />
                  <div>{feature.trim().charAt(0).toUpperCase() + feature.trim().slice(1)}</div>
                </div>
              ))}
            </div>
          </div>

          <PropertyAddress property={property} />
        </div>

        <FeatureListings />
      </div>

      {/* Feature Your Properties section */}

      <div className="w-[73%] bg-white rounded-lg bord border-gray-600 mb-6 font-poppins">
        {/* Heading and Sentence */}
        <div className="text-left mb-6">
          <h3 className="text-2xl font-semibold mb-2">Feature Your Properties</h3>
          <p>Explore all the plans, Feature your property on top to sell 10x faster.</p>
        </div>

        {/* Plans */}
        <div className="flex flex-wrap gap-6">
          {plans.map((plan, index) => (
            <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-[0px_0px_15px_0px_#00000026] p-4 flex-1 min-w-[250px] grid grid-rows-[max-content]">
              {/* Price */}
              <div className="text-4xl font-bold mb-2 leading-9">${plan.amount} <span className="font-normal !text-lg"> / {capitalize(plan.plan_type)}</span></div>
              <p className="text-gray-600 mb-4">{plan.description}</p>

              {/* Features */}
              {
                plan.sentences.length > 0 && (
                  <div className="mb-4">
                    {plan.sentences.map((feature, featureIndex) => (
                      <div key={featureIndex} className="flex items-center mb-2">
                        <img src={CircleCheckMark} alt="Check Mark Icon" className="text-gray-600 mr-2" />
                        <span>{feature}</span>
                      </div>
                    ))}
                  </div>
                )
              }

              {/* Button */}
              <div className="text-center mt-auto">
                <Button
                  variant="secondary"
                  className="font-poppins !rounded-full !bg-[#8E9C9C] px-5 py-2 text-sm mr-2 ml-2"
                  onClick={() => handleSelectedPlan(plan)}
                >
                  Choose plan
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {
        clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <StripePaymentModal selectedPlan={selectedPlan} />
          </Elements>
        )
      }
    </div>
  );
};
