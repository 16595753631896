import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../../constants/statuses";
import adRevenueService from "../../services/admin/adRevenueService";

export const getAdsDetails = createAsyncThunk(
  "adRevenue/getAdsDetails",
  async (days, thunkAPI) => {
    try {
      const response = await adRevenueService.getAdsDetails(days);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAdsLists = createAsyncThunk(
    "adRevenue/getAdsLists",
    async (days, thunkAPI) => {
      try {
        const response = await adRevenueService.getAdsLists(days);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

const adRevenueSlice = createSlice({
  name: "adRevenue",
  initialState: {
    status: null,
    error: null,
    days: 30,
    data: [],
    details: {},
    loading: false,
  },
  reducers: {
    setDays: (state, action) => {
      state.days = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdsDetails.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(getAdsDetails.fulfilled, (state, action) => {
        state.details = action.payload || {};
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(getAdsDetails.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getAdsLists.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(getAdsLists.fulfilled, (state, action) => {
        state.data = action.payload || [];
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(getAdsLists.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { setDays } = adRevenueSlice.actions;

export default adRevenueSlice.reducer;
