import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../constants/statuses";
import notificationsService from "../services/notificationsService";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";

export const fetchAllNotifications = createAsyncThunk("notifications/fetchAllNotifications", async (_, thunkAPI) => {
  try {
    const response = await notificationsService.fetchAllNotifications();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const markNotificationRead = createAsyncThunk("notifications/markNotificationRead", async (id, thunkAPI) => {
  try {
    const response = await notificationsService.markNotificationRead(id);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const markAllAsRead = createAsyncThunk("notifications/markAllAsRead", async (_, thunkAPI) => {
  try {
    const response = await notificationsService.markAllAsRead();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    status: null,
    error: null,
    data: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllNotifications.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllNotifications.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchAllNotifications.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(markNotificationRead.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(markNotificationRead.fulfilled, (state, action) => {
        // update notification status in state
        const notificationIndex = state.data.findIndex(notification => notification.id === action.payload.id);
        if (notificationIndex !== -1) {
          state.data[notificationIndex].is_read = action.payload.is_read;
        };
        state.status = STATUSES.IDLE;
      })
      .addCase(markNotificationRead.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(markAllAsRead.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(markAllAsRead.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
        showAllNotifications("Successfully Marked All As Read!", ToastColors.success)
      })
      .addCase(markAllAsRead.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
  },
});

export default notificationsSlice.reducer;
