import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AngularVector, AreaAIIcon, DownloadIcon, SearchIcon } from '../../assets/icons/icons';
import { useFormik } from 'formik';
import { contract_types } from '../../constants/property/property_contract_types';
import { USA_CITIES } from '../../constants/USA_Cities';
import { ContractSchema } from '../../utils/yup/schemas';
import { property_types } from '../../constants/property/property_types';
import { useDispatch, useSelector } from 'react-redux';
import { createContract, fetchContract, fetchProperty, generateContractWithAI, resetPDF } from '../../redux/slices/contractSlice';
import { paymentOptions } from '../../constants/property/payment_methods';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';
import { logout } from '../../redux/slices/authSlice';

const propertyTypeOptions = [
  ...property_types.residential,
  ...property_types.plot,
  ...property_types.commercial,
].map((type) => ({ label: type.label, value: type.label }));

const CreateContract = () => {
  const location = useLocation()
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { id } = location.state || {};
  const { property_data, contract_data } = useSelector((state) => state.contract);
  const { error, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(resetPDF());
    dispatch(fetchProperty(id))
    dispatch(fetchContract(id))
  }, [dispatch, id])

  const { pdf } = useSelector((state) => state.contract);

  const [showSellerDropdown, setShowSellerDropdown] = useState(false);
  const [showBuyerDropdown, setShowBuyerDropdown] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    if (pdf) {
      setPdfLoading(false)
    }
  }, [pdf])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contractType: contract_data?.contract_type || '',
      buyerName: (user?.first_name && user?.last_name) ? `${user?.first_name} ${user.last_name}` : user?.username || '',
      buyerCity: contract_data?.buyer_city || '',
      sellerName: contract_data?.seller_name
        ? contract_data.seller_name
        : property_data?.users
          ? `${property_data?.users?.first_name} ${property_data?.users?.last_name}`
          : '',
      sellerCity: contract_data?.seller_city || '',
      buyerAddress: contract_data?.buyer_address || '',
      sellerAddress: contract_data?.seller_address || '',
      propertyType: property_data?.MLSPropertyType || '',
      propertyLocation: property_data?.MLSListingAddress || '',
      amount: (contract_data?.amount || property_data?.OriginalListingPrice || ''),
      paymentMethod: contract_data?.payment_method || '',
      contractPDF: contract_data?.contract_pdf_url || '',
    },
    validationSchema: ContractSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const dataMap = (data) => {
    const updatedData = {
      contract_type: data.contractType,
      buyer_name: data.buyerName,
      seller_name: data.sellerName,
      buyer_city: data.buyerCity,
      seller_city: data.sellerCity,
      buyer_address: data.buyerAddress,
      seller_address: data.sellerAddress,
      property_type: data.propertyType,
      property_location: data.propertyLocation,
      amount: data.amount,
      payment_method: data.paymentMethod,
      contract_status: data.contract_status,
    };

    const dataWithPDF = {
      ...updatedData,
      ...(pdf ? { contract_pdf_url: pdf } : {}),
    };

    return dataWithPDF;
  }

  const handleAIContractCreation = async (e) => {
    e.preventDefault();
    const errors = await formik.validateForm();
    formik.setTouched(errors);

    if (Object.keys(errors).length === 0) {
      showAllNotifications("Please wait while we process your contract", ToastColors.info)
      setPdfLoading(true)
      dispatch(generateContractWithAI(formik.values))
    }
  };

  const handleData = (status) => {
    const data = {
      ...formik.values,
      contract_status: status
    };
    const updatedData = dataMap(data);
    dispatch(createContract({ id: id, data: updatedData }));
  }

  const handleSend = async () => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      handleData('Completed')
    }
  };

  const handleSaveAsDraft = () => {
    handleData('Draft')
  };


  if (error) {
    if (error?.code === 'token_not_valid') {
      showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
      dispatch(logout());
      navigate('/login');
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap items-center justify-center py-10 max-w-screen-xl mx-auto my-10 px-4">
          {/* Left Column */}
          <div className="w-full md:w-1/2 mb-6 md:mb-0 flex flex-col items-center md:items-start">
            <h2 className="text-4xl font-semibold text-[#37474F] mb-2 font-poppins">
              Create Contracts
            </h2>
            <p className="text-[#37474F] text-base flex items-center space-x-2">
              <Link to="/" className="text-[#37474F] no-underline hover:underline font-poppins">Home</Link>
              <img src={AngularVector} alt="Location Icon" />
              <Link to="/my-properties" className="text-[#37474F] no-underline hover:underline font-poppins">Properties</Link>
              <img src={AngularVector} alt="Location Icon" />
              <Link to={`/properties/${id}`} className="text-[#37474F] w-[80%] no-underline hover:underline font-poppins">{id}</Link>
              <img src={AngularVector} alt="Location Icon" />
              <span className="font-poppins">Create Contract</span>
            </p>
          </div>
          {/* Right Column */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <button
              type="button"
              onClick={handleAIContractCreation}
              style={{ backgroundColor: 'var(--Primary, #8E9C9C)' }}
              className="text-white py-3 px-4 rounded-full shadow focus:outline-none focus:shadow-outline font-poppins text-center text-sm font-semibold leading-6">
              USE AI TO CREATE YOUR CONTRACT
            </button>
          </div>
        </div>

        {/* NEW CONTENT */}
        <div className="flex flex-wrap justify-between max-w-screen-xl mx-auto px-4">
          {/* Left Column */}
          <div className="w-full md:w-[60%]">

            {/* Contract Details Section */}
            <div>
              <h3 className="text-xl font-semibold mb-4 font-poppins text-[#37474F]">Contractor Details:</h3>
              <div className="mb-4">
                <label className="block text-[#37474F] font-poppins mb-2">Contract Type</label>
                <select
                  name="contractType"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contractType}
                  className="appearance-none w-full h-[50px] pl-6 pr-6 rounded-[500px] border border-solid border-[#00000042] focus:outline-none font-poppins text-[#37474F]"
                >
                  <option value="" disabled>Select</option>
                  {contract_types.map(option => (
                    <option key={option} value={option} label={option} />
                  ))}
                </select>
                {formik.touched.contractType && formik.errors.contractType ? (
                  <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.contractType}</div>
                ) : null}
              </div>
              <div className="flex space-x-4 mb-4">
                <div className="w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Seller Name</label>
                  <input
                    type="text"
                    name="sellerName"
                    placeholder='Enter Name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sellerName}
                    className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 pr-10 text-[#37474F]"
                  />
                  {formik.touched.sellerName && formik.errors.sellerName ? (
                    <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.sellerName}</div>
                  ) : null}
                </div>
                <div className="w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Buyer Name</label>
                  <input
                    type="text"
                    name="buyerName"
                    placeholder='Enter Name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.buyerName}
                    className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 pr-10 text-[#37474F]"
                  />
                  {formik.touched.buyerName && formik.errors.buyerName ? (
                    <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.buyerName}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex space-x-4 mb-4">
                <div className="relative w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Seller City</label>
                  <div className="relative">
                    <input
                      type="text"
                      name="sellerCity"
                      placeholder="Select your city"
                      value={formik.values.sellerCity}
                      onChange={(e) => formik.setFieldValue('sellerCity', e.target.value)}
                      onClick={() => setShowSellerDropdown(!showSellerDropdown)}
                      onBlur={() => setTimeout(() => setShowSellerDropdown(false), 100)} // Close dropdown on blur
                      className={`appearance - none w-full py-2 pl-12 pr-6 rounded-[500px] border border-solid border-[#00000042] focus:outline-none font-poppins ${formik.touched.sellerCity && formik.errors.sellerCity ? 'border-red-500' : ''}`}
                    />
                    <div className="absolute bottom-0 left-0 h-full flex items-center pl-4">
                      <img src={SearchIcon} alt="Search Icon" className="w-5 h-5" />
                    </div>
                    {showSellerDropdown && (
                      <ul className="absolute w-full bg-white border border-solid border-[#00000042] max-h-60 overflow-y-auto z-10">
                        {USA_CITIES
                          .filter(city => city.toLowerCase().includes(formik.values.sellerCity.toLowerCase()))
                          .map(city => (
                            <li
                              key={city}
                              className="p-2 cursor-pointer hover:bg-gray-200"
                              onClick={() => {
                                formik.setFieldValue('sellerCity', city);
                                setShowSellerDropdown(false);
                              }}
                            >
                              {city}
                            </li>
                          ))}
                      </ul>
                    )}
                    {formik.touched.sellerCity && formik.errors.sellerCity ? (
                      <div className="absolute top-full left-5 text-red-500 text-sm mt-2 font-poppins">{formik.errors.sellerCity}</div>
                    ) : null}
                  </div>
                </div>
                <div className="relative w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Buyer City</label>
                  <div className="relative mb-2">
                    <input
                      type="text"
                      name="buyerCity"
                      placeholder="Select your city"
                      value={formik.values.buyerCity}
                      onChange={(e) => formik.setFieldValue('buyerCity', e.target.value)}
                      onClick={() => setShowBuyerDropdown(!showBuyerDropdown)}
                      onBlur={() => setTimeout(() => setShowBuyerDropdown(false), 100)} // Close dropdown on blur
                      className={`appearance - none w-full py-2 pl-12 pr-6 rounded-[500px] border border-solid border-[#00000042] focus:outline-none font-poppins ${formik.touched.buyerCity && formik.errors.buyerCity ? 'border-red-500' : ''}`}
                    />
                    <div className="absolute bottom-0 left-0 h-full flex items-center pl-4">
                      <img src={SearchIcon} alt="Search Icon" className="w-5 h-5" />
                    </div>
                    {showBuyerDropdown && (
                      <ul className="absolute w-full bg-white border border-solid border-[#00000042] max-h-60 overflow-y-auto z-10">
                        {USA_CITIES
                          .filter(city => city.toLowerCase().includes(formik.values.buyerCity.toLowerCase()))
                          .map(city => (
                            <li
                              key={city}
                              className="p-2 cursor-pointer hover:bg-gray-200"
                              onClick={() => {
                                formik.setFieldValue('buyerCity', city);
                                setShowBuyerDropdown(false);
                              }}
                            >
                              {city}
                            </li>
                          ))}
                      </ul>
                    )}
                    {formik.touched.buyerCity && formik.errors.buyerCity ? (
                      <div className="absolute top-full left-5 text-red-500 text-sm mt-2 font-poppins">{formik.errors.buyerCity}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex space-x-4 mb-4">
                <div className="w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Seller Address</label>
                  <input
                    type="text"
                    name="sellerAddress"
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sellerAddress}
                    className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 pr-10 text-[#37474F]"
                  />
                  {formik.touched.sellerAddress && formik.errors.sellerAddress ? (
                    <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.sellerAddress}</div>
                  ) : null}
                </div>
                <div className="w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Buyer Address</label>
                  <input
                    type="text"
                    name="buyerAddress"
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.buyerAddress}
                    className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 pr-10 text-[#37474F]"
                  />
                  {formik.touched.buyerAddress && formik.errors.buyerAddress ? (
                    <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.buyerAddress}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="w-full border-t border-[#DFDFDF] my-4"></div>

            {/* Property Details Section */}
            <div>
              <h3 className="text-xl font-semibold mb-4 font-poppins text-[#37474F]">Property Details:</h3>
              <div className="flex space-x-4 mb-4">
                <div className="w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Property Type</label>
                  <select
                    name="propertyType"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.propertyType}
                    className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 text-[#37474F]"
                  >
                    <option value="" disabled>Select</option>
                    {propertyTypeOptions.map((opt, i) => (
                      <option key={`opt - ${i}`} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                  {formik.touched.propertyType && formik.errors.propertyType ? (
                    <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.propertyType}</div>
                  ) : null}
                </div>
                <div className="w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Property Location Address</label>
                  <div className='relative'>
                    <input
                      type="text"
                      name="propertyLocation"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.propertyLocation}
                      className="appearance-none w-full h-[40px] pl-12 pr-6 rounded-[500px] border border-solid border-[#00000042] focus:outline-none font-poppins text-[#37474F]"
                    />
                    <img src={SearchIcon} alt="Search Icon" className="absolute top-1/2 left-4 transform -translate-y-1/2 w-5 h-5" />
                  </div>
                  {formik.touched.propertyLocation && formik.errors.propertyLocation ? (
                    <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.propertyLocation}</div>
                  ) : null}
                </div>
              </div>

              <div className="w-full border-t border-[#DFDFDF] my-4"></div>

              {/* Amount Section */}
              <h3 className="text-xl font-semibold mb-4 font-poppins text-[#37474F]">Property Price:</h3>
              <div className="flex space-x-4 mb-4">
                <div className="w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Amount</label>
                  <input
                    type="number"
                    name="amount"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.amount}
                    className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 pr-10 text-[#37474F]"
                  />
                  {formik.touched.amount && formik.errors.amount ? (
                    <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.amount}</div>
                  ) : null}
                </div>
                <div className="w-1/2">
                  <label className="block text-[#37474F] font-poppins mb-2">Payment Method</label>
                  <select
                    name="paymentMethod"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.paymentMethod}
                    className={`w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 ${formik.touched.paymentMethod && formik.errors.paymentMethod ? 'border-red-500' : ''}`}
                  >
                    <option value="" disabled>Select</option>
                    {paymentOptions.map(method => (
                      <option key={method.value} value={method.value} label={method.label} />
                    ))}
                  </select>
                  {formik.touched.paymentMethod && formik.errors.paymentMethod ? (
                    <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.paymentMethod}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* Right column */}
          <div className="w-full md:w-[35%] mt-4 md:mt-0">
            <div className="flex justify-between space-x-4 mb-4">
              <button className="text-[#8E9C9C] font-poppins text-[16px] font-semibold leading-[22px]">
                Review Contract
              </button>
              {
                pdf ? (
                  <a href={pdf} download="contract.pdf" target="_blank" className="ml-auto cursor-pointer" rel="noreferrer">
                    <img src={DownloadIcon} alt="Icon" />
                  </a>
                ) : (
                  <img src={DownloadIcon} alt="Icon" className="ml-auto" />
                )
              }
            </div>
            <div className="bg-white h-[500px] p-4 rounded-lg relative flex items-center justify-center" style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)" }}>
              <img
                className="w-24 sm:w-[9.125rem] absolute top-4"
                alt="Group"
                src={AreaAIIcon}
              />
              {pdfLoading ? (
                <svg
                  className="animate-spin h-8 w-8 text-gray-600"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                (pdf && pdf.includes("storage.googleapis.com")) ||
                  (contract_data && contract_data?.contract_pdf_url && contract_data.contract_pdf_url.includes("storage.googleapis.com")) ? (
                  <iframe
                    name="contractPDF"
                    src={pdf || contract_data.contract_pdf_url}
                    title="Contract generated by AI"
                    className="w-full h-full object-cover rounded-lg"
                  />
                ) : null
              )}
            </div>
          </div>
        </div>

        {/* Buttons */}
        < div className="w-[80%] flex justify-between px-4 mb-12 mx-auto" >
          <button
            onClick={handleSaveAsDraft}
            disabled={pdfLoading}
            type="button"
            className="px-4 py-2 font-poppins text-[#8E9C9C] text-[14px] font-semibold bg-transparent">
            SAVE AS DRAFT
          </button>
          <button
            type="submit"
            disabled={pdfLoading}
            onClick={handleSend}
            style={{ backgroundColor: 'var(--Primary, #8E9C9C)' }}
            className="text-white px-4 py-2 rounded-full shadow focus:outline-none focus:shadow-outline font-poppins text-center text-sm">
            SEND
          </button>
        </div >
      </form >
    </>
  );
};

export default CreateContract;