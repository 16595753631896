import React from 'react';
import { AngularVector, AreaStudioLogo } from '../../assets/icons/icons';
import { Link } from 'react-router-dom';
import { ForSale, LockBox, QRCode } from '../../assets/images/images';

const StarterKit = () => {
  return (
    <div className="mx-auto font-poppins max-w-screen-xl pt-4">
      {/* Starter Kit section */}
      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between py-8">
        {/* Left Column */}
        <div className="flex flex-col items-center lg:items-start lg:w-1/2 mt-4">
          <h2 className="text-4xl font-bold leading-tight text-center lg:text-left">
            Starter Kit
          </h2>
          <p className="text-gray-600 text-base flex items-center space-x-2 justify-center lg:justify-start">
            <Link to="/" className="text-gray-600 font-normal whitespace-nowrap no-underline hover:underline font-poppins">Area</Link>
            <img src={AngularVector} alt="Location Icon" className="w-4 h-4" />
            <Link to="/area-studio/listing-enhancement" className="text-gray-600 font-normal whitespace-nowrap no-underline hover:underline font-poppins">Area Studio</Link>
            <img src={AngularVector} alt="Location Icon" className="w-4 h-4" />
            <span className="font-normal whitespace-nowrap">Starter Kit</span>
          </p>
        </div>
        {/* Right Column */}
        <div className="flex items-center lg:items-start lg:w-1/2 justify-center lg:justify-end mt-2 lg:mt-4">
          <img
            src={AreaStudioLogo}
            alt="AreA"
            width="169"
            height="101"
            className="object-cover"
          />
        </div>
      </div>

      {/* Starter Kit for Sellers */}
      <div className="w-full text-center py-4">
        <div className="font-space-grotesk text-[45px] font-bold text-[#1C1C1E]">
          Starter Kit for Sellers
        </div>
        <div className="font-poppins text-[18px] font-normal text-[#666666]">
          Choose from a variety of items to include in your kit.
        </div>
      </div>

      {/* Customizable Kits Section */}
      <div className="w-full text-center py-8">
        <h3 className="font-space-grotesk text-[32px] font-bold text-[#1C1C1E]">
          Customizable Kits
        </h3>
        <p className="font-poppins text-[18px] font-normal text-[#666666] mb-10">
          Choose from a variety of items to include in your kit.
        </p>

        {/* Sub Sections */}
        <div className="flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-y-0 lg:space-x-8">
          {/* Lock Box */}
          <div className="flex flex-col items-center max-w-[280px] max-h-[450px] overflow-hidden">
            <img
              src={LockBox}
              alt="Lock Box"
              className="w-[280px] h-[280px] rounded-[12px] object-cover mb-4"
            />
            <div className="font-poppins text-[18px] font-semibold text-[#37474F] text-center flex-grow">
              Lock Box
            </div>
            <div className="font-poppins text-[16px] font-normal text-[#666666] text-center flex-grow">
              Secure your property with our reliable lock boxes.
            </div>
            <button className="mt-4 px-3 py-2 bg-white text-[#8E9C9C] font-poppins text-[12px] font-normal rounded-full border border-[#8E9C9C]">
              Buy Now
            </button>
          </div>

          {/* For Sale */}
          <div className="flex flex-col items-center max-w-[280px] max-h-[450px] overflow-hidden">
            <img
              src={ForSale}
              alt="For Sale"
              className="w-[280px] h-[280px] rounded-[12px] object-cover mb-4"
            />
            <div className="font-poppins text-[18px] font-semibold text-[#37474F] text-center flex-grow">
              Solar-Powered For Sale Signs
            </div>
            <div className="font-poppins text-[16px] font-normal text-[#666666] text-center flex-grow">
              Eco-friendly signs with high visibility.
            </div>
            <button className="mt-4 px-3 py-2 bg-white text-[#8E9C9C] font-poppins text-[12px] font-normal rounded-full border border-[#8E9C9C]">
              Buy Now
            </button>
          </div>

          {/* QR Code */}
          <div className="flex flex-col items-center max-w-[280px] max-h-[450px] overflow-hidden">
            <img
              src={QRCode}
              alt="QR Code"
              className="w-[280px] h-[280px] rounded-[12px] object-cover mb-4"
            />
            <div className="font-poppins text-[18px] font-semibold text-[#37474F] text-center flex-grow">
              Customized QR Codes
            </div>
            <div className="font-poppins text-[16px] font-normal text-[#666666] text-center flex-grow">
              Generate personalized QR codes for easy access to property details.
            </div>
            <button className="mt-4 px-3 py-2 bg-white text-[#8E9C9C] font-poppins text-[12px] font-normal rounded-full border border-[#8E9C9C]">
              Buy Now
            </button>
          </div>
        </div>
      </div>

      {/* Direct Purchase section */}
      <div className="flex flex-col items-center text-center py-8 mx-auto max-w-[888px]">
        <h3 className="font-space-grotesk text-[32px] font-bold text-[#1C1C1E] mb-4">
          Direct Purchase
        </h3>
        <p className="font-poppins text-[18px] font-normal text-[#666666] mb-6 max-w-[778px]">
          Seamlessly integrate with our e-commerce platform to purchase your starter kit easily. Click on the button below to get started.
        </p>
        {/* Empty BG */}
        <div className="w-[840px] h-[509px] bg-[#cccccc] rounded-[12px] mb-2 sm:w-[90%] sm:max-w-[840px]"></div>
        <button className="mt-4 px-3 py-2 bg-white text-[#8E9C9C] font-poppins text-[12px] font-normal rounded-full border border-[#8E9C9C]">
          Visit Our e-Commerce Platform
        </button>
      </div>

      {/* AreA SmartSign 360 */}
      <div className="flex flex-col items-center w-full py-8">
        <h2 className="font-space-grotesk text-[32px] font-bold text-[#1C1C1E] mb-2 text-center">
          Introducing the AreA SmartSign 360:<br />
          <span className="block">Your Ultimate Real Estate and Security Solution</span>
        </h2>
        <p className="font-poppins text-[18px] font-normal text-[#666666] mb-8 max-w-[90%] mx-auto">
          Beyond Listings, Beyond Security: SmartSign 360
        </p>
        {/* Features section */}
        <div className="flex flex-col w-[90%] lg:flex-row justify-between space-y-8 lg:space-y-0 lg:space-x-8">
          {/* Left Section */}
          <div className="w-full lg:w-2/5 h-[40vh] flex flex-col justify-start p-6 rounded-[12px]">
            <h3 className="font-space-grotesk text-[42.76px] font-bold text-[#000000] mb-4">
              Key Features
            </h3>
            <p className="font-poppins text-[18px] font-normal text-[#666666]">
              The AreA SmartSign 360 has evolved, offering unparalleled surveillance, security, and interactive real estate marketing. This cutting-edge system combines advanced digital signage with comprehensive security features, extending protection and interaction throughout your property.
            </p>
          </div>
          {/* Right Section */}
          <div className="w-full lg:w-3/5 flex flex-col items-end space-y-8">
            {/* Card 1 */}
            <div className="flex flex-col items-start rounded-[12px] p-10 space-x-6 custom-border">
              <h4 className="font-nunito text-[20px] font-bold text-[#000000] mb-4">
                Advanced Surveillance and Security
              </h4>
              <ul className="list-disc space-y-2">
                <li>
                  <span className="font-nunito text-[16px] font-bold text-[#000000]">360-Degree Surveillance:</span> <span className="font-nunito text-[16px] font-normal text-[#000000]">Four cameras and a microphone monitor the front yard and street, with additional cameras throughout the home and a fisheye camera for the backyard.</span>
                </li>
                <li>
                  <span className="font-nunito text-[16px] font-bold text-[#000000]">Intruder Alert System:</span> <span className="font-nunito text-[16px] font-normal text-[#000000]">The live chatbot can speak directly to intruders or offenders, deterring them and ensuring immediate action.</span>
                </li>
                <li>
                  <span className="font-nunito text-[16px] font-bold text-[#000000]">Emergency Response:</span> <span className="font-nunito text-[16px] font-normal text-[#000000]">Integrated with a 911 call feature, ensuring swift action during emergencies.</span>
                </li>
              </ul>
            </div>
            {/* Card 2 */}
            <div className="flex flex-col items-start rounded-[12px] p-10 space-x-6 custom-border">
              <h4 className="font-nunito text-[20px] font-bold text-[#000000] mb-2">
                Interactive Real Estate Marketing<br />
                <span className="font-nunito text-[16px] font-bold text-[#000000] text-[#000000]">24/7 Real Estate Agent Bots - Marea and Max:</span>
              </h4>
              <ul className="list-disc space-y-2">
                <li>
                  <span className="font-nunito text-[16px] font-bold text-[#000000]">Engaging Conversations:</span>
                  <span className="font-nunito text-[16px] font-normal text-[#000000]">Marea and Max interact with interested buyers 24/7, providing real-time updates on the housing market, interest rates, neighborhood comparisons, and open house schedules.</span>
                </li>
                <li>
                  <span className="font-nunito text-[16px] font-bold text-[#000000]">Facial Recognition:</span>
                  <span className="font-nunito text-[16px] font-normal text-[#000000]">The bots utilize facial recognition technology to monitor the emotions and enthusiasm of buyers, gauging their interest and tailoring the conversation accordingly.</span>
                </li>
              </ul>
            </div>
            {/* Card 3 */}
            <div className="flex flex-col items-start rounded-[12px] p-10 space-x-6 custom-border">
              <h4 className="font-nunito text-[20px] font-bold text-[#000000] mb-4">
                Home Integration
              </h4>
              <ul className="list-disc space-y-2">
                <li>
                  <span className="font-nunito text-[16px] font-bold text-[#000000]">Indoor Screen and Chat Bot:</span> <span className="font-nunito text-[16px] font-normal text-[#000000]">An additional screen and chatbot in the kitchen, plugged in and ready to assist with inquiries and security.</span>
                </li>
                <li>
                  <span className="font-nunito text-[16px] font-bold text-[#000000]">Room Cameras:</span> <span className="font-nunito text-[16px] font-normal text-[#000000]">Cameras in each room offer complete coverage and monitoring.</span>
                </li>
              </ul>
            </div>
            {/* Card 4 */}
            <div className="flex flex-col items-start rounded-[12px] p-10 space-x-6 custom-border">
              <h4 className="font-nunito text-[20px] font-bold text-[#000000] mb-4">
                Solar-Powered Efficiency
              </h4>
              <ul className="list-disc space-y-2">
                <li>
                  <span className="font-nunito text-[16px] font-bold text-[#000000]">Extended AreA Solar Panel:</span> <span className="font-nunito text-[16px] font-normal text-[#000000]">Positioned vertically, extending out on each side to provide more surface area to the sun, enhancing energy efficiency and shading the digital screens.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <button className="mt-5 px-3 py-2 bg-white text-[#8E9C9C] font-poppins text-[12px] font-normal rounded-full border border-[#8E9C9C]">
          Join the future of real estate and home security with the AreA SmartSign 360.
        </button>
      </div>
    </div>
  );
}

export default StarterKit;
