import {
  AvalonBayLogo,
  CompassLogo,
  DouglasLogo,
  DrHortonLogo,
  EquinixLogo,
  ExpLogo,
  FathomLogo,
  KBHomeLogo,
  LennarLogo,
  NVRLogo,
  PrologisLogo,
  PulteGroupLogo,
  REMaxLogo,
  RealogyLogo,
  RedfinLogo,
  SimonGroupLogo,
  TollBrothersLogo,
  WellTowerLogo,
  AmericanTowerLogo,
  RealLogo,
} from "../assets/icons/icons";

export const realEstateCompanies = {
  'Reits (Real Estate Investment Trusts)': [
    { company_name: 'Prologis, Inc.', company_symbol: 'PLD', logo: PrologisLogo },
    { company_name: 'American Tower Corporation', company_symbol: 'AMT', logo: AmericanTowerLogo },
    { company_name: 'Equinix, Inc.', company_symbol: 'EQIX', logo: EquinixLogo },
    { company_name: 'Simon Property Group', company_symbol: 'SPG', logo: SimonGroupLogo },
    { company_name: 'Welltower Inc.', company_symbol: 'WELL', logo: WellTowerLogo },
    { company_name: 'AvalonBay Communities', company_symbol: 'AVB', logo: AvalonBayLogo }
  ],
  'Home Builders': [
    { company_name: 'Lennar Corporation', company_symbol: 'LEN', logo: LennarLogo },
    { company_name: 'D.R. Horton', company_symbol: 'DHI', logo: DrHortonLogo },
    { company_name: 'PulteGroup, Inc.', company_symbol: 'PHM', logo: PulteGroupLogo },
    { company_name: 'NVR, Inc.', company_symbol: 'NVR', logo: NVRLogo },
    { company_name: 'Toll Brothers, Inc.', company_symbol: 'TOL', logo: TollBrothersLogo },
    { company_name: 'KB Home', company_symbol: 'KBH', logo: KBHomeLogo }
  ],
  'Brokerages': [
    { company_name: 'Realogy Holdings Corp.', company_symbol: 'HOUS', logo: RealogyLogo },
    { company_name: 'RE/MAX Holdings, Inc.', company_symbol: 'RMAX', logo: REMaxLogo },
    { company_name: 'Redfin Corporation', company_symbol: 'RDFN', logo: RedfinLogo },
    { company_name: 'eXp World Holdings Inc.', company_symbol: 'EXPI', logo: ExpLogo },
    { company_name: 'Compass, Inc.', company_symbol: 'COMP', logo: CompassLogo },
    { company_name: 'Fathom Holdings Inc.', company_symbol: 'FTHM', logo: FathomLogo },
    { company_name: 'REAL', company_symbol: 'REAX', logo: RealLogo },
    { company_name: 'Douglas Elliman Inc', company_symbol: 'DOUG', logo: DouglasLogo }
  ]
};
