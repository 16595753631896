export const contract_types = [
  "Purchase Agreement",
  "Option to Purchase Agreement",
  "Contract for Deed (Land Contract)",
  "Lease Purchase Agreement",
  "Lease Option Agreement",
  "Right of First Refusal",
  "Real Estate Assignment Contract",
  "Quitclaim Deed",
  "Warranty Deed",
  "Special Warranty Deed"
];
