import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AngularVector, CrossIcon, DownLoadImageIcon, EditIcon, PlusIcon, PlusIconGrayBorder, RedElipse, SearchIcon } from '../../assets/icons/icons';
import {
  SellIcon, RentIcon,
} from '../../assets/icons/icons';
import { useFormik } from 'formik';
import { propertyConditions } from '../../constants/property/property_conditions';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';
import { PropertySchema } from '../../utils/yup/schemas';
import { useDispatch, useSelector } from 'react-redux';
import { addNewProperty, clearError, fetchDraftProperty, savePropertyAsDraft, updateProperty, uploadImages, uploadPDF } from '../../redux/slices/propertySlice';
import { formatPhoneNumber } from '../../utils/format_contact_number';
import { property_types } from '../../constants/property/property_types';
import { logout } from '../../redux/slices/authSlice';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';
import '@reach/combobox/styles.css';
import { useLoadScript } from '@react-google-maps/api';
import axios from 'axios';

const libraries = ['places'];

export default function AddProperty() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_AREA_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>
  return <AddPropertyForm />
}

function AddPropertyForm() {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();

  const { error, draft_property } = useSelector((state) => state.properties);
  const { property, type } = location.state || {};
  const isUpdate = type === 'update';

  const dropdownRef = useRef(null);
  const formikRefs = {
    city: useRef(null),
    area: useRef(null),
    size: useRef(null),
    bedrooms: useRef(null),
    bathrooms: useRef(null),
    garage: useRef(null),
    built_year: useRef(null),
    property_name: useRef(null),
    condition: useRef(null),
    images: useRef(null),
    pdf: useRef(null),
    contact_number: useRef(null),
    name: useRef(null),
    asking_price: useRef(null),
  };

  const [bedroomRange, setBedroomRange] = useState('1-10');
  const [bathroomRange, setBathroomRange] = useState('1-10');
  const [garageRange, setGarageRange] = useState('1-10');
  const [showSearchField, setShowSearchField] = useState(false);
  const [selectedButton, setSelectedButton] = useState('accurate');
  const [addressValue, setAddressValue] = useState(draft_property?.MLSListingAddress || property?.MLSListingAddress || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [enhancedImages, setEnhancedImages] = useState([]);

  useEffect(() => {
    if (!isUpdate) {
      dispatch(fetchDraftProperty())
    }
  }, [dispatch, isUpdate])

  useEffect(() => {
    if (draft_property)
      setAddressValue(draft_property?.MLSListingAddress || '')
  }, [draft_property])

  useEffect(() => {
    if (property?.MLSListingAddress) {
      setAddressValue(property.MLSListingAddress);
    }
  }, [property?.MLSListingAddress]);

  const handleClick = (button) => {
    setSelectedButton(button);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    setAddressValue(address)
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);

      const addressComponents = results[0].address_components;

      let city = '';
      let postalCode = '';

      addressComponents.forEach(component => {
        const types = component.types;

        if (types.includes('locality')) {
          city = component.long_name;
        }

        if (types.includes('postal_code')) {
          postalCode = component.long_name;
        }
      });

      formik.setFieldValue('area', address);
      formik.setFieldValue('city', city);
      formik.setFieldValue('latitude', parseFloat(lat.toFixed(2)));
      formik.setFieldValue('longitude', parseFloat(lng.toFixed(2)));
      formik.setFieldValue('zip_code', postalCode);
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };

  const getImages = (property_images) => {
    const { PhotoURLPrefix, PhotoKey, PhotosCount } = property_images;

    if (PhotosCount > 0) {
      const urls = [];

      for (let i = 1; i <= PhotosCount; i++) {
        urls.push(`${PhotoURLPrefix}${PhotoKey}/photo_${i}.jpg`);
      }

      return urls;
    }
    return [];
  }

  const getFeatures = (features) => {
    if (!features) return []

    const resultArray = features
      .split(',')
      .map(item => item.trim())
      .filter(item => item.length > 0);
    return resultArray
  }

  const handleProperty = async (values, response) => {
    const formData = new FormData();
    if (values.pdf) {
      const pdfData = new FormData();
      pdfData.append('directory', 'property');
      pdfData.append('property_id', response.payload.PropertyID);
      pdfData.append('pdf', values.pdf);
      dispatch(uploadPDF(pdfData));
    }

    let public_urls = []
    if (enhancedImages.length > 0) {
      enhancedImages.forEach((image) => {
        public_urls.push(image)
      })
      formData.append('public_urls', JSON.stringify(public_urls));
      formData.append('count', public_urls.length);
    } else {
      formData.append('count', values.images.length);
      values.images.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });

    }

    formData.append('directory', 'property');
    formData.append('property_id', response.payload.PropertyID);
    dispatch(uploadImages(formData));
    navigate('/my-properties');
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      propertyType: property?.NumberOfLeaseOption === 0 || draft_property?.NumberOfLeaseOption === 0 ? 'sell' : 'rent',
      propertyKind: property?.MLSPropertySubType || draft_property?.MLSPropertySubType || 'residential',
      specificType: property?.MLSPropertyType || draft_property?.MLSPropertyType || '',
      city: property?.MLSListingCity || draft_property?.MLSListingCity || '',
      area: property?.MLSListingAddress || draft_property?.MLSListingAddress || '',
      bedrooms: property?.BedroomsTotal || draft_property?.BedroomsTotal || '',
      bathrooms: property?.BathroomsFull || draft_property?.BathroomsFull || '',
      garage: property?.GarageSpaces || draft_property?.GarageSpaces || '',
      built_year: property?.YearBuilt || draft_property?.YearBuilt || 2000,
      property_name: property?.LegalDetails || draft_property?.LegalDetails || '',
      condition: property?.PropertyConditionDetails || draft_property?.PropertyConditionDetails || '',
      feature: '',
      features: property ? getFeatures(property.BuildingKeywords) : draft_property ? getFeatures(draft_property.BuildingKeywords) : [],
      loveAboutPlace: property?.LotFeatureList || draft_property?.LotFeatureList || '',
      size: property?.LotSizeSquareFeet || draft_property?.LotSizeSquareFeet || 0,
      images: property ? getImages(property) : draft_property ? getImages(draft_property) : [],
      contact_number: property?.ListingAgentPreferredPhone || draft_property?.ListingAgentPreferredPhone || null,
      name: property?.ListingAgentFullName || draft_property?.ListingAgentFullName || '',
      asking_price: property?.OriginalListingPrice || draft_property?.OriginalListingPrice || 0,
      showDropdown: false,
      zip_code: property?.MLSListingZip || draft_property?.MLSListingZip || '',
      latitude: property?.Latitude || draft_property?.Latitude || null,
      longitude: property?.Longitude || draft_property?.Longitude || null,
      pdf: property?.Attachment || draft_property?.Attachment || null,
    },
    validationSchema: PropertySchema,
    onSubmit: async (values) => {
      const payload = {
        NumberOfLeaseOption: values.propertyType === 'rent' ? 1 : 0,
        MLSPropertySubType: values.propertyKind,
        MLSPropertyType: values.specificType,
        MLSListingCity: values.city,
        MLSListingAddress: values.area,
        LotSizeSquareFeet: values.size,
        BedroomsTotal: values.bedrooms,
        BathroomsFull: values.bathrooms,
        GarageSpaces: values.garage,
        YearBuilt: values.built_year,
        LegalDetails: values.property_name,
        PropertyConditionDetails: values.condition,
        BuildingKeywords: values.features.join(', '),
        LotFeatureList: values.loveAboutPlace,
        ListingAgentPreferredPhone: values.contact_number,
        ListingAgentFullName: values.name,
        OriginalListingPrice: values.asking_price,
        MLSListingZip: values.zip_code,
        Latitude: values.latitude,
        Longitude: values.longitude,
      }

      setIsSubmitting(true)

      if (isUpdate) {
        let public_urls = []
        let new_images = []

        if (enhancedImages.length > 0) {
          public_urls = enhancedImages;
        } else {
          formik.values.images.forEach(img => {
            if (typeof img === 'string')
              public_urls.push(img)
            else
              new_images.push(img)
          })
        }

        payload.PhotosCount = public_urls.length + new_images.length;
        const formData = new FormData();
        new_images.forEach((image, index) => {
          formData.append(`images[${index}]`, image);
        });


        formData.append('payload', JSON.stringify(payload));
        formData.append('public_urls', JSON.stringify(public_urls));

        await dispatch(updateProperty({ id: property.PropertyID, formData }));
        setIsSubmitting(false)
        navigate(`/properties/${property.PropertyID}`);
      } else {
        const response = await dispatch(addNewProperty(payload));
        setIsSubmitting(false)
        if (addNewProperty.fulfilled.match(response)) {
          handleProperty(values, response)
        }
      }
    },
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        formik.setFieldValue('showDropdown', false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, formik]);

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    formik.setFieldValue('contact_number', formatPhoneNumber(value));
  };

  const handleFeatureRemove = (feature) => {
    formik.setFieldValue('features', formik.values.features.filter(ft => ft !== feature));
  };

  const handleEnhancedImageRemove = (image) => {
    setEnhancedImages(prevImages => prevImages.filter(img => img.original !== image.original));
  };

  const handleSearchFieldKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newFeature = event.target.value.trim();
      if (newFeature !== '') {
        const featureExists = formik.values.features.some(tag => tag.toLowerCase() === newFeature.toLowerCase());
        if (featureExists) {
          showAllNotifications('Feature already exists', ToastColors.info)
          return;
        }
        formik.setFieldValue('feature', '');
        formik.setFieldValue('features', [...formik.values.features, newFeature]);
      }
    }
  };

  const handleSpecificType = () => {
    formik.setFieldTouched('specificType', false);
    formik.setFieldValue('specificType', '');
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    formik.setFieldValue('images', [...formik.values.images, ...files]);
  };

  const handlePDFFileChange = (event) => {
    formik.setFieldValue('pdf', event.target.files[0]);
  };

  const handleSaveAsDraft = () => {
    setIsLoading(true)
    const payload = {
      NumberOfLeaseOption: formik.values.propertyType === 'rent' ? 1 : 0,
      MLSPropertySubType: formik.values.propertyKind,
      MLSPropertyType: formik.values.specificType,
      MLSListingCity: formik.values.city,
      MLSListingAddress: formik.values.area,
      LotSizeSquareFeet: formik.values.size,
      BedroomsTotal: formik.values.bedrooms ? formik.values.bedrooms : 0,
      BathroomsFull: formik.values.bathrooms ? formik.values.bathrooms : 0,
      GarageSpaces: formik.values.garage ? formik.values.garage : 0,
      YearBuilt: formik.values.built_year,
      LegalDetails: formik.values.property_name,
      PropertyConditionDetails: formik.values.condition,
      BuildingKeywords: formik.values.features.join(', '),
      LotFeatureList: formik.values.loveAboutPlace,
      ListingAgentPreferredPhone: formik.values.contact_number ? formik.values.contact_number : '000-000-0000',
      ListingAgentFullName: formik.values.name,
      OriginalListingPrice: formik.values.asking_price,
      MLSListingZip: formik.values.zip_code,
      Latitude: formik.values.latitude,
      Longitude: formik.values.longitude,
      PropertyStatus: 'draft',
      pdf: formik.values.pdf
    }

    let public_urls = []
    let new_images = []

    if (enhancedImages.length > 0) {
      public_urls = enhancedImages;
    } else {
      formik.values.images.forEach(img => {
        if (typeof img === 'string')
          public_urls.push(img)
        else
          new_images.push(img)
      })
    }

    payload.PhotosCount = public_urls.length + new_images.length;
    const formData = new FormData();
    new_images.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    formData.append('payload', JSON.stringify(payload));
    formData.append('public_urls', JSON.stringify(public_urls));

    dispatch(savePropertyAsDraft(formData)).unwrap()
      .then((response) => {
        if (payload.pdf) {
          const pdfData = new FormData();
          pdfData.append('directory', 'property');
          pdfData.append('property_id', response.property.PropertyID);
          pdfData.append('pdf', payload.pdf);

          dispatch(uploadPDF(pdfData));
        }
        setIsLoading(false);
        showAllNotifications("Property has been drafted successfully", ToastColors.success);
        navigate('/my-properties');
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Failed to draft property', error);
        showAllNotifications("Failed to draft property", ToastColors.error)
      });
  }

  const handleEnhanceImages = async () => {
    if (formik.values.images.length === 0) {
      showAllNotifications('No Images Uploaded');
      return;
    }

    const enhancedImageUrls = [];
    showAllNotifications('Please wait while we enhance your images');
    setIsGeneratingImages(true);

    for (const img of formik.values.images) {
      try {
        if (typeof img === 'string') {
          const URL = 'https://api.claid.ai/v1-beta1/image/edit';
          const headers = {
            'Authorization': `Bearer ${process.env.REACT_APP_IMAGE_ENHANCEMENT_API_KEY}`,
            'Content-Type': 'application/json'
          };
          const data = {
            "input": img,
            "operations": {
              "restorations": {
                "upscale": "smart_enhance"
              }
            },
            "output": {
              "format": {
                "type": "jpeg",
                "quality": 80,
                "progressive": false
              }
            }
          };

          const result = await axios.post(URL, data, { headers });
          if (result.status === 200) {
            enhancedImageUrls.push(result.data.data.output.tmp_url);
          } else {
            throw new Error('Failed to enhance image');
          }
        } else {
          const formData = new FormData();
          formData.append('file', img);
          formData.append('data', JSON.stringify({
            "operations": {
              "restorations": {
                "upscale": "smart_enhance"
              }
            },
            "output": {
              "format": {
                "type": "jpeg",
                "quality": 80,
                "progressive": false
              }
            }
          }));

          const URL = 'https://api.claid.ai/v1-beta1/image/edit/upload/';
          const response = await fetch(URL, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_IMAGE_ENHANCEMENT_API_KEY}`
            },
            body: formData
          });

          if (response.ok) {
            const result = await response.json();
            enhancedImageUrls.push(result.data.output.tmp_url);
          } else {
            throw new Error('Failed to enhance image');
          }
        }
      } catch (error) {
        console.error('Error during image enhancement:', error);
        showAllNotifications('There was an error in enhancing your images!', ToastColors.error);
        setIsGeneratingImages(false);
        return;
      }
    }

    showAllNotifications('Your images have been enhanced successfully!', ToastColors.success);
    setEnhancedImages(enhancedImageUrls);
    setIsGeneratingImages(false);
  };


  const handleDownload = async (url) => {
    try {
      const response = await fetch(url, {
        mode: 'cors',
      });
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = url.split('/').pop(); // Sets the filename as the last part of the URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed", error);
    }
  };

  useEffect(() => {
    return () => {
      formik.values.images.forEach((image) => {
        if (typeof image !== 'string') {
          URL.revokeObjectURL(image);
        }
      });
    };
  }, [formik.values.images]);


  if (error) {
    if (error?.code === 'token_not_valid') {
      showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
      dispatch(logout());
      dispatch(clearError());
      navigate('/login');
    }
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="w-[80%] mx-auto">

      {/* Post new property section */}
      <div className="flex flex-wrap items-center justify-between py-10 max-w-screen-xl mx-auto my-10 px-4">
        {/* Left Column */}
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <h2 className="text-4xl font-semibold text-gray-900 mb-2 font-poppins">
            {!isUpdate ? 'Post New Property' : 'Update Property'}
          </h2>
          <p className="text-gray-600 text-base flex items-center space-x-2">
            <Link to="/" className="text-gray-600 no-underline hover:underline font-poppins">Home</Link>
            <img src={AngularVector} alt="Location Icon" />
            <Link to="/my-properties" className="text-gray-600 no-underline hover:underline font-poppins">My Properties</Link>
            <img src={AngularVector} alt="Location Icon" />
            <span className="font-poppins">
              {!isUpdate ? 'Post New Property' : 'Update Property'}
            </span>
          </p>
        </div>

        {/* Right Column */}
        <div className="w-full md:w-auto flex justify-end md:justify-start">
          <button
            style={{ backgroundColor: 'var(--Primary, #8E9C9C)' }}
            className="text-white py-3 px-4 rounded-full shadow focus:outline-none focus:shadow-outline font-poppins text-center text-sm font-semibold leading-6">
            USE AI TO CREATE YOUR ADD
          </button>
        </div>
      </div>

      {/* Property details section */}
      <div className="flex flex-wrap justify-between items-start max-w-screen-xl mx-auto my-10 px-4">

        {/* Left Column */}
        <div className="w-full md:w-[70%]">

          {/* What do you want to do */}
          <div className="mb-6">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F]">
              What do you want to do?
            </h1>
          </div>

          {/* Buttons */}
          <div className="flex space-x-2 mb-12">
            <button
              type="button"
              className="flex items-center gap-2 w-[103.58px] h-[43px] px-[22.16px] py-[10.71px] border rounded-lg font-poppins focus:outline-none"
              onClick={() => formik.setFieldValue('propertyType', 'sell')}
              style={{
                border: formik.values.propertyType === 'sell' ? '1px solid #8E9C9C' : '1px solid #F2F2F2',
                opacity: formik.values.propertyType === 'sell' ? 1 : 0.6,
              }}
            >
              <img src={SellIcon} alt="Sell Icon" />
              <span className="text-sm">Sell</span>
            </button>
            <button
              type="button"
              className="flex items-center gap-2 w-[103.58px] h-[43px] px-[22.16px] py-[10.71px] border rounded-lg font-poppins focus:outline-none"
              onClick={() => formik.setFieldValue('propertyType', 'rent')}
              style={{
                border: formik.values.propertyType === 'rent' ? '1px solid #8E9C9C' : '1px solid #F2F2F2',
                opacity: formik.values.propertyType === 'rent' ? 1 : 0.6,
              }}
            >
              <img src={RentIcon} alt="Rent Icon" />
              <span className="text-sm">Rent</span>
            </button>
          </div>

          {/* What kind of property do you have? */}
          <div className="mb-6">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F]">
              What kind of property do you have?
            </h1>
          </div>

          {/* Property Kind Buttons */}
          <div className="flex space-x-2 mb-12">
            <button
              type="button"
              className="flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none"
              onClick={() => { formik.setFieldValue('propertyKind', 'residential'); handleSpecificType(); }}
              style={{
                background: formik.values.propertyKind === 'residential' ? '#8E9C9C' : 'transparent',
                border: formik.values.propertyKind === 'residential' ? '1px solid #8E9C9C' : '1px solid #C8CBD0',
                opacity: formik.values.propertyKind === 'residential' ? 1 : 0.6,
                color: formik.values.propertyKind === 'residential' ? 'white' : 'black', // Set text color
              }}
            >
              <span className="text-sm">Residential</span>
            </button>
            <button
              type="button"
              className="flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none"
              onClick={() => { formik.setFieldValue('propertyKind', 'plot'); handleSpecificType(); }}
              style={{
                background: formik.values.propertyKind === 'plot' ? '#8E9C9C' : 'transparent',
                border: formik.values.propertyKind === 'plot' ? '1px solid #8E9C9C' : '1px solid #C8CBD0',
                opacity: formik.values.propertyKind === 'plot' ? 1 : 0.6,
                color: formik.values.propertyKind === 'plot' ? 'white' : 'black', // Set text color
              }}
            >
              <span className="text-sm">Plot</span>
            </button>
            <button
              type="button"
              className="flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none"
              onClick={() => { formik.setFieldValue('propertyKind', 'commercial'); handleSpecificType(); }}
              style={{
                background: formik.values.propertyKind === 'commercial' ? '#8E9C9C' : 'transparent',
                border: formik.values.propertyKind === 'commercial' ? '1px solid #8E9C9C' : '1px solid #C8CBD0',
                opacity: formik.values.propertyKind === 'commercial' ? 1 : 0.6,
                color: formik.values.propertyKind === 'commercial' ? 'white' : 'black', // Set text color
              }}
            >
              <span className="text-sm">Commercial</span>
            </button>
          </div>


          {/* Specific Type Buttons */}
          <div className="relative mb-5">
            <div className="flex flex-wrap">
              {property_types[formik.values.propertyKind].map(({ label, icon }) => (
                <button
                  name='specificType'
                  type="button"
                  key={label}
                  className="flex items-center gap-2 mr-2 mb-3 px-[20px] py-[16px] border rounded-lg font-poppins focus:outline-none"
                  onClick={() => formik.setFieldValue('specificType', label)}
                  style={{
                    background: formik.values.specificType === label ? 'white' : 'transparent',
                    border: formik.values.specificType === label ? '1px solid #8E9C9C' : '1px solid #C8CBD0',
                    opacity: formik.values.specificType === label ? 1 : 0.6,
                  }}
                >
                  <img src={icon} alt={`${label} Icon`} />
                  <span className="text-sm">{label}</span>
                </button>
              ))}
            </div>
            {formik.touched.specificType && formik.errors.specificType ? (
              <div className="absolute top-full left-0 text-red-500 text-sm mt-2 ml-2 font-poppins">{formik.errors.specificType}</div>
            ) : null}
          </div>

          {/* What area is your property in? */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              Property Address
            </h1>
            {/* Search bar for area */}
            <div className="relative w-[50%]">
              {<Combobox onSelect={handleSelect}>
                <ComboboxInput
                  value={addressValue}
                  autoComplete='off'
                  onChange={(e) => {
                    setAddressValue(e.target.value);
                    setValue(e.target.value);
                    formik.setFieldTouched('area', true)
                    formik.handleChange(e);
                  }}
                  disabled={!ready}
                  placeholder="Search and find"
                  className={`w-full h-[50px] text-[14px] pl-12 pr-6 rounded-[500px] border border-solid border-[#00000042] focus:outline-none ml-2 font-poppins ${formik.touched.area && formik.errors.area ? 'border-red-500' : ''
                    }`}
                  name="area"
                />
                <div className="absolute top-0 left-0 h-full flex items-center pl-6">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    className="w-5 h-5"
                  />
                </div>
                <ComboboxPopover>
                  {status === 'OK' && (
                    <ComboboxList>
                      {data.map(({ place_id, description }) => (
                        <ComboboxOption key={place_id} value={description} />
                      ))}
                    </ComboboxList>
                  )}
                </ComboboxPopover>
              </Combobox>
              }
              {formik.touched.area && formik.errors.area ? (
                <div className="absolute top-full left-5 text-red-500 text-sm mt-2 font-poppins">{formik.errors.area}</div>
              ) : null}
            </div>
          </div>

          {/* What is the size of your property ? */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              What is the size of your property?
            </h1>
            <div className="relative flex items-center mb-5">
              <div className="absolute left-4">
                <span className="font-poppins text-[15px] ml-4">SqFt</span>
              </div>
              <input
                ref={formikRefs.size}
                type="number"
                min="1"
                placeholder="Enter size"
                className="w-[50%] h-[50px] text-[14px] pl-20 pr-[70px] rounded-[500px] border border-solid border-[#00000042] focus:outline-none ml-2 font-poppins"
                name="size"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.size}
              />
              <div className="absolute right-4 flex items-center">
                <span className="font-poppins text-[20px]"></span>
              </div>
              {formik.touched.size && formik.errors.size ? (
                <div className="absolute top-full left-5 text-red-500 text-sm mt-2 font-poppins">{formik.errors.size}</div>
              ) : null}
            </div>
          </div>

          {/* How many bedrooms does it have? */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              How many bedrooms does it have?
            </h1>
            <div className="flex flex-wrap gap-2">
              {bedroomRange === '1-10' ? (
                Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
                  <button
                    ref={formikRefs.bedrooms}
                    key={number}
                    type="button"
                    className={`flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none ${formik.values.bedrooms === number ? 'bg-gray-800 text-white border-gray-800' : 'bg-transparent border-gray-300'}`}
                    onClick={() => formik.setFieldValue('bedrooms', number)}
                  >
                    <span className="text-sm">{number}</span>
                  </button>
                ))
              ) : (
                Array.from({ length: 10 }, (_, i) => i + 11).map((number) => (
                  <button
                    ref={formikRefs.bedrooms}
                    key={number}
                    type="button"
                    className={`flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none ${formik.values.bedrooms === number ? 'bg-gray-800 text-white border-gray-800' : 'bg-transparent border-gray-300'}`}
                    onClick={() => formik.setFieldValue('bedrooms', number)}
                  >
                    <span className="text-sm">{number}</span>
                  </button>
                ))
              )}
              <button
                type="button"
                className={`flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none ${bedroomRange === '10+' ? 'bg-gray-800 text-white border-gray-800' : 'bg-transparent border-gray-300'}`}
                onClick={() => setBedroomRange(bedroomRange === '1-10' ? '11-20' : '1-10')}
              >
                <span className="text-sm">10+</span>
              </button>
            </div>
            {formik.touched.bedrooms && formik.errors.bedrooms ? (
              <div className="text-red-500 text-sm mt-2 font-poppins">{formik.errors.bedrooms}</div>
            ) : null}

          </div>

          {/* How many bathrooms does it have? */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              How many bathrooms does it have?
            </h1>
            <div className="flex flex-wrap gap-2">
              {bathroomRange === '1-10' ? (
                Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
                  <button
                    ref={formikRefs.bathrooms}
                    key={number}
                    type="button"
                    className={`flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none ${formik.values.bathrooms === number ? 'bg-gray-800 text-white border-gray-800' : 'bg-transparent border-gray-300'}`}
                    onClick={() => formik.setFieldValue('bathrooms', number)}
                  >
                    <span className="text-sm">{number}</span>
                  </button>
                ))
              ) : (
                Array.from({ length: 10 }, (_, i) => i + 11).map((number) => (
                  <button
                    ref={formikRefs.bathrooms}
                    key={number}
                    type="button"
                    className={`flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none ${formik.values.bathrooms === number ? 'bg-gray-800 text-white border-gray-800' : 'bg-transparent border-gray-300'}`}
                    onClick={() => formik.setFieldValue('bathrooms', number)}
                  >
                    <span className="text-sm">{number}</span>
                  </button>
                ))
              )}
              <button
                type="button"
                className={`flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none ${bathroomRange === '10+' ? 'bg-gray-800 text-white border-gray-800' : 'bg-transparent border-gray-300'}`}
                onClick={() => setBathroomRange(bathroomRange === '1-10' ? '11-20' : '1-10')}
              >
                <span className="text-sm">10+</span>
              </button>
            </div>
            {formik.touched.bathrooms && formik.errors.bathrooms ? (
              <div className="text-red-500 text-sm mt-2 font-poppins">{formik.errors.bathrooms}</div>
            ) : null}
          </div>

          {/* How many garages does it have? */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              How many garages does it have?
            </h1>
            <div className="flex flex-wrap gap-2">
              {garageRange === '1-10' ? (
                Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
                  <button
                    ref={formikRefs.garage}
                    key={number}
                    type="button"
                    className={`flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none ${formik.values.garage === number ? 'bg-gray-800 text-white border-gray-800' : 'bg-transparent border-gray-300'}`}
                    onClick={() => formik.setFieldValue('garage', number)}
                  >
                    <span className="text-sm">{number}</span>
                  </button>
                ))
              ) : (
                Array.from({ length: 10 }, (_, i) => i + 11).map((number) => (
                  <button
                    ref={formikRefs.garage}
                    key={number}
                    type="button"
                    className={`flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none ${formik.values.garage === number ? 'bg-gray-800 text-white border-gray-800' : 'bg-transparent border-gray-300'}`}
                    onClick={() => formik.setFieldValue('garage', number)}
                  >
                    <span className="text-sm">{number}</span>
                  </button>
                ))
              )}
              <button
                type="button"
                className={`flex items-center gap-2 px-[20px] py-[8px] border rounded-full font-poppins focus:outline-none ${garageRange === '10+' ? 'bg-gray-800 text-white border-gray-800' : 'bg-transparent border-gray-300'}`}
                onClick={() => setGarageRange(garageRange === '1-10' ? '11-20' : '1-10')}
              >
                <span className="text-sm">10+</span>
              </button>
            </div>
          </div>

          {/* Year Built */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              In which year House Built?
            </h1>
            <div className="relative w-[50%]">
              <input
                ref={formikRefs.built_year}
                type="number"
                step="1"
                name="built_year"
                value={formik.values.built_year}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Built Year"
                className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 pr-10"
              />
              <span className="absolute right-12 top-1/2 transform -translate-y-1/2 text-gray-500">YEAR</span>
            </div>
            {formik.touched.built_year && formik.errors.built_year ? (
              <div className="text-red-500 text-sm ml-2 mt-2 font-poppins">{formik.errors.built_year}</div>
            ) : null}
          </div>

          {/* Name your property */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              Name your property
            </h1>
            <div className="relative w-[50%]">
              <input
                ref={formikRefs.property_name}
                type="text"
                name="property_name"
                value={formik.values.property_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Name your property"
                className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 pr-10"
              />
              <span className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <img src={EditIcon} alt="Edit Icon" className="w-5 h-5" />
              </span>
            </div>
            {formik.touched.property_name && formik.errors.property_name ? (
              <div className="text-red-500 text-sm ml-2 mt-2 font-poppins">{formik.errors.property_name}</div>
            ) : null}
          </div>

          {/* What is the condition of your property */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              What is the condition of your property?
            </h1>
            <div className="relative w-[50%]">
              <select
                ref={formikRefs.condition}
                name="condition"
                className={`appearance-none w-full h-[50px] pl-6 pr-6 rounded-[500px] border border-solid border-[#00000042] focus:outline-none font-poppins ${formik.touched.condition && formik.errors.condition ? 'border-red-500' : ''}`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.condition}
              >
                <option value="" label="Select condition" />
                {propertyConditions.map(condition => (
                  <option key={condition} value={condition} label={condition} />
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4 text-gray-600">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M7 10l5 5 5-5H7z" />
                </svg>
              </div>
            </div>

            {formik.touched.condition && formik.errors.condition ? (
              <div className="text-red-500 text-sm ml-2 mt-2 font-poppins">{formik.errors.condition}</div>
            ) : null}
          </div>

          {/* What features are available? */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              What amenities are available?
            </h1>
            <p className="font-poppins text-[12px] leading-[19.8px] text-left text-[#737678]">
              Add additional features e.g balcony, utilities, security details etc. (Optional)
            </p>
            <button
              type="button"
              className="flex items-center font-poppins text-[14px] font-semibold leading-[23.8px] text-left text-[#737678] bg-white border-none p-0"
              onClick={() => setShowSearchField(!showSearchField)}
            >
              <img src={PlusIcon} alt="Add Icon" className="w-5 h-5 mr-3" />
              ADD FEATURES
            </button>

            {showSearchField && (
              <input
                type="text"
                name="feature"
                className="w-[50%] text-[12px] mt-2 mb-2 px-3 py-2 border border-solid border-[#00000042] rounded-[500px] focus:outline-none font-poppins"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.feature}
                onKeyDown={handleSearchFieldKeyDown}
              />
            )}

            <div className="mt-2">
              {formik.values.features.map((feature, index) => (
                <div key={index} className="relative inline-block mr-4">
                  <span className="bg-gray-200 text-gray-800 text-sm font-poppins px-3 py-1 rounded-full mr-2 mb-2">
                    {feature}
                  </span>
                  <div className="absolute top-[-10px] right-[-10px] flex items-center justify-center w-[30px] h-[30px]">
                    <img src={RedElipse} alt="Red Ellipse" className="absolute w-6 h-6" />
                    <button
                      onClick={() => handleFeatureRemove(feature)} // Replace with your remove handler
                      className="relative z-10 rounded-full p-1 "
                    >
                      <img src={CrossIcon} alt="Remove Icon" className="w-2 h-2" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* What do you love about the place? */}

          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              What do you love about the place?
            </h1>
            <textarea
              name="loveAboutPlace"
              placeholder='Optional'
              className="w-[50%] mt-2 px-3 py-2 text-[12px] border border-solid border-[#00000042] rounded-[10px] bg-white focus:outline-none font-poppins h-[150px] resize-none"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.loveAboutPlace}
            />
          </div>

          {/* Upload images of your property */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              Upload images of your property
            </h1>
            <p className="font-poppins text-[12px] leading-[19.8px] text-left text-[#737678] mb-2">
              Properties with images of good quality generate <span className='font-bold'>8X more leads.</span>
            </p>
            <p className="font-poppins text-[12px] leading-[19.8px] text-left text-[#737678] mb-3">
              Supported file formats: png, jpeg, heic
            </p>
            {/* Upload images button */}
            <div className="flex items-start space-x-4">
              <label className="w-[120px] h-[145px] border border-solid border-[#E1E4E8] flex flex-col items-center justify-center cursor-pointer rounded-lg">
                <input
                  ref={formikRefs.images}
                  type="file"
                  name="images"
                  accept=".jpg"
                  multiple
                  onChange={handleFileChange}
                  className="hidden"
                />
                <img src={PlusIconGrayBorder} alt="Add Icon" className="w-8 h-8 mb-3" />
                <span className="font-poppins text-[15px] text-[#8E9C9C] font-medium leading-[22.13px] text-center mt-1">Upload</span>
                <span className="font-poppins text-[15px] text-[#8E9C9C] font-medium leading-[22.13px] text-center mt-1">image</span>
              </label>

              {/* Display uploaded images */}
              <div className="w-full flex flex-wrap gap-4">
                {formik.values.images.map((image, index) => {
                  const imageUrl = typeof image === 'string' ? image : URL.createObjectURL(image);
                  return (
                    <div key={index} className="relative w-[120px] h-[145px]">
                      <img
                        src={imageUrl}
                        alt={`Uploaded ${index}`}
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <div className="absolute top-[-10px] right-[-10px] flex items-center justify-center w-[30px] h-[30px]">
                        <img
                          src={RedElipse}
                          alt="Red Ellipse"
                          className="absolute w-full h-full"
                        />
                        <button
                          type='button'
                          onClick={() => {
                            const updatedImages = formik.values.images.filter((_, i) => i !== index);
                            formik.setFieldValue('images', updatedImages);
                          }}
                          className="relative z-10 rounded-full p-1"
                        >
                          <img
                            src={CrossIcon}
                            alt="Remove Icon"
                            className="w-3 h-3"
                          />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {formik.touched.images && formik.errors.images ? (
              <div className="text-red-500 text-sm mt-2 font-poppins">{formik.errors.images}</div>
            ) : null}
            <div className="w-full md:w-auto flex md:justify-start mt-4">
              <button
                onClick={handleEnhanceImages}
                disabled={isGeneratingImages}
                style={{ backgroundColor: 'var(--Primary, #8E9C9C)' }}
                className="text-white py-3 px-4 rounded-full shadow focus:outline-none focus:shadow-outline font-poppins text-center text-sm font-semibold leading-6"
              >
                {isGeneratingImages ? (
                  <svg
                    className="animate-spin h-5 w-5 text-gray-500 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="white"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : 'USE AI TO ENHANCE YOUR IMAGES'}
              </button>
            </div>
          </div>

          {(enhancedImages.length > 0) &&
            <div>
              <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
                Enhanced Images
              </h1>
              <div className="w-full flex flex-wrap gap-4">
                {enhancedImages.map((image, index) => {
                  const imageUrl = typeof image === 'string' ? image : URL.createObjectURL(image);
                  return (
                    <div key={index} className="relative w-[120px] h-[145px]">
                      <img
                        src={imageUrl}
                        alt={`Uploaded ${index}`}
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <div className="absolute top-[-10px] right-[-10px] flex items-center justify-center w-[30px] h-[30px]">
                        <img
                          src={RedElipse}
                          alt="Red Ellipse"
                          className="absolute w-full h-full"
                        />
                        <button
                          type='button'
                          onClick={handleEnhancedImageRemove}
                          className="relative z-10 rounded-full p-1"
                        >
                          <img
                            src={CrossIcon}
                            alt="Remove Icon"
                            className="w-3 h-3"
                          />
                        </button>
                      </div>
                      <div className="absolute bottom-2 left-2">
                        <button
                          onClick={() => handleDownload(imageUrl)}
                          className="flex items-center justify-center p-1 rounded-full shadow"
                        >
                          <img src={DownLoadImageIcon} alt="Download Icon" className="w-[32px] h-[32px]" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }



          {/* Upload PDF of your property */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              Upload File Attachment
            </h1>
            <p className="font-poppins text-[12px] leading-[19.8px] text-left text-[#737678] mb-2">
              Supported file formats: pdf only
            </p>
            {/* Upload PDF button */}
            <div className="flex items-start space-x-4">
              {
                !formik.values.pdf && (
                  <label className="w-[120px] h-[145px] border border-solid border-[#E1E4E8] flex flex-col items-center justify-center cursor-pointer rounded-lg">
                    <input
                      ref={formikRefs.pdf}
                      type="file"
                      name="pdf"
                      accept=".pdf"
                      onChange={handlePDFFileChange}
                      className="hidden"
                    />
                    <img src={PlusIconGrayBorder} alt="Add Icon" className="w-8 h-8 mb-3" />
                    <span className="font-poppins text-[15px] text-[#8E9C9C] font-medium leading-[22.13px] text-center mt-1">Upload</span>
                    <span className="font-poppins text-[15px] text-[#8E9C9C] font-medium leading-[22.13px] text-center mt-1">PDF</span>
                  </label>
                )
              }

              {/* Display uploaded or public URL PDF */}
              <div className="w-full flex flex-wrap gap-4">
                {formik.values.pdf && (
                  <div className="relative w-[120px] h-[145px]">
                    <iframe
                      src={
                        typeof formik.values.pdf === 'string'
                          ? null
                          : URL.createObjectURL(formik.values.pdf)
                      }
                      title="Attached File"
                      className="w-full h-full object-cover rounded-lg"
                      type="application/pdf"  // Ensure the MIME type is correct
                    />
                    <div className="absolute top-[-10px] right-[-10px] flex items-center justify-center w-[30px] h-[30px]">
                      <img src={RedElipse} alt="Red Ellipse" className="absolute w-full h-full" />
                      <button
                        type='button'
                        onClick={() => {
                          formik.setFieldValue('pdf', '');
                        }}
                        className="relative z-10 rounded-full p-1"
                      >
                        <img src={CrossIcon} alt="Remove Icon" className="w-3 h-3" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Tell us how to contact you */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[24px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              Tell us how to contact you
            </h1>
            <div className="mb-6">
              <input
                ref={formikRefs.contact_number}
                id="contact_number"
                name="contact_number"
                type="text"
                value={formik.values.contact_number}
                onChange={handlePhoneChange}
                onBlur={formik.handleBlur}
                className="w-1/2 h-10 text-[14px] px-4 py-2 border rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-gray-800"
                placeholder="123-456-7890"
              />
              {formik.errors.contact_number && formik.touched.contact_number && (
                <div className="text-red-500 text-sm ml-2 mt-2">{formik.errors.contact_number}</div>
              )}
            </div>
          </div>


          {/* What is your name? */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              What is your name?
            </h1>
            <div className="relative w-[50%]">
              <input
                ref={formikRefs.name}
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="What is your name"
                className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 pr-10"
              />
            </div>
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-sm ml-2 mt-2 font-poppins">{formik.errors.name}</div>
            ) : null}
          </div>


          {/* What is the asking price? */}
          <div className="mb-6 mt-12">
            <h1 className="font-poppins text-[20px] font-semibold leading-[22px] text-left text-[#37474F] mb-4">
              What is the asking price?
            </h1>
            <div className="relative w-[50%]">
              <input
                ref={formikRefs.asking_price}
                type="number"
                step="0.01"
                name="asking_price"
                value={formik.values.asking_price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter price"
                className="w-full text-[14px] px-4 py-2 border rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 pr-10"
              />
              <span className="absolute right-12 top-1/2 transform -translate-y-1/2 text-gray-500">USD</span>
            </div>
            {formik.touched.asking_price && formik.errors.asking_price ? (
              <div className="text-red-500 ml-2 text-sm mt-2 font-poppins">{formik.errors.asking_price}</div>
            ) : null}
          </div>

          {/* Pricing on other website */}
          <div className="w-[70%] mt-6 mb-12">
            <p className="font-poppins text-[14px] font-medium leading-[22px] text-left mb-4 text-[#37474F]">
              Check pricing on other websites with similar properties to get a better idea.
            </p>
            {/* Container for buttons */}
            <div className="flex w-full border border-[#C4C4C4] rounded-full">
              <div
                className="relative flex-1 p-2"
                onClick={() => handleClick('accurate')}
              >
                <div className={`text-center mb-2 text-sm ${selectedButton === 'accurate' ? 'font-bold' : ''}`}>Accurate</div>
                <div className="text-center mb-2 text-sm">$150,000</div>
                <div className="flex justify-center">
                  <div className="h-1 bg-green-500 w-1/2"></div>
                </div>
                <div className="absolute inset-y-0 right-0 w-px bg-[#C4C4C4] h-4/5" style={{ top: 'calc(50% - 30px)' }}></div>
              </div>
              <div
                className="relative flex-1 p-2"
                onClick={() => handleClick('high')}
              >
                <div className={`text-center mb-2 text-sm ${selectedButton === 'high' ? 'font-bold' : ''}`}>High</div>
                <div className="text-center mb-2 text-sm">$160,000</div>
                <div className="flex justify-center">
                  <div className="h-1 bg-gray-500 w-1/2"></div>
                </div>
                <div className="absolute inset-y-0 right-0 w-px bg-[#C4C4C4] h-4/5" style={{ top: 'calc(50% - 30px)' }}></div>
              </div>
              <div
                className="relative flex-1 p-2"
                onClick={() => handleClick('low')}
              >
                <div className={`text-center mb-2 text-sm ${selectedButton === 'low' ? 'font-bold' : ''}`}>Low</div>
                <div className="text-center mb-2 text-sm">$140,000</div>
                <div className="flex justify-center">
                  <div className="h-1 bg-red-500 w-1/2"></div>
                </div>
              </div>
            </div>
          </div>

          {/* Property Prices */}
          <div className="w-[90%] mt-6 mb-12">
            {/* Buttons */}
            <div className='flex gap-3'>
              <button className="flex items-center border border-[#C4C4C4] rounded-full px-4 py-3 text-sm flex-1">
                <span className="flex-1 text-left text-[#2464DB]">Property</span>
                <span className="flex-1 text-right text-[#8E9C9C] flex items-center justify-end gap-1">
                  <span className='text-[8px]'>From </span>
                  <span>{"$120,000 >"}</span>
                </span>
              </button>
              <button className="flex items-center border border-[#C4C4C4] rounded-full px-4 py-2 text-sm flex-1">
                <span className="flex-1 text-left text-[#2464DB]">Property</span>
                <span className="flex-1 text-right text-[#8E9C9C] flex items-center justify-end gap-1">
                  <span className='text-[8px]'>From </span>
                  <span>{"$120,000 >"}</span>
                </span>
              </button>
              <button className="flex items-center border border-[#C4C4C4] rounded-full px-4 py-2 text-sm flex-1">
                <span className="flex-1 text-left text-[#2464DB]">Property</span>
                <span className="flex-1 text-right text-[#8E9C9C] flex items-center justify-end gap-1">
                  <span className='text-[8px]'>From </span>
                  <span>{"$120,000 >"}</span>
                </span>
              </button>
            </div>
            {/* View all list */}
            <div className="text-right mt-4 flex items-center justify-end">
              <p href="#" className="text-[#8E9C9C] text-[14px] hover:underline font-poppins flex items-center">
                View All List
                <img src={AngularVector} alt="Location Icon" className="h-3 w-3 ml-1" /> {/* Adjust margin as needed */}
              </p>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between mb-12">
          {!isUpdate &&
            <button
              type='button'
              disabled={isLoading || isGeneratingImages}
              onClick={handleSaveAsDraft}
              className="px-4 py-2 font-poppins text-[#8E9C9C] text-[14px] font-semibold text-left bg-transparent">
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-gray-500 mr-3"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="white"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : 'SAVE AS DRAFT'}
            </button>
          }
          <div className="flex flex-col space-y-4 mb-12">
            <button
              type="submit"
              style={{ backgroundColor: 'var(--Primary, #8E9C9C)' }}
              disabled={isSubmitting || isLoading || isGeneratingImages}
              className="text-white px-4 py-2 rounded-full shadow focus:outline-none focus:shadow-outline font-poppins text-center text-sm font-semibold">
              {isSubmitting ? (
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                isUpdate ? 'Update' : 'Add Property'
              )}
            </button>
          </div>

        </div>

      </div>
    </form >
  );
};

