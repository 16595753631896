import React, { useEffect } from "react";
import { IncreaseArrowIcon, ActiveIcon } from "../../../assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAdsLists, getAdsDetails, setDays } from "../../../redux/slices/admin/adRevenueSlice";
const AdRevenue = () => {
  const dispatch = useDispatch();

  const { data, details, days } = useSelector((state) => state.admin.adRevenue);

  useEffect(() => {
    if (days) {
      dispatch(getAdsLists(days));
      dispatch(getAdsDetails(days));
    }
  }, [dispatch, days]);

  return (
    <div className="w-full">
      <div className="w-full flex justify-between mb-8">
        <div className="font-poppins font-medium text-[26px]">Ads Revenue Report</div>
        <select
          className="font-poppins text-md bg-white px-[14px] py-[10px] border border-[#EEEEEE] rounded-3xl focus:outline-none me-4"
          value={days}
          onChange={(e) => {dispatch(setDays(e.target.value))
          }}
        >
          <option value="7">Last 7 days</option>
          <option value="30">Last 30 days</option>
          <option value="60">Last 60 days</option>
          <option value="90">Last 90 days</option>
          <option value="365">Last year</option>
        </select>
      </div>
      <div className="w-full flex gap-6 mb-6">
        <div className="w-full rounded-2xl bg-[#F4F5F5] p-6">
          <div className="w-full font-poppins text-[28px] text-[#797979] mb-3">Featured Ads Revenue</div>
          <div className="text-[40px] font-semibold mb-2">${details.feature_ads_revenue}</div>
          <div className="flex font-poppins text-[12px] items-center">
            <img className="me-1" src={IncreaseArrowIcon} alt="increase" />
            {details.feature_ads_revenue_rate}% increase from last month
          </div>
        </div>
        <div className="w-full rounded-2xl bg-[#F4F5F5] p-6">
          <div className="w-full font-poppins text-[28px] text-[#797979] mb-3">Banners Ads Revenue</div>
          <div className="text-[40px] font-semibold mb-2">${details.banner_ads_revenue}</div>
          <div className="flex font-poppins text-[12px] items-center">
            <img className="me-1" src={IncreaseArrowIcon} alt="increase" />
            {details.banner_ads_revenue_rate}% increase from last month
          </div>
        </div>
        <div className="w-full rounded-2xl bg-[#F4F5F5] p-6">
          <div className="w-full font-poppins text-[28px] text-[#797979] mb-3">Total Revenue</div>
          <div className="text-[40px] font-semibold mb-2">${details.total_revenue}</div>
          <div className="flex font-poppins text-[12px] items-center">
            <img className="me-1" src={IncreaseArrowIcon} alt="increase" />
            {details.total_revenue_rate}% increase from last month
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between mb-8">
        <div className="font-poppins font-medium text-[26px]">Detailed Reports</div>
        <select
          className="font-poppins text-md bg-white px-[14px] py-[10px] border border-[#EEEEEE] rounded-3xl focus:outline-none me-4"
          value={days}
          onChange={(e) => dispatch(setDays(e.target.value))}
        >
          <option value="7">Last 7 days</option>
          <option value="30">Last 30 days</option>
          <option value="60">Last 60 days</option>
          <option value="90">Last 90 days</option>
          <option value="365">Last year</option>
        </select>
      </div>
      <div className="w-full overflow-auto flex-grow">
        <table className="min-w-full">
          <thead>
            <tr className="text-left">
              <th className="p-3 text-white text-[18px] font-medium bg-[#8E9C9C] rounded-tl-xl">Revenue</th>
              <th className="p-3 text-white text-[18px] font-medium bg-[#8E9C9C]">Type</th>
              <th className="p-3 text-white text-[18px] font-medium bg-[#8E9C9C]">Platform</th>
              <th className="p-3 text-white text-[18px] font-medium bg-[#8E9C9C]">Growth</th>
              <th className="p-3 text-white text-[18px] font-medium bg-[#8E9C9C] text-center rounded-tr-xl">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              data.length > 0 && data.map((revenue, i) => (
                <tr className="bg-[#FAFAFA]" key={`row-${i+1}`}>
                  <td className="p-3 border-b border-b-[#EDF1F5] font-poppins text-[20px]">${revenue.revenue}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] font-poppins text-[20px]">{revenue.type}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] font-poppins text-[20px]">{revenue.platform}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] font-poppins text-[20px]">{revenue.growth}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] text-[20px] flex justify-center items-center">
                    <img src={ActiveIcon} alt="action" />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AdRevenue;