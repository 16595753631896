import api from "../../../api/api";

const getDashboardOverview = (days) => {
  return api.get(`custom-admin/dashboard/?days=${days}`);
};

const getPendingApprovals = (days) => {
  return api.get(`custom-admin/pending-approval-properties/?days=${days}`);
};

const updatePropertyStatus = (data) => {
  return api.post("custom-admin/update-property-status/", data);
};

const dashboardOverviewService = {
  getDashboardOverview,
  getPendingApprovals,
  updatePropertyStatus,
};

export default dashboardOverviewService;
