import api from "../../api/api";

const createAd = (data) => {
  return api.post("area-studio/create-ad/", data);
};

const adService = {
  createAd,
};

export default adService;
