import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactService from "../../redux/services/contactService";
import { STATUSES } from "../../constants/statuses";

export const sendContactUsMessage = createAsyncThunk("contact/sendMessage", async (data, thunkAPI) => {
  try {
    const response = await contactService.createContactUsMessage(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    status: null,
    error: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendContactUsMessage.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(sendContactUsMessage.fulfilled, (state) => {
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(sendContactUsMessage.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default contactSlice.reducer;
