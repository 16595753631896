import api from "../../api/api";

const addProperty = (data) => {
  return api.post("api/properties/", data);
};

const myProperties = (limit, offset) => {
  return api.get(`api/properties/me/?limit=${limit}&offset=${offset}`);
};

const favoriteProperty = (id) => {
  return api.post(`api/properties/${id}/favourite/`);
};

const searchKeyword = (keyword) => {
  return api.get(`api/properties/filter/?keyword=${keyword}`);
};

const searchProperties = (filter, limit, offset) => {
  let url = `api/properties/search/?limit=${limit}&offset=${offset}`;
  for (const key in filter) {
    if (filter[key]) url = `${url}&${key}=${filter[key]}`;
  }
  return api.get(url);
};

const getFeaturedProperties = (selectedType) => {
  return api.get(`api/properties/featured/?selectedType=${selectedType}`);
};

const getMLSPropertyTypes = () => {
  return api.get("api/properties/featured-property-types/");
};

const getDashboardData = () => {
  return api.get("api/properties/dashboard/");
};

const searchPropertyByName = (name) => {
  return api.get(`api/properties/search-by-name/?q=${name}`);
};

const fetchProperty = (id) => {
  return api.get(`api/properties/${id}/`);
};

const getPropertiesLegalDetail = () => {
  return api.get(`api/properties/legal-details/`);
};

const closeProperty = (id) => {
  return api.delete(`api/properties/${id}/`);
};

const updateProperty = (id, formData) => {
  return api.put(`api/properties/${id}/`, formData);
};

const savePropertyAsDraft = (data) => {
  return api.put("api/properties/", data);
};

const fetchDraftProperty = () => {
  return api.get("api/properties/");
};

const fetchNeighborhoodCities = () => {
  return api.get(`api/properties/overview/`);
};

const uploadPDF = (data) => {
  return api.post('api/upload-attachment/',
    data
  )
};

const generateAIText = (data) => {
  return api.post('ai_ml/text_creator/', data);
};

const saveAIGeneratedDraft = (data) => {
  return api.put('ai_ml/text_creator/', data);
};

const getPropertiesCount = () => {
  return api.get('api/properties/all/');
};

const propertyService = {
  addProperty,
  myProperties,
  fetchProperty,
  getPropertiesLegalDetail,
  closeProperty,
  updateProperty,
  savePropertyAsDraft,
  fetchDraftProperty,
  favoriteProperty,
  searchKeyword,
  searchProperties,
  searchPropertyByName,
  getFeaturedProperties,
  getMLSPropertyTypes,
  getDashboardData,
  fetchNeighborhoodCities,
  uploadPDF,
  generateAIText,
  saveAIGeneratedDraft,
  getPropertiesCount,
};

export default propertyService;
