import React from "react";

const CircularProgress = ({ percentage }) => {
  const getStrokeColor = (percentage) => {
    if (percentage <= 25) return "text-blue-500";
    if (percentage <= 50) return "text-[#E65F2B]";
    if (percentage <= 75) return "text-[#EE201C]";
    return "text-[#1A932E]";
  };

  const radius = 20;
  const stroke = 5;
  const normalizedRadius = radius - stroke / 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="w-fit flex items-center justify-center relative">
      <svg
        height={radius * 2}
        width={radius * 2}
        className="transform rotate-[-90deg]"
      >
        {/* Background Circle */}
        <circle
          stroke="#e5e7eb" // Light gray color for the background circle
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        {/* Progress Circle */}
        <circle
          stroke="currentColor"
          className={`${getStrokeColor(percentage)} transition-colors duration-500`}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          strokeLinecap="round"
        />
      </svg>
      <span className="absolute text-[9px] font-semibold">
        {percentage}%
      </span>
    </div>
  );
};

export default CircularProgress;
