export const timeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return interval === 1 ? '1 year ago' : `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval === 1 ? '1 month ago' : `${interval} months ago`;
  }
  interval = Math.floor(seconds / 604800); // 1 week = 604800 seconds
  if (interval >= 1) {
    return interval === 1 ? '1 week ago' : `${interval} weeks ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval === 1 ? '1 day ago' : `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval === 1 ? '1 hour ago' : `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval === 1 ? '1 minute ago' : `${interval} minutes ago`;
  }
  return seconds === 1 ? '1 second ago' : `${Math.floor(seconds)} seconds ago`;
}

export const getTime = (dateString) => {
  const dateObj = new Date(dateString);

  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

export const formatPropertyCount = (count) => {
  if (count < 1000) {
    return count;
  } else if (count < 1000000) {
    return `${Math.floor(count / 1000)}k+`;
  } else {
    return `${Math.floor(count / 1000000)}M+`;
  }
};

export const getListingText = (count) => {
  return count === 1 ? 'Listing' : 'Listings';
};
