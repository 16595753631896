import React, { useState, useRef, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatPhoneNumber } from "../../utils/format_contact_number";
import debounce from "lodash.debounce";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";

const EditableField = ({ label, value, onSave, isDate, isPhone, isRequired }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);

  const debouncedHandleSave = useCallback(
    debounce((formattedValue) => {
      if (isRequired && formattedValue === "") {
        showAllNotifications(`${label} cannot be blank.`, ToastColors.error);
      } else {
        onSave(formattedValue);
        setIsEditing(false);
      }
    }, 300),
    [onSave]
  );

  const handleSave = () => {
    const formattedValue = isDate ? new Date(inputValue).toISOString().split('T')[0] : inputValue;
    debouncedHandleSave(formattedValue);
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (isPhone) {
      newValue = formatPhoneNumber(newValue);
    }
    setInputValue(newValue);
  };

  return (
    <div className="mb-5 w-full md:w-1/2 px-4">
      <div className="flex justify-between items-center">
        <label className="text-sm text-[#7A7E83]">{label}</label>
        <button
          className="text-sm text-[#7A7E83] pt-2"
          onClick={() => {
            if (isEditing) {
              handleSave();
            } else {
              setIsEditing(true);
            }
          }}
        >
          {isEditing ? "Save" : "Edit"}
        </button>
      </div>
      {isEditing ? (
        isDate ? (
          <DatePicker
            selected={inputValue ? new Date(inputValue) : null}
            onChange={(date) => {
              setInputValue(date ? date.toISOString().split('T')[0] : '');
            }}
            dateFormat="dd MMMM yyyy"
            className="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 text-[#121314] min-h-10"
            onBlur={handleSave}
            ref={inputRef}
          />
        ) : (
          <input
            type="text"
            className="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 text-[#121314] min-h-10"
            value={inputValue}
            onChange={handleChange}
            onBlur={handleSave}
            ref={inputRef}
          />
        )
      ) : (
        <div className="w-full border-b-2 border-gray-300 text-[#121314] py-1 min-h-10">
          {isDate ? new Date(value).toLocaleDateString('en-GB') : inputValue}
        </div>
      )}
    </div>
  );
};

export default EditableField;
