import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import agentService from '../services/agentService';
import { STATUSES } from '../../constants/statuses';

export const clearAllChats = createAsyncThunk("agent/clearAllChats", async (_, thunkAPI) => {
  try {
    const response = await agentService.clearChats();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const clearChat = createAsyncThunk("agent/clearChat", async (data, thunkAPI) => {
  try {
    const response = await agentService.clearChats(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchChatHistory = createAsyncThunk("agent/fetchChatHistory", async (_, thunkAPI) => {
  try {
    const response = await agentService.fetchChatHistory();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchConversation = createAsyncThunk("agent/fetchConversation", async (id, thunkAPI) => {
  try {
    const response = await agentService.fetchConversation(id);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createConversation = createAsyncThunk("agent/createConversation", async (data, thunkAPI) => {
  try {
    const response = await agentService.createConversation(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const sendChatMessage = createAsyncThunk("agent/sendMessage", async (data, thunkAPI) => {
  try {
    const response = await agentService.sendMessage(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const sendAudio = createAsyncThunk("agent/sendAudio", async (data, thunkAPI) => {
  try {
    const response = await agentService.uploadAudio(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


const mareaSlice = createSlice({
  name: 'marea',
  initialState: {
    agentMessages: {
      'Max': {
        chat_id: null,
        messages: []
      },
      'Marea': {
        chat_id: null,
        messages: []
      }
    },
    showChooseAgentModal: false,
    selected_agent: null,
    agent_is_typing: false,
    isOpen: false,
    status: null,
    fetching_conversation_status: null,
    chat_history: [],
    error: null,
    loading: false,
  },
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
    },
    setShowChooseAgentModal: (state, action) => {
      state.showChooseAgentModal = action.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    toggleAgentTyping: (state, action) => {
      state.agent_is_typing = action.payload
    },
    setAgent: (state, action) => {
      const selectedAgent = action.payload;
      state.selected_agent = selectedAgent;

      if (state.agentMessages[selectedAgent].messages.length < 1) {
        state.agentMessages[selectedAgent].messages.push({
          text: `Hi there! I'm ${selectedAgent}, and I'm here to help with all your real estate questions today. Feel free to ask me anything in text or audio. I can create interactive visualizations—so you can zoom in and explore details—along with providing detailed answers. Looking forward to assisting you—just let me know what you need!`,
          isUser: false,
          timestamp: new Date().toISOString(),
        });
      }
    },
    addMessageToAgent: (state, action) => {
      state.agentMessages[action.payload.agentName].messages.push(action.payload.message);
    },
    toggleAgent: (state, action) => {
      state.selected_agent = action.payload;
    },
    setAgentChatId: (state, action) => {
      const { agentName, chat_id } = action.payload;
      state.agentMessages[agentName].chat_id = chat_id;
    },
    updateAgentMessage: (state, action) => {
      const { agentName, messages } = action.payload;

      const introMessage = {
        text: `Hi there! I'm ${agentName}, and I'm here to help with all your real estate questions today. Feel free to ask me anything in text or audio. I can create interactive visualizations—so you can zoom in and explore details—along with providing detailed answers. Looking forward to assisting you—just let me know what you need!`,
        isUser: false,
        timestamp: new Date().toISOString(),
      };

      state.agentMessages[agentName].messages = [
        introMessage,
        ...messages
      ];
    },
    newChat: (state, action) => {
      state.agentMessages[action.payload].chat_id = null;
      state.agentMessages[action.payload].messages = [
        {
          text: `Hi there! I'm ${action.payload}, and I'm here to help with all your real estate questions today. Feel free to ask me anything in text or audio. I can create interactive visualizations—so you can zoom in and explore details—along with providing detailed answers. Looking forward to assisting you—just let me know what you need!`,
          isUser: false,
          timestamp: new Date().toISOString(),
        }
      ]
    },
    clearState: (state, action) => {
      state.selected_agent = null;
      state.agentMessages = {
        'Max': {
          chat_id: null,
          messages: []
        },
        'Marea': {
          chat_id: null,
          messages: []
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearAllChats.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(clearAllChats.fulfilled, (state, action) => {
        Object.keys(state.agentMessages).forEach(agent => {
          state.agentMessages[agent].chat_id = null;
          state.agentMessages[agent].messages = [{
            text: `Hi there! I'm ${agent}, and I'm here to help with all your real estate questions today. Feel free to ask me anything in text or audio. I can create interactive visualizations—so you can zoom in and explore details—along with providing detailed answers. Looking forward to assisting you—just let me know what you need!`,
            isUser: false,
            timestamp: new Date().toISOString(),
          }];
        });

        state.chat_history = action.payload.data;
        state.status = STATUSES.IDLE;
      })
      .addCase(clearAllChats.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(clearChat.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(clearChat.fulfilled, (state, action) => {
        if (state.agentMessages[state.selected_agent].chat_id === action.payload.chat_details.chat_id) {
          state.agentMessages[state.selected_agent].chat_id = null;
          state.agentMessages[state.selected_agent].messages = [{
            text: `Hi there! I'm ${action.payload.chat_details.agentName}, and I'm here to help with all your real estate questions today. Feel free to ask me anything in text or audio. I can create interactive visualizations—so you can zoom in and explore details—along with providing detailed answers. Looking forward to assisting you—just let me know what you need!`,
            isUser: false,
            timestamp: new Date().toISOString(),
          }];
        }
        state.chat_history = action.payload.data;
        state.status = STATUSES.IDLE;
      })
      .addCase(clearChat.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(fetchChatHistory.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchChatHistory.fulfilled, (state, action) => {
        state.chat_history = action.payload.data;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchChatHistory.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(fetchConversation.pending, (state) => {
        state.fetching_conversation_status = STATUSES.LOADING
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(fetchConversation.fulfilled, (state, action) => {
        state.fetching_conversation_status = STATUSES.IDLE
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(fetchConversation.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createConversation.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(createConversation.fulfilled, (state, action) => {
        state.chat_history = action.payload.chat_history;
        state.agentMessages[action.payload.data.agentName].chat_id = action.payload.data.id
        state.selected_agent = action.payload.data.agentName;
        state.status = STATUSES.IDLE;
      })
      .addCase(createConversation.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(sendChatMessage.pending, (state) => {
        state.agent_is_typing = true;
        state.status = STATUSES.LOADING;
      })
      .addCase(sendChatMessage.fulfilled, (state, action) => {
        state.agent_is_typing = false;
        state.status = STATUSES.IDLE;
      })
      .addCase(sendChatMessage.rejected, (state, action) => {
        state.agent_is_typing = false;
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(sendAudio.pending, (state) => {
        state.agent_is_typing = true;
        state.status = STATUSES.LOADING;
      })
      .addCase(sendAudio.fulfilled, (state, action) => {
        state.agent_is_typing = false;
        state.status = STATUSES.IDLE;
      })
      .addCase(sendAudio.rejected, (state, action) => {
        state.agent_is_typing = false;
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      });
  }
});

export const {
  openModal, closeModal, setAgent, addMessageToAgent, toggleAgent, setAgentChatId, updateAgentMessage, newChat, toggleAgentTyping, clearState, setShowChooseAgentModal
} = mareaSlice.actions;

export default mareaSlice.reducer;
