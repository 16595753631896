import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import authService from '../../redux/services/authService';
import AreaLogo from '../../features/PropertyDefaultWrapper/area-logo.svg';
import { useNavigate, Link } from 'react-router-dom';
import { RegisterImage } from 'src/assets/images/images';
import { ForgotPasswordSchema } from '../../utils/yup/schemas';
import AuthPageLayout from '../../components/common/AuthPageLayout';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const initialValues = { email: '' };

  const handleForgotPassword = async (values, { setSubmitting }) => {
    try {
      let email = values.email;
      const response = await authService.resendOTP(email); // perfoms same functionality as send OTP
      showAllNotifications(response.data.success, ToastColors.success)
      navigate('/otp', { state: { email: email, type: 'forgot_password' } });
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <div className="flex flex-col justify-center items-center bg-gray-50 w-full md:w-1/2 p-4 md:p-8 lg:p-12">
        <div className="w-full max-w-md mx-auto">
          <img src={AreaLogo} alt="Logo" className="mb-6 md:mb-8 lg:mb-10" />
          <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 mt-4 mb-4 md:mb-6 lg:mb-8 text-center">
            Reset Password
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleForgotPassword}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3 md:mb-4">
                  <label className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2" htmlFor="email">
                    Email
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter Email"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <Link to="/login" className="text-black hover:text-gray-900 no-underline">
                  Already have an account?
                </Link>
                <div className="my-4 md:my-6">
                  <button
                    className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-1 px-3 md:py-2 md:px-4 rounded-full focus:outline-none focus:shadow-outline w-full text-xs md:text-sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AuthPageLayout src={RegisterImage} alt={"Register Illustration"} imgClassName={"object-cover h-full w-full"} divClassName={'hidden md:flex w-1/2 justify-center items-center bg-gray-100'} />
    </div>
  );
};

export default ForgotPassword;
