import api from "../../api/api";

const createContactUsMessage = (contactFormData) => {
  return api.post("api/contact-us/", { ...contactFormData });
};

const contactService = {
  createContactUsMessage,
};

export default contactService;
