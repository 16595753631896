import React, { useMemo } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { AreaMarkerIcon } from '../../assets/icons/icons';

const libraries = ['places'];

export default function PropertyAddress({ property }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_AREA_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <PropertyMap property={property} />;
}

const PropertyMap = ({ property }) => {
  const latitude = parseFloat(parseFloat(property?.Latitude).toFixed(2));
  const longitude = parseFloat(parseFloat(property?.Longitude).toFixed(2));

  const center = useMemo(() => ({ lat: latitude, lng: longitude }), [latitude, longitude]);

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-[0px_0px_15px_0px_#00000026] p-6 mb-6 font-poppins">
      <h3 className="text-2xl font-semibold mb-2">Address</h3>
      <hr className="border-gray-300 mb-4" />
      <div className="flex flex-col md:flex-row mb-4">
        <div className="w-full md:w-1/2 flex items-center mb-2 mr-3 md:mb-0">
          <div className="font-bold mr-2">Address</div>
          <div>{property.MLSListingAddress}</div>
        </div>
        <div className="w-full md:w-1/2 flex items-center mb-2 mr-3 md:mb-0">
          <div className="font-bold mr-2">Country</div>
          <div>USA</div>
        </div>
      </div>
      <div className="w-full h-80 mt-4">
        <GoogleMap
          mapContainerClassName="w-full h-full"
          zoom={10}
          center={center}
        >
          <Marker
            position={center}
            icon={{
              url: AreaMarkerIcon,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        </GoogleMap>
      </div>
    </div>
  );
}
