import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../constants/statuses";
import contractService from "../services/contractService";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";

export const generateContractWithAI = createAsyncThunk("contracts/generateContractWithAI", async (data, thunkAPI) => {
  try {
    const response = await contractService.generateContractWithAI(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchContract = createAsyncThunk("contracts/fetchContract", async (id, thunkAPI) => {
  try {
    const response = await contractService.fetchContract(id);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchProperty = createAsyncThunk("contracts/fetchProperty", async (id, thunkAPI) => {
  try {
    const response = await contractService.fetchProperty(id);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createContract = createAsyncThunk("contracts/createContract", async ({ id, data }, thunkAPI) => {
  try {
    const response = await contractService.createContract(id, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const contractSlice = createSlice({
  name: "contracts",
  initialState: {
    status: null,
    error: null,
    property_data: {},
    contract_data: {},
    pdf: null,
    loading: false,
  },
  reducers: {
    resetPDF: (state) => {
      state.pdf = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateContractWithAI.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(generateContractWithAI.fulfilled, (state, action) => {
        state.pdf = action.payload.data.pdf;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(generateContractWithAI.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchProperty.fulfilled, (state, action) => {
        state.property_data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(fetchContract.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchContract.fulfilled, (state, action) => {
        state.contract_data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchContract.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(createContract.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(createContract.fulfilled, (state, action) => {
        showAllNotifications("Contract have been saved successfully!", ToastColors.success);
        state.contract_data = action.payload;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(createContract.rejected, (state, action) => {
        showAllNotifications("Contract could not be saved due to an error!", ToastColors.error);
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { resetPDF } = contractSlice.actions;

export default contractSlice.reducer;
