import React from "react";
import { Link } from "react-router-dom";
import { CallIcon, HomeIcon, BulletIcon, ContactIcon, EmailIcon } from "src/assets/icons/icons";
import { NeedHouseImg, SellHouseImg } from "src/assets/images/images";

export const Footer = () => {
  return (
    <div className="relative w-full h-auto bg-[#8e9c9c] overflow-hidden">
      <div className="flex flex-wrap justify-center items-center gap-20 p-20">
        <div className="relative w-full max-w-lg bg-[#f3f3f3] rounded-xl shadow-lg px-8 py-2">
          <div className="flex items-center gap-6">
            <div className="relative flex w-44 h-32 me-2">
              <img src={NeedHouseImg} className="w-full" alt="NeedHouseImg" />
            </div>
            <div className="flex flex-col">
              <div className="text-3xl font-['space_grotesk'] font-bold text-[#1c1c1e] leading-9">
                Find your dream home
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 transform translate-y-1/2 w-full flex justify-center">
            <Link to="/contact-us" className="flex items-center gap-2 bg-black text-white px-10 py-3 rounded-full mr-10">
              <img className="w-6 h-6" src={CallIcon} alt="Call" />
              <span>Contact Seller</span>
            </Link>
          </div>
        </div>

        <div className="relative w-full max-w-lg bg-[#f3f3f3] rounded-xl shadow-lg px-8 py-2">
          <div className="flex items-center gap-6">
            <div className="relative w-32 h-32 me-2">
              <img src={SellHouseImg} className="h-full" alt="SellHouseImg" />
            </div>
            <div className="flex flex-col">
              <div className="text-3xl font-['space_grotesk'] font-bold text-[#1c1c1e] leading-9">
                Sell your property
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 transform translate-y-1/2 w-full flex justify-center">
            <Link to="/properties/new" className="flex items-center gap-3 bg-black text-white px-10 py-3 rounded-full mr-10">
              <img className="w-6 h-6" src={HomeIcon} alt="Home" />
              <span>Sell Property</span>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="w-full border-t border-[#ffffff1a] py-4">
        <div className="flex justify-center items-center gap-2">
          <img
            className="w-9 h-9"
            alt="Area final rgb"
            src="area-final-rgb-04-removebg-preview-1.png"
          />
          <img
            className="w-24 h-5"
            alt="Area final rgb"
            src="area-final-rgb-04-removebg-preview-1.png"
          />
        </div>
        <img className="mx-auto mt-4 w-60 h-7" alt="List" src="list.svg" />
      </div> */}

      <footer className="w-full bg-transparent py-10">
        <div className="flex flex-wrap justify-between max-w-7xl mx-auto">
          <div className="w-full md:w-1/4 p-4">
            <div className="text-base text-white">Office Address</div>
            <div className="mt-2">
              <div className="text-sm font-bold text-white">Head office:</div>
              <div className="text-sm text-[#f1faee]">260 Newport Center Drive, Suite 100</div>
              <div className="text-sm text-[#f1faee]">Newport Beach, CA 92660</div>
            </div>
          </div>
          <div className="w-full md:w-1/4 p-4">
            <div className="text-base text-white">Contact Information</div>
            <div className="mt-4 border-b border-[#b9b9b9] pb-2">
              <div className="flex items-center gap-2">
                <img className="w-9 h-9" alt="Icon" src={ContactIcon} />
                <div>
                  <div className="text-sm text-white">Hotline:</div>
                  <a href="tel:+9497918160" className="text-sm no-underline text-white">
                    (949) 791-8160
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-4 flex items-center gap-2">
              <img className="w-9 h-9" alt="Icon" src={EmailIcon} />
              <div>
                <div className="text-sm text-white">Email:</div>
                <div className="text-sm">
                  <a href="mailto:info@areahub.ai" className="no-underline text-white">
                    Info@AreAHub.ai
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 p-4">
            <div className="text-base text-white">Our Company</div>
            <div className="mt-4">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <img className="w-4 h-4" alt="Icon" src={BulletIcon} />
                  <a href="/my-properties" className="no-underline text-sm text-neutral-200">
                    Listings
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <img className="w-4 h-4" alt="Icon" src={BulletIcon} />
                  <a href="/about-us" className="no-underline text-sm text-neutral-200">
                  About Us
                  </a>
                </div>
                {/* <div className="flex items-center gap-2">
                  <img className="w-4 h-4" alt="Icon" src={BulletIcon} />
                  <div className="text-sm text-neutral-200">Marea suggests</div>
                </div> */}
                <div className="flex items-center gap-2">
                  <img className="w-4 h-4" alt="Icon" src={BulletIcon} />
                  <a href="/area-studio/listing-enhancement" className="no-underline text-sm text-neutral-200">
                  AreA Studio
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <img className="w-4 h-4" alt="Icon" src={BulletIcon} />
                  <a href="/contact-us" className="no-underline text-sm text-neutral-200">
                  Contact Us
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <img className="w-4 h-4" alt="Icon" src={BulletIcon} />
                  <a href="/terms-and-conditions#privacy-policy-section" className="no-underline text-sm text-neutral-200">
                  Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 p-4">
            <div className="text-base text-white">Newsletter</div>
            <div className="mt-4">
              <p className="text-sm text-neutral-200">
                Sign up to receive the latest articles
              </p>
              <div className="">
                <input
                  className="w-full py-4 mt-3 px-4 bg-white text-sm text-[#8e8e93] outline-none rounded-full"
                  placeholder="Your email address"
                  type="email"
                />
              </div>
              <button className="mt-4 w-full py-3 bg-[#ffffff1a] text-white rounded-full border border-solid">
                <span className="text-center">Sign Up</span>
              </button>
              <div className="flex flex-row mt-4 text-xs text-neutral-200">
              <div className="mr-2 w-5 h-5 bg-[#ffffff14] rounded border border-[#ffffff30]" />
                <span>
                  I have read and agree to the terms &amp; conditions
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="w-full bg-[#727f7f] border-t border-[#ffffff1a] py-4">
        <div className="text-center text-sm text-[#ffffff80]">
          Copyright © 2024 AreA.
        </div>
      </div>
    </div>
  );
};
