export const categorizeNotifications = (notifications) => {
  const now = new Date();
  const categories = {
    'Today': [],
    'Last Week': [],
    'Last Month': [],
    'Last Year': [],
    'Older': []
  };

  notifications.forEach(notification => {
    const createdAt = new Date(notification.created_at);
    const diffInMs = now - createdAt;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInDays < 1) {
      categories['Today'].push(notification);
    } else if (diffInWeeks < 1) {
      categories['Last Week'].push(notification);
    } else if (diffInMonths < 1) {
      categories['Last Month'].push(notification);
    } else if (diffInYears < 1) {
      categories['Last Year'].push(notification);
    } else {
      categories['Older'].push(notification);
    }
  });

  // Maintain the order from most recent to least recent
  const orderedCategories = {
    'Today': categories['Today'],
    'Last Week': categories['Last Week'],
    'Last Month': categories['Last Month'],
    'Last Year': categories['Last Year'],
    'Older': categories['Older']
  };

  return orderedCategories
};
