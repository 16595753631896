import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import authService from '../../redux/services/authService';
import AreaLogo from '../../features/PropertyDefaultWrapper/area-logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { RegisterImage } from 'src/assets/images/images';
import { SetNewPasswordSchema } from '../../utils/yup/schemas';
import AuthPageLayout from '../../components/common/AuthPageLayout';
import ToastColors from '../../constants/toastColors';
import { showAllNotifications } from '../../utils/notificationHelper';

const SetNewPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {};


  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const handleSetNewPassword = async (values, { setSubmitting }) => {
    try {
      const response = await authService.resetPassword(email, values.password);
      showAllNotifications(response.data.success, ToastColors.success)
      navigate('/login')
    } catch (error) {
      console.error(error.response.data.error)
      showAllNotifications(error.response.data.error, ToastColors.error)
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <div className="flex flex-col justify-center items-center bg-gray-50 w-full md:w-1/2 p-4 md:p-8 lg:p-12">
        <div className="w-full max-w-md mx-auto">
          <img src={AreaLogo} alt="Logo" className="mb-6 md:mb-8 lg:mb-10" />
          <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 mt-4 mb-4 md:mb-6 lg:mb-8 text-center">
            Create New Password
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={SetNewPasswordSchema}
            onSubmit={handleSetNewPassword}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3 md:mb-4 relative">
                  <label className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2" htmlFor="password">
                    Create Password
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter Password"
                  />
                  <ErrorMessage name="password" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="mb-3 md:mb-4 relative">
                  <label className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2" htmlFor="confirmPassword">
                    Re-enter Password
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    placeholder="Re-enter Password"
                  />
                  <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="my-4 md:my-6">
                  <button
                    className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-1 px-3 md:py-2 md:px-4 rounded-full focus:outline-none focus:shadow-outline w-full text-xs md:text-sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Reset
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AuthPageLayout src={RegisterImage} alt={"Register Illustration"} imgClassName={"object-cover h-full w-full"} divClassName={'hidden md:flex w-1/2 justify-center items-center bg-gray-100'} />
    </div>

  )
}

export default SetNewPassword;
