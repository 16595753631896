import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../constants/statuses";
import tickerTapeService from "../services/tickerTapeService";

export const fetchMetricsData = createAsyncThunk("tickerTape/fetchMetricsData", async (_, thunkAPI) => {
  try {
    const response = await tickerTapeService.fetchMetricsData();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchStockRates = createAsyncThunk("tickerTape/fetchStockRates", async (_, thunkAPI) => {
  try {
    const response = await tickerTapeService.fetchStockRates();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const tickerTapeSlice = createSlice({
  name: "tickerTape",
  initialState: {
    status: null,
    error: null,
    stock_data: [],
    metrics_data: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetricsData.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(fetchMetricsData.fulfilled, (state, action) => {
        state.metrics_data = action.payload;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(fetchMetricsData.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchStockRates.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(fetchStockRates.fulfilled, (state, action) => {
        state.stock_data = action.payload;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(fetchStockRates.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default tickerTapeSlice.reducer;
