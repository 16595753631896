import React from "react";
import { BlogSeparator, ForwardArrow1 } from "src/assets/icons/icons";

export const Blogs = () => {
  return (
    <div className="w-full bg-[#8e9c9c1a] py-10">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-5xl font-['space_grotesk'] font-bold text-[#1c1c1e] mb-2">
            From Our Blog
          </h2>
          <p className="text-gray-600 text-base">
            Property tips, hints, and hacks to help realtors get better results.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="flex flex-col">
            <div className="relative">
              <div className="h-52 bg-cover bg-center rounded-lg bg-[url(./blog-property.png)]">
                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent opacity-70 rounded-lg"></div>
                <div className="absolute bottom-4 left-4 bg-white text-black text-xs rounded-md px-2 py-1">
                  <div className="flex flex-row">
                    Feb
                    <img
                      src={BlogSeparator}
                      alt="separator"
                      className="ml-4 mr-4"
                    />
                    House
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-normal text-[#1c1c1e] leading-tight mb-2">
                Building gains into housing stocks and how to trade the sector
              </h3>
              <div className="flex items-center text-[#9f9f9f] text-sm">
                <span>Read more</span>
                <img className="ml-2 w-3 h-3" alt="Icon" src={ForwardArrow1} />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="relative">
              <div className="h-52 bg-cover bg-center rounded-lg bg-[url(./assets/images/blog2-home1-2.webp.webp)]">
                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent opacity-70 rounded-lg"></div>
                <div className="absolute bottom-4 left-4 bg-white text-black text-xs rounded-md px-2 py-1">
                  <div className="flex flex-row">
                    Feb
                    <img
                      src={BlogSeparator}
                      alt="separator"
                      className="ml-4 mr-4"
                    />
                    House
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-normal text-[#1c1c1e] leading-tight mb-2">
                92% of millennial homebuyers say inflation has impacted their
                plans
              </h3>
              <div className="flex items-center text-[#9f9f9f] text-sm">
                <span>Read more</span>
                <img className="ml-2 w-3 h-3" alt="Icon" src={ForwardArrow1} />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="relative">
              <div className="h-52 bg-cover bg-center rounded-lg bg-[url(./assets/images/blog3-home1-2.webp.webp)]">
                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent opacity-70 rounded-lg"></div>
                <div className="absolute bottom-4 left-4 bg-white text-black text-xs rounded-md px-2 py-1">
                  <div className="flex flex-row">
                    Feb
                    <img
                      src={BlogSeparator}
                      alt="separator"
                      className="ml-4 mr-4"
                    />
                    House
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-normal text-[#1c1c1e] leading-tight mb-2">
                We are hiring ‘moderately,’ says Compass CEO
              </h3>
              <div className="flex items-center text-[#9f9f9f] text-sm">
                <span>Read more</span>
                <img className="ml-2 w-3 h-3" alt="Icon" src={ForwardArrow1} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
