import {
  AffiliatePartner,
  BrightPartner,
  CoverlyPartner,
  NimblePartner,
  ProTectPartner,
  TitlemarkPartner,
} from "../assets/icons/icons";

export const AreaPartners = [
  // {
  //   logo: CoverlyPartner,
  //   width: "248.4px",
  //   height: "48px",
  // },
  {
    logo: AffiliatePartner,
    width: "324px",
    height: "48px",
  },
  {
    logo: ProTectPartner,
    width: "264px",
    height: "48px",
  },
  {
    logo: BrightPartner,
    width: "235.56px",
    height: "48px",
  },
  {
    logo: TitlemarkPartner,
    width: "82.38px",
    height: "48px",
  },
  {
    logo: NimblePartner,
    width: "186.21px",
    height: "48px",
  },
];

export const AREA_FAQS = [
  {
    question: "What is AreA?",
    answer: "AreA is a revolutionary real estate platform that leverages advanced AI conversational technology to provide personalized assistance through our AI agents, Marea and Max. These agents act as your autonomous real estate guides, helping you at every step of your property transaction."
  },
  {
    question: "How does AreA enhance real estate listings?",
    answer: "AreA is a revolutionary real estate platform that leverages advanced AI conversational technology to provide personalized assistance through our AI agents, Marea and Max. These agents act as your autonomous real estate guides, helping you at every step of your property transaction."
  },
  {
    question: "Can AreA help me find properties within the US?",
    answer: "AreA is a revolutionary real estate platform that leverages advanced AI conversational technology to provide personalized assistance through our AI agents, Marea and Max. These agents act as your autonomous real estate guides, helping you at every step of your property transaction."
  },
  {
    question: "What sets AreA apart from other real estate platforms?",
    answer: "AreA is a revolutionary real estate platform that leverages advanced AI conversational technology to provide personalized assistance through our AI agents, Marea and Max. These agents act as your autonomous real estate guides, helping you at every step of your property transaction."
  },
  {
    question: "How does AreA handle privacy and data security?",
    answer: "AreA is a revolutionary real estate platform that leverages advanced AI conversational technology to provide personalized assistance through our AI agents, Marea and Max. These agents act as your autonomous real estate guides, helping you at every step of your property transaction."
  },
  {
    question: "Is there a fee to use AreA?",
    answer: "AreA is a revolutionary real estate platform that leverages advanced AI conversational technology to provide personalized assistance through our AI agents, Marea and Max. These agents act as your autonomous real estate guides, helping you at every step of your property transaction."
  }
];
