import api from "../../api/api";

const fetchAllNotifications = () => {
  return api.get('api/notifications/');
};

const markNotificationRead = (id) => {
  return api.post(`api/notifications/${id}/mark-as-read/`);
};

const markAllAsRead = () => {
  return api.post('api/notifications/mark-all-as-read/');
};

const notificationsService = {
  fetchAllNotifications,
  markNotificationRead,
  markAllAsRead,
};

export default notificationsService;
