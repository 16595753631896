import React, { createContext, useContext, useEffect, useState } from 'react';
import { WEBSOCKET_URL } from '../../api/api';
import { useSelector } from 'react-redux';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticated) return;

    const ws = new WebSocket(`${WEBSOCKET_URL}/lobby/?token=${localStorage.getItem('access')}`);
    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [isAuthenticated]);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
