export const propertyConditions = [
  "New",
  "Like New",
  "Excellent",
  "Very Good",
  "Good",
  "Fair",
  "Needs Work",
  "Renovated",
  "Partially Renovated",
  "As-Is",
  "Distressed",
  "Fixer-Upper",
  "Unfinished",
  "Foreclosed"
];
