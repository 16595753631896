import React from "react";
import { Link } from "react-router-dom";
import {
  DoubleArrows,
  AreA,
  OffersHistory,
  MakeOffer,
} from "src/assets/icons/icons";

export const Buy = () => {
  return (
    <div className="bg-white flex flex-col items-center w-full">
      <div className="w-full bg-white overflow-hidden relative mt-[-96px]">
        <div className="relative w-full h-[684px] p-24 pt-[200px] bg-cover bg-center bg-[url(./assets/images/jjbq_luxury_modern_house_front_professional_image_--ar_32_--v_8f10f7b1-bff9-4476-8f29-7fc4d6044541_3.webp)] font-['Space_Grotesk']">
          <div className="flex flex-col items-center mt-8">
            <div className="w-[80%] font-extrabold text-[43px] leading-[76px] text-white uppercase text-center">
              Experience the future of home buying with AREA's AI—let Max and Marea find your dream home effortlessly.
            </div>
            <div className="flex justify-center mt-8">
                <Link to="/login" className="mt-4 text-white py-3 px-6 rounded-full text-sm bg-[#8E9C9C]">
                  Get Started
                </Link>
              </div>
          </div>
        </div>
        <div>
          <div className="mt-20">
            <div className="text-center w-full mb-6 mt-4">
              <h2 className="text-[69.42px] font-normal leading-[77.07px] tracking-[-0.055em] text-center font-['Space_Grotesk'] mb-2">
                Find Your&nbsp;
                <span style={{ color: "#8E9C9C" }}>Dream</span>
                <span className="block w-full text-center">Home Here</span>
              </h2>
            </div>
            <div className="relative mx-auto w-11/12 max-w-full h-[547px] p-24 pt-[200px] bg-cover bg-center bg-[url(./buyhomes1.png)] rounded-lg font-['Space_Grotesk']">
              <div className="absolute bottom-10 left-11 text-white font-['Synonym'] text-lg font-semibold px-2 py-1">
                Strategic Location
              </div>
              <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 text-white font-['Synonym'] text-lg font-semibold px-2 py-1">
                <img src={DoubleArrows} alt="Double Arrows" />
              </div>
              <div className="absolute bottom-10 right-11 text-white bg-[#8E9C9C] font-['Synonym'] text-lg font-semibold px-2 py-1">
                $15,000
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <div className="w-11/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mx-2">
              <div className="relative h-72">
                <div className="absolute inset-0 bg-cover bg-center rounded-lg bg-[url(./assets/images/r-architecture-wDDfbanbhl8-unsplash1.webp)]">
                  <div className="absolute top-5 right-5">
                    <p className="text-white bg-[#8E9C9C] font-['Synonym'] text-lg font-semibold px-2 py-1">
                      {" "}
                      $10,000
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative h-72">
                <div className="absolute inset-0 bg-cover rounded-lg bg-center bg-[url(./assets/images/low-angle-shot-white-church-with-beautiful-flower-garden1.webp)]">
                  <div className="absolute top-5 right-5">
                    <p className="text-white bg-[#8E9C9C] font-['Synonym'] text-lg font-semibold px-2 py-1">
                      {" "}
                      $11,000
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col items-center mt-8 md:mt-20 px-8">
          <div className="container w-4/5 mx-auto py-10 flex flex-col lg:flex-row items-center justify-between">
          <div className="w-6/12 flex justify-left mt-8 lg:mt-0">
              <div className="relative">
                <img
                  src={MakeOffer}
                  alt="Nimble Mortgage"
                  className="w-full h-auto max-w-md"
                />
              </div>
            </div>
            <div className="text-left lg:text-left w-6/12">
              <h1 className="text-8xl lg:text-5xl font-bold text-gray-900 mb-4 leading-tight tracking-tight font-['Space_Grotesk']">
                <span style={{ fontWeight: 400 }}>Get Prequalified with Nimble Mortgage</span>
              </h1>
              <div className="flex items-center justify-center lg:justify-start">
                <p className="text-md lg:text-lg font-light text-gray-600">
                  Enhance your offer’s credibility with a pre-approval letter from Nimble Mortgage.
                  Achieve success swiftly—it only takes a few minutes.
                </p>
              </div>
              <div className="flex justify-left">
                <Link to="/login" className="bg-[#8E9C9C] mt-4 text-white py-3 px-6 rounded-full text-sm">
                  Get Started Free
                </Link>
              </div>
            </div>
            
          </div>
        </div>

        <div>
          <div className="w-full flex flex-col items-center mt-8 md:mt-20 px-8">
            <div className="container w-4/5 mx-auto py-10 flex flex-col lg:flex-row items-center justify-between">
              <div className="text-left lg:text-left w-6/12">
                <h1 className="text-8xl lg:text-5xl font-bold text-gray-900 mb-4 leading-tight tracking-tight font-['Space_Grotesk']">
                  <span style={{ fontWeight: 400 }}>Buy with AI</span>
                </h1>
                <div className="flex justify-center lg:justify-start">
                  <p className="text-md lg:text-lg font-light text-gray-600">
                    The buying experience you’ve always wanted. For the <br />
                    first time ever, gain the clarity and confidence you need{" "}
                    <br /> to make an offer you know sellers will accept with
                    AreA
                    <br />
                    AI.
                  </p>
                </div>
                <div className="flex justify-left">
                  <Link to="/login" className="bg-[#8E9C9C] mt-4 text-white py-3 px-6 rounded-full text-sm">
                    Get Started Free
                  </Link>
                </div>
              </div>
              <div className="w-6/12 flex justify-center mt-8 lg:mt-0">
                <div className="relative">
                  <img
                    src={AreA}
                    alt="House"
                    className="w-full h-auto max-w-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="w-full flex flex-col items-center mt-8 md:mt-20 px-8">
            <div className="container w-4/5 mx-auto py-10 flex flex-col lg:flex-row items-center justify-between">
              <div className="w-6/12 flex justify-left mt-8 lg:mt-1 order-1 lg:order-1">
                <div className="relative">
                  <img
                    src={OffersHistory}
                    alt="House"
                    className="w-full h-auto max-w-md"
                  />
                </div>
              </div>
              <div className="text-left lg:text-left w-6/12 order-1 lg:order-2">
                <h1 className="text-8xl lg:text-5xl font-bold text-gray-900 mb-4 leading-tight tracking-tight font-['Space_Grotesk']">
                  <span style={{ fontWeight: 400 }}>Offers History</span>
                </h1>
                <div className="flex items-center justify-center lg:justify-start">
                  <p className="text-md lg:text-lg font-light text-gray-600 text-left">
                    All offers are visible in the listing’s offer history,
                    allowing <br />
                    buyers and their agents to view the details of what they{" "}
                    <br /> need to do in order to make a better offer.
                  </p>
                </div>
                <div>
                  <Link to="/login">
                    <button to="/login" className="bg-[#8E9C9C] mt-4 text-white py-3 px-6 rounded-full text-sm">
                      Create Your Account
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col items-center mt-8 md:mt-20 px-8">
            <div className="container w-4/5 mx-auto py-10 flex flex-col lg:flex-row items-center justify-between">
              <div className="text-left lg:text-left w-6/12">
                <h1 className="text-8xl lg:text-5xl font-bold text-gray-900 mb-4 leading-tight tracking-tight font-['Space_Grotesk']">
                  <span style={{ fontWeight: 400 }}>
                    Make an offer
                    <br /> online in seconds
                  </span>
                </h1>
                <div className="flex items-center justify-center lg:justify-start">
                  <p className="text-md lg:text-lg font-light text-gray-600">
                    Set your price, review your terms and make your offer –
                    all within
                    <br />
                    seconds.
                  </p>
                </div>
                <div>
                <Link to="/login">
                  <button to="/login" className="bg-[#8E9C9C] mt-4 text-white py-3 px-6 rounded-full text-sm">
                    Make Offer With AI
                  </button>
                  </Link>
                </div>
              </div>
              <div className="w-6/12 flex justify-center mt-8 lg:mt-0">
                <div className="relative">
                  <img
                    src={MakeOffer}
                    alt="House"
                    className="w-full h-auto max-w-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
