import React,{useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { AngularVector } from '../../assets/icons/icons';

const TermsAndConditions = () => {
    const location = useLocation();
    
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
            const headerOffset = 300; 
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
            }
        }
        }, [location]);
    
    return (
        <div className="container mt-5 px-44">
            <div className="row">
                <div className="col-12 my-5">
                    <h1 className="text-5xl font-bold leading-tight lg:text-left mt-5">Terms & Conditions</h1>
                    <p className="text-gray-600 text-base flex items-center space-x-2 justify-center lg:justify-start">
                        <span className="text-gray-600 font-normal whitespace-nowrap">Home</span>
                        <img src={AngularVector} alt="Location Icon" className="w-4 h-4" />
                        <span className="font-normal whitespace-nowrap">Terms & Conditions</span>
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <h2 className="text-[69.42px] font-normal leading-[77.07px] tracking-[-0.055em] text-center font-['Space_Grotesk'] mb-2">WELCOME TO AREA!</h2>
                    <p className="text-[20px] font-normal text-center font-grotesk mt-4">
                        BEFORE YOU PROCEED, PLEASE TAKE A MOMENT TO REVIEW OUR TERMS OF USE AND CONDITIONS. BY LOGGING IN AND USING OUR PLATFORM, YOU AGREE TO THESE TERMS, WHICH ARE DESIGNED TO ENSURE A RELIABLE AND SECURE EXPERIENCE FOR ALL OUR USERS. AREA IS HERE TO SUPPORT YOUR JOURNEY IN THE REAL ESTATE MARKET, BUT REMEMBER, OUR AI AND DATA TOOLS ARE AIDS, NOT SUBSTITUTES FOR PROFESSIONAL ADVICE. LOG IN TO CONNECT, DISCOVER, AND MOVE FORWARD WITH YOUR REAL ESTATE GOALS.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <h1 className="text-[30px] font-bold leading-tight lg:text-left mt-5">AREA Terms of Use and Conditions</h1>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">1. Introduction: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            Welcome to AreA, a real estate listing platform that facilitates connections between sellers and buyers directly. AreA is not a licensed brokerage or real estate agent and does not engage in brokerage activities. This platform is intended to assist "For Sale By Owner" listings and buyers in the real estate market.
                        </p>
                    </section>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">2. Services Provided: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            AreA offers a posting service for property sellers and a search facility for potential buyers. Our services include automated scheduling, posting of listings, and negotiation tools facilitated through our AI bots, Marea and Max. AreA and its services are designed to support, not replace, the due diligence and services provided by professional real estate agents, attorneys, and financial advisors.
                        </p>
                    </section>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">3. Disclaimer of Liability: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            AreA, including its AI bots Marea and Max, provides tools and data that are not infallible and may contain inaccuracies. Users should verify all information independently and exercise due diligence in all real estate and financial transactions. AreA is not responsible for errors or inaccuracies that arise from the use of the platform.
                        </p>
                    </section>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">4. Affiliated Services: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            AreA is affiliated with several service providers including Nimble Mortgage, BrightPath I-Buyer, ProTect Property Insurance, Coverly Home Warranty, RiskVeu NHD, and TitleMark Title and Escrow. While these entities operate under their own licenses and may charge fees for their services, AreA may benefit financially from your use of these services. Your decision to engage any affiliated service provider should be based on your own judgment and advice from professionals.
                        </p>
                    </section>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">5. Non-Affiliation: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            with Traditional Real Estate Bodies AreA is not affiliated with the National Association of Realtors (NAR), any multiple listing services (MLS), or localized real estate boards. The representation of active listings on AreA may not be comprehensive or complete.
                        </p>
                    </section>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">6. User Representations: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            By posting a property on AreA, you represent that you have the legal authority to sell the property listed. By making an offer on AreA, you affirm that you are financially qualified to make such an offer, either through evidence of funds or financing pre-approvals as applicable.
                        </p>
                    </section>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">7. User Conduct Users: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            are expected to use AreA responsibly and with professional integrity. AreA reserves the right to remove listings, block users, or suspend accounts of users who abuse the platform or engage in fraudulent or deceptive practices.
                        </p>
                    </section>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">8. Waiver of Liability: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            By using AreA, you agree to release AreA, its parent company AreAHub, subsidiaries including AreAStudio, and affiliated services from any claims, damages, and liabilities arising from your use of the platform. This includes errors made by AI bots Marea and Max, inaccuracies in data provided, and any other user-generated content.
                        </p>
                    </section>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">9. Privacy Policy: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            Your privacy is important to us. By using AreA and agreeing to these terms, you consent to the collection, sharing, and potential sale of your personal information as described in our detailed privacy policy available at [Privacy Policy Link]. This may include sharing information with affiliated services to facilitate your real estate transactions.
                        </p>
                    </section>

                    <section>
                        <h4 className="text-1xl font-bold mt-3">10. Changes to Terms: </h4>
                        <p className="text-1xl mb-4 text-gray-600">
                            AreA reserves the right to modify these terms and conditions at any time. Continued use of the platform after such changes constitutes your consent to the new terms.
                        </p>
                    </section>
                    <h3 className="text-1xl my-5 font-bold text-gray-400">
                        <span>Please review our complete Privacy Policy and additional terms of service at </span>
                        <Link
                            to="/"
                        >
                            Www.AreAHub.ai.
                        </Link>
                    </h3> 
                    <section id="privacy-policy-section" >
                        <h1 className="text-[30px] font-bold leading-tight lg:text-left mt-5">Privacy Policy for AREA</h1>
                        <p className="text-muted my-4">Effective Date: August 19th 2024</p>
                    </section>
                    <section>
                        <p className="text-gray-600 text-justify mb-4">
                            Welcome to AreA! We are committed to protecting your privacy and handling your data with transparency and care. This Privacy Policy explains how AreA, including our affiliated services (Nimble Mortgage, BrightPath I-Buyer, ProTect Property Insurance, Coverly Home Warranty, RiskVeu NHD, TitleMark Title and Escrow, Endpoint Title and Escrow), collect, use, and share information about you when you access our website, mobile application, and other online services (Collectively, the "Services").
                        </p>
                    </section>

                    <section>
                        <h4 className="text-[20px] font-bold my-3">1. Information We Collect</h4>
                        <ul className="list-disc my-4 mx-4">
                            <li><span className="font-bold">Information you provide: </span><span className="text-gray-600">This includes information provided when you create an account, list a property, make an offer, or use any of our transactional services. It may include, but is not limited to, your name, email address, phone number, financial details, and any other property-related information.</span></li>
                            <li><span className="font-bold">Automated Information: </span><span className="text-gray-600">We collect certain information automatically when you use our Services. This includes your IP address, browser type, operating system, referring URLs, device information, and pages visited.</span></li>
                            <li><span className="font-bold">Cookies and Tracking Technologies: </span><span className="text-gray-600">We use cookies and other tracking technologies to enhance your experience, improve our services, and analyze how our platform is used. You can control the use of cookies at the individual browser level.</span></li>
                        </ul>
                    </section>

                    <section>
                        <h4 className="text-[20px] font-bold my-3">2. Use of Information</h4>
                        <ul className="list-disc my-4 mx-4">
                            <li><span className="font-bold">To Provide Services: </span><span className="text-gray-600">We use the information we collect to operate, maintain, and provide the features and functionality of AreA, to complete transactions, and to communicate with you about these services.</span></li>
                            <li><span className="font-bold">For Improvement and Development: </span><span className="text-gray-600">We use the information to understand and analyze the usage trends and preferences of our users, to improve the platform, and to develop new features and functionality.</span></li>
                            <li><span className="font-bold">For Communication: </span><span className="text-gray-600">We may use your information to contact you for administrative and informational purposes. This may include providing customer service, sending you technical notices, updates, security alerts, and support messages.</span></li>
                        </ul>
                    </section>

                    <section>
                        <h4 className="text-[20px] font-bold my-3">3. Sharing of Information</h4>
                        <ul className="list-disc my-4 mx-4">
                            <li><span className="font-bold">With Affiliated Services: </span><span className="text-gray-600">We may share your information with our affiliated services (as listed above) to facilitate your real estate transactions. These services operate under their own privacy policies.</span></li>
                            <li><span className="font-bold">Service Providers: </span><span className="text-gray-600">We share information with third-party service providers who perform services on our behalf, including web hosting, maintenance, and other services.</span></li>
                            <li><span className="font-bold">Legal Compliance and Protection of Rights: </span><span className="text-gray-600">We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend our rights or property, or in urgent circumstances to protect the personal safety of users of the Services or the public.</span></li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
