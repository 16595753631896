import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../../constants/statuses";
import dashboardOverviewService from "../../services/admin/dashboardOverviewService";
import { showAllNotifications } from "../../../utils/notificationHelper";
import ToastColors from "../../../constants/toastColors";

export const getDashboardOverview = createAsyncThunk("dashboardOverview/getDashboardOverview", async (days, thunkAPI) => {
  try {
    const response = await dashboardOverviewService.getDashboardOverview(days);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getPendingApprovals = createAsyncThunk("dashboardOverview/getPendingApprovals", async (days, thunkAPI) => {
  try {
    const response = await dashboardOverviewService.getPendingApprovals(days);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updatePropertyStatus = createAsyncThunk("dashboardOverview/updatePropertyStatus", async (data, thunkAPI) => {
  try {
    const response = await dashboardOverviewService.updatePropertyStatus(data);
    return { ...response.data, propertyStatus: data.property_status };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const dashboardOverviewSlice = createSlice({
  name: "dashboardOverview",
  initialState: {
    status: null,
    error: null,
    days: 30,
    data: {
      total_revenue: 0,
      percentage_revenue_difference: 0,
      active_properties: 0,
      pre_active_properties: 0,
      percentage_active_difference: 0,
      percentage_pending_difference: 0,
      all_properties_count: 0,
      sold_properties_count: 0,
      pending_approval_count: 0,
      productivity: 0
    },
    pendingApprovalsData: [],
    loading: false,
  },
  reducers: {
    setDays: (state, action) => {
      state.days = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardOverview.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getDashboardOverview.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getDashboardOverview.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(getPendingApprovals.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(getPendingApprovals.fulfilled, (state, action) => {
        state.pendingApprovalsData = action.payload.pending_approval_properties;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(getPendingApprovals.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updatePropertyStatus.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(updatePropertyStatus.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        if (action.payload.propertyStatus === "active") {
          showAllNotifications("You have accepted the property", ToastColors.success);
        } else {
          showAllNotifications("You have rejected the property", ToastColors.error);
        }
        state.loading = false;
      })
      .addCase(updatePropertyStatus.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { setDays } = dashboardOverviewSlice.actions;

export default dashboardOverviewSlice.reducer;
