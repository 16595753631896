import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  AdminAreaStudioIcon, AdminDashboardIcon, AdminMenuSettingIcon, AdminPropertyApprovalsIcon, AdminUserReferralsIcon, AdminUsersIcon, AreaAdminDashboardLogo
} from "../../../assets/icons/icons";
import "./sidebar.css";

const AdminSidebar = () => {
  const [activeMenu, setActiveMenu] = useState("");
  const [activeOpt, setActiveOpt] = useState("overview");
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapseIcon = (e) => {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
  }

  return (
    <div className={`relative p-1 flex flex-col bg-[#8E9C9C] ${isCollapsed ? "w-[100px]" : "w-[260px]"} h-[100vh]`}>
      <div
        className={`${isCollapsed ? "w-6 h-6 -right-3 top-6" : "w-8 h-8 -right-4 top-7"} absolute rounded-full flex justify-center items-center bg-white shadow-[2px_2px_8px_0px_#0000001A] cursor-pointer`}
        onClick={handleCollapseIcon}
      >
        <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} />
      </div>
      <div className="w-full flex justify-center items-center py-8">
        <Link className="max-w-[75%]" to="/">
          <img className="w-full" src={AreaAdminDashboardLogo} alt="area-logo" />
        </Link>
      </div>
      <div className="w-full flex-grow overflow-y-scroll">
        <div className={`w-full mb-2 select-none ${activeMenu === "admin-dashboard" ? "active-opt" : ""}`}>
          <div className={`main-link w-full flex items-center ${!isCollapsed ? "px-[10px]" : "pe-[10px] justify-between"} text-white cursor-pointer`} onClick={() => {
            setActiveMenu(activeMenu === "admin-dashboard" ? "" : "admin-dashboard");
            setActiveOpt("");
          }}>
            {isCollapsed && <div className="w-[10px]"></div>}
            <div className="p-[12px]">
              {AdminDashboardIcon}
            </div>
            {!isCollapsed && <div className="text-sm flex-grow">Admin Dashboard</div>}
            <FontAwesomeIcon className={`${isCollapsed ? "" : "ms-auto"}`} icon={activeMenu === "admin-dashboard" ? faChevronDown : faChevronRight} />
          </div>
          <div className={`sub-link ${isCollapsed ? "text-center" : ""}`}>
          {
        (isCollapsed ?
          ["Partner", "Premier Partners", "Affiliated Partners", "Affiliate Marketing"] :
          ["AreA Partner", "AreA Premier Partners", "AreA Affiliated Partners", "Affiliate Marketing"]
        ).map((opt, i) => {
          const routes = [
            "/admin/area-partners",
            "/admin/premier-partners",
            "/admin/area-affiliated-partners",
            "/admin/affiliate-marketing"
          ];

          return (
            <Link
              key={i+1}
              to={routes[i]}
              className={`w-full flex border-t border-t-[#DBDBDB] items-center ${isCollapsed ? "px-2 h-10" : "ps-[10px]"} cursor-pointer hover:bg-black hover:text-white ${activeOpt === opt ? "bg-black text-white" : "text-[#8E9C9C]"}`}
              onClick={() => setActiveOpt(opt)}
            >
              {!isCollapsed && <div className="w-10"></div>}
              <div className={`${isCollapsed ? "text-[11px] py-1" : "text-sm py-[10px]"} flex-grow`}>{opt}</div>
            </Link>
          );
        })
      }
          </div>
        </div>
        <div className={`w-full mb-2 select-none ${activeMenu === "area-studio" ? "active-opt" : ""}`}>
          <div className={`main-link w-full flex items-center ${!isCollapsed ? "px-[10px]" : "pe-[10px] justify-between"} text-white cursor-pointer`} onClick={() => {
            setActiveMenu(activeMenu === "area-studio" ? "" : "area-studio");
            setActiveOpt("");
          }}>
            {isCollapsed && <div className="w-[10px]"></div>}
            <div className="p-[12px]">
              {AdminAreaStudioIcon}
            </div>
            {!isCollapsed && <div className="text-sm flex-grow">Area Studio</div>}
            <FontAwesomeIcon className={`${isCollapsed ? "" : "ms-auto"}`} icon={activeMenu === "area-studio" ? faChevronDown : faChevronRight} />
          </div>
          <div className={`sub-link ${isCollapsed ? "text-center" : ""}`}>
            {
            (isCollapsed ?
              ["Ad Revenue", `Starter Kits${isCollapsed ? "" : " and For Sale Signs"}`, "Home Accessories"] :
              ["Ad Revenue", `Starter Kits${isCollapsed ? "" : " and For Sale Signs"}`, "Home Accessories"]
            ).map((opt, i) => {
              const routes = [
                "/admin/ad-revenue",
                "/admin/starter-kits",
                "/admin/home-accessories"
              ];

              return (
                <Link
                  key={i+1}
                  to={routes[i]}
                  className={`w-full flex border-t border-t-[#DBDBDB] items-center ${isCollapsed ? "px-2 h-10" : "ps-[10px]"} cursor-pointer hover:bg-black hover:text-white ${activeOpt === opt ? "bg-black text-white" : "text-[#8E9C9C]"}`}
                  onClick={() => setActiveOpt(opt)}
                >
                  {!isCollapsed && <div className="w-10"></div>}
                  <div className={`${isCollapsed ? "text-[11px] py-1" : "text-sm py-[10px]"} flex-grow`}>{opt}</div>
                </Link>
              );
            })
          }
          </div>
        </div>
        <div className={`w-full mb-2 select-none ${activeMenu === "property-approvals" ? "active-opt" : ""}`}>
          <Link to="/admin/property-approvals" className={`main-link w-full flex items-center ${!isCollapsed ? "px-[10px]" : "pe-[10px] justify-center"} text-white cursor-pointer`} onClick={() => {
            setActiveMenu(activeMenu === "property-approvals" ? "" : "property-approvals");
            setActiveOpt("");
          }}>
            <div className="p-[12px]">
              {AdminPropertyApprovalsIcon}
            </div>
            {!isCollapsed && <div className="text-sm flex-grow">Property Approvals</div>}
          </Link>
        </div>
        <div className={`w-full mb-2 select-none ${activeMenu === "user-referrals" ? "active-opt" : ""}`}>
          <Link to="/admin/user-referrals" className={`main-link w-full flex items-center ${!isCollapsed ? "px-[10px]" : "pe-[10px] justify-center"} text-white cursor-pointer`} onClick={() => {
            setActiveMenu(activeMenu === "user-referrals" ? "" : "user-referrals");
            setActiveOpt("");
          }}>
            <div className="p-[12px]">
              {AdminUserReferralsIcon}
            </div>
            {!isCollapsed && <div className="text-sm flex-grow">User Referrals</div>}
          </Link>
        </div>
        <div className={`w-full mb-2 select-none ${activeMenu === "users" ? "active-opt" : ""}`}>
          <Link to="/admin/users" className={`main-link w-full flex items-center ${!isCollapsed ? "px-[10px]" : "pe-[10px] justify-center"} text-white cursor-pointer`} onClick={() => {
            setActiveMenu(activeMenu === "users" ? "" : "users");
            setActiveOpt("");
          }}>
            <div className="p-[12px]">
              {AdminUsersIcon}
            </div>
            {!isCollapsed && <div className="text-sm flex-grow">Users</div>}
          </Link>
        </div>
        <div className={`w-full mb-2 select-none ${activeMenu === "menu-settings" ? "active-opt" : ""}`}>
          <Link to="/admin/menu-settings" className={`main-link w-full flex items-center ${!isCollapsed ? "px-[10px]" : "pe-[10px] justify-center"} text-white cursor-pointer`} onClick={() => {
            setActiveMenu(activeMenu === "menu-settings" ? "" : "menu-settings");
            setActiveOpt("");
          }}>
            <div className="p-[12px]">
              {AdminMenuSettingIcon}
            </div>
            {!isCollapsed && <div className="text-sm flex-grow">Menu settings</div>}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
