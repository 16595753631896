import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import { FiSearch } from 'react-icons/fi';
import Properties from "../../components/common/Properties";
import { AngularVector } from "../../assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { myProperties, searchPropertyByName } from "../../redux/slices/propertySlice";

const LIMIT = 8;

export const MyProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data } = useSelector((state) => state.properties);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    dispatch(myProperties({ limit: LIMIT, offset }));
  }, [dispatch, offset]);

  return (
    <div className="bg-white flex flex-col items-center w-full">
      <div className="w-full bg-white overflow-hidden relative">
        <div className="w-full mt-4 py-10 max-w-screen-xl mx-auto my-10 px-4">
          <div className="text-left mb-6">
            <h2 className="text-4xl font-semibold text-gray-900 mb-2 font-poppins">
              My Properties
            </h2>
            <p className="text-gray-600 text-base flex items-center space-x-2">
              <Link to="/" className="text-gray-600 no-underline hover:underline font-poppins">Home</Link>
              <img src={AngularVector} alt="Location Icon" />
              <span className="font-poppins">My Properties</span>
            </p>
          </div>
          <div className="text-left mb-6 mt-20">
            <h2 className="text-2xl font-semibold text-gray-900 mb-2 font-grotesk">
              Your Properties
            </h2>
            <p className="text-gray-600 text-base font-bold font-poppins">
              {data ? data.length : 0} Properties Available
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between items-center mb-6 space-y-4 md:space-y-0 md:space-x-4">
            <Formik
              initialValues={{ search: '' }}
              onSubmit={(values) => {
                if (values.search) {
                  dispatch(searchPropertyByName(values.search));
                } else {
                  dispatch(myProperties({ limit: 6, offset: 0 }));
                }
              }}
            >
              <Form className="flex flex-col md:flex-row items-center w-full md:max-w-lg">
                <div className="relative flex-1 w-[70%] md:w-full">
                  <Field
                    name="search"
                    type="text"
                    placeholder="Search Property by name here"
                    className="w-full py-2 pl-10 pr-4 border bg-white shadow-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <FiSearch className="absolute left-3 top-2.5 text-gray-500" />
                </div>
              </Form>
            </Formik>
            <button
              onClick={() => navigate('/properties/new')}
              className="bg-[#8E9C9C] text-white font-bold py-2 px-4 rounded-full shadow focus:outline-none focus:shadow-outline mt-4 md:mt-0 font-jost"
            >
              Post New Property
            </button>
          </div>
        </div>
        <div className="w-full max-w-screen-xl mx-auto px-4">
          <div className="flex flex-col items-center mb-[100px]">
            <Properties properties={data} isMyProperty={true} setOffset={setOffset} />
          </div>
        </div>
      </div>
    </div>
  );
};
