import React from "react";
import {
  RealEstateicon,
} from "src/assets/icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import ContactUsForm from "../../components/Sell/ContactUsForm";
import { Link } from "react-router-dom";

export const Sell = () => {
  return (
    <div className="bg-white flex flex-col items-center w-full">
      <div className="w-full bg-white overflow-hidden relative mt-[-96px]">
        <div className="relative w-full h-[684px] p-24 pt-[200px] bg-cover bg-center bg-[url(./sellbg.png)] font-['Space_Grotesk']">
          <div className="text-[52px] font-extrabold uppercase leading-[76px] text-white">
            Sell your Property with Full Ease&nbsp;
            <span style={{ color: "#8E9C9C" }}>AI-POWERED</span>
          </div>
          <div className="flex flex-col items-center mt-8">
            <div className="w-[80%] text-[43px] leading-[76px] text-white uppercase text-center">
              Enhance your Property Ads including all
              <span className="block w-full text-center">
                details with the help of Area AI
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-20">
            <div className="text-center w-full mb-6 mt-4">
              <h2 className="text-[69.42px] font-normal leading-[77.07px] tracking-[-0.055em] text-center font-['Space_Grotesk'] mb-2">
                AI Image Enhancer &nbsp;
                <span className="block w-full text-center">
                  & Image Upscaler
                </span>
              </h2>
              <p
                className="font-['poppins'] text-[22px] font-normal leading-[27px] text-center"
                style={{ color: "#666666" }}
              >
                Effortlessly enhance and upscale your photo to get higher
                resolution image <br />
                with one single click.
              </p>
            </div>
          </div>
          <div>
            <div className="w-full flex flex-col items-center mt-8 md:mt-20 px-8">
              <div className="container w-4/5 mx-auto py-10 flex flex-col lg:flex-row items-center justify-between">
                <div className="text-center lg:text-left w-4/12 mx-auto py-8 border border-gray-200 rounded-lg shadow-box">
                  <div className="flex flex-col items-center justify-center lg:justify-start">
                    <button className="mt-3 text-white py-3 px-6 rounded-full text-lg flex items-center gap-2 bg-[#8E9C9C]">
                      <FontAwesomeIcon icon={faArrowUpFromBracket} />
                      Upload Image
                    </button>
                    <p className="text-md lg:text-lg font-light text-gray-600 text-center mt-4">
                      or drop a file here
                      <br /> Ctrl + V to paste image or URL
                    </p>
                  </div>
                </div>

                <div className="w-6/12 flex justify-center h-80 mt-8 lg:mt-0">
                  <div className="relative">
                    <iframe
                      src="https://www.youtube.com/embed/VIDEO_ID_HERE" // Replace VIDEO_ID_HERE with your YouTube video ID
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full max-w-md"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <div>
            <div className="w-full flex flex-col items-center mt-8 md:mt-20 px-8">
              <div className="container w-4/5 mx-auto py-10 flex flex-col lg:flex-row items-center justify-between">
                <div className="w-6/12 flex justify-center mt-8 lg:mt-0 order-1 lg:order-1">
                  <div className="relative">
                    <img
                      src={RealEstateicon}
                      alt="House"
                      className="w-full h-auto max-w-md"
                    />
                  </div>
                </div>
                <div className="text-left lg:text-left w-6/12 order-2 lg:order-2 pl-16">
                  <h1 className="text-8xl lg:text-5xl font-bold text-gray-900 mb-6 leading-tight tracking-tight font-['Space_Grotesk']">
                    <span style={{ fontWeight: 400 }}>For Real-Estate</span>
                  </h1>
                  <div className="flex items-center justify-center lg:justify-start">
                    <p className="text-md lg:text-lg font-light text-gray-600">
                      Get high-resolution, vibrant and saturated property <br />
                      photos in excellent quality and clear details with <br />{" "}
                      magic AI image enhancer.
                    </p>
                  </div>
                  <div  className="flex justify-left">
                    <Link to="/login" className="mt-8 text-white py-3 px-6 rounded-full text-sm  bg-[#8E9C9C]">
                      Get Started Free
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-full flex flex-col items-center mt-8 md:mt-20 px-8">
              <div className="container w-4/5 mx-auto py-10 flex flex-col lg:flex-row items-center justify-between">
                <div className="text-left lg:text-left w-2/5">
                  <h1 className="text-8xl lg:text-5xl font-bold text-gray-900 mb-4 leading-tight tracking-tight font-['Space_Grotesk']">
                    <span style={{ fontWeight: 400 }}>AI Descriptions</span>
                  </h1>
                  <div className="flex items-center justify-center lg:justify-start">
                    <p className="text-md lg:text-lg font-light text-gray-600">
                      Get high-resolution, vibrant and saturated <br />
                      property descriptions and title.
                    </p>
                  </div>
                  <div  className="flex justify-left">
                    <Link to="/login" className="mt-4 text-white py-3 px-6 rounded-full text-sm  bg-[#8E9C9C]">
                      Get Started Free
                    </Link>
                  </div>
                </div>
                <div className="w-3/6">
                  <div className="relative p-8 rounded-lg  border border-gray-200 shadow-box">
                    <form className="space-y-6">
                      <div>
                        <h2 className="text-xl font-semibold mb-4">Subject</h2>
                        <p className="text-xs text-gray-500 mb-1">
                          Describe in a few words what your page is about
                        </p>
                        <input
                          type="text"
                          className="w-full py-4 px-4 bg-white text-sm text-gray-700 border border-gray-300 rounded-full"
                          placeholder="e.g. Keto diet for beginners"
                        />
                      </div>

                      <div>
                        <h2 className="text-xl font-semibold mb-4">Keywords</h2>
                        <p className="text-xs text-gray-500 mb-1">
                          Add one or more keywords (separated by commas) that
                          you would like to be included in the
                          <br /> meta description
                        </p>
                        <input
                          type="text"
                          className="w-full py-4 px-4 bg-white text-sm text-gray-700 border border-gray-300 rounded-full"
                          placeholder="e.g. proteins, plans"
                        />
                      </div>

                      <button className="bg-[#8E9C9C] mt-4 text-white py-3 px-6 rounded-full text-sm">
                        Generate Descriptions
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-full flex flex-col items-center mt-8 mb-12 md:mt-20 px-8">
              <div className="container w-4/5 mx-auto py-10 flex flex-col lg:flex-row items-center justify-between">
                <div className="text-left lg:text-left w-2/5">
                  <h1 className="capitalize text-8xl lg:text-5xl font-bold text-gray-900 mb-4 leading-tight tracking-tight font-['Mulish']">
                    <span style={{ fontWeight: 400 }}>
                      Sell with AreA: Global Reach, Local Expertise
                    </span>
                  </h1>
                  <div className="flex items-center justify-center lg:justify-start">
                    <p className="text-md lg:text-lg font-light text-gray-600">
                      At AreA, we empower you to sell your property on the world’s largest international real estate syndication platform. Enjoy unparalleled exposure across 71 portals in 44 countries, connecting you to over 117.35 million potential buyers. Our partnerships include prestigious sites such as The Wall Street Journal and Juwai in Asia, ensuring your listing receives premium visibility both locally and globally.
                    </p>
                  </div>
                  <div className="mt-4 text-left">
                    <h2 className="text-xl font-semibold">Our Extensive Network Includes:</h2>
                    <ul className="list-disc pl-5 text-md lg:text-lg font-light text-gray-600 mt-2">
                      <li><strong>Europe:</strong> From <a href="https://immobilier.lefigaro.fr/" target="_blank" rel="noreferrer" className="text-blue-600 underline">Immobilier.lefigaro.fr</a> in France to <a href="https://www.immobiliare.it/" target="_blank" rel="noreferrer" className="text-blue-600 underline">Immobiliare.it</a> in Italy</li>
                      <li><strong>North America:</strong> Featuring sites like <a href="https://www.kangalou.com/fr/" target="_blank" rel="noreferrer" className="text-blue-600 underline">Kangalou.com</a> in Canada</li>
                      <li><strong>South America:</strong> Including <a href="https://www.goplaceit.com/cl" target="_blank" rel="noreferrer" className="text-blue-600 underline">GoPlaceIt.com</a> platforms across Argentina, Chile, Colombia, Peru, and Uruguay</li>
                      <li><strong>Asia:</strong> Expansive coverage with <a href="https://www1.fang.com/" target="_blank" rel="noreferrer" className="text-blue-600 underline">Fang.com</a> and <a href="https://www.dongxilian.com/" target="_blank" rel="noreferrer" className="text-blue-600 underline">EastWestProperty.com</a> in China</li>
                      <li><strong>Oceania:</strong> Listings on <a href="https://www.acproperty.com.au/" target="_blank" rel="noreferrer" className="text-blue-600 underline">ACproperty.com.au</a> in Australia</li>
                      <li><strong>Africa:</strong> Showcasing properties on <a href="http://mubawab.ma/" target="_blank" rel="noreferrer" className="text-blue-600 underline">Mubawab.ma</a> in Morocco</li>
                      <li><strong>Global Exposure:</strong> Enhanced listings on global platforms such as <a href="https://www.mansionglobal.com/" target="_blank" rel="noreferrer" className="text-blue-600 underline">MansionGlobal.com</a> and <a href="https://en.arkadia.com/" target="_blank" rel="noreferrer" className="text-blue-600 underline">Arkadia.com</a></li>
                    </ul>
                  </div>
                  <div className="flex items-center justify-center lg:justify-start mt-4">
                    <p className="text-md lg:text-lg font-light text-gray-600">
                      Our strategic presence in these key markets ensures that your property reaches a diverse audience of buyers, maximizing your opportunities for a swift and profitable sale.
                    </p>
                  </div>
                  <div>
                    <button className="bg-[#8E9C9C] mt-4 text-white py-3 px-6 rounded-full text-sm">
                      See Full List of Portals
                    </button>
                  </div>
                  <div className="flex items-center justify-center lg:justify-start mt-4">
                    <p className="text-md lg:text-lg font-light text-gray-600">
                      Join AreA and leverage our expertly curated network to showcase your property to the world.
                    </p>
                  </div>
                </div>

                <div>
                  <ContactUsForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
