import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../../constants/statuses";
import userService from "../../services/admin/userService";

export const getUsers = createAsyncThunk("user/getUsers", async (data, thunkAPI) => {
  try {
    const response = await userService.getUsers(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    status: null,
    error: null,
    days: 30,
    users: [],
    count: 0,
    next: null,
    previous: null,
    loading: false,
  },
  reducers: {
    setDays: (state, action) => {
      state.days = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.results || [];
        state.count = action.payload.count || 0;
        state.next = action.payload.next;
        state.previous = action.payload.previous;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { setDays } = userSlice.actions;

export default userSlice.reducer;
