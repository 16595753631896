import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, setDays } from "../../../redux/slices/admin/userSlice";
import { ActiveIcon, SearchIcon } from "../../../assets/icons/icons";
import { Button } from "react-bootstrap";
import { formatDate } from "../../../utils/format_timestamp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const AdminUsers = () => {
  const dispatch = useDispatch();

  const { users, days, count } = useSelector((state) => state.admin.users);

  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (days && limit) {
      dispatch(getUsers({ days, limit, offset, keyword: searchInput }));
    }
  }, [dispatch, days, offset, limit]);

  const handleSearchInput = (e) => {
    e.preventDefault();
    dispatch(getUsers({ days, keyword: searchInput }));
  };

  return (
    <>
      <div className="w-full flex justify-between">
        <div className="text-[22px] font-medium">
          All Users
        </div>
        <select
          className="text-sm px-[14px] py-[10px] border border-[#EEEEEE] rounded-3xl focus:outline-none"
          value={days}
          onChange={(e) => dispatch(setDays(e.target.value))}
        >
          <option value="30">Last 30 days</option>
          <option value="7">Last 7 days</option>
          <option value="60">Last 60 days</option>
          <option value="90">Last 90 days</option>
          <option value="365">Last year</option>
        </select>
      </div>
      <div className="w-full mb-8 text-xs font-medium">{count} Active User</div>
      <div className="w-full flex justify-between mb-8">
        <div className="min-w-52 w-[30%] flex relative ps-10 border border-[#EEEEEE] rounded-3xl items-end">
          <img className="h-4 absolute top-[11px] left-4" src={SearchIcon} alt="search" />
          <input
            type="text"
            placeholder="Search User"
            className="w-full text-sm leading-5 py-[9px] text-[#8E9C9C] rounded-3xl border-none hover:outline-none focus:outline-none"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearchInput(e)}
          />
        </div>
        {/* <Button
          variant="secondary"
          className="!bg-[#8E9C9C] rounded-3xl text-sm font-medium py-[6px] px-3 !border-none"
        >
          Invite User
        </Button> */}
      </div>
      <div className="w-full text-[22px] mb-3">Users Listings</div>
      <div className="w-full overflow-auto flex-grow">
        <table className="min-w-full">
          <thead>
            <tr className="text-left">
              <th className="p-3 text-white text-sm font-medium bg-[#8E9C9C] rounded-tl-xl">User Name</th>
              <th className="p-3 text-white text-sm font-medium bg-[#8E9C9C]">Email</th>
              <th className="p-3 text-white text-sm font-medium bg-[#8E9C9C]">Joining Date</th>
              <th className="p-3 text-white text-sm font-medium bg-[#8E9C9C]">Listed Properties</th>
              <th className="p-3 text-white text-sm font-medium bg-[#8E9C9C]">Sold Properties</th>
              <th className="p-3 text-white text-sm font-medium bg-[#8E9C9C]">Purchased Properties</th>
              <th className="p-3 text-white text-sm font-medium bg-[#8E9C9C] text-center rounded-tr-xl">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              users.length > 0 && users.map((usr, i) => (
                <tr className="bg-[#FAFAFA]" key={`row-${i+1}`}>
                  <td className="p-3 border-b border-b-[#EDF1F5] text-sm">{usr.username}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] text-sm">{usr.email}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] text-sm">{formatDate(usr.date_joined)}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] text-sm">{usr.listed_properties_count}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] text-sm">{usr.sold_properties_count}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] text-sm">{usr.purchased_properties_count}</td>
                  <td className="p-3 border-b border-b-[#EDF1F5] text-sm flex justify-center items-center">
                    {/* <img src={ActiveIcon} alt="action" /> */}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className="w-full flex justify-end items-center px-4 py-3 shadow-[0px_4px_4px_0px_#0000000D] rounded-bl-xl rounded-br-xl">
        <div className="text-xs text-[#A1A4A9] font-medium">
          Rows per page
          <select className="w-fit" value={limit} onChange={(e) => {
            setLimit(e.target.value);
            setOffset(0);
          }}>
            {
              ["10", "20", "50", "100"].map((lim, i) => (
                <option value={lim}>{lim}</option>
              ))
            }
          </select>
        </div>
        <div className="ms-4 text-xs text-[#A1A4A9] font-medium">{(offset + 1)}-{((offset / limit + 1) * limit) > count ? count : ((offset / limit + 1) * limit)} Of {count}</div>
        <div className="ms-4 text-xs text-[#A1A4A9] font-medium">
          <FontAwesomeIcon className="cursor-pointer" icon={faChevronLeft} onClick={(e) => {
            e.preventDefault();
            if (offset >= limit) setOffset(offset - limit);
          }} />
        </div>
        <div className="ms-4 text-xs text-[#A1A4A9] font-medium">
          <FontAwesomeIcon className="cursor-pointer" icon={faChevronRight} onClick={(e) => {
            e.preventDefault();
            if (offset < (count - limit)) setOffset(offset + limit);
          }} />
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
