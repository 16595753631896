import api from "../../api/api";

const clearChats = (data) => {
  return api.delete('ai_ml/upload_chat/', { data })
};

const fetchConversation = (id) => {
  return api.get(`ai_ml/view_chat/${id}/`)
};

const uploadAudio = (data) => {
  return api.post('ai_ml/upload_audio/', data)
}

const createConversation = (data) => {
  return api.post('ai_ml/create_chat/', data)
};

const fetchChatHistory = () => {
  return api.get('ai_ml/upload_chat/')
}

const sendMessage = (data) => {
  return api.post('ai_ml/upload_chat/', data)
}

const agentService = {
  clearChats,
  fetchConversation,
  createConversation,
  fetchChatHistory,
  sendMessage,
  uploadAudio,
};

export default agentService;
