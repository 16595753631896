import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import authService from '../../redux/services/authService';
import AreaLogo from '../../features/PropertyDefaultWrapper/area-logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { RegisterImage } from '../../assets/images/images';
import { OTPSchema } from '../../utils/yup/schemas';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';

const VerifyOTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, type } = location.state || {};
  const [error, setError] = useState(null)

  const initialValues = {
    otp: '',
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    try {
      await authService.resendOTP(email);
      showAllNotifications('Token has been resent to your email address', ToastColors.success)
    } catch (error) {
      console.error('error', error)
    }
  }


  const handleVerifyOTP = async (values, { setSubmitting }) => {
    try {
      await authService.verifyOTP(values.otp, email);
      showAllNotifications('Token successfully verified, please login to continue', ToastColors.success)
      if (type === 'forgot_password') navigate('/set-new-password', { state: { email: email } });
      else navigate('/login')
    } catch (error) {
      console.error('error', error);
      setError(error?.response?.data?.non_field_errors[0])
    } finally {
      setSubmitting(false);
    }
  };

  if (error)
    showAllNotifications(error, ToastColors.error);

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <div className="flex flex-col justify-center items-center bg-gray-50 w-full md:w-1/2 p-4 md:p-8 lg:p-12">
        <div className="w-full max-w-md mx-auto">
          <img src={AreaLogo} alt="Logo" className="mb-6 md:mb-8 lg:mb-10" />
          <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 mt-4 mb-4 md:mb-6 lg:mb-8 text-center">
            Verify OTP
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={OTPSchema}
            onSubmit={handleVerifyOTP}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3 md:mb-4">
                  <label className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2" htmlFor="otp">
                    OTP
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="otp"
                    name="otp"
                    type="text"
                    placeholder="Enter OTP"
                  />
                  <ErrorMessage name="otp" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="my-4 md:my-6">
                  <button
                    className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-1 px-3 md:py-2 md:px-4 rounded-full focus:outline-none focus:shadow-outline w-full text-xs md:text-sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Verify OTP
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {(error === 'OTP has expired') ? (
            <button
              onClick={resendOTP}
              className='bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline w-full'
            >
              Your OTP has expired, click to resend
            </button>
          )
            : (false)
          }
        </div>
      </div>
      <div className="hidden md:flex w-1/2">
        <img src={RegisterImage} alt="Verify OTP" className="w-full h-full object-cover" />
      </div>
    </div>
  );
};

export default VerifyOTP;
