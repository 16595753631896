import React, { useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { AngularVector, Attachment, DefaultImg, Info, ThreeDots } from "../../assets/icons/icons";
import { Formik, Form, Field } from 'formik';
import { FiSearch } from 'react-icons/fi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { getTime, timeAgo } from "../../utils/time_ago";
import { useDispatch, useSelector } from "react-redux";
import { setDirectMessages, setInputMessage, setReceiver, setUnreadMessagesCount, setUserConversations, setUserMessages } from "../../redux/slices/chatSlice";
import { useWebSocket } from "../../utils/context/WebsocketContext";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";

const Chat = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { userConversations, directMessages, inputMessage, receiver } = useSelector((state) => state.chat);

  const endOfMessagesRef = useRef(null);
  const websocket = useWebSocket();

  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId');

  const [conversations, setConversations] = useState([]);
  const [uploadAttachment, setUploadAttachment] = useState();

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handleSendMessage = async () => {
    if (websocket && websocket.readyState !== WebSocket.OPEN) return;

    let data = {
      action: "send_message",
      receiver_id: receiver.id,
    };

    if (uploadAttachment) {
      const base64 = await convertFileToBase64(uploadAttachment);
      data = {
        ...data,
        attachment: {
          filename: uploadAttachment.name,
          content_type: uploadAttachment.type,
          base64: base64.split(',')[1]
        },
      }
    }

    if (inputMessage.trim()) data = { ...data, message: inputMessage.trim() };

    if (data.message || data.attachment) {
      websocket.send(JSON.stringify(data));
      dispatch(setInputMessage(""));
      setUploadAttachment(null);
    }
  };

  const handleConversationClick = (msg) => {
    const id = getUserId(msg);

    if (id && websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify({ action: "get_messages", receiver_id: id }));
      dispatch(setReceiver({
        id: id,
        name: getUserName(msg),
        image: getUserImg(msg),
        is_online: getIsOnline(msg),
      }));
    }
  }

  const getUserId = (msg) => msg.sender.id === user.id ? msg.receiver.id : msg.sender.id;
  const getUserName = (msg) => (msg.sender.id === user.id) ? (msg.receiver_name.trim() || msg.receiver_username) : (msg.sender_name.trim() || msg.sender_username);
  const getUserImg = (msg) => msg.sender.id === user.id ? msg.receiver.profile_picture : msg.sender.profile_picture;
  const getIsOnline = (msg) => msg.sender.id === user.id ? msg.receiver.is_online : msg.sender.is_online;

  const handleAttachment = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setUploadAttachment(['image/jpeg', 'application/pdf'].includes(selectedFile.type) ? selectedFile : null);
    }
  }

  useEffect(() => {
    if (endOfMessagesRef.current && directMessages.length > 5) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [directMessages]);

  useEffect(() => {
    if (userId) {
      dispatch(setReceiver({ id: parseInt(userId) }));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (receiver.id && userConversations.length > 0) {
      const msg = userConversations.find((conv) => [conv.sender.id, conv.receiver.id].includes(receiver.id));
      if (msg) {
        dispatch(setReceiver({
          id: receiver.id,
          name: getUserName(msg),
          image: getUserImg(msg),
          is_online: getIsOnline(msg),
        }));
      }
      setConversations(userConversations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userConversations, receiver.id]);

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (event) => {
        const response = JSON.parse(event.data).data;

        if (![200, 201].includes(response.status_code)) {
          showAllNotifications(response.error, ToastColors.error);
          return;
        }

        switch (response.action) {
          case "get_unread_messages_count":
            dispatch(setUnreadMessagesCount(response.data));
            break;
          case "inbox_view_messages":
            dispatch(setUserMessages(response.data));
            break;
          case "mark_all_as_read":
            if (response.data) {
              if (websocket && websocket.readyState === WebSocket.OPEN) {
                websocket.send(JSON.stringify({ action: "get_unread_messages_count" }));
                websocket.send(JSON.stringify({ action: "inbox_view_messages" }));
              }
            }
            if (websocket && websocket.readyState === WebSocket.OPEN) {
              websocket.send(JSON.stringify({ action: "get_conversations" }));
            }
            break;
          case "send_message":
            dispatch(setDirectMessages({ message: response.data }));
            if (websocket && websocket.readyState === WebSocket.OPEN) {
              websocket.send(JSON.stringify({ action: "get_conversations" }));
              if (response.data.receiver.id === user.id) {
                websocket.send(JSON.stringify({ action: "get_messages", receiver_id: response.data.sender.id }));
              } else {
                websocket.send(JSON.stringify({ action: "get_unread_messages_count" }));
                websocket.send(JSON.stringify({ action: "inbox_view_messages" }));
              }
            }
            break;
          case "get_conversations":
            dispatch(setUserConversations(response.data));
            break;
          case "get_messages":
            dispatch(setDirectMessages({ messages: response.data }));
            if (websocket && websocket.readyState === WebSocket.OPEN) {
              websocket.send(JSON.stringify({ action: "get_unread_messages_count" }));
              websocket.send(JSON.stringify({ action: "inbox_view_messages" }));
            }
            break;
          default:
            console.warn("Unhandled action: ", response.action);
        }
      };

      websocket.onopen = () => {
        if (websocket && websocket.readyState === WebSocket.OPEN) {
          websocket.send(JSON.stringify({ action: "get_unread_messages_count" }));

          if (window.location.pathname === "/messages") {
            websocket.send(JSON.stringify({ action: "get_conversations" }));
            if (receiver.id) {
              websocket.send(JSON.stringify({ action: "get_messages", receiver_id: receiver.id }));
            }
          }
        }
      };
    }
  }, [dispatch, websocket]);

  useEffect(() => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify({ action: "get_unread_messages_count" }));
      websocket.send(JSON.stringify({ action: "get_conversations" }));

      if (receiver.id) {
        websocket.send(JSON.stringify({ action: "get_messages", receiver_id: receiver.id }));
      }
    }
  }, [receiver.id, websocket]);

  return (
    <div className="w-full h-full max-w-screen-xl px-4 mx-auto pt-20 pb-10 my-10 flex justify-between">
      <div className="w-[27%] h-full">
        <div className="text-left mb-10">
          <div className="text-4xl font-semibold text-gray-900 mb-2 font-poppins">
            Chats
          </div>
          <p className="text-gray-600 text-base flex items-center space-x-2">
            <Link to="/" className="text-gray-600 no-underline hover:underline font-poppins">Home</Link>
            <img src={AngularVector} alt="Location Icon" />
            <span className="font-poppins">Chats</span>
          </p>
        </div>
        <div className="w-full text-2xl font-bold mb-2">Messages</div>
        <div className="w-full text-2xl font-bold mb-2">
          <Formik
            initialValues={{ search: '' }}
            onSubmit={(values) => {
              if (values.search) {
                setConversations(userConversations.filter((conv) => user.id === conv.sender.id ? conv.sender_name.includes(values.search) : conv.receiver_name.includes(values.search)));
              } else {
                setConversations(userConversations);
              }
            }}
          >
            <Form className="flex flex-col md:flex-row items-center w-full md:max-w-lg">
              <div className="relative flex-1 w-[70%] md:w-full">
                <Field
                  name="search"
                  type="text"
                  placeholder="Search by name"
                  className="w-full py-1 pl-10 pr-4 border text-lg font-medium bg-white shadow-sm rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <FiSearch className="absolute left-3 top-2.5 text-lg text-gray-500" />
              </div>
            </Form>
          </Formik>
        </div>
        <div className="w-full mt-4">
          {
            conversations && conversations.map((conv) => (
              <div className="flex items-center cursor-pointer mb-3" key={`message-${conv.id}`} onClick={() => handleConversationClick(conv)}>
                <img className="w-9 h-9 me-2 rounded-full object-cover" src={getUserImg(conv) || DefaultImg} alt="properties" />
                <div className="w-full">
                  <div className="w-full font-medium text-sm leading-5 flex justify-between items-center">
                    <span className="text-[#8E9C9C]">{getUserName(conv)}</span>
                    <span className="text-xs leading-4 text-[#000000A6]">{timeAgo(conv.timestamp)}</span>
                  </div>
                  <div className="w-full font-medium text-[#00000073] overflow-hidden text-ellipsis whitespace-nowrap leading-5">
                    {conv.message}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="relative w-[71%] rounded-2xl p-6 border border-[1px_solid_#DFDFDF] flex flex-col h-full min-h-96 max-h-[675px]">
        {
          receiver.id ? (
            <>
              <div className="w-full p-2 flex justify-between border-b border-b-[1px_solid_#DFDFDF]">
                <div className="w-fit flex items-center">
                  <img className="w-11 h-11 rounded-full object-cover me-2" src={receiver.image || DefaultImg} alt="profile_picture" />
                  <div>
                    <div className="text-[#8e9c9c] font-medium w-full">{receiver.name}</div>
                    {
                      receiver.is_online && (
                        <div className="text-[#27AE60] text-xs font-medium">Online</div>
                      )
                    }
                  </div>
                </div>
                <div className="flex">
                  <img src={ThreeDots} alt="options" />
                  <img src={Info} alt="info" />
                </div>
              </div>
              <div className="overflow-y-scroll px-6 py-4 flex-grow flex flex-col items-end gap-3 w-full">
                {
                  directMessages.length > 0 ? (
                    <>
                      {
                        directMessages.map((msg, index) => (
                          <div className={`max-w-[350px] p-1 text-sm rounded-2xl ${msg.sender.id === user.id ? "ms-auto bg-[#8E9C9C] !rounded-br-none" : "me-auto bg-[#F4F6F6] !rounded-tl-none"}`} key={`message-${msg.id}-${index}`}>
                            {
                              msg.attachment && (
                                <>
                                  {msg.attachment_type === 'pdf' ? (
                                    <iframe
                                      name={msg.attachment.split("/").pop()}
                                      src={`https://docs.google.com/viewer?url=${msg.attachment}&embedded=true`}
                                      title={msg.attachment.split("/").pop()}
                                      className={`w-full h-96 ${msg.sender.id === user.id ? "rounded-tl-xl" : ""} rounded-tr-xl`}
                                    />
                                  ) : (
                                    <img src={msg.attachment} alt={msg.attachment_type} className={`w-full ${msg.sender.id === user.id ? "rounded-tl-xl" : ""} rounded-tr-xl`} />
                                  )}
                                </>
                              )
                            }
                            <div className={`px-2 py-1 ${msg.sender.id === user.id ? "text-white" : "text-black"}`}>
                              {msg.message}
                            </div>
                            <div className="w-full text-[#00000073] text-xs px-2 pb-1">{getTime(msg.timestamp)}</div>
                          </div>
                        ))
                      }
                      {
                        uploadAttachment && (
                          <div className="py-4 w-full" ref={endOfMessagesRef} />
                        )
                      }
                      <div className="py-3 w-full" ref={endOfMessagesRef} />
                    </>
                  ) : (
                    <h3 className="my-auto mx-auto">Start conversation with {receiver.name}</h3>
                  )
                }
              </div>
              <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center mx-6 mb-4 bg-white pt-2">
                <div className="flex items-center w-full px-2 bg-white rounded-full shadow-md border-[0.5px]">
                  <div className="relative">
                    <input
                      type="file"
                      accept="image/jpg, application/pdf"
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10 file:cursor-pointer"
                      onChange={handleAttachment}
                    />
                    <img src={Attachment} alt="attachment" />
                  </div>
                  <div className="flex flex-col flex-grow">
                    {
                      uploadAttachment && (
                        <div className="relative ps-3 mt-2 w-fit">
                          {uploadAttachment.type === 'application/pdf' ? (
                            <iframe
                              name="attachment"
                              src={URL.createObjectURL(uploadAttachment)}
                              title="Contract generated by AI"
                              className="w-20 h-14"
                            />
                          ) : (
                            <img src={URL.createObjectURL(uploadAttachment)} alt="preview" className="h-14 rounded-lg" />
                          )}
                          <div
                            className="absolute -top-2 -right-2 w-4 h-4 rounded-full bg-red-500 flex justify-center items-center text-white text-[9px] z-10 cursor-pointer"
                            onClick={(e) => {
                              setUploadAttachment(null);
                            }}
                          >X</div>
                        </div>
                      )
                    }
                    <input
                      type="text"
                      className="p-3 focus:outline-none"
                      placeholder="Type Here..."
                      value={inputMessage}
                      onChange={(e) => dispatch(setInputMessage(e.target.value))}
                      onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                    />
                  </div>
                  <button
                    className="w-10 h-10 flex justify-center items-center rounded-full text-gray-500 focus:outline-none hover:bg-gray-100"
                    onClick={handleSendMessage}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>
              {/* <div className="h-20 w-full"></div> */}
            </>
          ) : (
            <h3 className="my-auto mx-auto">Open conversation</h3>
          )
        }
      </div>
    </div>
  );
};

export default Chat;
