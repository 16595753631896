import React, { useEffect } from 'react';
import { IconLocation } from '../../assets/icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNeighborhoodCities, getFeaturedProperties } from '../../redux/slices/propertySlice';
import { HousePlacholder } from '../../assets/images/images';

const FeatureListings = () => {
  const dispatch = useDispatch();
  const { neighborhoodCities } = useSelector((state) => state.properties);
  const { featuredProperties } = useSelector((state) => state.properties);

  useEffect(() => {
    dispatch(getFeaturedProperties('All'));
    dispatch(fetchNeighborhoodCities());
  }, [dispatch]);

  const getPropertyImage = (property) => {
    const { PhotoURLPrefix, PhotoKey, PhotosCount } = property;

    if (PhotosCount > 0)
      return `${PhotoURLPrefix}${PhotoKey}/photo_1.jpg`

    return HousePlacholder
  }

  return (
    <div className="w-full md:w-[35%] flex flex-col space-y-6 font-poppins">
      {/* Featured Listings Section */}
      <div className="bg-white p-6 mb-6">
        <h3 className="text-2xl font-bold mb-4">Featured Listings</h3>
        <div className="space-y-4 h-[650px] overflow-y-auto">
          {featuredProperties && featuredProperties.map((p, index) => (
            <div
              key={p.id}
              className={`flex items-center pb-4 ${index < featuredProperties.length - 1 ? 'border-b border-gray-200' : ''}`}
            >
              <img
                src={getPropertyImage(p)}
                alt="Property Img"
                className="w-24 h-24 object-cover rounded mr-4"
              />
              <div>
                <div className='mb-1 font-normal'>{p.LegalDetails}</div>
                <div className="flex items-center space-x-1 mb-1">
                  <img src={IconLocation} alt="Location Icon" className="text-gray-600" />
                  <div className="text-gray-500 font-normal">{p.MLSListingAddress}</div>
                </div>
                <div className="font-semibold mb-1">${p.OriginalListingPrice}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Explore Neighborhoods Section */}
      <div className="w-full max-w-screen-xl mx-auto mt-10 px-4 font-poppins">
        <h3 className="text-2xl font-bold mb-4">Real estate near you</h3>
        <div className="grid grid-cols-2 gap-4 max-h-[350px] overflow-y-auto">
          {
            neighborhoodCities && Object.entries(neighborhoodCities).map(([city, count], index) => (
              <div key={`${city}-${count}-${index}`} className="relative relative h-[103px] w-[185px]">
                <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url(/assets/images/${city.replace(/ /g, '%20')}.webp)` }}>
                  <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent opacity-70"></div>
                  <div className="absolute bottom-0 left-2 p-1">
                    <h3 className="text-white text-sm font-medium mb-1">{city}</h3>
                    <p className="text-white text-xs mb-2">{count} Properties</p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default FeatureListings;
