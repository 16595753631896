import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, updatePassword, updateProfile, uploadPicture } from "../../redux/slices/authSlice";
import EditableField from "../../components/common/EditableField"; // Adjust the import path as needed
import { Icon } from "src/assets/icons/icons";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import { useNavigate } from "react-router-dom";
import { DefaultProfilePicture } from "../../assets/images/images";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, error } = useSelector((state) => state.auth);

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const handleUpdate = (updatedData) => {
    dispatch(updateProfile(updatedData));
  }

  const handleUpdatePassword = () => {
    if (!newPassword.trim() || !confirmNewPassword.trim() || !oldPassword.trim()) {
      showAllNotifications('A password field is empty!', ToastColors.error)
      return
    }

    if (oldPassword.trim() === newPassword.trim()) {
      showAllNotifications('New password cannot be same as old password!', ToastColors.error)
      return
    }

    if (newPassword !== confirmNewPassword) {
      showAllNotifications('Passwords must match!', ToastColors.error)
      return
    }

    dispatch(updatePassword({ old_password: oldPassword, password: newPassword }))
  }

  const handleSave = (field, value) => {
    const updatedUser = { [field]: value };

    if (user[field] === value) return;

    handleUpdate(updatedUser);
  };

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      formData.append('images', e.target.files[0]);
      formData.append('directory', 'images');
      dispatch(uploadPicture(formData))
    }
  };

  if (error) {
    if (error?.code === 'token_not_valid') {
      showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
      dispatch(logout());
      navigate('/login');
    }
    if (error.error_type === 'validation') {
      Object.entries(error.errors).forEach(([key, value]) => {
        showAllNotifications(value, ToastColors.error);
      });
    }
  }

  return (
    <div className="bg-white min-h-screen flex flex-col items-center">
      <div className="w-[90%] p-8 pt-20 mt-10 w-[80%]">
        <div className="text-gray-800 text-3xl font-semibold mb-4">My Profile</div>
        <div className="flex flex-col items-center lg:flex-row lg:items-start lg:space-x-8">
          <div className="relative w-24 h-24 rounded-full overflow-hidden">
            <img src={user?.profile_picture || DefaultProfilePicture} alt="User Avatar" className="w-full h-full object-cover" />
            <input
              type="file"
              accept="image/*"
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              onChange={handleImageChange}
            />
            <div className="absolute bottom-0 right-0 w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer">
              <img src={Icon} alt="Upload Icon" className="w-4 h-4" />
            </div>
          </div>
          <div className="flex flex-col items-center lg:items-start mt-4 lg:mt-0">
            <div className="text-2xl font-semibold text-gray-800">{user.username || "John Doe"}</div>
          </div>
        </div>
        <div className="mt-8 flex flex-wrap -mx-4 w-[80%]">
          <EditableField
            label="First Name"
            isRequired={true}
            value={user.first_name}
            onSave={(value) => handleSave('first_name', value)}
          />
          <EditableField
            label="Last Name"
            isRequired={true}
            value={user.last_name}
            onSave={(value) => handleSave('last_name', value)}
          />
          <EditableField
            label="Username"
            isRequired={true}
            value={user.username}
            onSave={(value) => handleSave('username', value)}
          />
          <EditableField
            label="Email"
            isRequired={true}
            value={user.email || "abc123@example.com"}
            onSave={(value) => handleSave('email', value)}
          />
          <EditableField
            label="Date of Birth"
            value={user.date_of_birth || "06 January 1998"}
            onSave={(value) => handleSave('date_of_birth', value)}
            isDate={true}
          />
          <EditableField
            label="Gender (M/F/O)"
            isRequired={true}
            value={user.gender || "M"}
            onSave={(value) => handleSave('gender', value.toUpperCase())}
          />
          <EditableField
            label="Contact Number"
            value={user.contact_no || "123-456-7890"}
            onSave={(value) => handleSave('contact_no', value)}
            isPhone={true}
          />
        </div>
        <div className="mt-8">
          <div className="text-xl font-semibold">Security Information</div>
          <div className="-mx-4 mt-4 w-[80%]">
            <EditableField
              label="Old Password"
              value=""
              onSave={(value) => setOldPassword(value)}
            />
          </div>
          <div className="flex flex-wrap -mx-4 mt-4 w-[80%]">
            <EditableField
              label="New Password"
              value=""
              onSave={(value) => setNewPassword(value)}
            />
            <EditableField
              label="Confirm Password"
              value=""
              onSave={(value) => setConfirmNewPassword(value)}
            />
          </div>
          <button onClick={handleUpdatePassword} className="bg-[#8E9C9C] text-white py-3 px-5 rounded-full inline-flex items-center font-normal">
            Update New Password
          </button>
        </div>
        <div className="mt-8">
          {/* <div className="text-lg font-medium text-gray-600">Account Information</div>
          <div className="mt-4">
            <div className="bg-gray-100 p-4 rounded-md shadow-md max-w-xs">
              <div className="text-gray-800">Payment Methods</div>
              <div className="text-gray-600">Credit/Debit Card, JazzCash, EasyPaisa, SadaPay</div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Profile;
