import React from 'react';
import {
  LocationIcon,
  BedIcon,
  BathIcon,
  AreaIcon,
  PropertyArrows,
  PropertyFavorite,
  PropertySearch,
} from "src/assets/icons/icons";
import { DefaultProfilePicture, HousePlacholder } from '../../assets/images/images';
import { formatPrice } from '../../utils/price_formatter';
import { timeAgo } from '../../utils/time_ago';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { favoriteProperty, setLoadMore } from '../../redux/slices/propertySlice';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';

const PropertyCard = ({ property, isMyProperty, heightImage, heightContainer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePropertyClick = (id) => {
    dispatch(setLoadMore(true));
    navigate(`/properties/${id}`);
  };

  const handleCopyUrlToClipboard = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(`${window.location.origin}/properties/${id}`)
      .then(() => {
        showAllNotifications("Property link is copied", ToastColors.success);
      })
      .catch(err => {
        showAllNotifications("Failed to copy property link", ToastColors.error);
      });
  }

  const getPropertyImage = () => {
    const { PhotoURLPrefix, PhotoKey, PhotosCount } = property;

    if (PhotosCount > 0)
      return `${PhotoURLPrefix}${PhotoKey}/photo_1.jpg` // return first image

    return HousePlacholder
  }

  return (
    <div
      className={`bg-white flex flex-col rounded-xl border border-gray-200 shadow-md p-[12px] cursor-pointer ${heightContainer ? 'h-90' : ''}`}
      onClick={() => handlePropertyClick(property.PropertyID)}
    >
      <div className="relative mb-2">
        <img
          src={getPropertyImage()}
          alt="Property"
          className={`w-full object-cover rounded-lg ${heightImage ? 'h-40' : 'h-52'}`}
        />
        <div className="absolute bottom-2 left-2 bg-white text-gray-900 font-semibold px-2 py-1">
          {property.OriginalListingPrice ? formatPrice(property.OriginalListingPrice) : formatPrice(property.LatestListingPrice)}
        </div>
        <div className="absolute bottom-2 right-2 flex space-x-2">
          <img src={PropertyArrows} alt="Icons" className="h-9" onClick={(e) => handleCopyUrlToClipboard(e, property.PropertyID)} />
          {
            !isMyProperty && (
              <img src={PropertyFavorite} alt="Icons" className="h-9" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(favoriteProperty(property.PropertyID));
              }} />
            )
          }
          {/* <img src={PropertySearch} alt="Icons" className="h-9" /> */}
        </div>
      </div>
      <h3 className="text-lg flex-grow font-semibold text-gray-900 mb-2">
        {property.LegalDetails}
      </h3>
      <p className="text-gray-700 text-[11.36px] mb-2 overflow-hidden text-ellipsis whitespace-nowrap">
        <img
          src={LocationIcon}
          alt="Location"
          className="inline w-4 h-4 mr-2"
        />
        {property.MLSListingAddress}
      </p>
      <div className="flex justify-between text-gray-700 text-[11.36px] mb-3">
        <div>
          <img src={BedIcon} alt="Beds" className="inline w-4 h-4 mr-2" />
          Beds <span className="font-bold">{property.BedroomsTotal}</span> 
        </div>
        <div>
          <img
            src={BathIcon}
            alt="Baths"
            className="inline w-4 h-4 mr-2"
          />
          Baths <span className="font-bold">{property.BathroomsFull}</span>
        </div>
        <div>
          <img
            src={AreaIcon}
            alt="Area"
            className="inline w-4 h-4 mr-2"
          />
          SqFt <span className="font-bold">{property.LotSizeSquareFeet || property.AvgMarketPricePerSqFt}</span>
        </div>
      </div>
      <div className="flex">
        <div className="flex items-center w-full">
          <div
            className="w-10 h-10 bg-cover rounded-full mr-2"
            style={{ backgroundImage: `url(${DefaultProfilePicture})` }}
          ></div>
          <div className="flex flex-row w-full items-center">
            <div className="text-gray-700 text-sm">
              {property.ListingAgentFullName}
            </div>
            <div className="text-gray-500 text-xs ml-auto">
              {property.ListingDate ? timeAgo(property.ListingDate) : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyCard;
