import api from "../../api/api";

const fetchMetricsData = () => {
  return api.get("api/properties/economic-indicator/");
};

const fetchStockRates = () => {
  return api.get("api/properties/latest-stock-rates/");
};

const tickerTapeService = {
  fetchMetricsData,
  fetchStockRates,
};

export default tickerTapeService;
