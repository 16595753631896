import React, { useState } from "react";
import ContactUsForm from "../../components/ContactUs/Form";
import { ContactUsBanner } from "../../assets/images/images";
import { AREA_FAQS, AreaPartners } from "../../constants/AreaPartners";
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { AddressIcon, AreaMarkerIcon, ContactDivider, CrossButton, FAQCross, FAQPlus, MailIcon, PhoneIcon, PlusIcon } from '../../assets/icons/icons';
import '../../components/Frame/frame.css'
import './ContactUs.css'

const libraries = ['places'];

export default function ContactUs() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_AREA_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <ContactUsSection />;
}

const ContactUsSection = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <>
      <div className="w-full bg-white overflow-hidden relative mt-[-96px]">
        {/* Banner Section */}
        <div
          className="relative w-full h-[684px] p-24 pt-[200px] bg-cover bg-center font-['Space_Grotesk']"
          style={{ backgroundImage: `url(${ContactUsBanner})` }}
        >
          <div className="flex flex-col items-center mt-8">
            <div className="w-[80%] text-white text-[53px] font-space-grotesk leading-[67.63px] font-medium uppercase text-center tracking[-0.055em]">
              We provide the most suitable and quality real estate.
            </div>
          </div>
          <div className="flex flex-col items-center mt-8">
            <div className="w-[80%] text-[41px] leading-[76px] font-space-grotesk font-normal text-white uppercase text-center">
              LOREM IPSUM DOLOR SIT AMET, CONSECTETUR
            </div>
          </div>
        </div>

        {/* Partners Section */}
        <div className="w-full py-20">
          <h2 className="text-[69px] font-space-grotesk font-normal text-center mb-4">
            Our Partners
          </h2>
          {/* Partner Animation */}
          <div className="flex whitespace-nowrap animate-scroll gap-8 justify-center">
            {AreaPartners.map((partner, index) => (
              <img
                key={index}
                src={partner.logo}
                alt={`Partner logo ${index + 1}`}
                style={{ width: partner.width, height: partner.height }}
                className="object-contain"
              />
            ))}
          </div>
        </div>

        {/* Google Map */}
        <div className="w-full h-[615px] mt-4">
          <GoogleMap
            mapContainerClassName="w-full h-full"
            zoom={10}
            center={{ lat: 39.2904, lng: -76.6122 }}
          >
            <Marker
              position={{ lat: 39.2904, lng: -76.6122 }}
              icon={{
                url: AreaMarkerIcon,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          </GoogleMap>
        </div>
      </div>

      {/* Contact Us Form */}
      <div className="relative w-[80%] mx-auto -mt-[150px] z-10 shadow-sm mb-5">
        <ContactUsForm contact_us_page={true} />
      </div>

      {/* Contact Details */}
      <div className="mx-auto mt-10 flex flex-wrap gap-20 justify-center items-center mb-10">
        <div className="flex items-center">
          <img src={AddressIcon} alt="Address" className="w-[50px] h-[50px] mr-10" />
          <div className="flex flex-col">
            <p className="text-[14px] font-semibold text-[#8E8E93] font-poppins">Office address</p>
            <p className="text-[16px] font-normal text-[#8E9C9C] font-poppins">
              260 Newport Center Drive, Suite 100
            </p>
            <p className="text-[16px] font-normal text-[#8E9C9C] font-poppins">
              Newport Beach, CA 92660
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <img src={PhoneIcon} alt="Phone" className="w-[45px] h-[45px] mr-5" />
          <img src={ContactDivider} alt="Address" className=" h-[54px] mr-5" />
          <div className="flex flex-col">
            <p className="text-[14px] font-semibold text-[#8E8E93] font-poppins">Contact</p>
            <p className="text-[30px] font-extrabold text-[#8E9C9C] font-mulish">(949) 791-8160</p>
          </div>
        </div>
        <div className="flex items-center">
          <img src={MailIcon} alt="Email" className="w-[50px] h-[50px] mr-10" />
          <div className="flex flex-col">
            <p className="text-[14px] font-semibold text-[#8E8E93] font-poppins">Email us</p>
            <p className="text-[16px] font-normal text-[#8E9C9C] font-poppins">Info@AreAHub.ai</p>
          </div>
        </div>
      </div>

      {/* Frequently Asked Questions */}
      <div className="w-full py-10">
        <h2 className="text-[45px] font-space-grotesk font-bold leading-[53.32px] text-center mb-5">
          Frequently Asked Questions
        </h2>

        <div className="w-[50%] mx-auto">
          {AREA_FAQS.map((faq, index) => (
            <div key={index} className="border-b border-gray-300 py-10">
              <div className="flex items-center justify-between cursor-pointer" onClick={() => handleToggle(index)}>
                <p className="font-space-grotesk text-[30px] font-[500] leading-[35.55px] text-left">
                  {faq.question}
                </p>
                {expandedIndex === index ? (
                  <img src={FAQCross} alt="Cross Icon" />
                ) : (
                  <img src={FAQPlus} alt="Show Icon" />
                )}
              </div>
              <div
                className={`overflow-hidden ${expandedIndex === index ? 'h-auto opacity-100' : 'h-0 opacity-0'} transition-height-opacity`}
              >
                {expandedIndex === index && (
                  <p className="mt-4 font-poppins text-[20px] font-normal leading-[23.7px] text-left text-[#8E9C9C]">
                    {faq.answer}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>

      </div>
    </>
  );
};
