import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    unreadMessagesCount: 0,
    userMessages: [],
    userConversations: [],
    directMessages: [],
    inputMessage: "",
    receiver: {},
  },
  reducers: {
    setUnreadMessagesCount: (state, action) => {
      state.unreadMessagesCount = action.payload;
    },
    setDirectMessages: (state, action) => {
      if (action.payload.messages) state.directMessages = action.payload.messages;
      if (action.payload.message) state.directMessages = [...state.directMessages, action.payload.message];
    },
    setUserMessages: (state, action) => {
      state.userMessages = action.payload;
    },
    setUserConversations: (state, action) => {
      state.userConversations = action.payload;
    },
    setInputMessage: (state, action) => {
      state.inputMessage = action.payload;
    },
    setReceiver: (state, action) => {
      state.receiver = action.payload;
    },
  },
});

export const {
  setUnreadMessagesCount,
  setDirectMessages,
  setUserMessages,
  setUserConversations,
  setInputMessage,
  setReceiver,
} = chatSlice.actions;

export default chatSlice.reducer;
