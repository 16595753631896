import React, { useEffect } from "react";
import { AcceptCheckmarkIcon, ActivePropertiesIcon, DecreaseArrowIcon, IncreaseArrowIcon, PendingApprovalsIcon, ReferralWinnerApprovalsIcon, RejectCrossIcon, TotalRevenueIcon } from "../../../assets/icons/icons";
import CircularProgress from "../../../components/common/CircularProgess";
import SemiCircularProgress from "../../../components/common/SemiCircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardOverview, getPendingApprovals, setDays, updatePropertyStatus } from "../../../redux/slices/admin/dashboardOverviewSlice";

const AdminDashboardOverview = () => {
  const dispatch = useDispatch();

  const { data, pendingApprovalsData, days } = useSelector((state) => state.adminOverview);

  useEffect(() => {
    if (days) {
      dispatch(getDashboardOverview(days));
      dispatch(getPendingApprovals(days));
    }
  }, [dispatch, days]);

  return (
    <div className="w-full">
      <div className="w-full flex justify-between mb-8">
        <div className="text-[22px]">Overview</div>
        <select
          className="text-sm px-[14px] py-[10px] border border-[#EEEEEE] rounded-3xl focus:outline-none"
          value={days}
          onChange={(e) => dispatch(setDays(e.target.value))}
        >
          <option value="30">Last 30 days</option>
          <option value="7">Last 7 days</option>
          <option value="60">Last 60 days</option>
          <option value="90">Last 90 days</option>
          <option value="365">Last year</option>
        </select>
      </div>
      <div className="w-full flex gap-6 mb-6">
        <div className="w-full rounded-2xl bg-[#F4F5F5] p-6">
          <img className="mb-6" src={TotalRevenueIcon} alt="totalRevenue" />
          <div className="w-full text-sm text-[#797979] mb-2">Total Revenue</div>
          <div className="text-3xl font-semibold mb-2">${data.total_revenue}</div>
          <div className="flex text-[10px] items-center">
            <img className="me-1" src={IncreaseArrowIcon} alt="increase" />
            {data.percentage_revenue_difference}% increase from last month
          </div>
        </div>
        <div className="w-full rounded-2xl bg-[#F4F5F5] p-6">
          <img className="mb-6" src={ActivePropertiesIcon} alt="totalRevenue" />
          <div className="w-full text-sm text-[#797979] mb-2">Active Properties</div>
          <div className="text-3xl font-semibold mb-2">
            {data.active_properties}
            <span className="text-lg"> /{data.all_properties_count}</span>
          </div>
          <div className="flex text-[10px] items-center">
            <img className="me-1" src={IncreaseArrowIcon} alt="decrease" />
            {data.percentage_active_difference}% increase from last month
          </div>
        </div>
        <div className="w-full rounded-2xl bg-[#F4F5F5] p-6">
          <img className="mb-6" src={PendingApprovalsIcon} alt="totalRevenue" />
          <div className="w-full text-sm text-[#797979] mb-2">Pending Approvals</div>
          <div className="text-3xl font-semibold mb-2">{data.pending_approval_count}</div>
          <div className="flex text-[10px] items-center">
            <img className="me-1" src={IncreaseArrowIcon} alt="increase" />
            {data.percentage_pending_difference}% increase from last month
          </div>
        </div>
        <div className="w-full rounded-2xl bg-[#F4F5F5] p-6">
          <img className="mb-6" src={ReferralWinnerApprovalsIcon} alt="totalRevenue" />
          <div className="w-full text-sm text-[#797979] mb-2">Referral Winner Approval</div>
          <div className="text-3xl font-semibold mb-2">
            0
          <span className="text-lg"> /0</span>
          </div>
          <div className="flex text-[10px] items-center">
            <img className="me-1" src={IncreaseArrowIcon} alt="increase" />
            0% increase from last month
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between gap-8">
        <div className="flex-grow rounded-2xl bg-[#F4F5F5] p-6">
          <div className="w-full mb-4 leading-5">Pending Approvals</div>
          <div className="w-full overflow-auto">
            <table className="min-w-full">
              <thead>
                <tr className="text-left">
                  <th className="py-2 border-b border-b-[#0000001A]">Name</th>
                  <th className="py-2 border-b border-b-[#0000001A]">Area</th>
                  <th className="py-2 border-b border-b-[#0000001A]">Price</th>
                  <th className="py-2 border-b border-b-[#0000001A]">Completion</th>
                  <th className="py-2 border-b border-b-[#0000001A]">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  pendingApprovalsData.length > 0 && pendingApprovalsData.map((property, index) => (
                    <tr className="hover:bg-gray-100" key={`row-${index+1}`}>
                      <td className="pt-2">{property.LegalDetails}</td>
                      <td className="pt-2">{property.MLSListingAddress}</td>
                      <td className="pt-2">${property.OriginalListingPrice}</td>
                      <td className="pt-2"><CircularProgress percentage={parseInt(property.completion_percentage)} /></td>
                      <td className="pt-2 flex gap-1">
                        <img
                          className="cursor-pointer"
                          src={RejectCrossIcon}
                          alt="reject"
                          onClick={() => dispatch(updatePropertyStatus({
                            property_id: property.PropertyID,
                            property_status: "rejected",
                          }))}
                        />
                        <img
                          className="cursor-pointer"
                          src={AcceptCheckmarkIcon}
                          alt="accept"
                          onClick={() => dispatch(updatePropertyStatus({
                            property_id: property.PropertyID,
                            property_status: "active",
                          }))}
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-96 rounded-2xl bg-[#F4F5F5] p-6">
          <div className="w-full leading-4">All Properties</div>
          <div className="w-full mt-5">
            {
              data.productivity !== null && (
                <SemiCircularProgress percentage={data.productivity} />
              )
            }
          </div>
          <div className="w-full flex gap-2">
            <div className="w-full text-center">
              <div className="text-[22px] mb-2">{data.all_properties_count}</div>
              <div className="text-sm text-[#797979]">Total Properties</div>
            </div>
            <div className="w-full text-center">
              <div className="text-[22px] mb-2 text-[#1A932E]">{data.sold_properties_count}</div>
              <div className="text-sm text-[#797979]">Sold Properties</div>
            </div>
            <div className="w-full text-center">
              <div className="text-[22px] mb-2 text-[#DFA510]">0</div>
              <div className="text-sm text-[#797979]">Queue Properties</div>
            </div>
            <div className="w-full text-center">
              <div className="text-[22px] mb-2 text-[#E65F2B]">{data.pending_approval_count}</div>
              <div className="text-sm text-[#797979]">Pending Properties</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardOverview;
