import api from "../../api/api";

const subscribeProperty = (data) => {
  return api.post("payments/charge/", data);
};

const createPropertyPlan = (data) => {
  return api.post("payments/subscriptions/", data);
};

const getPropertyPlans = () => {
  return api.get("payments/subscriptions/");
};

const updatePropertyPlan = (id, data) => {
  return api.put(`payments/subscriptions/${id}/`, data);
};

const deletePropertyPlan = (id) => {
  return api.delete(`payments/subscriptions/${id}/`);
};

const fetchClientSecret = async (data) => {
  return api.post(`payments/payment_intent/`, data);
};

const paymentService = {
  subscribeProperty,
  createPropertyPlan,
  getPropertyPlans,
  updatePropertyPlan,
  deletePropertyPlan,
  fetchClientSecret,
};

export default paymentService;
