import React, { useEffect, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { logout } from "../../../redux/slices/authSlice";
import NotificationsModal from "../../../components/Notifications/NotificationsModal";
import { Dashboard, DefaultImg, Logout, MyProfile, MyProperties, NotificationIcon, SearchIcon } from "../../../assets/icons/icons";

const AdminNavbar = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { data } = useSelector((state) => state.notifications);

  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const countUnreadNotifications = () => {
    const unread = data.filter(notification => !notification.is_read).length;
    setUnreadCount(unread);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  useEffect(() => {
    if (data.length > 0) {
      countUnreadNotifications();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <div className="w-full flex px-8 py-6 justify-between items-center shadow-[0px_4px_4px_0px_#0000000D]">
    <div className="text-3xl font-semibold">Admin Dashboard</div>
    <div className="w-2/6 flex gap-3">
      <div className="min-w-52 flex flex-grow relative ps-10 border border-[#EEEEEE] rounded-3xl items-end">
        <img className="h-5 absolute top-[14px] left-3" src={SearchIcon} alt="search" />
        <input type="text" placeholder="Search for anything..." className="w-full text-sm leading-5 py-[12px] text-[#A1A4A9] rounded-3xl border-none hover:outline-none focus:outline-none" />
      </div>
      {/* Notification Image Modal */}
      <Menu as="div" className="relative w-12 h-12 rounded-full border border-[#EEEEEE] flex justify-center items-center">
        <div className="flex relative">
          <MenuButton onClick={() => setShowNotificationModal(!showNotificationModal)}>
            <img src={NotificationIcon} alt="Notification Badge" className="relative" />
            {unreadCount > 0 && (
              <div className="absolute top-[5px] left-[10px] flex items-center justify-center w-[20px] h-[20px] bg-gray-400 rounded-full">
                <p className="text-[12px] mt-3 font-medium text-white flex items-center justify-center">
                  {unreadCount}
                </p>
              </div>
            )}
          </MenuButton>
        </div>
        <NotificationsModal
          show={showNotificationModal}
          setShowNotificationModal={setShowNotificationModal}
          notifications={data}
          onClose={() => setShowNotificationModal(false)}
        />
      </Menu>
      {/* Logged In User dropdown */}
      <Menu as="div" className="relative">
        <MenuButton className="relative rounded-full border border-[#EEEEEE] flex p-[4px]">
          <img
            className="rounded-full w-9 h-9"
            src={user?.profile_picture || DefaultImg}
            alt="img"
          />
          <div className="w-9 h-9 flex justify-center items-center">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </MenuButton>
        <MenuItems
          transition
          className="grid w-40 absolute right-0 z-10 mt-2 px-3 whitespace-nowrap origin-top-right rounded-lg !bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <MenuItem>
            <div className="whitespace-nowrap !border-b-[0.5px] !border-b-[#F2F2F2] !border-b-solid">
              <Link
                to="/dashboard"
                className="w-full flex items-center text-black no-underline py-[8px]"
              >
                <img className="h-4 pe-2" src={Dashboard} alt="properties" />
                Dashboard
              </Link>
            </div>
          </MenuItem>
          <MenuItem>
            <div className="whitespace-nowrap !border-b-[0.5px] !border-b-[#F2F2F2] !border-b-solid">
              <Link
                to="/my-properties"
                className="w-full flex items-center text-black no-underline py-[8px]"
              >
                <img className="h-4 pe-2" src={MyProperties} alt="properties" />
                My Properties
              </Link>
            </div>
          </MenuItem>
          <MenuItem>
            <div className="w-full whitespace-nowrap !border-b-[0.5px] !border-b-[#F2F2F2] !border-b-solid">
              <Link to="/profile" className="w-full flex items-center text-black no-underline py-[8px]">
                <img className="h-4 pe-2" src={MyProfile} alt="profile" />
                My Profile
              </Link>
            </div>
          </MenuItem>
          <MenuItem>
            <div className="w-full whitespace-nowrap !border-b-[0.5px] !border-b-[#F2F2F2] !border-b-solid hover:!bg-transparent">
              <Button
                className="w-full !flex items-center text-black no-underline text-sm text-start font-medium !border-none !px-0 py-[8px] !bg-transparent"
                onClick={handleLogout}
              >
                <img className="h-4 pe-2" src={Logout} alt="logout" />
                Logout
              </Button>
            </div>
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
  </div>;
};

export default AdminNavbar;
