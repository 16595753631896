import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import adService from "../../redux/services/adService";
import { STATUSES } from "../../constants/statuses";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";

export const createAd = createAsyncThunk("ad/createAd", async (data, thunkAPI) => {
  try {
    const response = await adService.createAd(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const adSlice = createSlice({
  name: "ads",
  initialState: {
    status: null,
    error: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAd.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(createAd.fulfilled, (state) => {
        state.status = STATUSES.IDLE;
        state.loading = false;
        showAllNotifications("Campaign has been created successfully", ToastColors.success);
      })
      .addCase(createAd.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
        showAllNotifications("Got an Error while creating Campaign", ToastColors.error);
      });
  },
});

export default adSlice.reducer;
