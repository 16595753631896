import React, { useEffect, useState } from "react";
import {
  AddressIconAlternative,
  AlternateGroupFemale,
  AlternateGroupMale,
  ContactDivider,
  GroupFemale,
  GroupMale,
  HatIcon,
  ListingsIcon,
  MailIconAlternative,
  OurJourney,
  PeopleIcon,
  PhoneIconAlternative,
  SalesRateIcon,
  Signature, StarIcon
} from "src/assets/icons/icons";
import { MareaAboutUs, MaxAboutUs, WhyChooseUs1, WhyChooseUs2, WhyChooseUs3, DubaiBg } from "../../assets/images/images";
import ContactUsForm from "../../components/ContactUs/Form";
import { useDispatch, useSelector } from "react-redux";
import { getPropertiesCount } from "../../redux/slices/propertySlice";
import { formatPropertyCount, getListingText } from "../../utils/time_ago";

const AboutUs = () => {
  const dispatch = useDispatch()

  const { total_properties } = useSelector((state) => state.properties)

  const [selectedAgent, setSelectedAgent] = useState('Marea');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1428);

  const imageSource = selectedAgent === 'Marea' ? MareaAboutUs : MaxAboutUs;

  useEffect(() => {
    const handleWindowResize = () => {
      setIsSmallScreen(window.innerWidth < 1428);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getPropertiesCount())
  }, [dispatch])

  return (
    <div className="flex flex-col w-full">
      <div className="w-full bg-white overflow-hidden relative mt-[-96px]">
        <div className="flex flex-col items-center w-full h-[684px] p-24 pt-[200px] bg-cover bg-center bg-[url(./about-us.png)] font-['Space_Grotesk'] mb-10">
          <div className="w-[90%] font-space-grotesk text-[53px] font-medium uppercase leading-[67.63px] tracking-[-0.055em] text-white text-center mx-4 mt-2">
            START YOUR EXPERIENCE WITH AREA - THE LEADING <br /> AI REAL ESTATE MARKET
          </div>
          <div className="flex flex-col items-center mt-12">
            <div className="w-[100%] font-space-grotesk text-[41px] font-normal leading-[52.37px] text-white uppercase text-center">
              LOREM IPSUM DOLOR SIT AMET, CONSECTETUR
            </div>
          </div>
        </div>

        {/* Better lives with better homes section */}

        <div className="w-[70%] mx-auto flex flex-col md:flex-row gap-8 pt-[80px]">
          {/* <!-- Left Column --> */}
          <div className="w-full md:w-3/5 flex justify-start">
            <div className="w-full max-w-[80%]">
              {/* <!-- Content for the left column --> */}
              <h2 className="font-space-grotesk text-[45px] font-normal leading-[54px] text-left text-[#1C1C1E] mb-3">
                Better Lives With <br /> <span className="block">Better Homes</span>
              </h2>
              <p className="font-poppins text-[14px] font-normal leading-[21px] text-[#3A3A3C] mb-5">
                At AreA, we're redefining the real estate experience by combining cutting-edge technology with a personal touch. Our platform utilizes advanced AI to enhance property listings, ensuring they are showcased at their very best and reach a global audience. With the help of MareA, your autonomous real estate agent, we provide seamless, round-the-clock assistance in every step of your real estate journey. From finding your dream home to securing the best deals with zero commission, AreA is committed to making your real estate transactions as efficient, cost-effective, and stress-free as possible. Discover how we're transforming real estate into a smoother, more accessible experience by clicking the video to the right, featuring MareA, who will guide you through what makes AreA uniquely equipped to enhance your life and home.
              </p>
              <div className="pl-7 border-l-4 border-gray-500">
                <p className="font-poppins text-[14px] font-medium italic leading-[21px] text-left text-[#3A3A3C] mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse auctor, mi euismod dignissim scelerisque, eros augue vehicula lectus, quis vestibulum enim augue ut est.
                </p>
              </div>
              {/* Signature */}
              <div className="pl-7">
                <p className="font-poppins text-[14px] font-normal leading-[16.8px] text-left text-[#8E8E93] mb-3">
                  CEO & Co-Founder
                </p>
                <img src={Signature} alt="Signature" className="mt-2" />
              </div>
              {isSmallScreen &&
                <>
                  {/* Sales Rate */}
                  <div className="flex mt-5">
                    <div
                      className="w-[35%] rounded-full flex flex-col items-center justify-center bg-[#8E9C9C] py-3 px-3 mr-5"
                    >
                      <img src={SalesRateIcon} alt="Icon" className="mb-2" />
                      <div className="text-center">
                        <span className="font-poppins text-[40px] font-extrabold leading-[50.77px] block text-[#FFFFFF]">
                          87%
                        </span>
                        <span className="font-mulish text-[14px] font-semibold leading-[21px] block text-[#FFFFFF]">
                          Sales Rate
                        </span>
                      </div>
                    </div>
                    {/* Listings */}
                    <div className="w-[35%] flex flex-col items-center justify-center bg-[#8E9C9C] py-4 px-3 rounded-[10px]">
                      <img src={ListingsIcon} alt="Icon" className="mb-2" />
                      <div className="text-center">
                        <span className="font-poppins text-[26px] font-extrabold leading-[33px] block text-[#FFFFFF]">
                          {formatPropertyCount(total_properties)}
                        </span>
                        <span className="font-poppins text-[14px] font-semibold leading-[21px] block text-[#FFFFFF]">
                          {getListingText(total_properties)}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>

          {/* <!-- Right Column --> */}
          <div className="w-full md:w-2/5 flex justify-end">
            <div className="w-full text-right">
              {/* <!-- Content for the right column --> */}
              <div className="flex flex-row justify-start space-x-4 mb-2">
                <button
                  className={`flex items-center rounded-full px-2 ${selectedAgent === 'Marea' ? 'bg-black text-white border border-black' : 'bg-white text-black border border-gray-300'} h-[38px]`}
                  onClick={() => setSelectedAgent('Marea')}
                >
                  <img src={selectedAgent === 'Marea' ? GroupFemale : AlternateGroupFemale}
                    alt="Icon 1"
                    className="w-7 h-8"
                  />
                  <span className="font-poppins text-[12px] font-normal leading-[18px] text-center">
                    Marea
                  </span>
                </button>
                <button
                  className={`flex items-center rounded-full px-2 ${selectedAgent === 'Max' ? 'bg-black text-white border border-black' : 'bg-white text-black border border-gray-300'} h-[38px]`}
                  onClick={() => setSelectedAgent('Max')}
                >
                  <img src={selectedAgent === 'Max' ? AlternateGroupMale : GroupMale} alt="Icon 2" className="w-7 h-8" />
                  <span className="font-poppins text-[12px] font-normal leading-[18px] text-center">
                    Max
                  </span>
                </button>
              </div>
              {/* Image Section */}
              <div className="relative w-[85%] h-[677px]">
                <img
                  src={DubaiBg}
                  alt="Background"
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <img
                  src={imageSource}
                  alt="Foreground"
                  className="relative h-[677px] object-cover"
                />
                {/* Sales Rate */}
                {!isSmallScreen &&
                  <>
                    <div
                      className="absolute rounded-full flex flex-col items-center justify-center bg-[#8E9C9C] py-3 px-9 right-[60%] bottom-[37%] translate-x-[-50%] translate-y-[50%]"
                    >
                      <img src={SalesRateIcon} alt="Icon" className="mb-2" />
                      <div className="text-center">
                        <span className="font-poppins text-[40px] font-extrabold leading-[50.77px] block text-[#FFFFFF]">
                          87%
                        </span>
                        <span className="font-mulish text-[14px] font-semibold leading-[21px] block text-[#FFFFFF]">
                          Sales Rate
                        </span>
                      </div>
                    </div>
                    {/* Listings */}
                    <div className="absolute flex flex-col items-center justify-center bg-[#8E9C9C] py-4 px-12 right-[100%] bottom-[20%] translate-x-[-50%] translate-y-[50%] rounded-[10px]">
                      <img src={ListingsIcon} alt="Icon" className="mb-2" />
                      <div className="text-center">
                        <span className="font-poppins text-[26px] font-extrabold leading-[33px] block text-[#FFFFFF]">
                          {formatPropertyCount(total_properties)}
                        </span>
                        <span className="font-poppins text-[14px] font-semibold leading-[21px] block text-[#FFFFFF]">
                          {getListingText(total_properties)}
                        </span>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>


        {/* Our Journey section */}
        <div className="flex flex-col md:flex-row w-[90%] mx-auto p-12">
          {/* Left Column */}
          <div className="w-full md:w-[52%] flex flex-col space-y-8">
            <h2 className="text-[69px] font-normal leading-[103.5px] text-left font-grotesk">
              Our Journey:
            </h2>
            <h3 className="text-4xl font-normal font-grotesk">Pioneering the Future of Real <br /> <span className="block mt-2">Estate with AREA</span></h3>
            <p className="font-urbanist text-[16px] font-medium leading-[24px] text-[#999999] text-left max-w-[90%]">
              Welcome to the journey of AREA, where we stand at the forefront of the real estate industry through the power of AI. From the outset, our mission has been to transform how properties are bought and sold by integrating cutting-edge AI technology. Meet Max and Marea, your dedicated AI conversational agents, who are set to provide a seamless and customized real estate experience. At AREA, we are committed to offering an unmatched level of service and expertise, ensuring every interaction moves you closer to finding your dream property. Join us as we lead the real estate revolution, crafting a future that is not only innovative but tailored perfectly for you.
            </p>
            {/* Cards Container */}
            <div className="flex flex-col sm:flex-row gap-4">
              {/* Card 1 */}
              <div className="bg-[#8E9C9C] text-white px-4 py-4 rounded-lg border border-[#8E9C9C] flex flex-col items-start gap-1">
                <h4 className="text-[30px] font-urbanist font-bold leading-[45px] text-[#FFFFFF] text-left">200+</h4>
                <p className="text-[16px] font-urbanist font-medium leading-[24px] text-left">Happy Customers</p>
              </div>
              {/* Card 2 */}
              <div className="bg-[#8E9C9C] text-white px-4 py-4 rounded-lg border border-[#8E9C9C] flex flex-col items-start gap-1">
                <h4 className="text-[30px] font-urbanist font-bold leading-[45px] text-[#FFFFFF] text-left">10k+</h4>
                <p className="text-[16px] font-urbanist font-medium leading-[24px] text-left">Properties for clients</p>
              </div>
              {/* Card 3 */}
              <div className="bg-[#8E9C9C] text-white px-4 py-4 rounded-lg border border-[#8E9C9C] flex flex-col items-start gap-1">
                <h4 className="text-[30px] font-urbanist font-bold leading-[45px] text-[#FFFFFF] text-left">16+</h4>
                <p className="text-[16px] font-urbanist font-medium leading-[24px] text-left">Years of experience</p>
              </div>
            </div>
          </div>
          {/* Right Column */}
          <div className="w-full md:w-[48%] flex items-center justify-center mt-8 md:mt-0">
            <div className="w-full max-w-md md:max-w-full mx-auto">
              <img
                src={OurJourney}
                alt="About Us"
                className="w-full h-auto object-cover rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* Our values section */}
        <div className="flex flex-col lg:flex-row w-[90%] mx-auto p-12">
          {/* Left column */}
          <div className="lg:w-[30%] flex flex-col space-y-4 justify-center">
            <h1 className="text-[69px] font-normal leading-[103.5px] text-left font-grotesk">Our Values</h1>
            <p className="font-urbanist text-[16px] font-medium leading-[24px] text-[#999999] text-left max-w-[80%]">
              At AreA, customer satisfaction reigns supreme, underpinned by our steadfast commitment to trust and transparency. We believe these core values are the foundation of successful real estate interactions and lasting relationships.
            </p>
          </div>

          {/* Right column (Grid of Values) */}
          <div className="lg:w-[70%] w-full lg:pl-12 mt-8 lg:mt-0 font-urbanist text-white relative">
            <div className="px-6 py-4 bg-[#8E9C9C] rounded-lg relative">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 relative">
                {/* Value Card */}
                <div className="flex items-start border-opacity-20 px-8 pt-8 pb-2 flex-col relative">
                  <div className="text-white flex items-center justify-center rounded-full mb-4">
                    <img src={StarIcon} alt="Trust" className="w-full h-full object-contain mr-2" />
                    <p className="font-urbanist text-[20px] font-semibold leading-[30px] text-left">
                      Trust
                    </p>
                  </div>
                  <div>
                    <div className="font-urbanist text-[16px] font-medium leading-[24px] text-left">
                      Trust is the cornerstone of every successful real estate transaction.
                    </div>
                  </div>
                </div>

                {/* Value Card */}
                <div className="flex items-start border-opacity-20 flex-col relative pl-12 pt-8 pb-0 border-l-2">
                  <div className="text-white flex items-center justify-center rounded-full mb-4">
                    <img src={HatIcon} alt="Excellence" className="w-full h-full object-contain mr-2" />
                    <p className="font-urbanist text-[20px] font-semibold leading-[30px] text-left">
                      Excellence
                    </p>
                  </div>
                  <div>
                    <div className="font-urbanist text-[16px] font-medium leading-[24px] text-left">
                      We set the bar high for ourselves. From the properties we list to the services we provide.
                    </div>
                  </div>
                </div>

                {/* Value Card */}
                <div className="flex items-start border-opacity-20 px-8 pt-2 pb-4 flex-col relative mt-3">
                  <div className="text-white flex items-center justify-center rounded-full mb-4">
                    <img src={PeopleIcon} alt="Client Centric" className="w-18 h-18 object-contain mr-2" />
                    <p className="font-urbanist text-[20px] font-semibold leading-[30px] text-left">
                      Client Centric
                    </p>
                  </div>
                  <div>
                    <div className="font-urbanist text-[16px] font-medium leading-[24px] text-left">
                      Your dreams and needs are at the forefront of everything we do.                    </div>
                  </div>
                </div>

                {/* Value Card */}
                <div className="flex items-start border-opacity-20 flex-col relative pl-12 pt-2 pb-4 border-l-2 mt-3">
                  <div className="text-white flex items-center justify-center rounded-full mb-4">
                    <img src={HatIcon} alt="Commitment" className="w-18 h-18 object-contain mr-2" />
                    <p className="font-urbanist text-[20px] font-semibold leading-[30px] text-left">
                      Our Commitment
                    </p>
                  </div>
                  <div>
                    <div className="font-urbanist text-[16px] font-medium leading-[24px] text-left">
                      We are dedicated to providing you with the highest level of service, professionalism.                    </div>
                  </div>
                </div>

                <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                  <div className="relative w-full h-full">
                    {/* Horizontal Line */}
                    <div className="absolute inset-x-6 top-[49.5%] transform -translate-y-1/2 bg-white h-0.5" style={{ marginTop: '5px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-[90%] mx-auto p-12">
          <h2 className="text-[69px] font-normal leading-[88.04px] text-left font-grotesk">
            Our Achievements: A Legacy:
          </h2>
          <h2 className="text-[69px] font-normal leading-[88.04px] text-left font-grotesk">
            of Innovation and Excellence
          </h2>
          <p className="font-urbanist text-[16px] font-medium leading-[24px] text-left mb-4 text-[#999999] mt-2">
            Our story is one of remarkable growth and evolution. AREA represents the pinnacle of leadership and overachievement, fused with a transformative shift in technology. This powerful combination has propelled us to redefine the real estate experience, setting new standards and consistently exceeding expectations. Our journey from a visionary startup to a market leader exemplifies our commitment to excellence and innovation, making every milestone a testament to our groundbreaking approach in the industry.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 font-urbanist">
            <div className="bg-[#8E9C9C] rounded-lg p-8 flex flex-col">
              <h3 className="font-urbanist text-[24px] font-semibold leading-[36px] text-left text-white mb-4">
                3+ Years of Excellence
              </h3>
              <p className="text-[16px] font-urbanist font-medium leading-[24px] text-left text-[#FFFFFF]">
                With over 3 years in the industry, we've amassed a wealth of knowledge and experience.              </p>
            </div>
            <div className="bg-[#8E9C9C] rounded-lg p-8 flex flex-col">
              <h3 className="font-urbanist text-[24px] font-semibold leading-[36px] text-left text-white mb-4">
                Happy Clients
              </h3>
              <p className="text-[16px] font-urbanist font-medium leading-[24px] text-left text-[#FFFFFF]">
                Our greatest achievement is the satisfaction of our clients. Their success stories fuel our passion for what we do.
              </p>
            </div>
            <div className="bg-[#8E9C9C] rounded-lg p-8 flex flex-col">
              <h3 className="font-urbanist text-[24px] font-semibold leading-[36px] text-left text-white mb-4">
                Industry Recognition
              </h3>
              <p className="text-[16px] font-urbanist font-medium leading-[24px] text-left text-[#FFFFFF]">
                We've earned the respect of our peers and industry leaders, with accolades and awards that reflect our commitment to excellence.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-[90%] mx-auto p-12">
          <h2 className="text-[69px] font-normal leading-[103.5px] text-left font-grotesk">Navigating the AI Experience</h2>
          <p className="text-lg text-[#999999] font-urbanist mb-8">
            AreA invites you to a personal AI experience that’s as warm and inviting as it is intuitive and efficient. Enjoy a platform that values your time and enhances your journey, making every interaction smoother and more rewarding.          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="flex flex-col">
              <div className="flex items-center">
                <p
                  className="font-space-grotesk text-[16px] font-medium leading-[24px] text-left text-[#999999] pl-3 py-3"
                  style={{ borderLeft: '1px solid #232323' }}
                >
                  Step 01
                </p>
              </div>
              <div className="rounded-tr-lg rounded-br-lg rounded-bl-lg p-6 flex flex-col flex-grow py-8 px-8" style={{ border: '1px solid', borderColor: '#262626', background: 'linear-gradient(120.79deg, #939393 -49.01%, rgba(148, 148, 148, 0) 13.65%)' }}>
                <h3 className="font-grotesk text-black text-lg font-bold mb-2">
                  Discover a World of Possibilities
                </h3>
                <p className="font-urbanist text-[16px] font-medium leading-[24px] text-left text-[#999999]">
                  Your journey begins with exploring our carefully curated property listings. Use our intuitive search tools to filter properties based on your preferences, including location.
                </p>
              </div>

            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <p
                  className="font-space-grotesk text-[16px] font-medium leading-[24px] text-left text-[#999999] pl-3 py-3"
                  style={{ borderLeft: '1px solid #232323' }}
                >
                  Step 02
                </p>
              </div>
              <div className="rounded-tr-lg rounded-br-lg rounded-bl-lg p-6 flex flex-col flex-grow py-8 px-8" style={{ border: '1px solid', borderColor: '#262626', background: 'linear-gradient(120.79deg, #939393 -49.01%, rgba(148, 148, 148, 0) 13.65%)' }}>
                <h3 className="font-grotesk text-black text-lg font-bold mb-2">Narrowing Down Your Choices With AI</h3>
                <p className="font-urbanist text-[16px] font-medium leading-[24px] text-left text-[#999999]">
                  Once you've found properties that catch your eye, save them to your account or make a shortlist. This allows you to compare and revisit your favorites as you make your decision.
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <p
                  className="font-space-grotesk text-[16px] font-medium leading-[24px] text-left text-[#999999] pl-3 py-3"
                  style={{ borderLeft: '1px solid #232323' }}
                >
                  Step 03
                </p>
              </div>
              <div className="rounded-tr-lg rounded-br-lg rounded-bl-lg p-6 flex flex-col flex-grow py-8 px-8" style={{ border: '1px solid', borderColor: '#262626', background: 'linear-gradient(120.79deg, #939393 -49.01%, rgba(148, 148, 148, 0) 13.65%)' }}>
                <h3 className="font-grotesk text-black text-lg font-bold mb-2">AI Recommendations</h3>
                <p className="font-urbanist text-[16px] font-medium leading-[24px] text-left text-[#999999]">
                  Have questions about a property or need more information? Just ask Max or Marea, your dedicated 24/7 AI conversational agents. They are here to provide you with expert recommendations and assist you anytime, anywhere.
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <p
                  className="font-space-grotesk text-[16px] font-medium leading-[24px] text-left text-[#999999] pl-3 py-3"
                  style={{ borderLeft: '1px solid #232323' }}
                >
                  Step 04
                </p>
              </div>
              <div className="rounded-tr-lg rounded-br-lg rounded-bl-lg p-6 flex flex-col flex-grow py-8 px-8" style={{ border: '1px solid', borderColor: '#262626', background: 'linear-gradient(120.79deg, #939393 -49.01%, rgba(148, 148, 148, 0) 13.65%)' }}>
                <h3 className="font-grotesk text-black text-lg font-bold mb-2">See It for Yourself</h3>
                <p className="font-urbanist text-[16px] font-medium leading-[24px] text-left text-[#999999]">
                  Arrange viewings of the properties you're interested in. We'll coordinate with the property owners and accompany you to ensure you get a firsthand look at your potential new home.
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <p
                  className="font-space-grotesk text-[16px] font-medium leading-[24px] text-left text-[#999999] pl-3 py-3"
                  style={{ borderLeft: '1px solid #232323' }}
                >
                  Step 05
                </p>
              </div>
              <div className="rounded-tr-lg rounded-br-lg rounded-bl-lg p-6 flex flex-col flex-grow py-8 px-8" style={{ border: '1px solid', borderColor: '#262626', background: 'linear-gradient(120.79deg, #939393 -49.01%, rgba(148, 148, 148, 0) 13.65%)' }}>
                <h3 className="font-grotesk text-black text-lg font-bold mb-2">Create Ads With AI in Minutes</h3>
                <p className="font-urbanist text-[16px] font-medium leading-[24px] text-left text-[#999999]">
                  Before making an offer, our team will assist you with due diligence, including property inspections, legal checks, and market analysis. We want you to be fully informed.
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <p
                  className="font-space-grotesk text-[16px] font-medium leading-[24px] text-left text-[#999999] pl-3 py-3"
                  style={{ borderLeft: '1px solid #232323' }}
                >
                  Step 06
                </p>
              </div>
              <div className="rounded-tr-lg rounded-br-lg rounded-bl-lg p-6 flex flex-col flex-grow py-8 px-8" style={{ border: '1px solid', borderColor: '#262626', background: 'linear-gradient(120.79deg, #939393 -49.01%, rgba(148, 148, 148, 0) 13.65%)' }}>
                <h3 className="font-grotesk text-black text-lg font-bold mb-2">Getting the Best Deal</h3>
                <p className="font-urbanist text-[16px] font-medium leading-[24px] text-left text-[#999999]">
                  We'll help you negotiate the best terms and prepare your offer. Our goal is to secure the property at the right price and on favorable terms.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Why choose us */}

        <div className="flex flex-col items-center w-[90%] mx-auto p-12 text-center">
          <h2 className="text-[69px] font-normal leading-[103.5px] text-[#000000] font-grotesk mb-2">Why Choose Us</h2>
          <p className="font-poppins text-[14px] font-normal leading-[21px] text-center text-[#3A3A3C] mb-10">
            It’s our job to make sure that you get the best possible deal on the property.
          </p>

          <div className="flex flex-wrap justify-center gap-8 font-poppins">
            <div className="text-center">
              <p className="text-base font-semibold mb-3">Thousands of posts everyday</p>
              <img src={WhyChooseUs1} alt="whyus1" className="w-[308px] h-[173px] rounded-tl-[10px] object-cover" />
            </div>
            <div className="text-center">
              <p className="text-base font-semibold mb-3">Smart filter</p>
              <img src={WhyChooseUs2} alt="whyus2" className="w-[308px] h-[173px] rounded-tl-[10px] object-cover" />
            </div>
            <div className="text-center">
              <p className="text-base font-semibold mb-3">AI Support</p>
              <img src={WhyChooseUs3} alt="whyus3" className="w-[308px] h-[173px] rounded-tl-[10px] object-cover" />
            </div>
          </div>
        </div>

        <div className="bg-[#8E9C9C] h-[654.88px] flex flex-col items-center">
          <h2 className="font-grotesk text-[45px] font-bold leading-[54px] text-center text-white mt-[120px] w-[50%]">
            We Provide The Most Suitable And Quality Properties.
          </h2>
          <div className="mx-auto mt-10 flex flex-wrap gap-x-20 gap-y-5 justify-center items-center mb-10">
            <div className="flex items-center">
              <img src={AddressIconAlternative} alt="Address" className="w-[50px] h-[50px] mr-10" />
              <div className="flex flex-col">
                <p className="text-[14px] font-semibold  text-white font-poppins">Office address</p>
                <p className="text-[16px] font-normal text-white font-poppins">
                  260 Newport Center Drive, Suite 100
                </p>
                <p className="text-[16px] font-normal text-white font-poppins">
                  Newport Beach, CA 92660
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <img src={PhoneIconAlternative} alt="Phone" className="w-[45px] h-[45px] mr-5" />
              <img src={ContactDivider} alt="Address" className=" h-[54px] mr-5" />
              <div className="flex flex-col">
                <p className="text-[14px] font-semibold text-white font-poppins">Contact</p>
                <p className="text-[30px] font-extrabold text-white font-mulish">(949) 791-8160</p>
              </div>
            </div>
            <div className="flex items-center">
              <img src={MailIconAlternative} alt="Email" className="w-[50px] h-[50px] mr-10" />
              <div className="flex flex-col">
                <p className="text-[14px] font-semibold text-white font-poppins">Email us</p>
                <p className="text-[16px] font-normal text-white font-poppins">Info@AreAHub.ai</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[80%] mx-auto -mt-[100px] md:-mt-[100px] sm:mt-[50px] mt-[100px] z-10 shadow-sm mb-5">
          <ContactUsForm contact_us_page={false} />
        </div>

      </div>
    </div >
  );
};

export default AboutUs;
