import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import paymentService from "../services/paymentService";
import { showAllNotifications } from "../../utils/notificationHelper";
import { STATUSES } from "../../constants/statuses";
import ToastColors from "../../constants/toastColors";

export const subscribeProperty = createAsyncThunk("payment/subscribeProperty", async (data, thunkAPI) => {
  try {
    const response = await paymentService.subscribeProperty(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getPropertyPlans = createAsyncThunk("payment/getPropertyPlans", async (_, thunkAPI) => {
  try {
    const response = await paymentService.getPropertyPlans();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchClientSecret = createAsyncThunk("payment/fetchClientSecret", async (data, thunkAPI) => {
  try {
    const response = await paymentService.fetchClientSecret(data);
    return { ...response.data, planId: data.plan_id, propertyId: data.property_id };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    isOpen: false,
    plans: [],
    clientSecret: "",
    selectedPlan: {
      planId: null,
      propertyId: null,
    },
    status: null,
    error: null,
    loading: false,
  },
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
      state.isOpen = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscribeProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(subscribeProperty.fulfilled, (state) => {
        state.status = STATUSES.IDLE;
        state.loading = false;
        showAllNotifications("You have successfully featured your property Ad.", ToastColors.success)
      })
      .addCase(subscribeProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getPropertyPlans.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getPropertyPlans.fulfilled, (state, action) => {
        state.plans = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getPropertyPlans.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(fetchClientSecret.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchClientSecret.fulfilled, (state, action) => {
        state.clientSecret = action.payload.clientSecret;
        state.selectedPlan = {
          ...state.selectedPlan,
          planId: action.payload.planId,
          propertyId: action.payload.propertyId,
        };
        state.isOpen = true;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchClientSecret.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      });
  },
});

export const { openModal, closeModal, setSelectedPlan } = paymentSlice.actions;
export default paymentSlice.reducer;
