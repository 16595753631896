import React, { useEffect, useState } from "react";
import { realEstateCompanies } from "../../constants/realStateCompanies";
import { BedIcon } from "../../assets/icons/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp, faArrowTrendDown } from '@fortawesome/free-solid-svg-icons';
import './frame.css';
import { formatPrice } from "../../utils/price_formatter";
import { useDispatch, useSelector } from "react-redux";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import { fetchMetricsData, fetchStockRates } from "../../redux/slices/tickerTapeSlice";

export const Frame = () => {
  const dispatch = useDispatch();
  const { metrics_data, stock_data, error } = useSelector((state) => state.tickerTape);
  const [mergedCompanies, setMergedCompanies] = useState([]);

  useEffect(() => {
    dispatch(fetchStockRates());
    dispatch(fetchMetricsData());
  }, [dispatch]);

  useEffect(() => {
    if (stock_data.length > 0) {
      const updatedCompanies = {};

      Object.entries(realEstateCompanies).forEach(([category, companies]) => {
        updatedCompanies[category] = companies.map(company => {
          const matchingStockData = stock_data.find(stock => stock.company_name === company.company_name);
          return {
            ...company,
            stock_rate: matchingStockData ? matchingStockData.stock_rate : null,
            change: matchingStockData ? matchingStockData.change : null,
          };
        });
      });

      setMergedCompanies(updatedCompanies);
    }
  }, [stock_data]);


  if (error) {
    showAllNotifications(error, ToastColors.error);
  }

  return (
    <div className="relative border-y border-gray-300 flex justify-center">
      {/* Company Data */}
      <div className="flex whitespace-nowrap animate-scroll">
        {mergedCompanies && Object.entries(mergedCompanies).map(([category, companies]) => (
          <div key={category} className="flex mx-4 relative">
            {/* Category on the left */}
            <div className="h-full flex flex-col justify-center items-center px-3 py-2 border-l border-r border-gray-300">
              <div className="font-inter text-2xl font-bold">{category.split(' (')[0]}</div>
              {category.includes('(') && (
                <div className="text-sm text-[#757575] text-center min-w-32 whitespace-normal">{`(${category.split(' (')[1].replace(')', '')})`}</div>
              )}
            </div>
            {/* Companies to the right */}
            <div className="flex items-center py-2">
              {companies.map((company, index) => (
                <div key={index} className="flex px-5 items-center">
                  {/* Company Logo */}
                  <img
                    src={company.logo}
                    alt={`${company.company_name} logo`}
                    className="w-[50px] h-full mr-4 object-contain"
                  />
                  {/* Company Details */}
                  <div className="flex flex-col font-inter mr-5">
                    <div className="font-semibold">{company.company_name}</div>
                    <div className="text-sm text-gray-600">{formatPrice(company.stock_rate)}</div>
                  </div>
                  {/* Chart */}
                  <div className="flex flex-col font-inter mr-5 items-center">
                    <div className="font-semibold text-[#757575] text-xs">Chart</div>
                    <FontAwesomeIcon
                      icon={company.change >= 0 ? faArrowTrendUp : faArrowTrendDown}
                      className={`h-[25px] text-xl ${company.change >= 0 ? 'text-green-500' : 'text-red-500'}`}
                    />
                  </div>
                  {/* Change */}
                  <div className="flex flex-col font-inter mr-5 items-center">
                    <div className="font-semibold text-[#757575] text-xs">Change</div>
                    <div
                      className={`text-lg ${company.change >= 0 ? 'text-green-500' : 'text-red-500'}`}
                    >
                      {company.change > 0 ? `+${company.change}` : company.change}%
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* Ticker Tape Data */}
        {metrics_data && metrics_data.map(({ metric_name, value }, index) => (
          <div className="flex items-start mr-20 mt-4" key={`${metric_name}-${value}-${index}`}>
            <img className="mr-3 w-8 h-8" src={BedIcon} alt={metric_name} />
            <div>
              <div className="font-['Inter'] text-black text-lg font-extrabold">{metric_name}</div>
              <div>{value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Frame;
