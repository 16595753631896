import api from '../../api/api';

const register = (first_name, last_name, username, email, password, role) => {
    return api.post('api/register/', {
        first_name,
        last_name,
        username,
        email,
        password,
        role,
    });
};

const googleAuth = (token) => {
    return api.post('api/auth/convert-token/', {
        token
    });
};

const login = (username, password) => {
    return api.post('api/login/', {
        username,
        password,
    });
};

const getProfile = () => {
    return api.get('api/profile/');
};

const verifyOTP = (otp, email) => {
    return api.post('api/verify-signup-otp/', {
        otp,
        email
    });
}

const resendOTP = (email) => {
    return api.post('api/resend-otp/', {
        email
    });
}

const resetPassword = (email, password) => {
    return api.post('api/set-password/', {
        email,
        password
    });
}

const updateProfile = (updated_data) => {
    return api.patch('api/profile/',
        updated_data
    );
}

const updatePassword = (updated_data) => {
    return api.post('api/update-password/',
        updated_data
    );
}

const uploadPicture = (data) => {
    return api.post('api/upload-picture/',
        data
    )
}

const updateAgent = (id, data) => {
    return api.put(`api/update-agent/${id}/`, data);
};

const authService = {
    register,
    login,
    getProfile,
    verifyOTP,
    resendOTP,
    resetPassword,
    updateProfile,
    uploadPicture,
    updatePassword,
    googleAuth,
    updateAgent,
};

export default authService;
