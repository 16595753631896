import api from "../../api/api";

const generateContractWithAI = (data) => {
  return api.post("ai_ml/generate_contract/", data);  
};

const fetchProperty = (id) => {
  return api.get(`api/properties/${id}/`);
};

const fetchContract = (id) => {
  return api.get(`api/properties/contract-generation/${id}/`);
};

const createContract = (id, data) => {
  return api.post(`api/properties/contract-generation/${id}/`, data);
};

const contractService = {
  generateContractWithAI,
  fetchContract,
  createContract,
  fetchProperty,
};

export default contractService;
