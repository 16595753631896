import React from "react";
import { Messages  } from "../../assets/icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faPhone, faWarehouse } from "@fortawesome/free-solid-svg-icons";

const ContactUsForm= () => {
  return (
    <>
    <div className="bg-gray-100 rounded px-8 pt-6 pb-8 mb-4">
        <div className="flex justify-between">
        <div>
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <p className="text-gray-600 mb-4">
            We will respond as soon as we receive your message.
            </p>
        </div>
        <div className="flex">
            <img
            className="w-[36px] h-[36px] mt-4"
            src={Messages}
            alt="message icon"
            />
        </div>
        </div>
        <div className="mb-4">
        <label
            htmlFor="name"
            className="block text-gray-700 text-sm font-bold mb-2"
        >
            Your name
        </label>
        <div className="flex items-center border border-gray-300 rounded-full bg-white">
        <FontAwesomeIcon icon={faUser} className="ml-4 text-gray-400" />
        <input
            type="text"
            id="name"
            className="w-full py-4 px-4 bg-white text-sm text-gray-700 "
            placeholder="Your Name"
        />
        </div>
        </div>

        <div className="mb-4 flex gap-4">
        <div className="w-full">
            <label
            htmlFor="email"
            className="block text-gray-700 text-sm font-bold mb-2"
            >
            Email
            </label>
            <div className="flex items-center border border-gray-300 rounded-full bg-white">
            <FontAwesomeIcon icon={faEnvelope} className="ml-4 text-gray-400" />
            <input
              type="email"
              id="email"
              placeholder="Email"
              className="w-full py-4 px-4 bg-transparent text-sm text-gray-700 focus:outline-none rounded-full"
            />
          </div>
        </div>
        <div className="w-full">
            <label
            htmlFor="phone"
            className="block text-gray-700 text-sm font-bold mb-2"
            >
            Phone
            </label>
            <div className="flex items-center border border-gray-300 rounded-full bg-white">
            <FontAwesomeIcon icon={faPhone} className="ml-4 text-gray-400" />
            <input
              type="tel"
              id="phone"
              placeholder="Phone"
              className="w-full py-4 px-4 bg-transparent text-sm text-gray-700 focus:outline-none rounded-full"
            />
          </div>
        </div>
        </div>

        <div className="mb-4">
        <label
            htmlFor="property_type"
            className="block text-gray-700 text-sm font-bold mb-2"
        >
            Property type
        </label>
        <div className="flex items-center border border-gray-300 rounded-full bg-white pr-4">
          <FontAwesomeIcon icon={faWarehouse} className="ml-4 text-gray-400" />
          <select
            id="property_type"
            className="w-full py-4 px-4 bg-transparent text-sm text-gray-700 focus:outline-none rounded-full"
          >
            <option>Choose</option>
          </select>
        </div>
        </div>

        <div className="mb-6">
        <label
            htmlFor="message"
            className="block text-gray-700 text-sm font-bold mb-2"
        >
            Message
        </label>
        <input
            id="message"
            className="w-full py-4 px-4 h-32 bg-white text-sm text-gray-700 border border-gray-300 rounded-2xl"
        />
        </div>

        <div className="flex">
        <button className="bg-[#8E9C9C] mt-4 text-white py-3 px-6 rounded-full text-sm flex items-center gap-2">
            Send Request
        </button>
        </div>
    </div>
    </>
  )
}

export default ContactUsForm;