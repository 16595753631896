import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="flex justify-center space-x-6 text-center" style={{fontSize:"2rem"}}>
      <div>
        <div className=" font-bold countdown-number">{timeLeft.days} :</div>
        <div className="text-4xl countdown-label">Days</div>
      </div>
      <div>
        <div className=" font-bold countdown-number">{timeLeft.hours} :</div>
        <div className="text-4xl countdown-label">Hours</div>
      </div>
      <div>
        <div className=" font-bold countdown-number">{timeLeft.minutes} :</div>
        <div className="text-4xl countdown-label">Mins</div>
      </div>
      <div>
        <div className=" font-bold countdown-number">{timeLeft.seconds}</div>
        <div className="text-4xl countdown-label">Sec</div>
      </div>
    </div>
  );
};

export default Countdown;