import axios from 'axios';

const IP = '127.0.0.1';
const PORT = '8000';
export const BASE_URL = process.env.REACT_APP_API_URL || `http://${IP}:${PORT}/`;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL || `ws://${IP}:${PORT}/ws/chat`;

// Create an axios instance
const api = axios.create({
    baseURL: BASE_URL,
});

// Set withCredentials to true to allow cookies in cross-origin requests
api.defaults.withCredentials = true;

// Add request interceptor to include token in the header
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
