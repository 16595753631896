import React from 'react';
import { OverviewRooms, OverviewPricePerSqFt, OverviewBath, OverviewBeds, OverviewCalendar, OverviewGarages, OverviewPropertyType, OverviewSizes } from "../../assets/icons/icons";

const PropertyOverview = ({ property }) => {
  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-[0px_0px_15px_0px_#00000026] p-6 mb-6">
      <h3 className="text-2xl font-semibold mb-2 font-poppins">Overview</h3>
      <hr className="border-gray-300 mb-4" />
      <div className="grid grid-cols-2 md:grid-cols-4 gap-x-8 gap-y-4">
      <div className="flex items-center">
          <img src={OverviewRooms} alt="Bath Icon" className="text-gray-600 mr-2" />
          <div className="font-poppins">
            <div>Rooms</div>
            <div className="font-bold">{property.RoomsTotal || 'N/A'}</div>
          </div>
        </div>
        <div className="flex items-center">
          <img src={OverviewBath} alt="Bath Icon" className="text-gray-600 mr-2" />
          <div className="font-poppins">
            <div>Baths</div>
            <div className="font-bold">{property.BathroomsFull || 'N/A'}</div>
          </div>
        </div>
        <div className="flex items-center">
          <img src={OverviewBeds} alt="Bed Icon" className="text-gray-600 mr-2" />
          <div className="font-poppins">
            <div>Beds</div>
            <div className="font-bold">{property.BedroomsTotal || 'N/A'}</div>
          </div>
        </div>
        <div className="flex items-center">
          <img src={OverviewSizes} alt="Size Icon" className="text-gray-600 mr-2" />
          <div className="font-poppins">
            <div>Size</div>
            <div className="font-bold">{property.LotSizeSquareFeet || 'N/A'} SqFt</div>
          </div>
        </div>
        <div className="flex items-center">
          <img src={OverviewCalendar} alt="Bed Icon" className="text-gray-600 mr-2" />
          <div className="font-poppins">
            <div>Year Built</div>
            <div className="font-bold">{property.YearBuilt || 'N/A'}</div>
          </div>
        </div>
        <div className="flex items-center">
          <img src={OverviewGarages} alt="Bed Icon" className="text-gray-600 mr-2" />
          <div className="font-poppins">
            <div>Garages</div>
            <div className="font-bold">{property.GarageSpaces || 'N/A'}</div>
          </div>
        </div>
        <div className="flex items-center">
          <img src={OverviewPropertyType} alt="Property Type Icon" className="text-gray-600 mr-2" />
          <div className="font-poppins">
            <div>Property Type</div>
            <div className="font-bold">{(property.MLSPropertySubType.charAt(0).toUpperCase() + property.MLSPropertySubType.slice(1)) || 'N/A'}</div>
          </div>
        </div>
        <div className="flex items-center">
          <img src={OverviewPricePerSqFt} alt="Bed Icon" className="text-gray-600 mr-2" />
          <div className="font-poppins">
            <div>Price Per SqFt</div>
            <div className="font-bold">{(property.OriginalListingPrice / property.LotSizeSquareFeet) 
              ? Number.isInteger(property.OriginalListingPrice / property.LotSizeSquareFeet) 
                ? (property.OriginalListingPrice / property.LotSizeSquareFeet) 
                : (property.OriginalListingPrice / property.LotSizeSquareFeet).toFixed(2) 
              : 'N/A'}</div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default PropertyOverview;
