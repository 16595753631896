import React, { useState, useRef, useEffect, useMemo, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faMicrophoneSlash,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { addMessageToAgent, clearAllChats, closeModal, createConversation, fetchChatHistory, fetchConversation, newChat, sendChatMessage, setAgent, setAgentChatId, toggleAgent, updateAgentMessage, clearChat, clearState, sendAudio } from "../../redux/slices/mareaSlice";
import MareaImg from "../../assets/images/marea.png";
import MaxImg from '../../assets/images/Max.png'
import { AttachmentIcon, DefaultImg, MareaProfilePicture, ModalMessage, Mute, NewChat, TranslateIcon, TrashButton, ViewHistoryIcon } from "../../assets/icons/icons";
import { logout, updateAgent } from "../../redux/slices/authSlice";
import PropertyCard from "./PropertyCard";
import { STATUSES } from "../../constants/statuses";
import TypingAnimation from "./TypingAnimation";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../../utils/format_timestamp";

// Create the worker on-the-fly
const workerCode = `
    onmessage = function(e) {
        let { graph_json } = e.data;
        
        if (typeof graph_json === 'string') {
            try {
                graph_json = JSON.parse(graph_json);
            } catch (error) {
                postMessage({ error: 'Invalid JSON string' });
                return;
            }
        }

        postMessage(graph_json);
    };
`;
const blob = new Blob([workerCode], { type: 'application/javascript' });
const blobURL = URL.createObjectURL(blob);
const plotWorker = new Worker(blobURL);

const MareaModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, chat_history, fetching_conversation_status, selected_agent, agentMessages, agent_is_typing, error } = useSelector((state) => state.marea);
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  const audioRef = useRef();
  const messageEndRef = useRef();

  const [isRecording, setIsRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(user?.selected_agent || 'Marea');

  useEffect(() => {
    if (selectedAgent) {
      dispatch(setAgent(selectedAgent))
    }
  }, [selectedAgent]);

  useEffect(() => {
    setSelectedAgent(selected_agent)
  }, [agentMessages]);

  const [inputText, setInputText] = useState("");
  const [unAuthUserData, setUnAuthUserData] = useState({
    'Max': {
      chat_id: null,
      messages: [
        {
          text: `Hi there! I'm Max, and I'm here to help with all your real estate questions today. Feel free to ask me anything in text or audio. I can create interactive visualizations—so you can zoom in and explore details—along with providing detailed answers. Looking forward to assisting you—just let me know what you need!`,
          isUser: false,
          timestamp: new Date().toISOString(),
        },
      ]
    },
    'Marea': {
      chat_id: null,
      messages: [
        {
          text: `Hi there! I'm Marea, and I'm here to help with all your real estate questions today. Feel free to ask me anything in text or audio. I can create interactive visualizations—so you can zoom in and explore details—along with providing detailed answers. Looking forward to assisting you—just let me know what you need!`,
          isUser: false,
          timestamp: new Date().toISOString(),
        },
      ]
    }
  });

  const handleUpdateAgent = (agent) => {
    if (user) {
      dispatch(toggleAgent(agent))
      dispatch(setAgentChatId({ agentName: agent, chat_id: agentMessages[agent].chat_id }))
      dispatch(updateAgent({ id: user.id, data: { agent_name: agent } }))
        .unwrap()
        .then(() => {
          setSelectedAgent(agent)
        })
        .catch((error) => {
          console.error('Failed to update agent:', error);
        });
    } else {
      setSelectedAgent(agent)
    }
  }

  const handleCreateChat = () => {
    dispatch(newChat(selectedAgent))
    dispatch(createConversation({ agent_name: selectedAgent }))
  }

  const handleDeleteChat = (id) => {
    dispatch(clearChat({ chat_id: id }))
  }

  const handleClearAllChats = () => {
    dispatch(clearAllChats())
  }

  const handleOpenConversation = (id, topic) => {
    if (id !== agentMessages[selectedAgent].chat_id) {
      dispatch(toggleAgent(topic.agentName))
      dispatch(setAgentChatId({ agentName: topic.agentName, chat_id: id }))
      dispatch(fetchConversation(id))
        .unwrap()
        .then((response) => {
          dispatch(updateAgentMessage({
            agentName: topic.agentName,
            messages: response.data,
          }))
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const sendMessage = async () => {
    // If user is signed out but their chat is open
    if (agentMessages[selectedAgent].chat_id && !user) {
      dispatch(clearState())
      dispatch(logout())
      showAllNotifications('Your session has expired, please login again', ToastColors.error)
      navigate('/login')
      return
    }

    if (!inputText.trim()) return;

    const newMessages = {
      text: inputText,
      isUser: true,
      timestamp: new Date().toISOString(),
    };

    if (user) {
      dispatch(
        addMessageToAgent({
          agentName: selectedAgent,
          message: newMessages,
        })
      );
    } else {
      setUnAuthUserData((prevData) => ({
        ...prevData,
        [selectedAgent]: {
          ...prevData[selectedAgent],
          messages: [...prevData[selectedAgent].messages, newMessages],
        },
      }));
    }

    setInputText("");

    try {
      const response = await dispatch(
        sendChatMessage({
          message: inputText,
          agent_name: selectedAgent,
          chat_id: user ? agentMessages[selectedAgent].chat_id : unAuthUserData[selectedAgent].chat_id,
          is_new: user ? (agentMessages[selectedAgent].chat_id === null) : false,
        })
      ).unwrap();

      if (user) {
        if (agentMessages[selectedAgent].chat_id === null) {
          dispatch(fetchChatHistory())
        }
      }

      const agentResponseMessages = [];

      if (response.data.message) {
        agentResponseMessages.push({
          text: response.data.message,
          isUser: false,
          timestamp: new Date().toISOString(),
        });
      }

      if (response.data.property && (JSON.parse(response.data.property).length > 0)) {
        agentResponseMessages.push({
          property_cards: JSON.parse(response.data.property),
          isUser: false,
          timestamp: new Date().toISOString(),
        });
      }

      // Handling the graph data asynchronously
      if (response.data.graph_json) {
        const graphData = await new Promise((resolve) => {
          plotWorker.postMessage({
            graph_json: response.data.graph_json,
          });

          plotWorker.onmessage = (e) => {
            const parsedData = e.data;

            resolve({
              graph_json: parsedData,
              isUser: false,
              timestamp: new Date().toISOString(),
            });
          };
        });

        agentResponseMessages.push(graphData);
      }

      if (user) {
        dispatch(fetchChatHistory())
        dispatch(setAgentChatId({ agentName: selectedAgent, chat_id: response.data.chat_id }))

        agentResponseMessages.forEach((msg) => {
          dispatch(
            addMessageToAgent({
              agentName: selectedAgent,
              message: msg,
            })
          );
        });
      } else {
        setUnAuthUserData((prevData) => ({
          ...prevData,
          [selectedAgent]: {
            ...prevData[selectedAgent],
            chat_id: response.data.chat_id,
            messages: [...prevData[selectedAgent].messages, ...agentResponseMessages],
          },
        }));
      }

    } catch (error) {
      console.error("Failed to send message", error);
    }
  };

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [agentMessages, unAuthUserData]);

  useEffect(() => {
    if (user && isOpen) {
      dispatch(fetchChatHistory())
      setSelectedAgent(user.selected_agent)
    }
  }, [dispatch, user, isOpen])

  useEffect(() => {
    if (audioChunks.length > 0 && !isRecording) {
      const audioBlob = new Blob(audioChunks, {
        type: "audio/wav; codecs=opus",
      });
      const audioUrl = URL.createObjectURL(audioBlob);

      const newMessages = {
        isUser: true,
        timestamp: new Date().toISOString(),
        audio: audioUrl,
      }

      if (user) {
        dispatch(
          addMessageToAgent({
            agentName: selectedAgent,
            message: newMessages,
          })
        );
      } else {
        setUnAuthUserData((prevData) => ({
          ...prevData,
          [selectedAgent]: {
            ...prevData[selectedAgent],
            messages: [...prevData[selectedAgent].messages, newMessages],
          },
        }));
      }

      const sendAudioMessage = async () => {
        try {
          const formData = new FormData();
          formData.append("audio_data", audioBlob, "recorded_audio.wav");
          formData.append("agent_name", selectedAgent)
          formData.append("chat_id", user ? agentMessages[selectedAgent].chat_id : unAuthUserData[selectedAgent].chat_id)
          formData.append("is_new", user ? (agentMessages[selectedAgent].chat_id === null) : false)

          const response = await dispatch(
            sendAudio(formData)
          ).unwrap();


          let audio = response.data?.audio?.audio || response.data.audio;

          let agentResponseMessages = [
            {
              audio,
              isUser: false,
              timestamp: new Date().toISOString(),
            },
            {
              text: response.data.message,
              isUser: false,
              timestamp: new Date().toISOString(),
            },
          ]

          if (response.data.graph_json) {
            agentResponseMessages.push(
              {
                graph_json: response.data.graph_json,
                isUser: false,
                timestamp: new Date().toISOString(),
              },
            );
          }

          if (response.data.property && (JSON.parse(response.data.property).length > 0)) {
            agentResponseMessages.push({
              property_cards: JSON.parse(response.data.property),
              isUser: false,
              timestamp: new Date().toISOString(),
            });
          }

          if (user) {
            dispatch(fetchChatHistory())
            dispatch(setAgentChatId({ agentName: selectedAgent, chat_id: response.data.chat_id }))

            agentResponseMessages.forEach((msg) => {
              dispatch(
                addMessageToAgent({
                  agentName: selectedAgent,
                  message: msg,
                })
              );
            });

          } else {
            setUnAuthUserData((prevData) => ({
              ...prevData,
              [selectedAgent]: {
                ...prevData[selectedAgent],
                chat_id: response.data.chat_id,
                messages: [...prevData[selectedAgent].messages, ...agentResponseMessages],
              },
            }));
          }

        } catch (error) {
          console.error("Failed to send audio", error);
        }

        setAudioChunks([]);
      };

      sendAudioMessage();
    }
  }, [audioChunks, isRecording]);

  const toggleRecording = async () => {
    if (!isRecording) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          setAudioChunks((prev) => [...prev, e.data]);
        } else {
          console.warn('No data available in event');
        }
      };

      audioRef.current = mediaRecorder;
      audioRef.current.start();
      setIsRecording(true);
    } else {
      audioRef.current.stop();
      setIsRecording(false);
    }
  };

  // Use useMemo to ensure memoization of constant data and prevent unnecessary re-renders
  const renderPlot = useMemo(() => {
    return (parsedData, idx) => (
      <Suspense fallback={<div>Loading plot...</div>} key={`plot-${idx}`}>
        <div className="plot-container" style={{ width: '100%', height: '400px' }}>
          <iframe
            title={`Plot-${selectedAgent}-${idx}`}
            srcDoc={`
              <html>
                <head>
                  <style>body { margin: 0; }</style>
                </head>
                <body>
                  <div id="plot"></div>
                  <script src="https://cdn.plot.ly/plotly-latest.min.js"></script>
                  <script>
                    const plotData = ${typeof parsedData === 'string' ? JSON.stringify(JSON.parse(parsedData).data) : JSON.stringify(parsedData.data)};
                    const plotLayout = ${typeof parsedData === 'string' ? JSON.stringify(JSON.parse(parsedData).layout) : JSON.stringify(parsedData.layout)};
                    Plotly.newPlot('plot', plotData, plotLayout);
                  </script>
                </body>
              </html>
            `}
            style={{ border: 'none', width: '100%', height: '100%' }}
          />
        </div>
      </Suspense>
    );
  }, []);

  if (error) {
    if (error?.code === 'token_not_valid') {
      dispatch(clearState());
      showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
      dispatch(logout());
      navigate('/login');
    }
  }

  return (
    <>
      {isOpen &&
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4 z-50">

          <div className="bg-white rounded-lg shadow-lg relative max-w-screen-xl w-full h-full max-h-screen flex flex-col">
            <button
              onClick={() => dispatch(closeModal())}
              className="absolute top-4 right-5 text-4xl font-extralight text-[#A1A4A9]"
            >
              &times;
            </button>
            <h1 className="font-poppins text-[24px] font-semibold border-b px-6 py-4">
              Welcome to AreA, Your Autonomous Real Estate Agent!
            </h1>

            {/* Parent Section */}
            <div className="flex flex-1 flex-col overflow-auto md:flex-row md:overflow-auto">
              {/* Left Column */}
              <div className="p-3 flex flex-col gap-4 lg:overflow-y-auto lg:w-3/12 md:w-1/3 sm:w-1/2">

                {/* First Card */}
                <div className="flex flex-col bg-white shadow px-4 py-3 rounded-lg">
                  <h2 className="font-poppins text-[20px] text-[#888888] font-medium mb-3">
                    Select AI Agent
                  </h2>

                  {/* Buttons */}
                  <div className="flex flex-wrap gap-2 mb-3">
                    <button
                      className={`flex items-center space-x-1 ${selectedAgent === 'Marea' ? 'bg-[#8E9C9C] text-white' : 'bg-white text-black'
                        } border rounded-full px-2 py-2 h-[30px]`}
                      onClick={() => handleUpdateAgent('Marea')}
                      disabled={agent_is_typing}
                    >
                      <span className="font-poppins text-[12px] font-normal px-1">Marea</span>
                    </button>
                    <button
                      className={`flex items-center space-x-1 ${selectedAgent === 'Max' ? 'bg-[#8E9C9C] text-white' : 'bg-white text-black'
                        } border rounded-full px-2 py-2 h-[30px]`}
                      onClick={() => handleUpdateAgent('Max')}
                      disabled={agent_is_typing}
                    >
                      <span className="font-poppins text-[12px] font-normal px-1">Max</span>
                    </button>
                  </div>

                  {/* Image */}
                  <div className="relative flex-1 border-[0.66px] border-[#8E9C9C] rounded-md">
                    <div className=" rounded-md overflow-hidden flex items-center justify-center">
                      <img
                        src={selectedAgent === 'Marea' ? MareaImg : MaxImg}
                        alt="Alt"
                        className="max-h-[220px] object-contain"
                      />
                    </div>
                    <button className="absolute bottom-2 right-2">
                      <img src={Mute} alt="Icon" className="h-4 w-4" />
                    </button>
                  </div>
                </div>

                {/* Second Card */}
                {
                  isAuthenticated && (
                    <div className="bg-white shadow p-4 rounded-lg flex flex-col">
                      <h2 className="font-poppins text-[20px] text-[#888888] font-medium mb-4">
                        Chat History
                      </h2>

                      {/* New chat */}
                      <button
                        onClick={handleCreateChat}
                        disabled={agent_is_typing || !user}
                        className="w-[90%] h-[50px] bg-[#8E9C9C] text-white font-mier-a text-[16px] font-medium rounded-full flex flex-col items-center justify-center mb-4 p-2"
                      >
                        <div className="flex items-center justify-center space-y-1 sm:space-y-0 sm:space-x-2">
                          <img src={NewChat} alt="Plus Icon" className="w-6 h-6 flex-shrink-0 p-1 sm:p-0" />
                          <span className="sm:w-auto w-full text-center sm:p-0">New chat</span>
                        </div>
                      </button>

                      {/* Conversations */}
                      <div className="border-t border-b border-[#EDF1F5] py-3 flex flex-wrap justify-between items-center mb-3">
                        <span className="font-mier-a mr-2 text-[14px] font-medium text-[#6A6969] flex-grow">
                          Your conversations
                        </span>
                        <button
                          disabled={agent_is_typing || !user}
                          onClick={handleClearAllChats}
                          className="font-mier-a text-[14px] font-semibold text-[#8E9C9C] bg-transparent border-none flex-shrink-0 sm:mt-0">
                          Clear All
                        </button>
                      </div>

                      {/* Chat Topics */}
                      <div className="flex-1 overflow-y-auto mt-3 max-h-[170px] w-[fixed-width] overflow-hidden">
                        {user && chat_history && [...chat_history].reverse().map((topic, index) => (
                          <div key={index} className="flex items-center justify-between mb-3">
                            <button
                              disabled={agent_is_typing}
                              onClick={() => handleOpenConversation(topic.id, topic)}
                              className="flex items-center space-x-2 w-[90%] truncate"
                            >
                              <img src={ModalMessage} alt="Chat Icon" className="w-5 h-5" />
                              <span
                                className={`font-poppins text-[14px] ${topic.id === agentMessages[selectedAgent].chat_id ? 'font-bold' : 'font-normal'} text-[#333] truncate overflow-hidden whitespace-nowrap`}
                              >
                                {topic.title ? `${topic.title}` : `Untitled Conversation ${index + 1}`}
                              </span>
                            </button>
                            <button
                              disabled={agent_is_typing}
                              className="ml-2 w-[10%] flex-shrink-0"
                              onClick={() => handleDeleteChat(topic.id)}
                            >
                              <img src={TrashButton} alt="Delete Icon" className="w-5 h-5 cursor-pointer mx-auto" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                }
              </div>

              {/* Right Column */}
              <div className="w-full md:w-3/4 p-4 flex flex-col justify-between">
                {/* Top Section */}
                <div className="flex-1 flex flex-col p-3 overflow-auto">
                  {/* Messages */}
                  <div className="flex flex-col space-y-4 max-h-[400px]">
                    {/* If the user is not signed in */}
                    {!user ? (
                      <>
                        {unAuthUserData && unAuthUserData[selectedAgent].messages.map((msg, index) => (
                          <div key={index}>
                            {msg.isUser ? (
                              <div
                                className="w-full flex items-center py-2"
                                key={`${msg.text?.slice(0, 10) || "audio-text-"}-${index.toString()}`}
                              >
                                <div className="ms-auto text-right text-[#8e9c9c] text-sm">
                                  <div>
                                    <div className="text-xs mb-1">
                                      {formatTimestamp(msg.timestamp)}
                                    </div>
                                    {msg.text}
                                    {msg.audio && (
                                      <audio controls src={msg.audio} className="ms-2"></audio>
                                    )}
                                  </div>
                                </div>
                                <img
                                  className="h-14 w-14 ml-2"
                                  src={user?.profile_picture || DefaultImg}
                                  alt="img"
                                />
                              </div>
                            ) : (
                              <div className="flex items-start space-x-3 mb-2">
                                <img
                                  className="h-14 w-14 rounded-full"
                                  src={selectedAgent === 'Marea' ? MareaProfilePicture : MaxImg}
                                  alt="Profile"
                                />
                                <div className="flex-1">
                                  {msg.text && (
                                    <div
                                      className="w-full rounded-xl bg-[#8E9C9C4D] font-poppins text-[14px] text-[#8E9C9C] py-3 px-4"
                                    >
                                      <div className="text-xs text-left text-[#8e9c9c] mb-3">
                                        {formatTimestamp(msg.timestamp)}
                                      </div>
                                      <div
                                        key={`${msg.text.slice(0, 10)}-${index.toString()}`}
                                        dangerouslySetInnerHTML={{ __html: msg.text }}
                                      >
                                      </div>
                                    </div>
                                  )}
                                  {msg.audio && (
                                    <>
                                      <div className="text-xs text-left text-[#8e9c9c] mb-1 ml-3">
                                        {formatTimestamp(msg.timestamp)}
                                      </div>
                                      <audio controls src={msg.audio} className="my-2"></audio>
                                    </>
                                  )}
                                  {msg.graph_json && renderPlot(msg.graph_json, index)}
                                  <div>
                                    {msg.property_cards && (msg.property_cards.length > 0) && (
                                      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
                                        {msg.property_cards.map((card, index) => (
                                          <div key={index} onClick={() => dispatch(closeModal())}>
                                            <PropertyCard
                                              property={card}
                                              isMyProperty={false}
                                              heightImage={true}
                                              heightContainer={true}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                        {agent_is_typing && (
                          <div className="flex items-start space-x-3 mb-2 mt-2">
                            <img
                              className="h-14 w-14 rounded-full"
                              src={selectedAgent === 'Marea' ? MareaProfilePicture : MaxImg}
                              alt="Profile"
                            />
                            <div className="flex-1">
                              <div className="w-full rounded-xl bg-[#8E9C9C4D] font-poppins text-[14px] text-[#8E9C9C] py-3 px-4">
                                <TypingAnimation text={`${selectedAgent} is typing`} />
                              </div>
                            </div>
                          </div>
                        )}
                        <div ref={messageEndRef}></div>
                      </>
                    ) : (
                      <>
                        {/* If the user is signed in */}
                        {fetching_conversation_status === STATUSES.LOADING ? (
                          <div className="flex justify-center items-center h-full">
                            <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
                          </div>
                        ) : (
                          selectedAgent &&
                          agentMessages &&
                          agentMessages[selectedAgent].messages.map((msg, index) => (
                            <div key={index}>
                              {msg.isUser ? (
                                <div
                                  className="w-full flex items-center py-2"
                                  key={`${msg.text?.slice(0, 10) || "audio-text-"}-${index.toString()}`}
                                >
                                  <div className="ms-auto text-right text-[#8e9c9c] text-sm">
                                    <div className="font-poppins text-[14px] font-normal text-[#8E9C9C]">
                                      <div className="text-xs mb-1">
                                        {formatTimestamp(msg.timestamp)}
                                      </div>
                                      {msg.text}
                                      {msg.audio && (
                                        <audio controls src={msg.audio} className="ms-2"></audio>
                                      )}
                                    </div>
                                  </div>
                                  <img
                                    className="h-14 w-14 ml-2"
                                    src={user?.profile_picture || DefaultImg}
                                    alt="img"
                                  />
                                </div>
                              ) : (
                                <div className="flex items-start space-x-3 mb-2">
                                  <img
                                    className="h-14 w-14 rounded-full"
                                    src={selectedAgent === 'Marea' ? MareaProfilePicture : MaxImg}
                                    alt="Profile"
                                  />
                                  <div className="flex-1">
                                    {msg.text && (
                                      <div
                                        className="w-full rounded-xl bg-[#8E9C9C4D] font-poppins text-[14px] text-[#8E9C9C] py-3 px-4"
                                      >
                                        <div className="text-xs text-left text-[#8e9c9c] mb-3">
                                          {formatTimestamp(msg.timestamp)}
                                        </div>
                                        <div
                                          key={`${msg.text.slice(0, 10)}-${index.toString()}`}
                                          dangerouslySetInnerHTML={{ __html: msg.text }}
                                        >
                                        </div>
                                      </div>
                                    )}
                                    {msg.audio && (
                                      <>
                                        <div className="text-xs text-left text-[#8e9c9c] mb-1">
                                          {formatTimestamp(msg.timestamp)}
                                        </div>
                                        <audio controls src={msg.audio} className="my-2"></audio>
                                      </>
                                    )}
                                    {msg.graph_json && renderPlot(msg.graph_json, index)}
                                    <div>
                                      {msg.property_cards && (msg.property_cards.length > 0) && (
                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
                                          {msg.property_cards.map((card, index) => (
                                            <div key={index} onClick={() => dispatch(closeModal())}>
                                              <PropertyCard
                                                property={card}
                                                isMyProperty={false}
                                                heightImage={true}
                                                heightContainer={true}
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                        )}
                        {agent_is_typing && (
                          <div className="flex items-start space-x-3 mb-2 mt-2">
                            <img
                              className="h-14 w-14 rounded-full"
                              src={selectedAgent === 'Marea' ? MareaProfilePicture : MaxImg}
                              alt="Profile"
                            />
                            <div className="flex-1">
                              <div className="w-full rounded-xl bg-[#8E9C9C4D] font-poppins text-[14px] text-[#8E9C9C] py-3 px-4">
                                <TypingAnimation text={`${selectedAgent} is typing`} />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div ref={messageEndRef}></div>
                  </div>
                </div>

                {/* Bottom Section */}
                <div className="flex flex-col mt-auto">
                  {/* First Row: Buttons with Text and Icons */}
                  {/* <div className="flex items-center space-x-4 p-3">
                    <button className="flex items-center space-x-2 text-gray-600 hover:text-black">
                      <img src={TranslateIcon} alt="Icon 1" className="w-5 h-5" />
                      <span className="font-poppins text-[14px] font-normal">Translate</span>
                    </button>
                    <button className="flex items-center space-x-2 text-gray-600 hover:text-black">
                      <img src={ViewHistoryIcon} alt="Icon 2" className="w-5 h-5" />
                      <span className="font-poppins text-[14px] font-normal">View History</span>
                    </button>
                  </div> */}

                  {/* Input Field */}
                  <div className="flex items-center w-full px-2 bg-white rounded-full shadow-md border-[0.5px] mt-2 mb-2">
                    {/* Icon on the left side of the input */}
                    <button className="flex items-center px-2">
                      <img src={AttachmentIcon} alt="Icon" className="w-5 h-5 text-gray-500" />
                    </button>
                    <input
                      type="text"
                      className="flex-grow px-1 py-3 rounded-l-full focus:outline-none"
                      placeholder="Type Here..."
                      value={inputText}
                      onChange={(e) => setInputText(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                    />
                    <button
                      className={`w-10 h-10 flex justify-center items-center rounded-full text-gray-500 focus:outline-none hover:bg-gray-100 ${isRecording ? 'animate-microphonePulse' : ''}`}
                      onClick={toggleRecording}
                    >
                      <FontAwesomeIcon
                        icon={isRecording ? faMicrophoneSlash : faMicrophone}
                      />
                    </button>
                    <button
                      className="w-10 h-10 flex justify-center items-center rounded-full text-gray-500 focus:outline-none hover:bg-gray-100"
                      onClick={sendMessage}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div >
        </div >
      }
    </>
  );
};

export default MareaModal;