import * as Yup from 'yup';
import { USA_CITIES } from '../../constants/USA_Cities';
import { propertyConditions } from '../../constants/property/property_conditions';

export const RegisterSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  username: Yup.string().required('Username is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required')
});

export const OTPSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required').length(6, 'OTP must be 6 digits'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
});

export const SetNewPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required')
});

export const LoginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

export const PropertySchema = Yup.object().shape({
  area: Yup.string().required('Address is required').min(3, 'Address must be at least 3 characters'),
  specificType: Yup.string().required('Property Subtype is required'),
  property_name: Yup.string().required('Property name is required'),
  images: Yup.array().of(Yup.string()).min(1, 'At least one image is required'),
  bedrooms: Yup.number().required('Number of bedrooms is required'),
  bathrooms: Yup.number().required('Number of bathrooms is required'),
  size: Yup.number()
    .required('Size is required')
    .min(100, 'Minimum size is 100 SqFt'),
  condition: Yup.string()
    .required('Property condition is required')
    .oneOf(propertyConditions, 'Invalid property condition'),
  contact_number: Yup.string()
    .matches(/^\d{3}-\d{3}-\d{4}$/, 'Contact number must be in the format 123-456-7890')
    .required('Contact number is required'),
  built_year: Yup.number()
    .required('Built Year is required')
    .min(1000, 'Built Year must be 4 digits number')
    .max((new Date()).getFullYear(), 'Built Year cannot be greater than current year'),
  name: Yup.string().required('Name is required'),
  asking_price: Yup.number()
    .required('Price is required')
    .min(10000, 'Minimum price is $10,000')
});

export const ContractSchema = Yup.object({
  contractType: Yup.string().required('Contract Type is required'),
  buyerName: Yup.string().required('Buyer Name is required'),
  sellerName: Yup.string().required('Seller Name is required'),
  buyerCity: Yup.string()
    .required('Buyer City is required')
    .oneOf(USA_CITIES, 'Buyer City must be a valid city from the USA'),
  sellerCity: Yup.string()
    .required('Seller City is required')
    .oneOf(USA_CITIES, 'Seller City must be a valid city from the USA'),
  buyerAddress: Yup.string().required('Buyer Address is required'),
  sellerAddress: Yup.string().required('Seller Address is required'),
  propertyType: Yup.string().required('Property Type is required'),
  propertyLocation: Yup.string().required('Property Location is required'),
  amount: Yup.number()
    .required('Amount is required')
    .positive('Amount must be a positive number'),
  paymentMethod: Yup.string().required('Payment Method is required'),
});

export const AdSchema = Yup.object({
  property: Yup.string().required('Property is required'),
  propertyURL: Yup.string().required('Property URL is required'),
  socialPlatform: Yup.string().required('Social Platform is required'),
  headline: Yup.string()
    .required('Headline is required')
    .min(5, 'Headline must be at least 5 characters')
    .max(50, 'Headline must be 50 characters or less'),
  description: Yup.string().required('Description is required'),
  targetedKeywords: Yup.array().of(Yup.string()).min(1, 'At least one keyword is required'),
  locations: Yup.array().of(Yup.string()).min(1, 'At least one city is required'),
});
