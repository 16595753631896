import React from "react";
import {
  BackwardArrow,
  StarIcon,
  QuotesIcon,
  UserImage,
  WhiteCheckMarkIcon
} from 'src/assets/icons/icons';

export const Reviews = () => {
  return (
    <div className="w-full bg-white py-20">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-1 flex flex-col justify-center items-start text-left mb-8 md:mb-0">
            <h2 className="text-5xl font-bold text-gray-900 mb-2" style={{
              fontFamily: 'Space Grotesk',
              fontSize: '59.42px',
              fontWeight: 400,
              lineHeight: '77.07px',
              letterSpacing: '-0.055em',
              color: '#010101'
            }}>
              Our <span style={{ fontWeight: 500 }}>Customers</span> Think We’re <br /> The Best
            </h2>
            <div className="flex justify-start items-center mt-4">
              <button className="w-12 h-12 bg-gray-300 rounded-full flex justify-center items-center mx-2">
                <img className="w-5 h-5" alt="Backward Arrow" src={BackwardArrow} />
              </button>
              <button className="w-12 h-12 bg-gray-700 rounded-full flex justify-center items-center mx-2">
                <img className="w-5 h-5 rotate-180" alt="Forward Arrow" src={BackwardArrow} />
              </button>
            </div>
          </div>
          <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gray-100 rounded-lg p-6 flex flex-col justify-between relative">
              <div>
                <div className="flex items-center mb-4">
                  <img className="w-10 h-10 mr-2" alt="Quotes Icon" src={QuotesIcon} />
                  <div className="flex items-center">
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                  </div>
                </div>
                <p className="text-lg text-gray-900 mb-4">
                  I had the pleasure of working with ABC Realty to find my dream home, and I couldn&#39;t be happier
                  with the entire experience. <br /><br />
                  From the moment I walked into their office, I felt welcomed and supported by their friendly team.
                </p>
              </div>
              <div className="flex items-center mb-4">
                <img className="w-14 h-14 rounded-full mr-4" alt="User" src={UserImage} />
                <div>
                  <p className="text-lg font-medium text-gray-900">Sarah M.</p>
                  <p className="text-gray-600">Property consultation</p>
                </div>
                <img className="w-6 h-6 ml-auto" alt="Check Mark" src={WhiteCheckMarkIcon} />
              </div>
            </div>
            <div className="bg-gray-100 rounded-lg p-6 flex flex-col justify-between relative">
              <div>
                <div className="flex items-center mb-4">
                  <img className="w-10 h-10 mr-2" alt="Quotes Icon" src={QuotesIcon} />
                  <div className="flex items-center">
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                    <img className="w-5 h-5 mr-1" alt="Star Icon" src={StarIcon} />
                  </div>
                </div>
                <p className="text-lg text-gray-900 mb-4">
                  Once we found the perfect home, Sarah guided me through every step of the buying process, making it
                  feel effortless on my part. She was an excellent negotiator, and thanks to her skills, I was able to
                  secure a fantastic deal on my new home.
                </p>
              </div>
              <div className="flex items-center mb-4">
                <img className="w-14 h-14 rounded-full mr-4" alt="User" src={UserImage} />
                <div>
                  <p className="text-lg font-medium text-gray-900">Esther Howard</p>
                  <p className="text-gray-600">Property consultation</p>
                </div>
                <img className="w-6 h-6 ml-auto" alt="Check Mark" src={WhiteCheckMarkIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
