import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearError } from '../../redux/slices/propertySlice';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';
import { logout } from '../../redux/slices/authSlice';
import PropertyCard from './PropertyCard';

const LIMIT = 8;

const Properties = ({ properties, isMyProperty, setOffset }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, loadMore } = useSelector((state) => state.properties);

  const loadMoreProperties = () => {
    setOffset((prevOffset) => prevOffset + LIMIT);
  };

  if (error) {
    if (error?.code === 'token_not_valid') {
      showAllNotifications('Your session has expired, please login again to continue', ToastColors.error);
      dispatch(logout());
      dispatch(clearError());
      navigate('/login');
    }
  }

  return (
    <div className="w-full mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {properties && properties.length > 0 && properties.map((property) => (
          <PropertyCard property={property} key={`property-${property.PropertyID}`} isMyProperty={isMyProperty} />
        ))}
      </div>
      {
        (!properties || (properties && properties.length === 0)) && (
          <div className="w-full text-center pt-10 px-6 text-xl font-semibold text-[#8E9C9C]">There is no Featured Property available right now</div>
        )
      }
      <div className="flex justify-center my-6 mt-20">
        {isMyProperty && loadMore && <button
          onClick={loadMoreProperties}
          className="bg-[#FFFFFF] text-gray-400 border border-[#8E9C9C] font-bold py-3 px-20 font-jost text-lg"
        >
          Load More
        </button>
        }
      </div>
    </div>
  )
}

export default Properties;
