import { combineReducers } from 'redux';
import authReducer from '../redux/slices/authSlice';
import mareaReducer from '../redux/slices/mareaSlice';
import propertyReducer from '../redux/slices/propertySlice';
import contractReducer from '../redux/slices/contractSlice';
import paymentReducer from '../redux/slices/paymentSlice';
import tickerTapeReducer from '../redux/slices/tickerTapeSlice'
import notificationReducer from '../redux/slices/notificationSlice';
import chatReducer from '../redux/slices/chatSlice';
import dashboardOverviewReducer from '../redux/slices/admin/dashboardOverviewSlice';
import userReducer from '../redux/slices/admin/userSlice';
import adRevenueReducer from '../redux/slices/admin/adRevenueSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    marea: mareaReducer,
    properties: propertyReducer,
    contract: contractReducer,
    payment: paymentReducer,
    tickerTape: tickerTapeReducer,
    notifications: notificationReducer,
    chat: chatReducer,
    adminOverview: dashboardOverviewReducer,
    admin: combineReducers({
        users: userReducer,
        adRevenue: adRevenueReducer,
    }),
});

export default rootReducer;
