import React, { useEffect, useRef, useState } from "react";
import { AngularVector, AreaMarkerIcon, AreaStudioLogo, CircleCheckMark, SearchIcon } from "../../assets/icons/icons";
import { useFormik } from "formik";
import { Button } from "react-bootstrap";
import { AdSchema } from "../../utils/yup/schemas";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import '@reach/combobox/styles.css';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useDispatch, useSelector } from "react-redux";
import { closeModal, fetchClientSecret, getPropertyPlans } from "../../redux/slices/paymentSlice";
import { getPropertiesLegalDetail } from "../../redux/slices/propertySlice";
import { createAd } from "../../redux/slices/adSlice";
import StripePaymentModal from "../../components/common/StripePaymentModal";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { capitalize } from "../../utils/properties";
import { socialPlatforms } from "../../utils/socialPlatforms";

const libraries = ['places'];

const AdCreation = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_AREA_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>
  return <AdCreationForm />
}

const AdCreationForm = () => {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const dispatch = useDispatch();

  const { plans, clientSecret } = useSelector((state) => state.payment);
  const { properties } = useSelector((state) => state.properties);

  const [locationInput, setLocationInput] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(0);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  const mapRef = useRef(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      property: '',
      propertyURL: '',
      socialPlatform: '',
      headline: '',
      description: '',
      targetedKeywords: [],
      locations: [],
      mapLocations: [],
    },
    validationSchema: AdSchema,
    onSubmit: (_values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);

      const addressComponents = results[0].address_components;

      let city = '';

      addressComponents.forEach(component => {
        const types = component.types;

        if (types.includes('locality')) {
          city = component.long_name;
        }
      });

      if (city !== "" && !formik.values.locations.includes(city)) {
        formik.setFieldValue('locations', [...formik.values.locations, city]);
        formik.setFieldValue('mapLocations', [...formik.values.mapLocations, { lat, lng }]);
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
    }

    setValue(false);
    setLocationInput("");
  };

  const handleSelectedPlan = (plan) => {
    setSelectedPlan(plan);
    dispatch(fetchClientSecret({ plan_id: plan.id, property_id: formik.values.property }));
  };

  const getPayloadData = (values) => ({
    property: values.property,
    property_url: values.propertyURL,
    social_platform: values.socialPlatform,
    headline: values.headline,
    description: values.description,
    target_keywords: values.targetedKeywords,
    locations: values.locations
  });

  const handlePublishCampaign = async () => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length !== 0) {
      formik.setTouched(errors);

      const firstErrorKey = Object.keys(errors)[0];
      const errorField = document.querySelector(`[name="${firstErrorKey}"]`);

      if (errorField) {
        const offset = 200;
        const elementPosition = errorField.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }

      return;
    }

    const initialValues = formik.initialValues;
    const currentValues = formik.values;

    const hasChanged = Object.keys(initialValues).some(key => initialValues[key] !== currentValues[key]);

    if (!hasChanged) return;

    const payload = getPayloadData(currentValues);

    dispatch(createAd(payload));
  };

  useEffect(() => {
    if (mapRef.current && formik.values.mapLocations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      formik.values.mapLocations.forEach((location) => {
        bounds.extend(location);
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [formik.values.mapLocations]);

  useEffect(() => {
    if (formik.values.property) {
      formik.setFieldValue('propertyURL', `${window.location.origin}/properties/${formik.values.property}`);
    }
  }, [formik.values.property]);

  useEffect(() => {
    dispatch(getPropertyPlans());
    dispatch(closeModal());
    dispatch(getPropertiesLegalDetail());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="mx-auto font-poppins max-w-screen-xl pt-4">
      {/* Ad Creation section */}
      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between py-8">
        {/* Left Column */}
        <div className="flex flex-col items-center lg:items-start lg:w-1/2 mt-4">
          <h2 className="text-4xl font-bold leading-tight text-center lg:text-left">
            Ad Creation
          </h2>
          <p className="text-gray-600 text-base flex items-center space-x-2 justify-center lg:justify-start">
            <span className="text-gray-600 font-normal whitespace-nowrap">Area</span>
            <img src={AngularVector} alt="Location Icon" className="w-4 h-4" />
            <span className="font-normal whitespace-nowrap">Area Studio</span>
            <img src={AngularVector} alt="Location Icon" className="w-4 h-4" />
            <span className="font-normal whitespace-nowrap">Ad Creation</span>
          </p>
        </div>
        {/* Right Column */}
        <div className="flex items-center lg:items-start lg:w-1/2 justify-center lg:justify-end mt-2 lg:mt-4">
          <img
            src={AreaStudioLogo}
            alt="AreA"
            width="169"
            height="101"
            className="object-cover"
          />
        </div>
      </div>

      <div className="w-full text-center py-4">
        <div className="text-5xl text-[#1C1C1E] font-bold">Free Ad Creation</div>
        <div className="text-lg textt-[#666666]">Create an Ads for your property and boost your sale.</div>
      </div>

      <div className="flex p-2 justify-between">
        <div className="w-[31%] min-w-52">
          <div className="text-lg font-semibold mb-5">Create Conversion</div>
          <div className="w-full mb-7">
            <label className="block text-[#37474F] font-poppins mb-2 font-medium">Choose your property you want to create ads on</label>
            <select
              name="property"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.property}
              className="w-full text-xs leading-[17px] px-3 py-2 border rounded-full border-[#0000003B] font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 text-[#37474F]"
            >
              <option value="" disabled>Select</option>
              {properties.map((opt, i) => (
                <option key={`opt-${i+1}`} value={opt.PropertyID}>
                  {opt.LegalDetails}
                </option>
              ))}
            </select>
            {formik.touched.property && formik.errors.property ? (
              <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.property}</div>
            ) : null}
          </div>
          <div className="w-full mb-7">
            <label className="block text-[#37474F] font-poppins mb-2 font-medium">Property URL</label>
            <div className='relative'>
              <input
                type="text"
                name="propertyURL"
                placeholder="https://ads.google.com/"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.propertyURL}
                className="w-full h-[40px] px-3 rounded-[500px] border border-solid border-[#0000003B] focus:outline-none font-poppins text-[#37474F] text-xs leading-[17px]"
              />
            </div>
            {formik.touched.propertyURL && formik.errors.propertyURL ? (
              <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.propertyURL}</div>
            ) : null}
          </div>
          <div className="w-full mb-7">
            <label className="block text-[#37474F] font-poppins mb-2 font-medium">Select Social Platform</label>
            <select
              name="socialPlatform"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.socialPlatform}
              className="w-full text-xs leading-[17px] px-3 py-2 border border-[#0000003B] rounded-full font-poppins placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-800 text-[#37474F]"
            >
              <option value="" disabled>Select</option>
              {socialPlatforms.map((opt, i) => (
                <option key={`opt-${i}`} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
            {formik.touched.socialPlatform && formik.errors.socialPlatform ? (
              <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.socialPlatform}</div>
            ) : null}
          </div>
          <div className="w-full mb-7">
            <label className="block text-[#37474F] font-poppins mb-2 font-medium">Headline</label>
            <div className='relative'>
              <input
                type="text"
                name="headline"
                placeholder="Enter"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.headline}
                className="w-full h-[40px] px-3 rounded-[500px] border border-solid border-[#0000003B] focus:outline-none font-poppins text-[#37474F] text-xs leading-[17px]"
              />
            </div>
            {formik.touched.headline && formik.errors.headline ? (
              <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.headline}</div>
            ) : null}
          </div>
          <div className="w-full mb-7">
            <label className="block text-[#37474F] font-poppins mb-2 font-medium">Description</label>
            <div className='relative'>
              <textarea
                name="description"
                placeholder="Enter"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                className="w-full px-3 py-2 h-[74px] rounded-xl border border-solid border-[#0000003B] focus:outline-none font-poppins text-[#37474F] text-xs leading-[17px]"
              />
            </div>
            {formik.touched.description && formik.errors.description ? (
              <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.description}</div>
            ) : null}
          </div>
          <div className="w-full mb-7">
            <label className="block text-[#37474F] font-poppins mb-2 font-medium">Targeted Keywords</label>
            <div className="relative rounded-3xl border border-solid border-[#0000003B] px-2 min-h-10">
              {/* Displaying the list of keywords */}
              <div className="flex flex-wrap items-center">
                {formik.values.targetedKeywords.map((keyword, index) => (
                  <div key={index} className="text-[#C8CBD0] border border-[0.5px_solid_#C8CBD0] text-xs leading-[17px] rounded-full px-2 py-1 ms-2 my-1 flex items-center">
                    <span>{keyword}</span>
                    <button
                      type="button"
                      onClick={() => {
                        const updatedKeywords = formik.values.targetedKeywords.filter((_, i) => i !== index);
                        formik.setFieldValue('targetedKeywords', updatedKeywords);
                      }}
                      className="ml-2 text-[#C8CBD0] text-lg leading-4"
                    >
                      &times;
                    </button>
                  </div>
                ))}
                <input
                  name="targetedKeywords"
                  type="text"
                  placeholder="Enter Keyword"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      const keyword = e.target.value.trim();
                      if (keyword) {
                        formik.setFieldValue('targetedKeywords', [...formik.values.targetedKeywords, keyword]);
                        e.target.value = ''; // Clear the input field
                      }
                    }
                  }}
                  className="flex-grow rounded-full px-2 py-[10px] border-none focus:outline-none font-poppins text-[#37474F] text-xs"
                />
              </div>
            </div>

            {formik.touched.targetedKeywords && formik.errors.targetedKeywords ? (
              <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.targetedKeywords}</div>
            ) : null}
          </div>
          <div className="w-full mb-7">
            <label className="block text-[#37474F] font-poppins mb-2 font-medium">Where do you want your ads to show up?</label>
            <div className="relative rounded-3xl border border-solid border-[#0000003B] ps-6 pe-2 min-h-10">
              <div className="absolute top-0 left-0 h-full flex items-center pl-2">
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  className="w-4 h-4"
                />
              </div>

              {/* Displaying the list of keywords */}
              <div className="flex flex-wrap items-center">
                {formik.values.locations.map((keyword, index) => (
                  <div key={index} className="text-[#C8CBD0] border border-[0.5px_solid_#C8CBD0] text-xs leading-[17px] rounded-full px-2 py-1 ms-2 my-1 flex items-center">
                    <span>{keyword}</span>
                    <button
                      type="button"
                      onClick={() => {
                        const updatedLocations = formik.values.locations.filter((_, i) => i !== index);
                        const updatedMapLocations = formik.values.mapLocations.filter((_, i) => i !== index);
                        formik.setFieldValue('locations', updatedLocations);
                        formik.setFieldValue('mapLocations', updatedMapLocations);
                      }}
                      className="ml-2 text-[#C8CBD0] text-lg leading-4"
                    >
                      &times;
                    </button>
                  </div>
                ))}

                {/* Search bar for locations */}
                <div className="flex-grow">
                  <Combobox onSelect={handleSelect}>
                    <ComboboxInput
                      value={locationInput}
                      autoComplete='off'
                      onChange={(e) => {
                        setValue(e.target.value);
                        setLocationInput(e.target.value);
                      }}
                      disabled={!ready}
                      placeholder="Search and find"
                      className="w-full rounded-full px-2 py-[10px] border-none focus:outline-none font-poppins text-[#37474F] text-xs"
                      name="locations"
                    />
                    <ComboboxPopover>
                      {status === 'OK' && (
                        <ComboboxList>
                          {data.map(({ place_id, description }) => (
                            <ComboboxOption key={place_id} value={description} />
                          ))}
                        </ComboboxList>
                      )}
                    </ComboboxPopover>
                  </Combobox>
                </div>
              </div>
            </div>

            {formik.touched.locations && formik.errors.locations ? (
              <div className="text-red-500 font-poppins ml-2 mt-2">{formik.errors.locations}</div>
            ) : null}
          </div>
          <div className="w-full h-80 mb-7">
            <GoogleMap
              mapContainerClassName="w-full h-full"
              zoom={4}
              center={formik.values.mapLocations.length > 0 ? formik.values.mapLocations[0] : { lat: 37.7749, lng: -122.4194 }}
              onLoad={(map) => (mapRef.current = map)}
            >
              {
                formik.values.mapLocations.map((center, index) => (
                  <Marker
                    key={`${index}`}
                    position={center}
                    icon={{
                      url: AreaMarkerIcon,
                      scaledSize: new window.google.maps.Size(40, 40),
                    }}
                  />
                ))
              }
            </GoogleMap>
          </div>
        </div>
        <div className="w-1/2"></div>
      </div>

      <div className="w-full my-6">
        {/* Feature Your Properties section */}
        <div className="bg-white rounded-lg bord border-gray-600 pb-10 font-poppins">
          {/* Heading and Sentence */}
          <div className="text-center mb-6">
            <h1 className="mb-2">Feature Your Property</h1>
            <p className="px-[12%]">We will run your property ad for free on selected platforms. However, if you prefer to manage your campaign independently, feature your ad to monitor real-time performance metrics such as clicks, impressions, and conversions.</p>
          </div>

          {/* Plans */}
          <div className="flex flex-wrap gap-6">
            {plans.map((plan, index) => (
              <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-[0px_0px_15px_0px_#00000026] p-4 flex-1 min-w-[250px] grid grid-rows-[max-content]">
                {/* Price */}
                <div className="text-4xl font-bold mb-2 leading-9">${plan.amount} <span className="font-normal !text-lg"> / {capitalize(plan.plan_type)}</span></div>
                <p className="text-gray-600 mb-4">{plan.description}</p>

                {/* Features */}
                {
                  plan.sentences.length > 0 && (
                    <div className="mb-4">
                      {plan.sentences.map((feature, featureIndex) => (
                        <div key={featureIndex} className="flex items-center mb-2">
                          <img src={CircleCheckMark} alt="Check Mark Icon" className="text-gray-600 mr-2" />
                          <span>{feature}</span>
                        </div>
                      ))}
                    </div>
                  )
                }

                {/* Button */}
                <div className="text-center mt-auto">
                  <Button
                    variant="secondary"
                    className="font-poppins !rounded-full !bg-[#8E9C9C] px-5 py-2 text-sm mr-2 ml-2"
                    onClick={() => handleSelectedPlan(plan)}
                    disabled={!formik.values.property}
                  >
                    Choose plan
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {
          clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <StripePaymentModal selectedPlan={selectedPlan} />
            </Elements>
          )
        }
      </div>

      <div className="w-full text-end mb-10">
        <Button
          variant="secondary"
          className="font-poppins !rounded-full !bg-[#8E9C9C] px-5 py-2 text-xs mr-2 ml-2"
          onClick={(e) => handlePublishCampaign(e)}
        >
          Publish your campaign
        </Button>
      </div>
    </div>
  );
};

export default AdCreation;
