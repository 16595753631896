import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import authService from "../../redux/services/authService";
import AreaLogo from "../../features/PropertyDefaultWrapper/area-logo.svg";
import { useNavigate, Link } from "react-router-dom";
import { RegisterImage } from "src/assets/images/images";
import { RegisterSchema } from "../../utils/yup/schemas";
import AuthPageLayout from "../../components/common/AuthPageLayout";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";

const Register = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "employee",
  };

  const handleRegister = async (values, { setSubmitting }) => {
    try {
      await authService.register(
        values.first_name,
        values.last_name,
        values.username,
        values.email,
        values.password,
        values.role
      );
      showAllNotifications(
        "A token has been sent to your email!",
        ToastColors.success
      );
      navigate("/otp", { state: { email: values.email, type: "register" } });
    } catch (error) {
      if (error.response && error.response.data) {
        Object.entries(error.response.data).forEach(([key, value]) => {
          showAllNotifications(value, ToastColors.error);
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <div className="flex flex-col justify-center items-center bg-gray-50 w-full md:w-1/2 p-4 md:p-8 lg:p-12">
        <div className="w-full max-w-md mx-auto">
          <img src={AreaLogo} alt="Logo" className="mb-6 md:mb-8 lg:mb-10" />
          <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 mt-4 mb-4 md:mb-6 lg:mb-8 text-center">
            Signup
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={RegisterSchema}
            onSubmit={handleRegister}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-3 md:mb-4">
                  <label
                    className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2"
                    htmlFor="first_name"
                  >
                    First Name
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder="Enter First Name"
                  />
                  <ErrorMessage name="first_name" component="p" className="text-red-500 text-xs italic" />
                </div>
                <div className="mb-3 md:mb-4">
                  <label
                    className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2"
                    htmlFor="last_name"
                  >
                    Last Name
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="last_name"
                    name="last_name"
                    type="text"
                    placeholder="Enter Last Name"
                  />
                  <ErrorMessage name="last_name" component="p" className="text-red-500 text-xs italic" />
                </div>
                <div className="mb-3 md:mb-4">
                  <label
                    className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="username"
                    name="username"
                    type="text"
                    placeholder="Enter Username"
                  />
                </div>
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-500 text-xs mb-3"
                />
                <div className="mb-3 md:mb-4">
                  <label
                    className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter Email"
                  />
                </div>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-xs mb-3"
                />
                <div className="mb-3 md:mb-4 relative">
                  <label
                    className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2"
                    htmlFor="password"
                  >
                    Create Password
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter Password"
                  />
                  <span
                    className="absolute inset-y-0 top-7 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <svg
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d={
                          showPassword
                            ? "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            : "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        }
                      />
                    </svg>
                  </span>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-xs mb-3"
                />
                <div className="mb-3 md:mb-4 relative">
                  <label
                    className="block text-gray-700 text-xs md:text-sm font-bold mb-1 md:mb-2"
                    htmlFor="confirmPassword"
                  >
                    Re-enter Password
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-1 px-3 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Re-enter Password"
                  />
                  <span
                    className="absolute inset-y-0 top-6 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <svg
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d={
                          showConfirmPassword
                            ? "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            : "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        }
                      />
                    </svg>
                  </span>
                </div>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-500 text-xs mb-3"
                />
                <div className="mb-4 flex items-center">
                  <Field
                    type="checkbox"
                    id="rememberMe"
                    name="rememberMe"
                    className="mr-2 leading-tight"
                  />
                  <label
                    className="text-gray-700 text-xs md:text-sm"
                    htmlFor="rememberMe"
                  >
                    Remember Me
                  </label>
                </div>
                <Link
                  to="/login"
                  className="text-black hover:text-gray-900 no-underline"
                >
                  Already have an account?
                </Link>
                <div className="my-4 md:my-6">
                  <button
                    className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-1 px-3 md:py-2 md:px-4 rounded-full focus:outline-none focus:shadow-outline w-full text-xs md:text-sm flex items-center justify-center"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white mr-3"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Signup"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="flex items-center justify-center mb-4">
            <div className="border-t border-gray-300 flex-grow mr-2"></div>
            <span className="text-gray-500 text-xs md:text-sm">OR</span>
            <div className="border-t border-gray-300 flex-grow ml-2"></div>
          </div>
          <div className="flex items-center justify-center mb-4">
            <button className="border border-gray-300 text-gray-700 font-bold py-1 px-3 md:py-2 md:px-4 rounded-full flex items-center w-full justify-center mb-2 text-xs md:text-sm">
              <svg
                className="h-5 w-5 text-blue-600 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1.317c1.954 0 3.517 1.563 3.517 3.517 0 1.87-1.3 3.385-3.043 3.477v1.36h1.83l.364-1.09h1.089v1.09h1.36v-1.36h.725c.725 0 1.36.635 1.36 1.36v7.68c0 .725-.635 1.36-1.36 1.36H4.36C3.635 18 3 17.365 3 16.64V4.36c0-.725.635-1.36 1.36-1.36H10zm1.36 11.09h-.725v-1.09h.725v1.09zm-.364-2.181h-.725V9.908h.725v1.318zm-2.452 0h-.726V9.908h.726v1.318zm-1.088 0h-.726V9.908h.726v1.318zm2.453-3.269v1.319h-.725v-1.318h.725zm-2.452 0v1.319h-.726v-1.318h.726zm-1.088 0v1.319h-.726v-1.318h.726z"
                  clipRule="evenodd"
                />
              </svg>
              Signup with Facebook
            </button>
          </div>
          <div className="flex items-center justify-center mb-4">
            <button className="border border-gray-300 text-gray-700 font-bold py-1 px-3 md:py-2 md:px-4 rounded-full flex items-center w-full justify-center text-xs md:text-sm">
              <svg
                className="h-5 w-5 text-red-600 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1.317c1.954 0 3.517 1.563 3.517 3.517 0 1.87-1.3 3.385-3.043 3.477v1.36h1.83l.364-1.09h1.089v1.09h1.36v-1.36h.725c.725 0 1.36.635 1.36 1.36v7.68c0 .725-.635 1.36-1.36 1.36H4.36C3.635 18 3 17.365 3 16.64V4.36c0-.725.635-1.36 1.36-1.36H10zm1.36 11.09h-.725v-1.09h.725v1.09zm-.364-2.181h-.725V9.908h.725v1.318zm-2.452 0h-.726V9.908h.726v1.318zm-1.088 0h-.726V9.908h.726v1.318zm2.453-3.269v1.319h-.725v-1.318h.725zm-2.452 0v1.319h-.726v-1.318h.726zm-1.088 0v1.319h-.726v-1.318h.726z"
                  clipRule="evenodd"
                />
              </svg>
              Signup with Google
            </button>
          </div>
        </div>
        <div className="mt-4 text-center text-xs md:text-sm">
          <Link to="/terms-and-conditions" className="text-gray-500">
            Terms and conditions
          </Link>{" "}
          •{" "}
          <Link to="/" className="text-gray-500">
            Privacy policy
          </Link>
        </div>
      </div>
      <AuthPageLayout
        src={RegisterImage}
        alt={"Register Illustration"}
        imgClassName={"object-cover h-full w-full"}
        divClassName={
          "hidden md:flex w-1/2 justify-center items-center bg-gray-100"
        }
      />
    </div>
  );
};

export default Register;
