import api from "../../../api/api";

const getAdsLists = ( days ) => {
  return api.get(`custom-admin/area-studio/revenue-report/list/?days=${days}`)
};

const getAdsDetails = ( days ) => {
  return api.get(`custom-admin/area-studio/revenue-report/detail/?days=${days}`)
};

const adsRevenueService = {
  getAdsLists,
  getAdsDetails
};

export default adsRevenueService;