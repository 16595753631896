import React, { useState } from 'react';
import { CrossButton, EmptyImageIcon } from '../../assets/icons/icons';

const PropertyImages = ({ images }) => {
  const [showMoreImages, setShowMoreImages] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const initialImageCount = 4;
  const displayImages = showMoreImages ? images : images.slice(0, initialImageCount);
  const remainingImagesCount = images.length - initialImageCount;

  const handleShowMoreImages = () => {
    setShowMoreImages(!showMoreImages);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowMoreImages(false);
  };

  const renderImages = () => {
    switch (images.length) {
      case 0:
        return (
          <div className="w-full flex justify-center border-2 border-transparent p-2">
            <div className="w-full">
              <img
                src={EmptyImageIcon}
                alt="No property images"
                className="object-cover w-full h-full rounded border-2 border-transparent"
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="w-full flex justify-center border-2 border-transparent p-2">
            <div className="w-full">
              <img
                src={displayImages[0]}
                alt="property-image"
                className="object-cover w-full h-full rounded border-2 border-transparent"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="w-full grid grid-cols-2">
            <div className="flex items-stretch border-2 border-transparent p-1">
              <img
                src={displayImages[0]}
                alt="property-image"
                className="object-cover w-full h-full rounded border-2 border-transparent"
              />
            </div>
            <div className="flex items-stretch border-2 border-transparent p-1">
              <img
                src={displayImages[1]}
                alt="property-image"
                className="object-cover w-full h-full rounded border-2 border-transparent"
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="w-full flex h-50">
            <div className="w-1/2 flex items-stretch border-2 border-transparent p-1">
              <img
                src={displayImages[0]}
                alt="property-image"
                className="object-cover w-full h-full rounded border-transparent"
              />
            </div>
            <div className="w-1/2 flex flex-col">
              <div className="flex-1 flex items-stretch border-b-2 border-transparent p-1">
                <img
                  src={displayImages[1]}
                  alt="property-image"
                  className="object-cover w-full h-full rounded border-transparent"
                />
              </div>
              <div className="flex-1 flex items-stretch border-t-2 border-transparent p-1">
                <img
                  src={displayImages[2]}
                  alt="property-image"
                  className="object-cover w-full h-full rounded border-transparent"
                />
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="w-full grid grid-cols-2 relative">
            <div className="flex items-stretch border-transparent p-1">
              <img
                src={displayImages[0]}
                alt="property-image"
                className="object-cover w-full h-full rounded border-transparent"
              />
            </div>
            <div className="flex flex-col gap-2 h-full border-transparent p-1">
              <div className="flex-1 flex items-stretch border-transparent">
                <img
                  src={displayImages[1]}
                  alt="property-image"
                  className="object-cover w-full h-full rounded border-transparent"
                />
              </div>
              <div className="flex-1 grid grid-cols-2">
                <div className="flex items-stretch border-r-2 border-transparent">
                  <img
                    src={displayImages[2]}
                    alt="property-image"
                    className="object-cover w-full h-full rounded border-transparent"
                  />
                </div>
                <div className="relative flex items-stretch border-l-2 border-transparent">
                  <img
                    src={displayImages[3]}
                    alt="property-image"
                    className="object-cover w-full h-full rounded border-transparent"
                  />
                  {!showMoreImages && remainingImagesCount > 0 && (
                    <div
                      className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-white text-lg font-semibold rounded cursor-pointer"
                      onClick={handleShowMoreImages}
                    >
                      <span>Show all</span>
                      <span>{remainingImagesCount} Photos</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="w-full max-w-screen-xl mx-auto my-10 px-4 font-poppins">
      {renderImages()}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative w-full max-w-5xl bg-white rounded-lg shadow-lg h-[90%] overflow-hidden">
            {/* Header Section */}
            <div className="flex justify-between items-center p-4 border-b bg-white z-10">
              <h2 className="text-[18px] font-medium font-poppins">{`View All ${images.length} Photos`}</h2>
              <button onClick={handleCloseModal}>
                <img
                  src={CrossButton}
                  alt="Close"
                />
              </button>
            </div>
            {/* Scrollable Images Section */}
            <div className="p-4 overflow-y-auto h-[calc(100%-60px)]"> {/* Adjust height according to header height */}
              {images.map((image, index) => (
                <div key={index} className="w-full flex justify-center mb-4">
                  <img
                    src={image}
                    alt={`property-image-${index}`}
                    className="object-cover w-full h-auto rounded"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyImages;
