import React, { useEffect, useState } from 'react';

const TypingAnimation = ({ text }) => {
  const [animatedText, setAnimatedText] = useState(`${text} `);
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prevCount) => (prevCount % 3) + 1);
      setAnimatedText(`${text} ${'.'.repeat(dotCount)}`);
    }, 500);

    return () => clearInterval(interval);
  }, [text, dotCount]);

  return <span>{animatedText}</span>;
};

export default TypingAnimation;
