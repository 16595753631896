import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import propertyService from "../services/propertyService";
import { STATUSES } from "../../constants/statuses";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import authService from "../services/authService";
import { mapped_columns } from "../../constants/property/columns_mapping";

const LIMIT = 6;

export const addNewProperty = createAsyncThunk("property/addProperty", async (data, thunkAPI) => {
  try {
    const response = await propertyService.addProperty(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const myProperties = createAsyncThunk("property/myProperties", async (data, thunkAPI) => {
  try {
    const response = await propertyService.myProperties(data.limit, data.offset);
    return { data: response.data, offset: data.offset };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const uploadImages = createAsyncThunk("property/uploadImages", async (data, thunkAPI) => {
  try {
    const response = await authService.uploadPicture(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const uploadPDF = createAsyncThunk("property/uploadPDF", async (data, thunkAPI) => {
  try {
    const response = await propertyService.uploadPDF(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const favoriteProperty = createAsyncThunk("property/favoriteProperty", async (id, thunkAPI) => {
  try {
    const response = await propertyService.favoriteProperty(id);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchKeywordListing = createAsyncThunk("property/fetchKeywordListing", async (keyword, thunkAPI) => {
  try {
    const response = await propertyService.searchKeyword(keyword);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchProperty = createAsyncThunk("property/fetchProperty", async (id, thunkAPI) => {
  try {
    const response = await propertyService.fetchProperty(id);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getPropertiesLegalDetail = createAsyncThunk("property/getPropertiesLegalDetail", async (_data, thunkAPI) => {
  try {
    const response = await propertyService.getPropertiesLegalDetail();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const closeProperty = createAsyncThunk("property/closeProperty", async (id, thunkAPI) => {
  try {
    const response = await propertyService.closeProperty(id);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateProperty = createAsyncThunk("property/updateProperty", async ({ id, formData }, thunkAPI) => {
  try {
    const response = await propertyService.updateProperty(id, formData);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const savePropertyAsDraft = createAsyncThunk("property/savePropertyAsDraft", async (data, thunkAPI) => {
  try {
    const response = await propertyService.savePropertyAsDraft(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const saveAIGeneratedDraft = createAsyncThunk("property/saveAIGeneratedDraft", async (data, thunkAPI) => {
  try {
    const response = await propertyService.saveAIGeneratedDraft(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const fetchDraftProperty = createAsyncThunk("property/fetchDraftProperty", async (_, thunkAPI) => {
  try {
    const response = await propertyService.fetchDraftProperty();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const searchProperties = createAsyncThunk("property/searchProperties", async (data, thunkAPI) => {
  try {
    const response = await propertyService.searchProperties(data.filter, data.limit, data.offset);
    return { data: response.data, offset: data.offset };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const searchPropertyByName = createAsyncThunk("property/searchPropertyByName", async (name, thunkAPI) => {
  try {
    const response = await propertyService.searchPropertyByName(name);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getFeaturedProperties = createAsyncThunk("property/featuredProperties", async (selectedType, thunkAPI) => {
  try {
    const response = await propertyService.getFeaturedProperties(selectedType);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getMLSPropertyTypes = createAsyncThunk("property/MLSPropertyTypes", async (_, thunkAPI) => {
  try {
    const response = await propertyService.getMLSPropertyTypes();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchNeighborhoodCities = createAsyncThunk("property/NeighborhoodCities", async (_, thunkAPI) => {
  try {
    const response = await propertyService.fetchNeighborhoodCities();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getDashboardData = createAsyncThunk("property/dashboardData", async (_, thunkAPI) => {
  try {
    const response = await propertyService.getDashboardData();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const generateAIText = createAsyncThunk("property/generateAIText", async (data, thunkAPI) => {
  try {
    const response = await propertyService.generateAIText(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getPropertiesCount = createAsyncThunk("property/getPropertiesCount", async (_, thunkAPI) => {
  try {
    const response = await propertyService.getPropertiesCount();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const propertySlice = createSlice({
  name: "properties",
  initialState: {
    status: null,
    error: null,
    data: [],
    count: 0,
    loadMore: true,
    dashboardData: null,
    neighborhoodCities: {},
    filteredProperties: [],
    featuredProperties: [],
    MLSPropertyTypes: ["All"],
    filter: {
      lease_option: "",
      keyword: "",
      keyword_column: "",
      property_type: "",
      property_city: "",
    },
    keywordListing: [],
    property: null,
    properties: [],
    draft_property: null,
    total_properties: 0,
    loading: false,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setFilterValue: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      };
    },
    resetFilter: (state) => {
      state.filter = {
        lease_option: "",
        keyword: "",
        keyword_column: "",
        property_type: "",
        property_city: "",
      };
    },
    setLoadMore: (state, action) => {
      state.loadMore = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(addNewProperty.fulfilled, (state, action) => {
        state.data = [...state.data, action.payload];
        state.status = STATUSES.IDLE;
        state.loading = false;
        showAllNotifications("Property has been added successfully", ToastColors.success);
      })
      .addCase(addNewProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(uploadImages.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(uploadImages.fulfilled, (state) => {
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(uploadImages.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(uploadPDF.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(uploadPDF.fulfilled, (state) => {
        state.status = STATUSES.IDLE;
      })
      .addCase(uploadPDF.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(myProperties.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(myProperties.fulfilled, (state, action) => {
        if (action.payload.data.length < LIMIT) state.loadMore = false;
        state.data = action.payload.offset === 0 ? action.payload.data : [...state.data, ...action.payload.data];
        state.status = STATUSES.IDLE;
      })
      .addCase(myProperties.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(fetchProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(fetchProperty.fulfilled, (state, action) => {
        state.property = action.payload;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(fetchProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getPropertiesLegalDetail.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getPropertiesLegalDetail.fulfilled, (state, action) => {
        state.properties = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getPropertiesLegalDetail.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(closeProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(closeProperty.fulfilled, (state) => {
        state.status = STATUSES.IDLE;
        showAllNotifications("Property Ad has been closed successfully", ToastColors.success);
      })
      .addCase(closeProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(updateProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        state.property = action.payload
        showAllNotifications("Property has been updated successfully", ToastColors.success);
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(favoriteProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(favoriteProperty.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        showAllNotifications(action.payload, ToastColors.success);
      })
      .addCase(favoriteProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(fetchKeywordListing.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchKeywordListing.fulfilled, (state, action) => {
        const data = action.payload;
        const keywordListing = [];

        const slimItem = (item) => item.length > 70 ? `${item.slice(0, 70)}...` : item;

        for (const key in data) {
          if (Array.isArray(data[key])) {
            data[key].forEach((item) => {
              keywordListing.push({ value: item, text: slimItem(item), column: key, label: mapped_columns[key] });
            });
          }
        }

        state.keywordListing = keywordListing;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchKeywordListing.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(searchProperties.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(searchProperties.fulfilled, (state, action) => {
        state.count = action.payload.data.count;
        if (action.payload.offset === 0) {
          state.filteredProperties = action.payload.data.results;
        } else {
          state.filteredProperties = [...state.filteredProperties, ...action.payload.data.results];
        }
        state.status = STATUSES.IDLE;
      })
      .addCase(searchProperties.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(searchPropertyByName.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(searchPropertyByName.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(searchPropertyByName.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(getFeaturedProperties.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(getFeaturedProperties.fulfilled, (state, action) => {
        state.featuredProperties = action.payload;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(getFeaturedProperties.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getMLSPropertyTypes.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getMLSPropertyTypes.fulfilled, (state, action) => {
        state.MLSPropertyTypes = ["All", ...action.payload.MLSPropertyTypes];
        state.status = STATUSES.IDLE;
      })
      .addCase(getMLSPropertyTypes.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(fetchNeighborhoodCities.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(fetchNeighborhoodCities.fulfilled, (state, action) => {
        state.neighborhoodCities = action.payload;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(fetchNeighborhoodCities.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getDashboardData.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.dashboardData = action.payload;
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(savePropertyAsDraft.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(savePropertyAsDraft.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(savePropertyAsDraft.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchDraftProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchDraftProperty.fulfilled, (state, action) => {
        state.draft_property = action.payload
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchDraftProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(generateAIText.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
      })
      .addCase(generateAIText.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.loading = false;
      })
      .addCase(generateAIText.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(saveAIGeneratedDraft.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(saveAIGeneratedDraft.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.draft_property = action.payload.property;
      })
      .addCase(saveAIGeneratedDraft.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      })
      .addCase(getPropertiesCount.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getPropertiesCount.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.total_properties = action.payload.total_properties;
      })
      .addCase(getPropertiesCount.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
      });
  },
});

export const { clearError, setFilterValue, resetFilter, setLoadMore } = propertySlice.actions;

export default propertySlice.reducer;
