import { useEffect, useState } from "react";
import { BackgroundComingSoon, TitlePicture } from "../../assets/icons/icons";
import { useDispatch } from "react-redux";
import { sendContactUsMessage } from "../../redux/slices/contactSlice";
import Countdown from "./Countdown";
import Footer from "./Footer";
import './Comingsoon.css';

function ComingSoon({ targetDate }) {
  const dispatch = useDispatch();
  const initialForm = {
    email: "",
  }
  const [form, setForm] = useState(initialForm);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendContactUsMessage(form));
    setForm(initialForm);
    setFormSubmitted(true);
  };

  return (
    <div className="mt-3">
      <div className="flex justify-end pr-12 pt-12">
        <button className="border border-[#8E9C9C] text-[#8E9C9C] py-[12px] px-14 rounded-3xl font-[Outfit] font-medium">
          Contact Us
        </button>
      </div>
      <div className="flex flex-col mt-2">
        <div className="flex justify-center content-center">
          <img src={TitlePicture} alt="AreA Title" className=" w-52" />
        </div>
        <p className="flex justify-center content-center text-[#8E9C9C] mt-4 text-lg">
          The Website Is Under Construction
        </p>
      </div>

      <div className="flex flex-col justify-center content-center mt-4">
        <div className="flex justify-center" style={{ position: "relative" }}>
          <div style={{ position: "absolute", opacity: 0.2 }}>
            <Countdown targetDate={targetDate} />
          </div>
          <img src={BackgroundComingSoon} alt="Background" className=" h-96" />
        </div>
        <p className="text-center pt-8">
          We'll let you know when things are good to go
        </p>
        <div className="flex items-center bg-white justify-center mt-4 border-Sgreen rounded-full overflow-hidden">
          <form
            onSubmit={handleSubmit}
          >
            <div
              className="border border-[#8E9C9C]"
              style={{ borderRadius: "25px" }}
            >
              <input
                className="ml-4 focus:outline-none"
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={form.email}
                onChange={handleChange}
              />
              <button
                type="submit"
                className="bg-Sgreen text-white px-4 py-2 font-semibold rounded-r-full bg-[#8e9c9c] rounded-tl-[0px] rounded-br-[500px] rounded-bl-[0px] rounded-tr-[500px]"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
      {
        formSubmitted && (
          <p className="text-center mt-4 text-green-500">Thank you for your message!</p>
        )
      }
      <Footer />
    </div>
  );
}

export default ComingSoon;