import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../../redux/slices/authSlice";
import AreaLogo from '../../features/PropertyDefaultWrapper/area-logo.svg';
import { LoginImage } from 'src/assets/images/images';
import AuthPageLayout from "../../components/common/AuthPageLayout";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { LoginSchema } from "../../utils/yup/schemas";
import { Spinner } from "react-bootstrap";
import GoogleLoginButton from "./GoogleLoginButton";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) navigate('/');
  }, [isAuthenticated, error, navigate]);

  const handleLogin = (values, { setSubmitting }) => {
    dispatch(login({ username: values.username, password: values.password }));
    setSubmitting(false);
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <div className="flex flex-col justify-center items-center bg-gray-50 w-full md:w-1/2 p-6 md:p-12">
        <div className="w-full max-w-md mx-auto">
          <img src={AreaLogo} alt="Logo" className="mb-8 md:mb-10" />
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mt-5 mb-6 text-center">
            Log In
          </h2>
          <Formik
            initialValues={{ username: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={handleLogin}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="username"
                    name="username"
                    type="text"
                    placeholder="Enter Username"
                  />
                  <ErrorMessage name="username" component="p" className="text-red-500 text-xs italic" />
                </div>
                <div className="mb-6 relative">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <Field
                    className="shadow-sm border border-gray-300 rounded-full w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="password"
                    name="password"
                    type={values.showPassword ? "text" : "password"}
                    placeholder="Enter Password"
                  />
                  <span
                    className="absolute inset-y-0 top-7 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setFieldValue('showPassword', !values.showPassword)}
                  >
                    <svg
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d={values.showPassword ? "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" : "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"}
                      />
                    </svg>
                  </span>
                  <ErrorMessage name="password" component="p" className="text-red-500 text-xs italic" />
                </div>
                <div className="flex justify-between text-xs md:text-sm mb-4">
                  <Link to="/register" className="text-black">
                    Don't have an account?
                  </Link>
                  <Link to="/forgot-password" className="text-gray-600 hover:text-gray-900">
                    Forgot your password?
                  </Link>
                </div>
                <div className="my-6 md:my-8">
                  <button
                    className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline w-full"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="flex items-center justify-center mb-4">
            <div className="border-t border-gray-300 flex-grow mr-2"></div>
            <span className="text-gray-500 text-xs md:text-sm">OR</span>
            <div className="border-t border-gray-300 flex-grow ml-2"></div>
          </div>
          <div className="flex items-center justify-center mb-4">
            <button className="border border-gray-300 text-gray-700 font-bold py-2 px-4 rounded-full flex items-center w-full justify-center mb-2 text-xs md:text-sm">
              <svg
                className="h-5 w-5 text-blue-600 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1.317c1.954 0 3.517 1.563 3.517 3.517 0 1.87-1.3 3.385-3.043 3.477v1.36h1.83l.364-1.09h1.089v1.09h1.36v-1.36h.725c.725 0 1.36.635 1.36 1.36v7.68c0 .725-.635 1.36-1.36 1.36H4.36C3.635 18 3 17.365 3 16.64V4.36c0-.725.635-1.36 1.36-1.36H10zm1.36 11.09h-.725v-1.09h.725v1.09zm-.364-2.181h-.725V9.908h.725v1.318zm-2.452 0h-.726V9.908h.726v1.318zm-1.088 0h-.726V9.908h.726v1.318zm2.453-3.269v1.319h-.725v-1.318h.725zm-2.452 0v1.319h-.726v-1.318h.726zm-1.088 0v1.319h-.726v-1.318h.726z"
                  clipRule="evenodd"
                />
              </svg>
              Login with Facebook
            </button>
          </div>
          <div className="flex items-center justify-center mb-4">
            <GoogleLoginButton/>
          </div>
        </div>
        <div className="mt-4 text-center text-xs md:text-sm">
          <Link to="/terms-and-conditions" className="text-gray-500">
            Terms and conditions
          </Link>{" "}
          •{" "}
          <Link to="/terms-and-conditions#privacy-policy-section" className="text-gray-500">
            Privacy policy
          </Link>
        </div>
      </div>
      <AuthPageLayout src={LoginImage} alt={"Login Illustration"} imgClassName={"object-cover h-full w-full"} divClassName={'hidden md:flex w-1/2 justify-center items-center bg-gray-100'} />
    </div>
  );
};

export default Login;
