import {
  HouseIcon,
  ApartmentIcon,
  LowerPortionIcon,
  UpperPortionIcon,
  RoomIcon,
  FarmHouseIcon,
  GuestHouseIcon,
  PentHouseIcon,
  AnnexeIcon,
  HostelIcon,
  HostelSuitesIcon
} from "../../assets/icons/icons";

export const property_types = {
  residential: [
    { label: "House", icon: HouseIcon },
    { label: "Flat", icon: ApartmentIcon },
    { label: "Lower Portion", icon: LowerPortionIcon },
    { label: "Upper Portion", icon: UpperPortionIcon },
    { label: "Room", icon: RoomIcon },
    { label: "Farm House", icon: FarmHouseIcon },
    { label: "Guest House", icon: GuestHouseIcon },
    { label: "Pent House", icon: PentHouseIcon },
    { label: "Annexe", icon: AnnexeIcon },
    { label: "Hostel", icon: HostelIcon },
    { label: "Hostel Suites", icon: HostelSuitesIcon }
  ],
  plot: [
    { label: "Industrial Plot", icon: FarmHouseIcon },
    { label: "Agricultural Plot", icon: RoomIcon },
    { label: "Development Plot", icon: GuestHouseIcon }
  ],
  commercial: [
    { label: "Retail", icon: PentHouseIcon },
    { label: "Office", icon: AnnexeIcon },
    { label: "Industrial", icon: HostelIcon },
    { label: "Hospitality", icon: HostelSuitesIcon }
  ]
};
