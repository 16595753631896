import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../services/authService';
import { STATUSES } from '../../constants/statuses';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';
import { capitalize } from '../../utils/properties';

export const login = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
    try {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        const response = await authService.login(credentials.username, credentials.password);
        localStorage.setItem('access', response.data.access);
        localStorage.setItem('refresh', response.data.refresh);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const googleAuth = createAsyncThunk('auth/googleAuth', async (credentials, thunkAPI) => {
    try {
        const response = await authService.googleAuth(credentials);
        localStorage.setItem('access', response.data.access);
        localStorage.setItem('refresh', response.data.refresh);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const fetchProfile = createAsyncThunk('auth/fetchProfile', async (_, thunkAPI) => {
    try {
        const response = await authService.getProfile();
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const checkAuthToken = createAsyncThunk('auth/checkAuthToken', async (_, thunkAPI) => {
    const token = localStorage.getItem('access');
    if (token) {
        try {
            const response = await authService.getProfile();
            return response.data;
        } catch (error) {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return thunkAPI.rejectWithValue(error.response.data);
        }
    } else {
        return thunkAPI.rejectWithValue('No token found');
    }
});

export const updateProfile = createAsyncThunk('auth/updateProfile', async (updatedData, thunkAPI) => {
    try {
        const response = await authService.updateProfile(updatedData);
        return { ...response.data, field: capitalize(Object.keys(updatedData)[0].split('_').join(' ')) };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const updatePassword = createAsyncThunk('auth/updatePassword', async (updatedData, thunkAPI) => {
    try {
        const response = await authService.updatePassword(updatedData);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


export const uploadPicture = createAsyncThunk('auth/uploadPicture', async (data, thunkAPI) => {
    try {
        const response = await authService.uploadPicture(data);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const updateAgent = createAsyncThunk("auth/updateAgent", async ({ id, data }, thunkAPI) => {
    try {
        const response = await authService.updateAgent(id, data);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        isAuthenticated: false,
        status: STATUSES.IDLE,
        error: null,
        loading: false,
    },
    reducers: {
        logout: (state) => {
            state.user = null;
            state.isAuthenticated = false;
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
        },
        setUser: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = null;
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.user = action.payload.user;
                state.status = STATUSES.IDLE;
                state.error = null;
                state.loading = false;
            })
            .addCase(login.rejected, (state) => {
                state.status = STATUSES.ERROR;
                state.loading = false;
                state.error = 'Invalid / Insufficient credentials';
                showAllNotifications(state.error, ToastColors.error)
            })
            .addCase(googleAuth.pending, (state) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(googleAuth.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.user = action.payload.user;
                state.status = STATUSES.IDLE;
                state.error = null;
            })
            .addCase(googleAuth.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
                state.error = action.payload.errors
            })
            .addCase(fetchProfile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.loading = true;
            })
            .addCase(fetchProfile.fulfilled, (state, action) => {
                state.user = action.payload;
                state.status = STATUSES.IDLE;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchProfile.rejected, (state, action) => {
                state.status = STATUSES.ERROR
                state.error = action.payload;
                state.loading = false;
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
            })
            .addCase(checkAuthToken.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(checkAuthToken.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;
                state.status = STATUSES.IDLE;
                state.error = null;
            })
            .addCase(checkAuthToken.rejected, (state, action) => {
                state.user = null;
                state.isAuthenticated = false;
                state.status = STATUSES.ERROR;
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.status = STATUSES.IDLE;
                state.error = null;
                showAllNotifications(`${action.payload.field} updated successfully`, ToastColors.success);
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.status = STATUSES.ERROR
                state.error = action.payload;
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.status = STATUSES.IDLE;
                state.error = null;
                showAllNotifications('Password updated successfully', ToastColors.success);
            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.status = STATUSES.ERROR
                state.error = action.payload;
            })
            .addCase(uploadPicture.pending, (state) => {
                state.status = STATUSES.LOADING;
                state.loading = true;
            })
            .addCase(uploadPicture.fulfilled, (state, action) => {
                state.user.profile_picture = action.payload.url;
                state.status = STATUSES.IDLE;
                state.error = null;
                state.loading = false;
            })
            .addCase(uploadPicture.rejected, (state, action) => {
                state.status = STATUSES.ERROR
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateAgent.fulfilled, (state, action) => {
                state.user = action.payload;
                state.status = STATUSES.IDLE;
                state.error = null;
            })
            .addCase(updateAgent.rejected, (state, action) => {
                state.status = STATUSES.ERROR
                state.error = action.payload;
            });
    },
});

export const { logout, setUser } = authSlice.actions;

export default authSlice.reducer;
