import React from "react";

const SemiCircularProgress = ({ percentage }) => {
  const radius = 140; // Radius of the semicircle
  const stroke = 15; // Stroke width
  const normalizedRadius = radius - stroke / 2;
  const circumference = Math.PI * normalizedRadius; // Half circle circumference

  // Function to determine the color of each segment based on the range
  const getSegmentColor = (endPercent) => {
    if (endPercent <= 25) return "text-green-500"; // 0-25%: Green
    if (endPercent <= 50) return "text-yellow-500"; // 26-50%: Yellow
    if (endPercent <= 75) return "text-orange-500"; // 51-75%: Orange
    return "text-red-500"; // 76-100%: Red
  };

  // Function to calculate the strokeDashoffset for a segment
  const calculateOffset = (startPercent, endPercent) => {
    const segmentLength = ((endPercent - startPercent) / 100) * circumference;
    const strokeDashoffset = ((100 - (2 * startPercent - endPercent)) / 100) * circumference;
    return { segmentLength, strokeDashoffset };
  };

  // Segments based on percentage ranges
  const segments = [
    { startPercent: 0, endPercent: Math.min(25, percentage) },
    { startPercent: 25, endPercent: Math.min(50, percentage) },
    { startPercent: 50, endPercent: Math.min(75, percentage) },
    { startPercent: 75, endPercent: percentage }
  ];

  return (
    <div className="relative flex flex-col items-center justify-center">
      <svg
        width={radius * 2}
        height={radius + stroke * 2}
        className="overflow-visible"
      >
        {/* Background semicircle */}
        <path
          d={`M ${stroke / 2},${radius} A ${normalizedRadius},${normalizedRadius} 0 0 1 ${radius * 2 - stroke / 2},${radius}`}
          fill="transparent"
          stroke="#e5e7eb"
          strokeWidth={stroke}
        />

        {/* Colored segments */}
        {segments.map((segment, index) => {
          if (segment.startPercent < segment.endPercent) {
            const { segmentLength, strokeDashoffset } = calculateOffset(
              segment.startPercent,
              segment.endPercent
            );
            return (
              <path
                key={index}
                d={`M ${stroke / 2},${radius} A ${normalizedRadius},${normalizedRadius} 0 0 1 ${radius * 2 - stroke / 2},${radius}`}
                fill="transparent"
                stroke="currentColor"
                className={`${getSegmentColor(segment.endPercent)} transition-colors duration-500`}
                strokeWidth={stroke}
                strokeDasharray={`${segmentLength} ${circumference}`}
                strokeDashoffset={`${strokeDashoffset}`}
                strokeLinecap="round"
              />
            );
          }
          return null;
        })}

        {/* Tick marks and Labels */}
        {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((tick, index) => {
          const angle = (Math.PI * tick) / 100; // Convert percentage to radians
          const x1 =
            radius + (normalizedRadius - stroke / 2) * Math.cos(angle - Math.PI);
          const y1 =
            radius + (normalizedRadius - stroke / 2) * Math.sin(angle - Math.PI);
          const x2 =
            radius +
            (normalizedRadius - stroke / 2 - 5) * Math.cos(angle - Math.PI);
          const y2 =
            radius +
            (normalizedRadius - stroke / 2 - 5) * Math.sin(angle - Math.PI);

          // Labels
          const labelX =
            radius + (normalizedRadius + 15) * Math.cos(angle - Math.PI);
          const labelY =
            radius + (normalizedRadius + 15) * Math.sin(angle - Math.PI);

          return (
            <g key={index}>
              {/* Tick marks */}
              <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke="black"
                strokeWidth="1"
              />
              {/* Labels */}
              <text
                x={labelX}
                y={labelY}
                textAnchor="middle"
                alignmentBaseline="middle"
                className="text-xs fill-current text-gray-700"
              >
                {tick}
              </text>
            </g>
          );
        })}
      </svg>
      <div className="absolute top-16 text-center">
        <div className="text-3xl font-semibold">{percentage}%</div>
        <div className="text-sm text-[#9A9A9A]">Productivity</div>
      </div>
    </div>
  );
};

export default SemiCircularProgress;
