import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "src/components/Homepage/Searchbar";
import { FeaturedProperties } from "src/components/Homepage/FeaturedProperties";
import { Blogs } from "src/components/Homepage/Blogs";
import { Reviews } from "src/components/Homepage/Reviews";
import { HomepagePropertyImg } from "../../assets/images/images";
import {
  Home,
  Eclipse1,
  Eclipse2,
  Arrow,
} from 'src/assets/icons/icons';
import Neighborhoods from "../../components/Homepage/Neighborhoods";
import { Frame } from "../../components/Frame/Frame";
import { resetFilter } from "../../redux/slices/propertySlice";
import { openModal, setShowChooseAgentModal } from "../../redux/slices/mareaSlice";
import { Link, useNavigate } from "react-router-dom";

export const Homepage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const handleAIAgent = () => dispatch(user && !user?.selected_agent ? setShowChooseAgentModal(true) : openModal());

  useEffect(() => {
    dispatch(resetFilter());
  }, [dispatch]);

  return (
    <div className="bg-white flex flex-col items-center w-full">
      <div className="w-full bg-white overflow-hidden relative mt-[-96px]">
        <div className="relative w-full h-[684px] p-24 pt-[200px] bg-cover bg-center bg-[url(./screenshot-2024-06-11-at-3-18-1.png)] font-['Space_Grotesk']">
          <div className="flex flex-col items-center mt-8">
            <div className="w-[80%] text-[43px] font-extrabold leading-[76px] text-white uppercase text-center">
              EMBRACE THE FUTURE OF REAL ESTATE WITH AREA
            </div>
          </div>
          <SearchBar className="absolute left-0 right-0 bottom-10 h-[180px] rounded-t-[30px] flex" bgColor="bg-[#f9fcff]" />
        </div>
        <div className="w-full mt-16 flex justify-center">
          <Frame />
        </div>
        <div className="w-full max-w-screen-xl mx-auto flex flex-col items-center mt-8 md:mt-20">
          <div className="container mx-auto py-10 flex flex-col lg:flex-row items-center justify-between">
            <div className="text-center lg:text-left">
              <div className="flex items-center mb-4 justify-center lg:justify-start">
                <img src={Arrow} alt="Arrow" className="w-4 h-4 mr-2" />
                <span className="text-gray-800 text-lg ml-4 font-semibold tracking-tight">AREA AI</span>
              </div>
              <h1 className="text-7xl lg:text-5xl font-bold text-gray-900 mb-4 leading-tight tracking-tight font-['Space_Grotesk'] text-left">
                <span className="font-normal">We Assist Buyers <br /> In Finding Their</span> <br /> <span style={{ color: '#8E9C9C' }}>Dream Homes.</span>
              </h1>
              <div className="flex items-center justify-center lg:justify-start">
                <button
                  onClick={() => isAuthenticated ? handleAIAgent() : navigate("/login")}
                  className="bg-[#8E9C9C] text-white py-[11px] px-7 rounded-full font-medium"
                >
                  { isAuthenticated ? "Activate AI Agent" : "Get Started Free"}
                </button>
                <p className="text-md lg:text-lg font-light text-gray-600 ml-4 text-start font-[Jost]">Our AI will guide you through the entire <br /> buying process, from property</p>
              </div>
            </div>
            <div className="flex justify-center mt-8 lg:mt-0">
              <div className="relative">
                <img src={Home} alt="House" className="w-full h-auto max-w-md" />
                <div className="absolute inset-x-0 bottom-0 transform translate-y-16 flex justify-center">
                  <div className="flex items-center">
                    <img src={Eclipse1} alt="Thumbnail" className="w-12 h-12 rounded-full" />
                    <img src={Eclipse2} alt="Thumbnail" className="w-12 h-12 rounded-full -ml-5 z-10" />
                    <div className="w-12 h-12 rounded-full bg-gray-400 flex items-center justify-center text-white text-lg -ml-5 z-20">4+</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <FeaturedProperties />
        </div>

        <div className="flex flex-col items-center mt-16 px-4">
          <div className="w-full max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center justify-between mb-16">
            <div className="lg:w-1/2 flex justify-center lg:justify-end">
              <img src={HomepagePropertyImg} alt="HomepagePropertyImg" />
            </div>
            <div className="lg:w-1/2 lg:text-left mb-8 lg:mb-0">
              <h2 className="text-7xl text-gray-900 leading-tight tracking-tight font-['Space_Grotesk'] mb-4">
                Create Property Ads With Help of <span className="text-[#8E9C9C]">AI</span>
              </h2>
              <p className="text-lg text-gray-600 leading-relaxed mb-8">
                Unlock the full potential of your property listings with Area AI. Our advanced AI tools are designed to enhance your property details, creating dynamic and impactful ads that attract buyers and streamline the sale process. Experience a smoother, faster way to market your property.
              </p>
              <Link to={isAuthenticated ? "/properties/new" : "/login"} className="bg-[#8E9C9C] text-white py-3 px-6 rounded-full text-sm">Get Started Free</Link>
            </div>
          </div>
        </div>

        <Neighborhoods />
        <Reviews />
        <Blogs />
      </div>
    </div>
  );
};
