import {
  PropertyImage,
  UserImage,
} from "src/assets/icons/icons";
import { NeighborhoodImage1 } from 'src/assets/icons/icons';

export const neighborhoods = [
  { name: "Austin", properties: 2, image: NeighborhoodImage1 },
  { name: "California", properties: 7, image: NeighborhoodImage1 },
  { name: "Chicago", properties: 8, image: NeighborhoodImage1 },
  { name: "Dallas", properties: 9, image: NeighborhoodImage1 },
  { name: "Florida", properties: 2, image: NeighborhoodImage1 },
  { name: "Houston", properties: 1, image: NeighborhoodImage1 },
  { name: "New York", properties: 12, image: NeighborhoodImage1 },
  { name: "Los Angeles", properties: 10, image: NeighborhoodImage1 },
  { name: "San Francisco", properties: 5, image: NeighborhoodImage1 }
];

const getRandomImages = () => {
  const numImages = Math.floor(Math.random() * 10) + 1; // Random number between 1 and 10
  return Array.from({ length: numImages }, () => PropertyImage);
};

const getRandomFeatures = () => {
  const availableFeatures = [
    "Air conditioning",
    "Alarm system",
    "Balcony",
    "Broadband",
    "Built in robes",
    "Dishwasher",
    "Ensuite",
    "Fully fenced",
  ];
  const numFeatures = Math.floor(Math.random() * availableFeatures.length) + 1; // Random number of features
  return Array.from({ length: numFeatures }, () => {
    const randomIndex = Math.floor(Math.random() * availableFeatures.length);
    return availableFeatures.splice(randomIndex, 1)[0]; // Ensure unique features
  });
};

export const properties = [
  {
    id: 1,
    address: "195 Houston Avill",
    location: "430 Lamar Street, Houston, Texas",
    beds: 4,
    baths: 4,
    area: 900,
    image: PropertyImage,
    price: "146.000",
    pricePerSqFt: (146000 / 900).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "Alexia Putellas",
    timeAgo: "2 years ago",
    images: getRandomImages(),
    rooms: 6,
    yearBuilt: 2005,
    garages: 2,
    type: "House",
    status: "For Sale",
    landArea: 1200, // in sqft
    garageSize: 300, // in sqft
    features: getRandomFeatures(),
    postalCode: "77002", // Example postal code
    country: "USA",
  },
  {
    id: 2,
    address: "123 Maple Street",
    location: "123 Maple Street, Springfield, Illinois",
    beds: 3,
    baths: 2,
    area: 1200,
    image: PropertyImage,
    price: "180.000",
    pricePerSqFt: (180000 / 1200).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "John Doe",
    timeAgo: "1 year ago",
    images: getRandomImages(),
    rooms: 5,
    yearBuilt: 2010,
    garages: 1,
    type: "Villa",
    status: "For Rent",
    landArea: 1500, // in sqft
    garageSize: 250, // in sqft
    features: getRandomFeatures(),
    postalCode: "62704", // Example postal code
    country: "USA",
  },
  {
    id: 3,
    address: "789 Oak Drive",
    location: "789 Oak Drive, Austin, Texas",
    beds: 5,
    baths: 3,
    area: 1500,
    image: PropertyImage,
    price: "220.000",
    pricePerSqFt: (220000 / 1500).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "Jane Smith",
    timeAgo: "6 months ago",
    images: getRandomImages(),
    rooms: 7,
    yearBuilt: 2015,
    garages: 3,
    type: "House",
    status: "For Sale",
    landArea: 2000, // in sqft
    garageSize: 350, // in sqft
    features: getRandomFeatures(),
    postalCode: "73301", // Example postal code
    country: "USA",
  },
  {
    id: 4,
    address: "456 Pine Road",
    location: "456 Pine Road, Denver, Colorado",
    beds: 4,
    baths: 4,
    area: 2000,
    image: PropertyImage,
    price: "300.000",
    pricePerSqFt: (300000 / 2000).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "Michael Johnson",
    timeAgo: "3 years ago",
    images: getRandomImages(),
    rooms: 8,
    yearBuilt: 2000,
    garages: 2,
    type: "Villa",
    status: "For Sale",
    landArea: 2500, // in sqft
    garageSize: 400, // in sqft
    features: getRandomFeatures(),
    postalCode: "80201", // Example postal code
    country: "USA",
  },
  {
    id: 5,
    address: "101 Birch Lane",
    location: "101 Birch Lane, Miami, Florida",
    beds: 2,
    baths: 2,
    area: 800,
    image: PropertyImage,
    price: "120.000",
    pricePerSqFt: (120000 / 800).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "Emily Davis",
    timeAgo: "4 years ago",
    images: getRandomImages(),
    rooms: 4,
    yearBuilt: 2018,
    garages: 1,
    type: "House",
    status: "For Rent",
    landArea: 1000, // in sqft
    garageSize: 200, // in sqft
    features: getRandomFeatures(),
    postalCode: "33101", // Example postal code
    country: "USA",
  },
  {
    id: 6,
    address: "202 Cedar Street",
    location: "202 Cedar Street, Seattle, Washington",
    beds: 6,
    baths: 5,
    area: 2500,
    image: PropertyImage,
    price: "450.000",
    pricePerSqFt: (450000 / 2500).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "Chris Lee",
    timeAgo: "5 years ago",
    images: getRandomImages(),
    rooms: 9,
    yearBuilt: 1995,
    garages: 4,
    type: "House",
    status: "For Sale",
    landArea: 3000, // in sqft
    garageSize: 500, // in sqft,
    features: getRandomFeatures(),
    postalCode: "98101", // Example postal code
    country: "USA",
  },
  {
    id: 7,
    address: "303 Elm Street",
    location: "303 Elm Street, Boston, Massachusetts",
    beds: 4,
    baths: 3,
    area: 1300,
    image: PropertyImage,
    price: "250.000",
    pricePerSqFt: (250000 / 1300).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "Sarah Connor",
    timeAgo: "8 months ago",
    images: getRandomImages(),
    rooms: 5,
    yearBuilt: 2007,
    garages: 2,
    type: "Villa",
    status: "For Sale",
    landArea: 1600, // in sqft
    garageSize: 300, // in sqft,
    features: getRandomFeatures(),
    postalCode: "02108", // Example postal code
    country: "USA",
  },
  {
    id: 8,
    address: "404 Maple Lane",
    location: "404 Maple Lane, San Francisco, California",
    beds: 3,
    baths: 2,
    area: 1100,
    image: PropertyImage,
    price: "190.000",
    pricePerSqFt: (190000 / 1100).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "David Beckham",
    timeAgo: "2 years ago",
    images: getRandomImages(),
    rooms: 4,
    yearBuilt: 2012,
    garages: 1,
    type: "House",
    status: "For Rent",
    landArea: 1400, // in sqft
    garageSize: 250, // in sqft,
    features: getRandomFeatures(),
    postalCode: "94102", // Example postal code
    country: "USA",
  },
  {
    id: 9,
    address: "505 Pine Street",
    location: "505 Pine Street, Chicago, Illinois",
    beds: 5,
    baths: 4,
    area: 1800,
    image: PropertyImage,
    price: "320.000",
    pricePerSqFt: (320000 / 1800).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "Jessica Alba",
    timeAgo: "1 year ago",
    images: getRandomImages(),
    rooms: 7,
    yearBuilt: 2002,
    garages: 3,
    type: "Villa",
    status: "For Sale",
    landArea: 2200, // in sqft
    garageSize: 350, // in sqft,
    features: getRandomFeatures(),
    postalCode: "60601", // Example postal code
    country: "USA",
  },
  {
    id: 10,
    address: "606 Oak Avenue",
    location: "606 Oak Avenue, New York, New York",
    beds: 6,
    baths: 5,
    area: 2200,
    image: PropertyImage,
    price: "500.000",
    pricePerSqFt: (500000 / 2200).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "Tom Hanks",
    timeAgo: "3 years ago",
    images: getRandomImages(),
    rooms: 8,
    yearBuilt: 1998,
    garages: 4,
    type: "House",
    status: "For Rent",
    landArea: 2700, // in sqft
    garageSize: 400, // in sqft,
    features: getRandomFeatures(),
    postalCode: "10001", // Example postal code
    country: "USA",
  },
  {
    id: 11,
    address: "707 Cedar Lane",
    location: "707 Cedar Lane, Atlanta, Georgia",
    beds: 4,
    baths: 3,
    area: 1400,
    image: PropertyImage,
    price: "275.000",
    pricePerSqFt: (275000 / 1400).toFixed(2), // Calculation here
    userImage: UserImage,
    userName: "Natalie Portman",
    timeAgo: "6 months ago",
    images: getRandomImages(),
    rooms: 6,
    yearBuilt: 2009,
    garages: 2,
    type: "Villa",
    status: "For Sale",
    landArea: 1800, // in sqft
    garageSize: 300, // in sqft,
    features: getRandomFeatures(),
    postalCode: "30301", // Example postal code
    country: "USA",
  },
];

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
export const camalCase = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
